<div class="mat-elevation-z8 mb-5" *ngIf="dataSource">
  <table style="width: 100%" mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="ref_number">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"Ref number" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.ref_number}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="date_achieved">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"Date Achieved" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.date_achieved | date}}
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal()}}</b></td>
    </ng-container>

    <ng-container matColumnDef="date_cancelled">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Date Cancelled</th>
      <td mat-cell *matCellDef="let element">
        {{element.date_cancelled | date}}
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal()}}</b></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
