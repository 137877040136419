import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseIncome } from '../../../../../_base-shared/models/Case/CaseIncome';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseIncomeService extends MainBaseApiService {
  public index(caseId: number) {
    return this.http.get<LaravelResourceResponse<CaseIncome>>(this.apiUrl + '/cases/' + caseId + '/incomes')
        .pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, caseAssets) {
    return this.http.put<LaravelResourceResponse<CaseIncome>>(
        this.apiUrl + '/cases/' + caseId + '/incomes', caseAssets,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
