import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicUnsubscribeComponent } from './public-unsubscribe/public-unsubscribe.component';
import { MainArcopayWidgetHandlerComponent } from './main-arcopay-widget-handler/main-arcopay-widget-handler.component';

const routes: Routes = [
  {
    path:     'public',
    children: [
      {path: 'unsubscribe', component: PublicUnsubscribeComponent},
      {
        path:     'payments',
        children: [
          {path: 'arcopay', component: MainArcopayWidgetHandlerComponent}
        ]
      }
    ],
  },
  {path: 'opt-out', component: PublicUnsubscribeComponent}, // alias, to shorten SMS
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PublicRoutingModule {
}
