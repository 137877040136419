import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { observable, Observable, Subscription, window } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../models/Case/Case';

@Component({
  selector:    'app-base-client-installment-list',
  templateUrl: './base-client-installment-list.component.html',
  styleUrls:   []
})
export class BaseClientInstallmentListComponent implements OnInit, OnDestroy {
  public case: Case;
  public isLoadingDocumentPreview = false;
  public documentHtml: string;
  public caseUuid: any;
  public userUuid: any;
  public isLoading                = 0;
  protected subscriptions: Array<Subscription> = [];

  constructor (
    protected route: ActivatedRoute,
    protected fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit (): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  protected getClientInstallmentListPreview (observable: Observable<any>): void {
   this.subscriptions.push(
     observable.pipe(finalize(() => {
       this.isLoadingDocumentPreview = false;
     })).subscribe(
       result => {
         this.documentHtml = result.data;
       },
     )
   );
  }
}
