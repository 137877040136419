import { Injectable } from '@angular/core';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationTriggerService extends MainBaseApiService {

    index(data, relations: Array<string> = []): Observable<LaravelResourceResponse> {
      const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
      return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/notification-triggers`, { params })
        .pipe(catchError(response => this.handleError(response)));
    }

    get(triggerId: number): Observable<LaravelResourceResponse> {
        return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`)
            .pipe(catchError(response => this.handleError(response)));
    }

    store(data): Observable<LaravelResourceResponse> {
        return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/notification-triggers`, data)
            .pipe(catchError(response => this.handleError(response)));
    }

    update(triggerId, data): Observable<LaravelResourceResponse> {
        return this.http.put<LaravelResourceResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`, data)
            .pipe(catchError(response => this.handleError(response)));
    }

    delete(triggerId: number): Observable<LaravelResourceResponse> {
        return this.http.delete<LaravelResourceResponse>(`${this.apiUrl}/notification-triggers/${triggerId}`)
            .pipe(catchError(response => this.handleError(response)));
    }
}
