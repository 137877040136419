<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h5>{{'CASES.case-snapshot.incomes' | translate}}</h5>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0">
      <div>
          <table class="table table-striped">
            <tbody>
            <tr>
              <th scope="col">   {{"CASES.single.alimony" | translate }}</th>
              <td>{{incomes?.alimony  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.other-pensions" | translate }}</th>
              <td>{{incomes?.pension_other  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">   {{"CASES.single.state-pensions" | translate }}</th>
              <td>{{incomes?.pension_state  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">   {{"CASES.single.wage-salary" | translate }}</th>
              <td>{{incomes?.salary_client  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">   {{"CASES.single.pension-credit" | translate }}</th>
              <td>{{incomes?.pension_credit  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col"> {{"CASES.single.partner-salary" | translate }}</th>
              <td>{{incomes?.salary_partner  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.private-pensions" | translate }}</th>
              <td>{{incomes?.pension_private  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col"> {{"CASES.single.subsidy-benefits" | translate }}</th>
              <td>{{incomes?.subsidy_benefit  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col"> {{"CASES.single.compensatory-pension" | translate }}</th>
              <td>{{incomes?.compensatory_pension  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.unemployment-benefit" | translate }}</th>
              <td>{{incomes?.unemployment_benefit  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.boarders" | translate }}</th>
              <td>{{incomes?.other_boarders_or_lodgers  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.student-loans-grants" | translate }}</th>
              <td>{{incomes?.other_student_loans_and_grants  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{"CASES.single.non-dependant" | translate }}</th>
              <td>{{incomes?.other_non_dependent_contributions  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="col">  {{'CLIENT-PORTAL.validator-form.special_incomes' | translate}}</th>
              <td *ngFor="let specialIncome of incomes?.special_incomes; let i = index">{{specialIncome.value  | currency: 'EUR'}}</td>
            </tr>
            </tbody>
          </table>
  </div>
</div>
</div>
