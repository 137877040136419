import { Component, Input } from '@angular/core';
import { UnnaxOrder } from '../../../../../../../../../_base-shared/models/Payment/Transaction/UnnaxOrder';

@Component({
  selector: 'app-unax-details',
  templateUrl: './unax-details.component.html',
  styleUrls: ['./unax-details.component.scss']
})
export class UnaxDetailsComponent {
  @Input() unnaxOrder: UnnaxOrder;
}
