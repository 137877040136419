<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h5>{{'CASES.case-snapshot.general.heading' | translate}}</h5>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0;padding-bottom: 0px;">
      <div class="row h-100">
        <ng-container>
          <div class="col" style="border-right: 1px solid #e9e9e9;">
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.model.ref_number' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.ref_number}}</p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.details.status' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.status}}</p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.single.general.status_editor.call_status' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.call_status}}
                </p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.single.general.status_editor.payment_status' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.payment_status}}
                </p>
              </div>
            </div>
          </div>
          <div class="col" style="border-right: 1px solid #e9e9e9;">
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.single.general.status_editor.packager_status' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.packager_status}}
                </p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.model.joint_application' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{(generalSnapshot?.joint_application === true ?
                  'SHARED.yes' :
                  'SHARED.no') | translate}}
                </p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.model.distribution_nonviable' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{(generalSnapshot?.distribution_nonviable === true ?
                  'SHARED.yes' :
                  'SHARED.no') | translate}}
                </p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.case-snapshot.general.require_lso_qualifiers' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{(generalSnapshot?.require_lso_qualifiers === true ?
                  'SHARED.yes' :
                  'SHARED.no') |translate}}
                </p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'PRODUCT.model_name.singular' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.product}}</p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.single.amount-paid' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.amount_paid}}
                </p>
              </div>
            </div>
            <div class="row align-items-baseline">
              <mat-label class="col-6">
                {{'CASES.case-snapshot.general.amount_unpaid' | translate}}
              </mat-label>
              <div class="col-6">
                <p class="text-end"
                   style="padding-top: 7px;">{{generalSnapshot?.amount_unpaid}}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
  </div>
</div>
