<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h5 class="pb-3">
          <span *ngIf="editorType === 'edit'">{{ 'LEGAL_ENTITY.town_hall.editor.heading_edit' | translate }}</span>
          <span *ngIf="editorType === 'create'">{{ 'LEGAL_ENTITY.town_hall.editor.heading_create' | translate }}</span>
        </h5>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
        <div class="row">
          <div class="col">
            <h5 class="ps-1 pb-3">{{ 'LEGAL_ENTITY.town_hall.editor.form.details_section' | translate }}</h5>
            <!-- Name -->
            <app-input formControlName="name" [label]="'SHARED.model.name' | translate" [fullWidth]="true"></app-input>
            <!-- Email -->
            <app-input type="email" formControlName="email" [label]="'SHARED.model.email' | translate"
                       [fullWidth]="true"></app-input>
            <!-- Phone -->
            <app-input type="number" formControlName="phone" [label]="'SHARED.model.phone' | translate"
                       [fullWidth]="true"></app-input>
          </div>
          <div class="col" formGroupName="address">
            <h5 class="ps-1 pb-3">{{ 'LEGAL_ENTITY.town_hall.editor.form.address_section' | translate }}</h5>
            <!-- Country -->
            <app-input formControlName="country" [label]="'ADDRESS.country' | translate" [fullWidth]="true"
                       readonly>
            </app-input>
            <!-- County -->
            <app-input formControlName="county" [label]="'ADDRESS.county' | translate" [fullWidth]="true">
            </app-input>
            <!-- Region -->
            <app-input formControlName="region" [label]="'ADDRESS.region' | translate" [fullWidth]="true">
            </app-input>
            <!-- City -->
            <app-input formControlName="city" [label]="'ADDRESS.city' | translate" [fullWidth]="true">
            </app-input>
            <!-- Municipality -->
            <app-input formControlName="municipality" [label]="'ADDRESS.municipality' | translate" [fullWidth]="true">
            </app-input>
            <!-- Postcode -->
            <app-input [type]="'number'" formControlName="postcode" [label]="'ADDRESS.postcode' | translate"
                       [fullWidth]="true">
            </app-input>
            <!-- Address 1 -->
            <app-input formControlName="address_1" [label]="'ADDRESS.address_1' | translate" [fullWidth]="true">
            </app-input>
            <!-- Address 2 -->
            <app-input formControlName="address_2" [label]="'ADDRESS.address_2' | translate" [fullWidth]="true">
            </app-input>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <app-spinner-btn type="submit" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
          </app-spinner-btn>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
  </mat-card>
</div>
