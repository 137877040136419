import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, filter, finalize, map } from 'rxjs/operators';
import { AppAlert } from '../../../../../../../_base-shared/contracts/app-alert.interface';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case, CasePrefillData } from '../../../../../../../_base-shared/models/Case/Case';
import { City } from '../../../../../../../_base-shared/models/Location/City';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { mustNotMatchEmail } from '../../../../../../../_base-shared/validators/custom.validators';
import { UserService } from '../../../user/user.service';
import { CaseService } from '../../case.service';
import { ProductService } from '../../product.service';

@Component({
  selector:    'app-case-general-editor',
  templateUrl: './case-general-editor.component.html',
  styles:      [
    `
      mat-error {
        padding-left: 10px;
        font-size: 10px;
      }
    `],
})
export class CaseGeneralEditorComponent implements OnInit, OnDestroy {
  public editorType: 'edit' | 'create';
  public case: Case;
  public isLoading                    = 0;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public localAlerts: Array<AppAlert> = [];
  public isSubmitting: boolean;
  public myControl                    = new UntypedFormControl('');

  public employmentOptions: Array<AppSelectOption>     = [];
  public economicRegimeOptions: Array<AppSelectOption> = [];
  public maritalStatusOptions: Array<AppSelectOption>  = [];
  public relationToApplicant: Array<AppSelectOption>   = [];
  public homeOwnerOptions: Array<AppSelectOption>      = [];
  public debtAwareOptions: Array<AppSelectOption>      = [];
  public vulnerabilityOptions: Array<AppSelectOption>  = [];
  public insolvencyOptions: Array<any>                 = [];

  public isLoadingDuplicates = 0;
  public isLoadingCases      = 0;

  public clientContainerClass = 'col-12';

  //  Linked cases dropdown data
  public cases: Array<Case>                      = [];
  public caseMultiFilterCtrl: UntypedFormControl = new UntypedFormControl('');
  public filteredCases: ReplaySubject<Case[]>    = new ReplaySubject<Case[]>(1);
  // End Linked cases
  private validateClientEmailTimeout: any;
  private validatePartnerEmailTimeout: any;
  private subscriptions: Array<Subscription>     = [];
  private products: Array<Product>               = [];
  private defaultProduct: Product;
  private prefillData: CasePrefillData           = new CasePrefillData();

  public filteredOptionsClient: Observable<City[]>  = new Observable<Array<City>>();
  public filteredOptionsPartner: Observable<City[]> = new Observable<Array<City>>();

  public currentLanguage: 'en' | 'es';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private userService: UserService,
    private productService: ProductService,
    private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    const state      = this.location.getState() as any;
    this.prefillData = state.hasOwnProperty('prefillData') ? state.prefillData : new CasePrefillData();
    this.editorType  = this.route.snapshot.data.editorType;
    this.fetchProducts();
    this.getInsolvencyOptions();
    this.setDropdownLabels();
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.translate.onLangChange.subscribe((next) => {
      this.setDropdownLabels();
      this.currentLanguage = next.lang === 'en' ? 'en' : 'es';
    });

    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      if (this.editorType === 'edit') {
        this.fetchCase(caseId);
      } else {
        this.fetchDefaultProduct();
        console.log('fetching product');
      }
    });

    this.filteredCases.next(this.cases.slice());
    this.caseMultiFilterCtrl.valueChanges.pipe(filter(val => val.length > 5))
      .subscribe(() => this.fetchCases());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const observable = this.editorType === 'edit' ?
      this.caseService.update(this.case.id, form.value) :
      this.caseService.store(form.value);

    this.isSubmitting = true;
    observable.pipe(finalize(() => this.isSubmitting = false)).subscribe(
      res => {
        this.form.markAsPristine();
        this.case             = res.data;
        const responseMessage = this.editorType === 'create' ?
          this.translate.instant('CASES.editor.general.result.store.success') :
          this.translate.instant('CASES.editor.general.result.update.success');
        this.toastr.success(responseMessage, this.translate.instant('SHARED.success'));
        if (this.editorType === 'create') {
          this.router.navigate(['/cases', this.case.id, 'edit', 'general']);
        }
      },
      error => {
        this.serverResponse   = error.error;
        const responseMessage = this.editorType === 'create' ?
          this.translate.instant('CASES.editor.general.result.store.error') :
          this.translate.instant('CASES.editor.general.result.update.error');
        this.toastr.error(error.error?.message, responseMessage);
      });
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  private buildForm() {
    this.form = this.fb.group({
      case:    this.fb.group({
        lead:                   this.fb.group({
          affiliate_id: [
            this.case.lead?.affiliate_id ? this.case?.lead?.affiliate_id : this.prefillData?.affiliate_id,
          ],
        }),
        product_id:             [this.case.product_id],
        linked_cases_ids:       [this.case.linked_cases_ids || []],
        joint_application:      [this.case.joint_application || false, Validators.required],
        department_assignments: this.fb.group({
          legal_advisor_id: [
            {value: this.prefillData?.legal_advisor_id, disabled: !this.prefillData?.legal_advisor_id},
          ],
          verifier_id:      [
            {value: this.prefillData?.verifier_id, disabled: !this.prefillData?.verifier_id},
          ],
        }),
      }),
      client:  this.fb.group({
        criminal_record:              [
          this.case?.client?.criminal_record,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        spanish_resident:             [
          this.case?.client?.spanish_resident,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        lso_in_five_years:            [
          this.case?.client?.lso_in_five_years,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        derivation_of_responsibility: [
          this.case?.client?.derivation_of_responsibility,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        more_than_one_creditor:       [
          this.case?.client?.more_than_one_creditor,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        public_sanctions:             [
          this.case?.client?.public_sanctions,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        more_than_eight_k_debt:             [
          this.case?.client?.more_than_eight_k_debt,
          this.case?.require_lso_qualifiers ? [Validators.required] : []],
        first_name:                   [this.case?.client?.first_name, Validators.required],
        last_name:                    [this.case?.client?.last_name, Validators.required],
        company_name:                 [this.case?.client?.company_name],
        date_of_birth:                [this.case?.client?.date_of_birth],
        id_card:                      [{value: this.case?.client?.id_card, disabled: false}],
        email:                        [
          this.case?.client?.email,
          [Validators.required, Validators.email],
          this.validateClientEmail.bind(this),
        ],
        phone_country_code:           ['34', [Validators.required]],
        phone:                        [
          this.case?.client?.phone, [
            Validators.required,
            Validators.maxLength(9),
            Validators.minLength(9),
            Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        home_phone:                   [
          this.case?.client?.home_phone, [
            Validators.maxLength(9), Validators.minLength(9), Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        work_phone:                   [
          this.case?.client?.work_phone, [
            Validators.maxLength(9), Validators.minLength(9), Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        nationality:                  [this.case?.client?.nationality],
        home_owner:                   [this.case?.client?.home_owner],
        employment:                   [this.case?.client?.employment],
        economic_regime:              [this.case?.client?.economic_regime],
        marital_status:               [this.case?.client?.marital_status],
        married_at:                   [this.case?.client?.married_at],
        marriage_city:                [this.case?.client?.marriage_city],
        debt_aware:                   [{value: true, disabled: true}],
        vulnerability:                [this.case?.client?.vulnerability],
        insolvency_causes:            [this.case?.client?.insolvency_causes],
        number_of_dependent:          [this.case?.client?.number_of_dependent],
        address:                      this.fb.group({
          country:      [this.case?.client?.address?.country],
          county:       [this.case?.client?.address?.county],
          region:       [this.case?.client?.address?.region],
          city:         [this.case?.client?.address?.city],
          municipality: [this.case?.client?.address?.municipality],
          postcode:     [this.case?.client?.address?.postcode, [Validators.min(3)]],
          address_1:    [this.case?.client?.address?.address_1],
          address_2:    [this.case?.client?.address?.address_2],
        }),
        time_to_contact:              [this.case.client?.time_to_contact],
        allow_email:                  [this.case.client?.allow_email || false],
        allow_call:                   [this.case.client?.allow_call || false],
        allow_sms:                    [this.case.client?.allow_sms || false],
        allow_post:                   [this.case.client?.allow_post || false],
        allow_marketing:              [this.editorType === 'create' ? true : this.case.client?.allow_marketing],
      }),
      partner: this.fb.group({
        criminal_record:              [
          this.case?.partner?.criminal_record,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        spanish_resident:             [
          this.case?.partner?.spanish_resident,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        lso_in_five_years:            [
          this.case?.partner?.lso_in_five_years,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        derivation_of_responsibility: [
          this.case?.partner?.derivation_of_responsibility,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        more_than_one_creditor:       [
          this.case?.partner?.more_than_one_creditor,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : [],
        ],
        public_sanctions:             [
          this.case?.partner?.public_sanctions,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        more_than_eight_k_debt:             [
          this.case?.partner?.more_than_eight_k_debt,
          this.case?.joint_application && this.case?.require_lso_qualifiers ? [Validators.required] : []],
        first_name:                   [
          this.case?.partner?.first_name,
          this.case?.joint_application ? [Validators.required] : [],
        ],
        last_name:                    [
          this.case?.partner?.last_name,
          this.case?.joint_application ? [Validators.required] : [],
        ],
        company_name:                 [this.case?.client?.company_name],
        date_of_birth:                [this.case?.partner?.date_of_birth],
        id_card:                      [{value: this.case?.partner?.id_card, disabled: false}],
        email:                        [
          this.case?.partner?.email,
          this.case?.joint_application ? [Validators.required, Validators.email] : [],
          this.case?.joint_application ? this.validatePartnerEmail.bind(this) : '',
        ],
        phone_country_code:           ['34'],
        phone:                        [
          this.case?.partner?.phone, [
            Validators.maxLength(9), Validators.minLength(9), Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        home_phone:                   [
          this.case?.partner?.home_phone, [
            Validators.maxLength(9), Validators.minLength(9), Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        work_phone:                   [
          this.case?.partner?.work_phone, [
            Validators.maxLength(9), Validators.minLength(9), Validators.pattern('(6|7|9)([0-9])\\w+'),
          ],
        ],
        nationality:                  [this.case?.partner?.nationality],
        home_owner:                   [this.case?.partner?.home_owner],
        employment:                   [this.case?.partner?.employment],
        economic_regime:              [this.case?.partner?.economic_regime],
        marital_status:               [this.case?.partner?.marital_status],
        married_at:                   [this.case?.partner?.married_at],
        marriage_city:                [this.case?.partner?.marriage_city],
        debt_aware:                   [this.case?.partner?.debt_aware || false],
        vulnerability:                [this.case?.partner?.vulnerability],
        insolvency_causes:            [this.case?.partner?.insolvency_causes],
        relation_to_applicant:        [
          this.case?.partner?.relation_to_applicant,
          this.case?.joint_application ? [Validators.required] : [],
        ],
        address:                      this.fb.group({
          country:      [this.case?.partner?.address?.country],
          region:       [this.case?.partner?.address?.region],
          county:       [this.case?.partner?.address?.county],
          municipality: [this.case?.partner?.address?.municipality],
          city:         [this.case?.partner?.address?.city],
          postcode:     [this.case?.partner?.address?.postcode, [Validators.min(3)]],
          address_1:    [this.case?.partner?.address?.address_1],
          address_2:    [this.case?.partner?.address?.address_2],
        }),
        time_to_contact:              [this.case.partner?.time_to_contact],
        allow_email:                  [this.case.partner?.allow_email || false],
        allow_call:                   [this.case.partner?.allow_call || false],
        allow_sms:                    [this.case.partner?.allow_sms || false],
        allow_post:                   [this.case.partner?.allow_post || false],
        allow_marketing:              [this.case.partner?.allow_marketing || true],
      }),
    });
    this.form.get('case.joint_application').valueChanges.subscribe(isJointApplication => {
      if (isJointApplication) {
        const clientGroup  = this.form.get('client') as FormGroup;
        const partnerGroup = this.form.get('partner') as FormGroup;
        this.form.get('partner.relation_to_applicant').setValidators([Validators.required]);
        this.form.get('partner.first_name').setValidators([Validators.required]);
        this.form.get('partner.last_name').setValidators([Validators.required]);
        this.form.get('partner.date_of_birth').setValidators([]);
        this.form.get('partner.email')
          .setValidators([
            mustNotMatchEmail('email', 'email', partnerGroup, clientGroup),
            Validators.required,
            Validators.email]);
        if (this.case.require_lso_qualifiers) {
          this.form.get('partner.criminal_record').setValidators([Validators.required]);
          this.form.get('partner.spanish_resident').setValidators([Validators.required]);
          this.form.get('partner.lso_in_five_years').setValidators([Validators.required]);
          this.form.get('partner.derivation_of_responsibility').setValidators([Validators.required]);
          this.form.get('partner.more_than_one_creditor').setValidators([Validators.required]);
          this.form.get('partner.public_sanctions').setValidators([Validators.required]);
        }
      } else {
        this.form.get('partner.relation_to_applicant').setValidators([]);
        this.form.get('partner.first_name').setValidators([]);
        this.form.get('partner.last_name').setValidators([]);
        this.form.get('partner.date_of_birth').setValidators([]);
        this.form.get('partner.email').setValidators([]);
        if (this.case.require_lso_qualifiers) {
          this.form.get('partner.criminal_record').setValidators([]);
          this.form.get('partner.spanish_resident').setValidators([]);
          this.form.get('partner.lso_in_five_years').setValidators([]);
          this.form.get('partner.derivation_of_responsibility').setValidators([]);
          this.form.get('partner.more_than_one_creditor').setValidators([]);
          this.form.get('partner.public_sanctions').setValidators([]);
        }
      }
      this.form.get('partner.relation_to_applicant').updateValueAndValidity();
      this.form.get('partner.first_name').updateValueAndValidity();
      this.form.get('partner.last_name').updateValueAndValidity();
      this.form.get('partner.date_of_birth').updateValueAndValidity();
      this.form.get('partner.email').updateValueAndValidity();
      if (this.case.require_lso_qualifiers) {
        this.form.get('partner.criminal_record').updateValueAndValidity();
        this.form.get('partner.spanish_resident').updateValueAndValidity();
        this.form.get('partner.lso_in_five_years').updateValueAndValidity();
        this.form.get('partner.derivation_of_responsibility').updateValueAndValidity();
        this.form.get('partner.more_than_one_creditor').updateValueAndValidity();
        this.form.get('partner.public_sanctions').updateValueAndValidity();
      }
      // this.rebuildPaymentPlanForm(this.case, isJointApplication, this.form.get('case.product_id').value);
    });
    if (this.form.get('case.linked_cases_ids').value.length) {
      this.caseService.index({'case_ids[]': this.form.get('case.linked_cases_ids').value, select_all: 0})
        .subscribe(next => {
          this.cases = next.data;
          this.filteredCases.next(this.cases);
        });
    }
  }

  public setGDPR(formControl, event) {
    this.form.get(formControl).patchValue(!this.form.get(formControl).value);
  }

  public triggerEvent($event) {
    //  When dropdown is closed and there is selected refs
    //  This is needed to populate dropdown only with cases with selected refs
    //  That way dropdown value is preserved
    if (!$event && this.form.get('case.linked_cases_ids').value.length) {
      this.caseService.index({'case_ids[]': this.form.get('case.linked_cases_ids').value, select_all: 0})
        .subscribe(next => {
          this.cases = next.data;
          this.filteredCases.next(this.cases);
        });
    }
  }

  private fetchCases() {
    if (!this.cases) {
      return;
    }
    let search = this.caseMultiFilterCtrl.value;
    if (!search) {
      this.filteredCases.next(this.cases.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.isLoadingCases++;
    this.caseService.index({ref_number: search, select_all: 1}).pipe(finalize(() => this.isLoadingCases--))
      .subscribe(next => {
        this.cases = next.data;
        this.filteredCases.next(this.cases);
      });

  }

  private setDropdownLabels() {
    this.employmentOptions     = [
      {value: 'employed', label: this.translate.instant('USERS.model.employment.options.employed')},
      {value: 'part-time', label: this.translate.instant('USERS.model.employment.options.part-time')},
      {value: 'self-employed', label: this.translate.instant('USERS.model.employment.options.self-employed')},
      {
        value: 'unemployed-without-benefits',
        label: this.translate.instant('USERS.model.employment.options.unemployed-without-benefits'),
      },
      {
        value: 'unemployed-with-benefits',
        label: this.translate.instant('USERS.model.employment.options.unemployed-with-benefits'),
      },
      {
        value: 'government-worker',
        label: this.translate.instant('USERS.model.employment.options.government-worker'),
      },
      {value: 'armed-forces', label: this.translate.instant('USERS.model.employment.options.armed-forces')},
      {value: 'student', label: this.translate.instant('USERS.model.employment.options.student')},
      {value: 'housewife', label: this.translate.instant('USERS.model.employment.options.housewife')},
      {value: 'retired', label: this.translate.instant('USERS.model.employment.options.retired')},
      {value: 'pensioner', label: this.translate.instant('USERS.model.employment.options.pensioner')},
      {value: 'unknown', label: this.translate.instant('USERS.model.employment.options.unknown')},
    ];
    this.maritalStatusOptions  = [
      {value: 'single', label: this.translate.instant('USERS.model.marital_status.options.single')},
      {value: 'married', label: this.translate.instant('USERS.model.marital_status.options.married')},
      {value: 'widow', label: this.translate.instant('USERS.model.marital_status.options.widow')},
      {value: 'separated', label: this.translate.instant('USERS.model.marital_status.options.separated')},
      {value: 'divorced', label: this.translate.instant('USERS.model.marital_status.options.divorced')},
      {
        value: 'domestic-partner',
        label: this.translate.instant('USERS.model.marital_status.options.domestic-partner'),
      },
      {value: 'other', label: this.translate.instant('USERS.model.marital_status.options.other')},
    ];
    this.relationToApplicant   = [
      {value: 'spouse', label: this.translate.instant('USERS.model.relation_to_applicant.spouse')},
      {value: 'sibling', label: this.translate.instant('USERS.model.relation_to_applicant.sibling')},
      {value: 'causing', label: this.translate.instant('USERS.model.relation_to_applicant.causing')},
      {value: 'parent', label: this.translate.instant('USERS.model.relation_to_applicant.parent')},
      {value: 'friend', label: this.translate.instant('USERS.model.relation_to_applicant.friend')},
      {value: 'garantor', label: this.translate.instant('USERS.model.relation_to_applicant.garantor')},
      {value: 'partner', label: this.translate.instant('USERS.model.relation_to_applicant.partner')},
      {value: 'child', label: this.translate.instant('USERS.model.relation_to_applicant.child')},
    ];
    this.economicRegimeOptions = [
      {value: 'community', label: this.translate.instant('USERS.model.economic_regime.options.community')},
      {
        value: 'separation_of_property',
        label: this.translate.instant('USERS.model.economic_regime.options.separation_of_property'),
      },
      {
        value: 'participation',
        label: this.translate.instant('USERS.model.economic_regime.options.participation'),
      },
      {value: 'other', label: this.translate.instant('CASES.single.other')},
    ];
    this.homeOwnerOptions      = [
      {label: this.translate.instant('USERS.model.home_owner.options.owner'), value: 'owner'},
      {label: this.translate.instant('USERS.model.home_owner.options.tenant'), value: 'tenant'},
      {label: this.translate.instant('USERS.model.home_owner.options.with_parents'), value: 'with_parents'},
    ];
    this.debtAwareOptions      = [
      {label: this.translate.instant('SHARED.yes'), value: true},
      {label: this.translate.instant('SHARED.no'), value: false},
    ];

    this.vulnerabilityOptions = [
      {label: this.translate.instant('USERS.model.vulnerability.options.depression'), value: 'depression'},
      {
        label: this.translate.instant('USERS.model.vulnerability.options.suicidal-thoughts'),
        value: 'suicidal-thoughts',
      },
      {label: this.translate.instant('USERS.model.vulnerability.options.extreme-poverty'), value: 'extreme-poverty'},
      {
        label: this.translate.instant('USERS.model.vulnerability.options.conflict-behaviour'),
        value: 'conflict-behaviour',
      },
      {label: this.translate.instant('USERS.model.vulnerability.options.health-problems'), value: 'health-problems'},
      {label: this.translate.instant('USERS.model.vulnerability.options.family-loss'), value: 'family-loss'},
      {
        label: this.translate.instant('USERS.model.vulnerability.options.family-history-of-violence'),
        value: 'family-history-of-violence',
      },
    ];
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data,
    );
  }

  private fetchCase(caseId: number) {
    const loadRelations = [
      'lead',
      'client.address',
      'partner.address',
      'product.payment_plans.payment_plan_phases',
      'linked_cases.client.address',
      'linked_by_cases.client.address',
    ];
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case                  = result.data;
          this.case.linked_cases_ids = this.case.linked_cases.map(linkedCase => linkedCase.id);
          this.buildForm();
          this.form.get('client.address.postcode')
            .valueChanges
            .pipe(debounceTime(300))
            .subscribe((data) => {
              this.filteredOptionsClient = this.caseService.getAutopopulateAddressList({search: data}, ['region'])
                .pipe(map(res => res.data));
            });
          this.form.get('partner.address.postcode')
            .valueChanges
            .pipe(debounceTime(300))
            .subscribe((data) => {
              this.filteredOptionsPartner = this.caseService.getAutopopulateAddressList({search: data}, ['region'])
                .pipe(map(res => res.data));
            });
        },
        err => this.serverResponse = err.error,
      ),
    );
  }

  public selectedPostcodeClient(selected: any) {
    this.form.get('client.address.city').patchValue(selected.name);
    this.form.get('client.address.region').patchValue(selected.region.name);
  }

  public selectedPostcodePartner(selected: any) {
    this.form.get('partner.address.city').patchValue(selected.name);
    this.form.get('partner.address.region').patchValue(selected.region.name);
  }

  private fetchDefaultProduct() {
    this.isLoading++;
    this.productService.getDefaultProduct().pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.defaultProduct = result.data;
        this.case           = this.getDefaultCase(this.defaultProduct);
        this.buildForm();
      },
    );
  }

  private getDefaultCase(defaultProduct: Product): Case {
    const clientCase            = new Case();
    clientCase.product_id       = defaultProduct.id;
    clientCase.product          = defaultProduct;
    clientCase.linked_cases_ids = [];

    return clientCase;
  }

  private validateClientEmail(control: UntypedFormControl): Promise<any> | Observable<any> {
    if (this.validateClientEmailTimeout) {
      clearTimeout(this.validateClientEmailTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.validateClientEmailTimeout = setTimeout(() => {
        this.userService.validateField('email', control.value, this.case?.user_id).subscribe(
          () => resolve(null),
          err => resolve({unique: err.error.data?.existing_model}),
        );
      }, 500);
    });
  }

  private validatePartnerEmail(control: UntypedFormControl): Promise<any> | Observable<any> {
    if (this.validatePartnerEmailTimeout) {
      clearTimeout(this.validatePartnerEmailTimeout);
    }
    return new Promise<any>((resolve, reject) => {
      this.validatePartnerEmailTimeout = setTimeout(() => {
        if (!this.case.joint_application) {
          resolve(null);
        }
        this.userService.validateField('email', control.value, this.case?.partner_user_id).subscribe(
          () => resolve(null),
          err => resolve({unique: err.error.data?.existing_model}),
        );
      }, 500);
    });
  }

  public getInsolvencyOptions(): void {
    this.isLoading++;
    this.caseService.getInsolvencyOptions({all: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.insolvencyOptions = res.data;
      });
  }
}
