import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { CaseCreditor, CaseCreditorPivot } from '../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../_base-shared/models/Case/CasePublicDebt';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { Creditor } from '../../../../../_base-shared/models/Entity/Creditor';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

type SupportedEmails = 'SettlementDemand' | 'ExtrajudicialNotice' | 'SendInvoiceToCreditorNotification' | 'NewContract';
type SupportedDocTypes = 'settlement-demand' | 'extrajudicial-notice' | 'creditor-invoice';

@Injectable({
  providedIn: 'root',
})
export class CaseCreditorService extends MainBaseApiService {
  public indexCaseCreditorsLegacy(caseId: number, params) {
    return this.http.get<LaravelResourceResponse<{
      secured: Array<Creditor>, unsecured: Array<CaseCreditorPivot>,
      claim: Array<Creditor>, excluded: Array<Creditor>
    }>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, requestData) {
    return this.http.put<LaravelResourceResponse<Case>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseCreditors(caseId: number, relations: Array<string> = [], requestData = {}): Observable<LaravelResourceResponse<Array<CaseCreditor>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditor>>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public showCaseCreditor(
    caseId: number,
    caseCreditorId: number,
    relations: Array<string> = [],
    requestData: any         = {}): Observable<LaravelResourceResponse<CaseCreditor>> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<CaseCreditor>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/' + caseCreditorId, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public update(caseId: number, caseCreditorId: number, requestData) {
    return this.http.patch<LaravelResourceResponse<Case>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/' + caseCreditorId, requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateCasePublicDebt(caseId, debtId, data): Observable<object> {
    return this.http.patch(`${ this.apiUrl }/cases/${ caseId }/public-debts/${ debtId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexCasePublicDebts(caseId: number, relations: Array<string> = [], requestData = {}): Observable<LaravelResourceResponse<Array<CasePublicDebt>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<Array<CasePublicDebt>>>(
      this.apiUrl + '/cases/' + caseId + '/public-debts', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewEmail(caseId: number, creditorId: number, documentType: SupportedEmails) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendEmail(caseId: number, creditorId: number, documentType: SupportedEmails) {
    return this.http.post<LaravelResourceResponse<AppDocument>>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/send-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveDocument(caseId: number, creditorId: number, documentType: 'settlement-demand' | 'extrajudicial-notice') {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveAndDownloadDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes) {
    return this.http.post(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-download-document',
      {document_type: documentType}, {observe: 'response', responseType: 'blob'},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public submitCaseCreditors(caseUuid: string, userUuid: string, data: any): Observable<LaravelResourceResponse>{
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/user/' + userUuid + '/case-creditor-client-data',
      {case_creditors: data},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getReasons(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/public/reasons',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseCreditor(caseId: number, caseCreditorId: number, data = {}): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/creditors/' + caseCreditorId,
      {client_data: data})
      .pipe(catchError(response => this.handleError(response)));
  }
}
