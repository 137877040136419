import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import {
  BaseClientInstallmentListComponent
} from '../../../../../../../../_base-shared/components/client/base-client-installment-list.component';
import { CaseDocumentService } from '../../../case-document.service';

@Component({
  selector:    'app-client-installment-list-preview',
  templateUrl: '../../../../../../../../_base-shared/components/client/base-client-installment-list.component.html',
  styleUrls:   ['./client-installment-list-preview.component.scss'],
})
export class ClientInstallmentListPreviewComponent extends BaseClientInstallmentListComponent implements OnInit {
  constructor (
    private caseDocumentService: CaseDocumentService,
    route: ActivatedRoute,
    protected fb: UntypedFormBuilder
  ) {
    super(route, fb);
  }

  ngOnInit () {
    super.ngOnInit();
    this.caseUuid = this.route.snapshot.paramMap.get('caseUuid');
    this.getClientInstallmentListPreview();
  }

  public getClientInstallmentListPreview (): void {
    super.getClientInstallmentListPreview(this.caseDocumentService.previewClientInstallmentList(this.caseUuid));
  }
}

