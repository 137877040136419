import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Chart, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { finalize } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

@Component({
  selector:    'app-mi-dashboard-customer',
  templateUrl: './mi-dashboard-customer.component.html',
  styleUrls:   ['./mi-dashboard-customer.component.scss'],
})
export class MiDashboardCustomerComponent implements OnInit {
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  @ViewChild(MatSort) sort: MatSort;
  public sortOrder                  = 'desc';
  public isLoading                                                      = 0;
  public availableProducts: Array<{ group_slug: string, name: string }> = [];
  public availableCharts: Array<{ name: string }>                       = [];
  public selectedProduct: 'dm' | 'lso' | 'dgs' | 'cajaplus'             = 'lso';
  public tableProductAgentData                                          = {lso: [], dm: [], dgs: [], cajaplus: []};
  public dataSourceAllProducts: MatTableDataSource<any>;
  public totalNumberOfCasoCongelado                                     = 0;
  public totalNumberOfDocumentacionCompleta                             = 0;
  public monthlyQuotaCasoCongelado;
  public monthlyQuotaDocCompleta;
  public displayedColumns: string[]                                     = [
    'name',
    'type',
    'case_count',
    'caso_congelado',
    'documentacion_completa',
    'impago_percent',
    'quota_documentacion_completa',
  ];

  public allProductsTotals = {lso: [], dm: [], dgs: [], cajaplus: []};
  public total             = {
    caseCount:             0,
    impago:                0,
    casoCancelado:         0,
    casoCongelado:         0,
    documentacionCompleta: 0,
  };
  public doughnutCharts: {
    casoCongelado?: {
      labels: any[],
      datasets: any,
      options: ChartOptions,
      type: ChartType,
      plugins: any[]
    },
    documentacionCompleta?: {
      labels: any[],
      datasets: any,
      options: ChartOptions,
      type: ChartType,
      plugins: any[]
    }
  }                        = {};
  public chartsReady: boolean;

  public doughnutChartLabelsDefaultCasoCongleado: any[]  = ['Target', 'Caso Congelado Conversions', 'Sales goal'];
  public doughnutChartLabelsExtendedCasoCongleado: any[] = [
    'Target',
    'Over Target',
    'Caso Congelado Conversions',
    'Sales goal'];

  public doughnutChartLabelsDefaultDocumentacionCompleta: any[]  = [
    'Target',
    'No of Documentacion Completa',
    'Sales goal'];
  public doughnutChartLabelsExtendedDocumentacionCompleta: any[] = [
    'Target',
    'Over Target',
    'No of Documentacion Completa',
    'Sales goal'];

  public doughnutChartOptionsDefault: any  = {
    // responsive: true,
    // maintainAspectRatio: false,
    aspectRatio: 1.9,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartOptionsExtended: any = {
    tooltips: {
      custom: (label) => {
        if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
          return label.opacity = 0;
        } else {
          return label;
        }
      },
    },
    plugins:  {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
            return null;
          } else {
            return value === 0 ? null : value;
          }
        },
      },
    },
  };

  public doughnutChartColorsDefault  = ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'];
  public doughnutChartColorsExtended = ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'];
  public form: UntypedFormGroup;

  // Doughnut
  public doughnutChartLabels: string[] = [
    'Target',
    'No of Documentacion Completa',
    'Sales goal',
  ];
  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService,
  ) {
  }

  ngOnInit(): void {
    Chart.register(ChartDataLabels);
    this.availableProducts = [
      {group_slug: 'lso', name: 'LSO'},
      {group_slug: 'dm', name: 'DM'},
      {group_slug: 'dgs', name: 'DGS'},
      {group_slug: 'cajaplus', name: 'Cajaplus'},
    ];
    this.availableCharts   = [
      {name: 'casoCongelado'},
      {name: 'documentacionCompleta'},
    ];
    this.availableCharts.forEach(chart => {
      this.doughnutCharts[chart.name] = {
        options:  this.doughnutChartOptionsDefault,
        labels:   chart.name === 'casoCongelado' ?
                    this.doughnutChartLabelsDefaultCasoCongleado :
                    this.doughnutChartLabelsDefaultDocumentacionCompleta,
        type:     'doughnut',
        plugins:  [ChartDataLabels],
        datasets: [
          {
            data:            [0, 0, 0],
            backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'],
          },
          {
            data:            [0, 0, 0],
            backgroundColor: this.doughnutChartColorsDefault,
          },
        ],
      };
    });
    this.buildForm();
    this.getData();
  }

  buildForm(): void {
    this.form = this.fb.group({
      from:      [new Date()],
      to:        [new Date()],
      dateRadio: ['today'],
    });
  }

  getData(): void {
    this.isLoading++;
    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date:   this.form.value.to ? this.form.value.to.toDateString() : '',
    };
    this.dashboardService.indexCustomerCare(dateRange)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.formatDataForTable(res.data.agent_sales);
          this.getTotalNumbers(this.tableProductAgentData);
          this.updateChartData(res.data);
        }, err => console.log(err),
      );
  }

  private formatDataForTable(allAgents): void {
    for (const product of this.availableProducts) {
      allAgents.forEach(agent => {
        const agentData = {
          name:                              agent.name,
          type:                              product.group_slug,
          case_count:                        agent['case_count_' + product.group_slug],
          caso_congelado:                    agent['caso_congelado_' + product.group_slug],
          documentacion_completa:            agent['documentacion_completa_' + product.group_slug],
          ['monthly_' + product.group_slug]: agent['monthly_' + product.group_slug],
          impago_percent:                    agent['impago_percent_' + product.group_slug],
          quota_documentacion_completa:      '',
          target_documentacion_completa:     agent.target_documentacion_completa,
          impago:                            agent['impago_' + product.group_slug],
        };
        this.tableProductAgentData[product.group_slug].push(agentData);
      });
    }
    this.dataSourceAllProducts = new MatTableDataSource(this.tableProductAgentData[this.selectedProduct]);
    this.dataSourceAllProducts.sort      = this.sort;
  }

  public onRadioChange($event): void {
    this.selectedProduct       = $event.value;
    this.dataSourceAllProducts = new MatTableDataSource<any>(this.tableProductAgentData[this.selectedProduct]);
    this.dataSourceAllProducts.sort      = this.sort;
  }

  public getPercentage(element): number {
    if (isFinite(element.documentacion_completa / +element.target_documentacion_completa)) {
      return +(((element.documentacion_completa / +element.target_documentacion_completa) * 100).toFixed(2));
    } else {
      return 0;
    }
  }

  getTotalNumbers(data): void {
    for (const product of this.availableProducts) {
      data[product.group_slug].forEach(agent => {
        this.total.caseCount += agent.case_count;
        this.total.impago += agent.impago;
        this.total.casoCancelado += agent.caso_congelado;
        this.total.documentacionCompleta += agent.documentacion_completa;
      });
      this.allProductsTotals[product.group_slug].push(this.total);
      this.total = {
        caseCount:             0,
        impago:                0,
        casoCancelado:         0,
        casoCongelado:         0,
        documentacionCompleta: 0,
      };
    }
  }

  public updateChartData(values): void {
    const {agent_sales, config} = values;
    const currentDayInMonth = DateTime.local().day;
    const chartDaysInMonth  = DateTime.local().daysInMonth;
    const percentOfTotal    = Math.round(currentDayInMonth / chartDaysInMonth * 100);
    agent_sales.forEach(agent => {
      this.totalNumberOfCasoCongelado += agent.caso_congelado;
      this.totalNumberOfDocumentacionCompleta += agent.documentacion_completa;
    });

    this.monthlyQuotaCasoCongelado = config.full_target_caso_congelado.value;
    this.monthlyQuotaDocCompleta   = config.full_target_documentacion_completa.value;
    const dayTargetCasoCongelado   = (this.monthlyQuotaCasoCongelado / 100) * percentOfTotal;
    const dayTargetDocumentcion    = (this.monthlyQuotaDocCompleta / 100) * percentOfTotal;
    const actualSalesCasoCongelado = this.totalNumberOfCasoCongelado;
    const actualSalesDocumentcion  = this.totalNumberOfDocumentacionCompleta;

    // Casp Congelado
    this.doughnutCharts.casoCongelado.datasets[0].data[0] = +dayTargetCasoCongelado.toFixed(); //  update day target
    this.doughnutCharts.casoCongelado.datasets[0].data[2] = +(this.monthlyQuotaCasoCongelado - dayTargetCasoCongelado).toFixed(); //  update sales goal
    this.doughnutCharts.casoCongelado.datasets[1].data[1] = +actualSalesCasoCongelado.toFixed(); //  update actual sales

    if (this.monthlyQuotaCasoCongelado - actualSalesCasoCongelado < 0) {
      this.doughnutCharts.casoCongelado.labels     = this.doughnutChartLabelsExtendedCasoCongleado;
      this.doughnutCharts.casoCongelado.datasets[0].backgroundColor     = this.doughnutChartColorsExtended;
      this.doughnutCharts.casoCongelado.datasets[1].backgroundColor     = this.doughnutChartColorsExtended;
      this.doughnutCharts.casoCongelado.options    = this.doughnutChartOptionsExtended;
      this.doughnutCharts.casoCongelado.datasets[1].data[3] = 0;
      this.doughnutCharts.casoCongelado.datasets[1].data[2] = +Math.abs(
        this.monthlyQuotaCasoCongelado - (actualSalesCasoCongelado - this.monthlyQuotaCasoCongelado))
        .toFixed();
      this.doughnutCharts.casoCongelado.datasets[1].data[1] = +Math.abs(
        this.monthlyQuotaCasoCongelado - actualSalesCasoCongelado).toFixed();
    } else {
      this.doughnutCharts.casoCongelado.datasets[1].data[2] = +((this.monthlyQuotaCasoCongelado -
        dayTargetCasoCongelado).toFixed());
    }

    // Documemtacion Completa
    this.doughnutCharts.documentacionCompleta.datasets[0].data[0] = +dayTargetDocumentcion.toFixed(); //  update day target
    this.doughnutCharts.documentacionCompleta.datasets[0].data[2] = +(this.monthlyQuotaDocCompleta -
      dayTargetDocumentcion).toFixed(); //  update sales goal
    this.doughnutCharts.documentacionCompleta.datasets[1].data[1] = +actualSalesDocumentcion.toFixed(); //  update actual sales

    if (this.monthlyQuotaDocCompleta - actualSalesDocumentcion < 0) {
      this.doughnutCharts.documentacionCompleta.labels     = this.doughnutChartLabelsExtendedDocumentacionCompleta;
      this.doughnutCharts.documentacionCompleta.datasets[0].backgroundColor     = this.doughnutChartColorsExtended;
      this.doughnutCharts.documentacionCompleta.datasets[1].backgroundColor     = this.doughnutChartColorsExtended;
      this.doughnutCharts.documentacionCompleta.options    = this.doughnutChartOptionsExtended;
      this.doughnutCharts.documentacionCompleta.datasets[1].data[3] = 0;
      this.doughnutCharts.documentacionCompleta.datasets[1].data[2] = +Math.abs(
        this.monthlyQuotaDocCompleta - (actualSalesDocumentcion - this.monthlyQuotaDocCompleta))
        .toFixed();
      this.doughnutCharts.documentacionCompleta.datasets[1].data[1] = +Math.abs(
        this.monthlyQuotaDocCompleta - actualSalesDocumentcion)
        .toFixed();
    } else {
      this.doughnutCharts.documentacionCompleta.datasets[1].data[2] = +((this.monthlyQuotaDocCompleta -
        dayTargetDocumentcion).toFixed());
    }

    this.charts.forEach(chart => chart.update());
    this.chartsReady = true;
  }
}
