import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-creditor-detail',
  templateUrl: './case-creditor-detail.component.html',
  styles:      []
})
export class CaseCreditorDetailComponent implements OnInit, OnDestroy {
  public isLoading                           = 0;
  public case: Case;
  public caseCreditor: CaseCreditor;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private caseCreditorService: CaseCreditorService) {
  }

  ngOnInit(): void {
    this.route.parent.parent.paramMap.subscribe(params => {
      this.fetchCase(+params.get('id'));
      this.route.paramMap.subscribe(p => {
        this.fetchCaseCreditor(+params.get('id'), +p.get('id'));
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchCase(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.case = result.data),
    );
  }

  private fetchCaseCreditor(caseId: number, caseCreditorId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.showCaseCreditor(caseId, caseCreditorId)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.caseCreditor = result.data;
        }),
    );
  }
}
