import { NgModule } from '@angular/core';
import {MiDashboardDetailsRoutingModule} from './mi-dashboard-details-routing.module';
import {SharedModule} from '../../../_shared/shared.module';


@NgModule({
    declarations: [],
    imports:      [
        SharedModule,
        MiDashboardDetailsRoutingModule
    ],
})
export class MiDashboardDetailsModule {

}
