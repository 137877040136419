import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import {
  ChangeCreditorStatusComponent,
} from '../../../../../_shared/components/change-creditor-status/change-creditor-status.component';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../../creditor/creditor.service';
import { VerifySignatureComponent } from '../../../../document/verify-signature/verify-signature.component';
import { StatusService } from '../../../../status/status.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { AdditionalInputComponent } from '../additional-input/additional-input.component';

@Component({
  selector:  'app-base-case-creditor',
  template:  '',
  styleUrls: ['./base-case-creditor.component.scss'],
})
export class BaseCaseCreditorComponent implements OnInit {
  public case: Case;
  public authUser: User;
  public isLoading                   = 0;
  public isSubmitting                = false;
  public creditors: MatTableDataSource<any>;
  public isPublicLoading             = 0;
  public isPublicLoadingId: number[] = [];
  public missingAepFiles             = true;

  public isLoadingIds: Array<number> = [];
  public creditorsSelection          = new SelectionModel<CaseCreditor>(true, []);

  constructor(
    protected router: Router,
    protected fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toast: ToastrService,
    protected translate: TranslateService,
    protected toastr: ToastrService,
    protected globalEventService: MainGlobalEventService,
    protected caseService: CaseService,
    protected creditorService: CreditorService,
    protected caseCreditorService: CaseCreditorService,
    protected caseDocumentService: CaseDocumentService,
    protected statusService: StatusService,
    protected el: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(selection, dataSource): boolean {
    const numSelected = selection.selected.length;
    const numRows     = dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(selection, dataSource): void {
    if (this.isAllSelected(selection, dataSource)) {
      selection.clear();
      return;
    }

    selection.select(...dataSource.data);
  }

  public verifyCreditorIds(caseCreditorIds: Array<number>, creditorType: string): void {
    const idObjectList: Array<CaseCreditor> = caseCreditorIds.map(id => {
      const newCaseCreditor = new CaseCreditor();
      return {...newCaseCreditor, id};
    });
    const selectedCaseCreditors             = new SelectionModel<CaseCreditor>(true, idObjectList);

    return this.verifyCreditor(selectedCaseCreditors, creditorType);
  }

  public verifyCreditor(caseCreditorIds: SelectionModel<CaseCreditor | CasePublicDebt>,
                        creditorType: string): void {
    if ( !! this.case.client.signature_verified_at) {
      this.verifySendMandates(caseCreditorIds as SelectionModel<CaseCreditor>, creditorType);
    } else {
      this.verifySignature(this.case.client, caseCreditorIds as SelectionModel<CaseCreditor>, creditorType);
    }
  }

  private verifySendMandates(caseCreditorId: SelectionModel<CaseCreditor>, creditorType: string): void {
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.id) : [];
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.verifySendMandates(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.forEach(message => {
            this.toast.success(message.message);
          });

          ids.forEach(id => {
            this.markAsVerified(id, creditorType);
            this.updateStatus(id, creditorType, 'mandate_sent');
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.resend-mandates-error'));
          }
        });
  }

  public markAsVerified(caseCreditorId: number, type: string): void {
    const caseCreditor    = this.creditors.data.find(i => i.id === caseCreditorId);
    caseCreditor.verified = 1;
  }

  private updateStatus(id, type, value): void {
    const caseCreditor             = this.creditors.data.find(i => i.id === id);
    caseCreditor.response_received = value;
  }

  public throwMultipleErrors(errorsArray: Array<string> | Array<{
    errors: string, id: number, name: string,
    reference_number: string
  }>): void {
    errorsArray.forEach(caseCreditorError => {
      this.toast.error(caseCreditorError, '',
        {disableTimeOut: true});
    });
  }

  public verifySignature(client: User, caseCreditorId: SelectionModel<CaseCreditor | CasePublicDebt>,
                         creditorType: string): void {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case:           this.case,
        user:           client,
        verifyCreditor: true,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data?.result) {
        if (creditorType === 'public_debts') {
          this.additionalInput(caseCreditorId as SelectionModel<CasePublicDebt>, creditorType);
        } else {
          this.verifySendMandates(caseCreditorId as SelectionModel<CaseCreditor>, creditorType);
        }
      }
    });
  }

  private additionalInput(debtIds: SelectionModel<CasePublicDebt>, creditorType: string): void {
    const ids              = debtIds.selected ? debtIds.selected.map(cred => cred.id) : [];
    this.isPublicLoading   = 1;
    this.isPublicLoadingId = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        debtIds,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          ids.forEach(id => {
            this.markAsVerified(id, creditorType);
          });
          this.isPublicLoading   = 0;
          this.isPublicLoadingId = [];
          if (debtIds.selected) {
            debtIds.clear(); //  Remove selections
          }
        }
      } else {
        this.isPublicLoading   = 0;
        this.isPublicLoadingId = [];
        if (debtIds.selected) {
          debtIds.clear(); //  Remove selections
        }
      }
    });
  }

  public sendMandatesRecoveryAgent($event, caseCreditorId): void {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendMandatesRecoveryAgent(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          // @ts-ignore
          value.data.messages.map(message => {
            this.toast.success(message.message);
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
          }
        });
  }

  public sendAep($event, caseCreditorId, creditorType): void {
    $event.preventDefault();
    const ids = caseCreditorId.selected ? caseCreditorId.selected.map(cred => cred.id) : caseCreditorId;
    this.isLoading++;
    this.isLoadingIds = ids;
    this.creditorService.sendAepEmails(this.case.id, {selected_ids: ids})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-aep-success'));
          ids.map(id => {
            const item = this.creditors.data.find(i => i.id === id);
            this.creditors.data.map(cred => { // Find all other creditor creditors with same id
              if (cred.id === item.id) {
                cred.aep_sent = 1;
              }
            });
          });
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
        },
        error => {
          if (caseCreditorId.selected) {
            caseCreditorId.clear(); //  Remove selections
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-aep-error'));
          }
        });

  }

  public sendProposalAndAccord(caseCreditorIds: Array<number>,
                               selection: SelectionModel<CaseCreditor> = null): void {
    this.isLoading++;
    this.isLoadingIds = caseCreditorIds;
    this.creditorService.sendProposalAndAcoord(this.case.id, {selected_ids: caseCreditorIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.send-proposal-accord-success'));
          if (selection.selected) {
            selection.clear();
          }
        },
        error => {
          if (selection.selected) {
            selection.clear();
          }
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('CASES.details.send-proposal-accord-error'));
          }
        });
  }

  public sendEmail($event: MouseEvent, id, creditor?): void {
    $event.preventDefault();
    const unsecuredCreditorIds = [];
    /* if (creditor === 'unsecured') {
     this.selectionUnsecured.selected.forEach(singleCreditor => {
     unsecuredCreditorIds.push(singleCreditor.id);
     });
     }*/
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = id;

        const observable = creditor === 'unsecured' ?
          this.creditorService.sendAzcarateEmailUnsecured(this.case.id, unsecuredCreditorIds) :
          this.creditorService.sendAzcarateEmail(id);
        observable.pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = null;
        }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
            }, error => {
              this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
            });
      }
    });
  }

  public changeStatus($event: MouseEvent, selection, type: string): void {
    $event.preventDefault();
    let ids;
    if (type === 'public_debts') {
      ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    } else {
      ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    }

    const dialogRef = this.dialog.open(ChangeCreditorStatusComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        ids,
        type,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (selection.selected) {
        selection.clear(); //  Remove selections
      }
      if (result) {
        ids.map(id => {
          this.updateStatus(id, type, result);
        });
      }
    });
  }

  public sendLastChance($event: MouseEvent, selection): void {
    $event.preventDefault();
    const ids = selection.selected ? selection.selected.map(cred => cred.id) : selection;
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CASES.details.send-last-chance-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.send'),
      confirmButtonColor: '#886AB5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.isLoadingIds = ids;

        this.creditorService.sendLastChanceEmail(ids[0], ids.length > 1 ? {selected_ids: ids} : null)
          .pipe(finalize(() => {
            this.isLoading--;
            this.isLoadingIds = [];
          }))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.email-sent'));
              if (selection.selected) {
                selection.clear(); //  Remove selections
              }
            }, error => {
              if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
                this.throwMultipleErrors(error.error.errors.case.errors);
              } else {
                this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
              }
            });
      }
    });
  }

  public sendDeleteNumber($event: MouseEvent, allIds?, creditorType?: string): void {
    $event.preventDefault();
    let ids = allIds;
    if (creditorType === 'unsecured') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;

    const dialogRef = this.dialog.open(AdditionalInputComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        type:       'number',
        case:       this.case,
        creditorId: ids[0],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading    = 0;
      this.isLoadingIds = [];
    });
  }

  private returnUnsecuredCreditorsIds(): any {
    const unsecuredCreditorIds = [];
    /*  this.selectionUnsecured.selected.forEach(singleCreditor => {
     unsecuredCreditorIds.push(singleCreditor.id);
     });*/
    return unsecuredCreditorIds;
  }

  public sendAntiHarassment($event: MouseEvent, allIds: any[], creditorType?: string): void {
    $event.preventDefault();

    let ids = allIds;
    if (creditorType === 'unsecured') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;

    const observable = creditorType === 'unsecured' ?
      this.creditorService.sendAntiHarassmentUnsecured(this.case.id, ids) :
      this.creditorService.sendAntiHarassmentEmail(ids[0]);
    observable.pipe(finalize(() => {
      this.isLoading--;
      this.isLoadingIds = [];
    }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public sendLoanCancellationEmail($event: MouseEvent, allIds: any[], creditorType?: string): void {
    $event.preventDefault();

    let ids = allIds;
    if (creditorType === 'unsecured') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.isLoading++;
    this.isLoadingIds = ids;
    const observable  = creditorType === 'unsecured' ?
      this.creditorService.sendLoanCancellationEmailUnsecured(this.case.id, ids) :
      this.creditorService.sendLoanCancellationEmail(ids[0]);
    observable.pipe(finalize(() => {
      this.isLoading--;
      this.isLoadingIds = [];
    }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.email-sent'));
        }, error => {
          this.toast.error(this.translate.instant('CASES.details.email-sent-error'));
        });
  }

  public getRightOfAccess(clientRole: 'client' | 'partner', creditorId: number, $event, creditorType?: string): void {
    $event.preventDefault();
    let ids = creditorId;
    if (creditorType === 'unsecured') {
      ids = this.returnUnsecuredCreditorsIds();
    }

    this.caseDocumentService.generateRightOfAccessLetter(clientRole, ids)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'right_of_access_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + 'pdf';
          saveAs(result.body, fileName);
          this.toast.success('Downloaded right of access letter');
        }
      }, err => {
        this.toast.error('Failed to generate Demanda document');
      });
  }
}
