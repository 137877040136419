import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';


@Injectable({
  providedIn: 'root',
})
export class ClientService extends MainBaseApiService {
  private navSubjectSource = new BehaviorSubject<boolean>(false);
  public navSubject$       = this.navSubjectSource.asObservable();

  setNavSubject(isTrue: boolean): void {
    this.navSubjectSource.next(isTrue);
  }

  optOutPublic(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/public/unsubscribe', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  updateUser(userUuid: string, userData): Observable<LaravelResourceResponse<User>> {
    return this.http.put<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid, userData)
      .pipe(catchError(response => this.handleError(response)));
  }
}
