import { Component, Input } from '@angular/core';
import { ArcopayOrder } from '../../../../../../../../../_base-shared/models/Payment/Transaction/ArcopayOrder';

@Component({
  selector: 'app-arcopay-details',
  templateUrl: './arcopay-details.component.html',
  styleUrls: ['./arcopay-details.component.scss']
})
export class ArcopayDetailsComponent {
  @Input() arcopayOrder: ArcopayOrder;
}
