import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { DocumentType } from '../../../../../../../../_base-shared/models/DocumentType';
import { DocumentTypeCategory } from '../../../../../../../../_base-shared/models/DocumentTypeCategory';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { SafePipe } from '../../../../../../../../_base-shared/pipes/safe.pipe';
import { environment } from '../../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../document/document-type.service';
import { LegacyDocumentTypeService } from '../../../../document/legacy-document-type.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-document-list-v2',
  templateUrl: './case-document-list-v2.component.html',
  styleUrls:   ['./case-document-list-v2.component.scss'],
})
export class CaseDocumentListV2Component implements OnInit, OnDestroy {
  public case: Case;
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;

  public form: FormGroup;
  public creditorForm: FormGroup;
  public creditorFormPartner: FormGroup;
  public legalForm: FormGroup;
  public legalFormCustom: FormGroup;
  public legalFormPartner: FormGroup;
  public legalFormCustomPartner: FormGroup;

  public courtForm: FormGroup;
  public courtFormCustom: FormGroup;
  public courtFormPartner: FormGroup;

  public bankFormClient: FormGroup;
  public bankFormPartner: FormGroup;

  public authUser: User;
  public lang                   = 'es';
  public requestAccordion       = false;
  public labels: {}             = {};
  public allCreditors           = [];
  public allBanks               = [];
  public files                  = [];
  public creditorDocsClient     = [];
  public creditorDocsPartner    = [];
  public legalDocsClient        = [];
  public legalDocsClientCustom  = [];
  public legalDocsPartner       = [];
  public courtDocsClient        = [];
  public courtDocsPartner       = [];
  public legalDocsPartnerCustom = [];
  public legalDocumentTypes     = [];

  public courtDocumentTypes = [];
  public documentTypeCategories: Array<DocumentTypeCategory>;
  public allCaseDocumentTypes: Array<DocumentType>;

  public activeCaseDocumentTypes: Array<DocumentType>;

  public storageUrl                                       = environment.STORAGE_URL + '/';
  public caseClientDocumentRequests: Array<DocumentType>  = [];
  public casePartnerDocumentRequests: Array<DocumentType> = [];
  public clientBasicDocumentRequests: any                 = {};
  public clientCircumstantialDocumentRequests: any        = {};
  public clientCaseCreditorDocumentRequests: any          = {};
  public clientPublicDebtDocumentRequests: any            = {};
  public clientCaseBankAssetDocumentRequests: any         = {};
  public clientCustomDocumentRequests: any                = {};
  public partnerBasicDocumentRequests: any                = {};
  public partnerCircumstantialDocumentRequests: any       = {};
  public partnerCaseCreditorDocumentRequests: any         = {};
  public partnerPublicDebtDocumentRequests: any           = {};
  public partnerCaseBankAssetDocumentRequests: any        = {};

  public partnerCustomDocumentRequests: any = {};
  public uploadSpinner                      = false;
  public uploadSpinnerClientLegal           = false;
  public uploadSpinnerClientLegalCustom     = false;
  public uploadSpinnerPartnerLegal          = false;
  public uploadSpinnerClientCourt           = false;
  public uploadSpinnerPartnerCourt          = false;
  public uploadSpinnerPartnerLegalCustom    = false;

  public files_by_creditor            = [];
  public files_by_public_debt         = [];
  public partner_files_by_creditor    = [];
  public partner_files_by_public_debt = [];

  public files_by_legal         = [];
  public partner_files_by_legal = [];

  public files_by_bank         = [];
  public partner_files_by_bank = [];

  public files_by_court         = [];
  public partner_files_by_court = [];

  public updateFiles = new BehaviorSubject<any>([]);
  public fileUrl: string;
  public fileType: string;

  public isSticky = false;

  public creditorDocumentsVisible = false;
  public bankDocumentsVisible     = false;

  public courtDocsClientCustom: any[];
  public uploadSpinnerClientCourtCustom: boolean;
  public courtDocsPartnerCustom: any[];
  public courtFormCustomPartner: any;
  public uploadSpinnerPartnerCourtCustom: boolean;
  public autoDeExoneracionUploaded: boolean;
  public autoDeConcursoUploaded: boolean;

  private subscriptions: Array<Subscription> = [];

  protected readonly Object = Object;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private legacyDocumentTypeService: LegacyDocumentTypeService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private documentTypeService: DocumentTypeService,
    private caseDocumentService: CaseDocumentService,
    private safePipe: SafePipe,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.lang = this.cookieService.get('lang');

    this.route.parent.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.translate.onLangChange.subscribe(next => {
        this.fetchCaseDocumentTypes(caseId);
      });
      this.isLoading++;
      this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.isLoading++;
        this.caseDocumentService.indexCaseDocuments(caseId)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(res => {
            this.case = res.data;
            //  Get all creditors and public debts
            this.case.public_debts.map(debt => {
              //  Add name prop so we can use it in select
              if (debt.public_organisation) {
                debt.name = debt.public_organisation.replace('-', ' ').toUpperCase();
              }
            });
            this.setDocumentVisibility();
            this.caseClientDocumentRequests  = this.case.file_requests;
            this.casePartnerDocumentRequests = this.case.partner_file_requests;
            this.allCreditors                = this.case.secured_creditors.concat(this.case.unsecured_creditors);
            this.allCreditors                = this.allCreditors.concat(this.case.public_debts);
            this.allBanks                    = this.case.assets.filter(asset => asset.type === 'bank_accounts');

            this.buildForms();

            this.files_by_creditor            = this.case.files_by_creditor;
            this.files_by_public_debt         = this.case.files_by_public_debt;
            this.partner_files_by_creditor    = this.case.partner_files_by_creditor;
            this.partner_files_by_public_debt = this.case.partner_files_by_public_debt;

            this.files_by_legal         = this.case.legal_documents_client;
            this.partner_files_by_legal = this.case.legal_documents_partner;

            this.files_by_bank         = this.case.client_files_by_bank;
            this.partner_files_by_bank = this.case.partner_files_by_bank;

            this.files_by_court         = this.case.court_documents_client;
            this.partner_files_by_court = this.case.court_documents_partner;
            this.fetchCaseDocumentTypes(caseId, true);

            this.legacyDocumentTypeService.getLegalDocumentTypes()
              .subscribe(r => this.legalDocumentTypes = r);

            this.legacyDocumentTypeService.getCourtDocumentTypes()
              .subscribe(r => this.courtDocumentTypes = r);
          });
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  onFileChange(event) {
    const filesArray = [...this.files];
    const files      = event.target.files;
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.forEach(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    this.files = filesArray;

  }

  legalDocsChange(event, type) {
    const files      = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.legalDocsClient = filesArray;
    } else {
      this.legalDocsPartner = filesArray;
    }
  }

  legalDocsChangeCustom(event, type) {
    const files      = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.legalDocsClientCustom = filesArray;
    } else {
      this.legalDocsPartnerCustom = filesArray;
    }
  }

  courtDocsChange(event, type) {
    const files      = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.courtDocsClient = filesArray;
    } else {
      this.courtDocsPartner = filesArray;
    }
  }

  removeFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.creditorDocsClient = this.creditorDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.creditorDocsPartner = this.creditorDocsPartner.filter(file => file.index !== index);
    } else {
      this.files = this.files.filter(file => file.index !== index);
    }
  }

  removeLegalFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.legalDocsClient = this.legalDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.legalDocsPartner = this.legalDocsPartner.filter(file => file.index !== index);
    }
  }

  removeCourtFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.courtDocsClient = this.courtDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.courtDocsPartner = this.courtDocsPartner.filter(file => file.index !== index);
    }
  }

  removeLegalFileCustom($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.legalDocsClientCustom = this.legalDocsClientCustom.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.legalDocsPartnerCustom = this.legalDocsPartnerCustom.filter(file => file.index !== index);
    }
  }

  resendDocumentRequest({fileId, type}) {
    const data = {
      caseId:      this.case.id,
      fileId,
      client_type: type,
    };
    this.caseDocumentService.resendRequest(data)
      .subscribe(
        res => {
          // this.toastr.success('Request sent');
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'),
            this.translate.instant('SHARED.success'));
        },
        err => {
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'),
            this.translate.instant('SHARED.error'));
        },
      );
  }

  documentInfoChange($event) {
    const data = {
      caseId:              this.case.id,
      fileId:              $event.document.id,
      document_type_id:    $event.newTypeDoc?.id || null,
      creditor_id:         $event.newTypeCred?.id || null,
      case_public_debt_id: $event.newTypePublic?.id || null,
      name:                $event.name,
      uploaded_by:         $event.uploaded_by,
    };
    this.caseDocumentService.changeStatus(data)
      .subscribe(
        res => {
          this.setClientDocuments(res.data.client_files_by_type);
          this.setPartnerDocuments(res.data.partner_files_by_type);
          this.updateFiles.next(res.data);  //  Update creditor files
          this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
        },
      );
  }

  documentStatusChange(data) {
    if (data.status === 'declined') {
      Swal.fire({
        title:             this.translate.instant('DOCUMENTS.rejection-warning') + '?',
        text:              this.translate.instant('DOCUMENTS.reason-of-rejection') + ':',
        input:             'text',
        showCancelButton:  true,
        showConfirmButton: true,
        inputValidator:    (value) => {
          return new Promise((resolve) => {
            if (value.length === 0) {
              resolve(this.translate.instant('DOCUMENTS.reason-of-rejection-required'));
            } else {
              resolve('');
            }
          });
        }

      }).then((result) => {
        if (result.value != null) {
          const newData = {...data, reason_of_rejection: result.value, version: '2'};
          this.changeStatus(newData);
        }
      });
    } else if (data.status === 'accepted') {
      this.changeStatus(data);
    }

  }

  private changeStatus(data): void {
    this.caseDocumentService.changeStatus(data)
      .subscribe(
        res => {
          this.setClientDocuments(res.data.client_files_by_type);
          this.setPartnerDocuments(res.data.partner_files_by_type);
          this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-status-changed-error'));
        },
      );
  }

  documentsMerged(data) {
    this.setClientDocuments(data.client_files_by_type);
    this.setPartnerDocuments(data.partner_files_by_type);
  }

  deleteFile(data) {
    this.caseDocumentService.removeUploadedFile(data.uuId, data.fileId)
      .subscribe(
        res => {
          this.setClientDocuments(res.data.client_files_by_type);
          this.setPartnerDocuments(res.data.partner_files_by_type);
          this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
        },
      );
  }

  closeRequestAccordion($event) {
    this.requestAccordion = $event;
  }

  changeUploadFor($event) {
    this.form.patchValue({fileType: ''});

    this.documentTypeService.legacyIndex().subscribe(res => {
      this.allCaseDocumentTypes = res.data.filter(docType => !!docType.visible);
      //  Set categories for upload files based on client type (partner | client)
      let customFiles           = [];
      if ($event === 'client') {
        customFiles = this.case.file_requests.filter(file => file.custom);
      } else {
        customFiles = this.case.partner_file_requests.filter(file => file.custom);
      }

      this.allCaseDocumentTypes = this.allCaseDocumentTypes.concat(customFiles);
    });
  }

  submitFiles() {
    if (this.files.length > 0) {
      this.uploadSpinner = true;
      const formData     = new FormData();
      const fileType     = this.form.value.fileType;
      const uploadFor    = this.form.value.uploadFor;
      //  Append files to form data
      this.files.map(file => {
        formData.append(`files[]`, file);
      });
      formData.append(`uploaded_by`, uploadFor);
      //  If file type is 'contract' or 'mandates'
      //  Call different API endpoint for upload
      if (fileType === 'contract' || fileType === 'mandate' || fileType === 'sepa') {
        formData.append(`type`, fileType);
        this.documentTypeService.adminUploadContract(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinner = false;
            this.files         = [];
          }))
          .subscribe(
            next => {
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            }, error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      } else {
        formData.append(`document_type_id`, fileType);
        this.caseDocumentService.adminUploadFiles(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinner = false;
            this.files         = [];
          }))
          .subscribe(
            next => {
              this.setClientDocuments(next.data.client_files_by_type);
              this.setPartnerDocuments(next.data.partner_files_by_type);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            });
      }
    }
  }

  submitLegalFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.legalDocsClient;
      if (this.legalForm.get('fileType')?.value) {
        this.uploadSpinnerClientLegal = true;
        const formData                = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalForm.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientLegal = false;
            this.legalDocsClient          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_legal         = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.legalDocsPartner;
      if (this.legalFormPartner.value.fileType) {
        this.uploadSpinnerPartnerLegal = true;
        const formData                 = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerLegal = false;
            this.legalDocsPartner          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_legal         = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitCourtFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.courtDocsClient;
      if (this.courtForm.value.fileType) {
        this.uploadSpinnerClientCourt = true;
        const formData                = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtForm.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientCourt = false;
            this.courtDocsClient          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_court         = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.courtDocsPartner;
      if (this.courtFormPartner.value.fileType) {
        this.uploadSpinnerPartnerCourt = true;
        const formData                 = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtFormPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerCourt = false;
            this.courtDocsPartner          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_court         = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitLegalFilesCustom(type): void {
    let files = [];
    if (type === 'client') {
      files = this.legalDocsClientCustom;
      if (this.legalFormCustom.value.fileType) {
        this.uploadSpinnerClientLegalCustom = true;
        const formData                      = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormCustom.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientLegalCustom = false;
            this.legalDocsClientCustom          = [];
          }))
          .subscribe(
            next => {
              this.files_by_legal         = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
              this.updateFiles.next(next);
              this.legalFormCustom.patchValue({fileType: ''});
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.legalDocsPartnerCustom;
      if (this.legalFormCustomPartner.value.fileType) {
        this.uploadSpinnerPartnerLegalCustom = true;
        const formData                       = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormCustomPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerLegalCustom = false;
            this.legalDocsPartnerCustom          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.files_by_legal         = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
              this.legalFormCustomPartner.patchValue({fileType: ''});
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitCourtFilesCustom(type): void {
    let files = [];
    if (type === 'client') {
      files = this.courtDocsClientCustom;
      if (this.courtFormCustom.value.fileType) {
        this.uploadSpinnerClientCourtCustom = true;
        const formData                      = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtFormCustom.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientCourtCustom = false;
            this.courtDocsClientCustom          = [];
          }))
          .subscribe(
            next => {
              this.files_by_court         = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
              this.updateFiles.next(next);
              this.courtFormCustom.patchValue({fileType: ''});
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.courtDocsPartnerCustom;
      if (this.courtFormCustomPartner.value.fileType) {
        this.uploadSpinnerPartnerCourtCustom = true;
        const formData                       = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtFormCustomPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerCourtCustom = false;
            this.courtDocsPartnerCustom          = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.files_by_court         = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
              this.courtFormCustomPartner.patchValue({fileType: ''});
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  public courtDocsChangeCustom(event, type) {
    const files      = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.courtDocsClientCustom = filesArray;
    } else {
      this.courtDocsPartnerCustom = filesArray;
    }
  }

  public removeCourtFileCustom($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.courtDocsClientCustom = this.courtDocsClientCustom.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.courtDocsPartnerCustom = this.courtDocsPartnerCustom.filter(file => file.index !== index);
    }
  }

  setFileUrl(file: any): void {
    this.fileUrl  = this.safePipe.transform(file.url + '?output=embed', 'resourceUrl') as string;
    this.fileType = file?.type;
  }

  private setDocumentVisibility(): void {
    if (this.case.creditors.length) {
      this.creditorDocumentsVisible = !!this.case.creditors[0].pivot.client_documents_visible;
    }

    const bankAccounts = this.case.assets.filter(asset => asset.type === 'bank_accounts');
    if (bankAccounts.length) {
      this.bankDocumentsVisible = !!bankAccounts[0].client_documents_visible;
    }
  }

  private buildForms(): void {
    this.form                   = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(1),
      uploadFor: this.fb.control('client'),
    });
    this.creditorForm           = this.fb.group({
      files: this.fb.control([]),
      //  Set select value to first creditor in array
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.creditorFormPartner    = this.fb.group({
      files: this.fb.control([]),
      //  Set select value to first creditor in array
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.legalForm              = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.legalFormPartner       = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.legalFormCustom        = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.legalFormCustomPartner = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.bankFormClient         = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.bankFormPartner        = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.courtForm              = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.courtFormPartner       = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.courtFormCustom        = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
  }


  private setClientDocuments(clientDocumentRequests: any) {
    Object.keys(clientDocumentRequests).forEach(key => {
      let pushToBasic          = false;
      let pushToCircumstantial = false;
      let pushToCaseCreditor   = false;
      let pushToPublicDebt     = false;
      let pushToBankAccount    = false;
      let pushToCustom         = false;
      const document           = this.allCaseDocumentTypes.find(doc => doc.name === key);
      if (document) {
        if (document.document_type_category_id === 1) {
          pushToBasic = true;
        }
        if (document.document_type_category_id === 2) {
          pushToCircumstantial = true;
        }
        if (document.name === 'public-debt-estate' || document.name === 'public-debt-social-security' ||
          document.name === 'public-debt-town-hall'
        ) {
          pushToPublicDebt = true;
        }
        if (document.case_creditor_id) {
          pushToCaseCreditor = true;
        }
        if (document.bank_asset_id) {
          pushToBankAccount = true;
        }
        if (!document.case_creditor_id && !document.bank_asset_id && document.custom) {
          pushToCustom = true;
        }
        if (pushToBasic) {
          this.clientBasicDocumentRequests[key] = clientDocumentRequests[key];
        }
        if (pushToCircumstantial) {
          this.clientCircumstantialDocumentRequests[key] = clientDocumentRequests[key];
        }
        if (pushToCaseCreditor) {
          this.clientCaseCreditorDocumentRequests[key] = clientDocumentRequests[key];
        }
        if (pushToPublicDebt) {
          this.clientPublicDebtDocumentRequests[key] = clientDocumentRequests[key];
        }
        if (pushToBankAccount) {
          this.clientCaseBankAssetDocumentRequests[key] = clientDocumentRequests[key];
        }
        if (pushToCustom) {
          this.clientCustomDocumentRequests[key] = clientDocumentRequests[key];
        }
      }
    });
  }

  private setPartnerDocuments(partnerDocumentRequests: any) {
    Object.keys(partnerDocumentRequests).forEach(key => {
      let pushToBasic          = false;
      let pushToCircumstantial = false;
      let pushToCaseCreditor   = false;
      let pushToPublicDebt     = false;
      let pushToBankAccount    = false;
      let pushToCustom         = false;
      const document           = this.allCaseDocumentTypes.find(doc => doc.name === key);
      if (document) {
        if (document.document_type_category_id === 1) {
          pushToBasic = true;
        }
        if (document.document_type_category_id === 2) {
          pushToCircumstantial = true;
        }
        if (document.name === 'public-debt-estate' || document.name === 'public-debt-social-security' ||
          document.name === 'public-debt-town-hall'
        ) {
          pushToPublicDebt = true;
        }
        if (document.case_creditor_id) {
          pushToCaseCreditor = true;
        }
        if (document.bank_asset_id) {
          pushToBankAccount = true;
        }
        if (!document.case_creditor_id && !document.bank_asset_id && document.custom) {
          pushToCustom = true;
        }
        if (pushToBasic) {
          this.partnerBasicDocumentRequests[key] = partnerDocumentRequests[key];
        }
        if (pushToCircumstantial) {
          this.partnerCircumstantialDocumentRequests[key] = partnerDocumentRequests[key];
        }
        if (pushToCaseCreditor) {
          this.partnerCaseCreditorDocumentRequests[key] = partnerDocumentRequests[key];
        }
        if (pushToPublicDebt) {
          this.partnerPublicDebtDocumentRequests[key] = partnerDocumentRequests[key];
        }
        if (pushToBankAccount) {
          this.partnerCaseBankAssetDocumentRequests[key] = partnerDocumentRequests[key];
        }
        if (pushToCustom) {
          this.partnerCustomDocumentRequests[key] = partnerDocumentRequests[key];
        }
      }
    });
  }

  private fetchCaseDocumentTypes(caseId: number, setCaseDocuments = false): void {
    this.subscriptions.push(
      this.caseDocumentService.getCaseDocumentTypeCategories(caseId, {document_types_only_active: 1}, ['document_types'])
        .subscribe(result => {
          this.documentTypeCategories  = result.data;
          this.allCaseDocumentTypes    = [];
          this.activeCaseDocumentTypes = [];
          this.documentTypeCategories.forEach(category => {
            category.document_types.forEach(documentType => {
              this.allCaseDocumentTypes.push(documentType);
              if (documentType.active) {
                this.activeCaseDocumentTypes.push(documentType);
              }
            });
          });
          if (setCaseDocuments) {
            this.setClientDocuments(this.case.client_files_by_type);
            this.setPartnerDocuments(this.case.partner_files_by_type);
          }
        })
    );
  }
}
