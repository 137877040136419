import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalEventInterface } from '../contracts/global-event.interface';
import { FilterType } from '../models/FilterType';

export abstract class SharedBaseApiService {
  protected apiUrl: string;
  protected apiDomain: string;
  protected globalEventsService: GlobalEventInterface;

  protected constructor(protected http: HttpClient,
                        protected router: Router,
                        globalEventsService: GlobalEventInterface,
                        apiUrl: string,
                        apiDomain: string,
                        secureApiProtocol: boolean
  ) {
    this.apiUrl    = apiUrl;
    this.apiDomain = (secureApiProtocol ? 'https://' : 'http://') + apiDomain;
  }

  public static convertFiltersForRequest(filter: FilterType, methodType: 'post' | 'get'): FilterType {
    const convertedFilter = {};
    const keys            = Object.keys(filter);

    if (methodType === 'post') {
      keys.forEach(key => {
        if (filter[key] !== null) {
          convertedFilter[key] = filter[key];
        }
      });
      return convertedFilter;
    }

    keys.forEach(key => {
      if (filter[key] !== null) {
        if (Array.isArray(filter[key])) {
          filter[key].forEach(id => {
            convertedFilter[key + '[]'] = filter[key];
          });
        } else if (filter[key] === true) {
          convertedFilter[key] = 1;
        } else if (filter[key] === false) {
          convertedFilter[key] = 0;
        } else {
          convertedFilter[key] = filter[key];
        }
      }
    });

    return convertedFilter;
  }

  public static getFormData(formValue): FormData {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];

      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }

    return formData;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status === 401) {
        this.globalEventsService.setAuthUser(null);
        this.router.navigateByUrl('/login');
      }

      if (error.status === 419) {
        this.getCSRFCookie().subscribe(r => {
          alert('Session expired, please try again');
        });
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${ error.status }, body was: ${ error.error }`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  public getCSRFCookie() {
    return this.http.get(this.apiDomain + '/sanctum/csrf-cookie')
      .pipe(catchError(response => this.handleError(response)));
  }

}
