import { Component, Input } from '@angular/core';
import { MymoidOrder } from '../../../../../../../../../_base-shared/models/Payment/Transaction/MymoidOrder';

@Component({
  selector: 'app-mymoid-details',
  templateUrl: './mymoid-details.component.html',
  styleUrls: ['./mymoid-details.component.scss']
})
export class MymoidDetailsComponent {
  @Input() mymoidOrder: MymoidOrder;
}
