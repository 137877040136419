<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>Arcopay</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row p-3">
        <div class="col-12">
          <h5>
            Arcopay Order ID: {{ arcopayOrder?.id }}
          </h5>
          <small id="frameResponse" hidden></small>
          <div *ngIf="arcopayWrapperActive" id="arcopayContainer"></div>
          <div class="d-flex justify-content-end">
            <button *ngIf="arcopayWrapperActive" mat-raised-button color="primary" (click)="closeArcoModal()">
              Close Modal
            </button>
            <button *ngIf="!arcopayWrapperActive" mat-raised-button color="primary" (click)="reloadArcoModal()">
              Reload widget
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
