<div>
  <div class="row">
    <div class="col-md-6">
      <!-- Payment Plan Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h5>{{ 'CASES.editor.payment.payment_plan.heading' | translate }}</h5>
          <small *ngIf="casePaymentPlanOutdated" class="badge badge-danger">
            {{ 'CASES.editor.payment.payment_plan.form.errors.plan_outdated' | translate }}
          </small>
        </div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="paymentPlanResponse" class="col-12">
              <app-server-response [response]="paymentPlanResponse"></app-server-response>
            </div>
          </div>
          <form *ngIf="paymentPlanForm" [formGroup]="paymentPlanForm">
            <div class="row">
              <!-- Phase One -->
              <div class="col-6">
                <!-- Phase One Total Value -->
                <app-input type="number" formControlName="phase_one_value" [extraLabel]="false" [fullWidth]="true"
                           [label]="('CASES.single.phase-one-value' | translate) + ' (inc VAT)'">
                </app-input>
                <!-- Phase One Monthly Fee -->
                <app-input type="number" formControlName="phase_one_monthly_fee" [extraLabel]="false"
                           [label]="'CASES.single.phase-one-fee' | translate" [fullWidth]="true">
                </app-input>
                <!-- Phase One Duration -->
                <app-input type="number" formControlName="phase_one_duration" [extraLabel]="false" readonly
                           [label]="'CASES.single.phase-one-duration' | translate" [fullWidth]="true">
                </app-input>
                <mat-error *ngIf="paymentPlanForm.get('phase_one_monthly_fee').hasError('uneven_modulo')">
                  {{ 'CASES.editor.payment.payment_plan.form.phase_one_monthly_fee.uneven_modulo' | translate }}
                </mat-error>
              </div>
              <!-- Phase Two -->
              <div class="col-6" *ngIf="selectedProduct.slug !== 'unified'">
                <!-- Phase Two Total Value -->
                <app-input type="number" formControlName="phase_two_value" [extraLabel]="false" [fullWidth]="true"
                           [label]="('CASES.single.phase-two-value' | translate) + ' (inc VAT)'">
                </app-input>
                <!-- Phase Two Monthly Fee-->
                <app-input type="number" formControlName="phase_two_monthly_fee" [extraLabel]="false"
                           [label]="(selectedProduct.group_slug === 'cajaplus' ?
                            ('CASES.editor.outcome.new_monthly_payment' | translate) :
                             ('CASES.single.phase-two-fee' | translate)) + ' (inc VAT)'"
                           [fullWidth]="true">
                </app-input>
                <!-- Phase Two Duration -->
                <app-input type="number" formControlName="phase_two_duration" [extraLabel]="false" readonly
                           [label]="(selectedProduct.group_slug === 'cajaplus' ?
                            ('CASES.editor.outcome.term_of_repayment_plan' | translate) :
                             ('CASES.single.phase-two-duration' | translate)) + ' (inc VAT)'"
                           [fullWidth]="true">
                </app-input>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6">
                    <!-- Defer Phase 2 -->
                    <app-input *ngIf="selectedProduct.group_slug !== 'cajaplus' && authUser?.packager?.master
                             && selectedProduct.slug !== 'unified'" type="checkbox" formControlName="phase_two_defer"
                               [extraLabel]="false" [fullWidth]="false" [label]="'Defer Phase 2'">
                    </app-input>
                  </div>
                  <div class="col-6">
                    <!-- Generate Scheduled Plans -->
                    <app-input
                      *ngIf="!case.debt_payment_plan.generated_scheduled_plans_at && [1,22].includes(authUser?.id)"
                      type="checkbox" formControlName="generate_scheduled_plans"
                      [extraLabel]="false" [fullWidth]="false" [label]="'Generate scheduled plans'">
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <app-spinner-btn type="submit" [loading]="submittingPaymentPlan" [name]="'SHARED.save' | translate"
                             [disabled]="paymentPlanForm?.invalid"
                             (click)="submitPaymentPlanForm(paymentPlanForm)">
            </app-spinner-btn>
          </div>
        </div>
      </div>
      <!-- ID Card Form -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h5>{{ 'CASES.editor.payment.id_card.heading' | translate }}</h5>
        </div>
        <div class="card-body">
          <!-- Client ID Card-->
          <app-case-id-card-editor *ngIf="case?.id" [case]="case" [user]="case.client"
                                   [inputLabel]="'Client 1 ' + ('USERS.model.id_card' | translate)"
                                   (updatedIdCard)="updateUserIdCard($event, 'client')"
                                   (updatedClient)="updatedClient($event, 'client')">
          </app-case-id-card-editor>
          <!-- Partner ID Card -->
          <app-case-id-card-editor *ngIf="case?.id && case.joint_application" [case]="case" [user]="case.partner"
                                   [inputLabel]="'Client 2 ' + ('USERS.model.id_card' | translate)" class="mt-3"
                                   (updatedIdCard)="updateUserIdCard($event, 'partner')"
                                   (updatedClient)="updatedClient($event, 'partner')">
          </app-case-id-card-editor>
        </div>
      </div>
      <!-- Payment Configuration -->
      <div class="card shadow mb-4">
        <div class="card-header">
          <h5>{{ 'CASES.editor.payment.payment_config.heading' | translate }}</h5>
        </div>
        <div class="card-body">
          <form *ngIf="paymentConfigurationForm" [formGroup]="paymentConfigurationForm">
            <!-- Initial Payment Day Date -->
            <div class="row">
              <div class="col-6">
                <mat-label>{{ "CASES.single.initial-payment-day" | translate }}</mat-label>
              </div>
              <div class="col-6">
                <div class="form-group" style="margin-bottom: 0">
                  <mat-form-field color="primary" appearance="fill" class="block-picker">
                    <mat-label>{{ "CASES.single.initial-payment-day" | translate }}</mat-label>
                    <input matInput [matDatepicker]="initialPaymentDayDate" formControlName="initial_payment_day">
                    <mat-datepicker-toggle matSuffix [for]="initialPaymentDayDate"></mat-datepicker-toggle>
                    <mat-datepicker #initialPaymentDayDate color="primary"></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="hasError(paymentConfigurationForm, 'initial_payment_day')">
                    {{ 'SHARED.field-required' | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <!-- Payment Day -->
            <app-input type="select" formControlName="payment_day" [extraLabel]="true"
                       [label]="'CASES.single.payment-day' | translate" [selectOptions]="daysInMonth"
                       [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
            </app-input>
            <!-- Contract Date -->
            <div class="row">
              <div class="col-6">
                <mat-label>{{ "CASES.single.contract-date" | translate }}</mat-label>
              </div>
              <div class="col-6">
                <div class="form-group" style="margin-bottom: 0">
                  <mat-form-field color="primary" appearance="fill" class="block-picker">
                    <mat-label>{{ "CASES.single.contract-date" | translate }}</mat-label>
                    <input matInput [matDatepicker]="contractDate" formControlName="contract_date">
                    <mat-datepicker-toggle matSuffix [for]="contractDate"></mat-datepicker-toggle>
                    <mat-datepicker #contractDate color="primary"></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="hasError(paymentConfigurationForm, 'contract_date')">
                    {{ 'SHARED.field-required' | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <!-- Payment Method -->
            <app-input *ngIf="paymentMethods.length" type="select" [searchable]="true"
                       formControlName="payment_method_id" [extraLabel]="true" [fullWidth]="true"
                       [label]="'CASES.single.payment-type' | translate" [selectOptions]="paymentMethods"
                       [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
            <!-- Initial Card Fee -->
            <div class="row">
              <div class="col-12" style="margin-bottom: 24px">
                <!-- Initial Fee -->
                <app-input type="number" formControlName="initial_fee" [fullWidth]="true"
                           [label]="('CASES.single.initial-fee' | translate) + ' (inc VAT)'" [extraLabel]="true">
                </app-input>
              </div>
            </div>
            <!-- SEPA IBAN -->
            <app-input *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                       formControlName="iban" [label]="'CASES.single.iban' | translate" [extraLabel]="true"
                       [fullWidth]="true">
            </app-input>
            <!-- Actions -->
            <div class="row">
              <div class="col-12 mt-3">
                <!-- Send SEPA Link -->
                <div *ngIf="getPaymentMethodSlug(paymentConfigurationForm.get('payment_method_id').value) === 'sepa'"
                     class="dropright d-inline">
                  <button type="button" [title]="'CASES.single.send-sepa-link' | translate" mat-raised-button
                          color="primary" class="btn me-2 mb-2" [matMenuTriggerFor]="sendSepaLinkMenu">
                    {{ 'CASES.single.send-sepa-link' | translate }}
                    <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
                  </button>
                  <mat-menu #sendSepaLinkMenu="matMenu" class="sendSepaLinkMenu">
                    <div class="dropdown-item" (click)="sendSepaLink('email')" href="#">Email</div>
                    <div class="dropdown-item" (click)="sendSepaLink('sms')" href="#">SMS</div>
                    <div class="dropdown-item" (click)="sendSepaLink('all')" href="#">Email & SMS</div>
                  </mat-menu>
                </div>
                <button [matMenuTriggerFor]="requestPaymentMenu" mat-raised-button
                        color="primary" class="me-2" (click)="$event.preventDefault()"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid">
                  {{ 'CASES.single.send-link' | translate }}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #requestPaymentMenu="matMenu">
                  <button mat-menu-item (click)="requestPaymentDialog('card', 'oppwa')">
                    <span>{{ "CASES.single.payments.actions.request_payment.options.oppwa" | translate }}</span>
                  </button>
                  <!--<button mat-menu-item *ngIf="appEnv === 'local' || appEnv === 'staging' ||-->
                  <!--         (this.authUser.role_id === 5 && allowedMymoidIds.includes(authUser.id))"-->
                  <!--        (click)="requestPaymentDialog('card', 'mymoid')">-->
                  <!--  <span>{{ "CASES.single.payments.actions.request_payment.options.mymoid" | translate }}</span>-->
                  <!--</button>-->
                </mat-menu>
                <!-- Charge card -->
                <button class="ms-2 me-2"
                        [disabled]="!paymentConfigurationForm.get('initial_fee').value || paymentConfigurationForm.get('initial_fee').invalid"
                        mat-raised-button color="primary" type="button" (click)="openCardForm('charge')">
                  {{ "CASES.single.pay" | translate }}
                  <ng-container *ngIf="paymentConfigurationForm.get('initial_fee').value">
                    ({{ paymentConfigurationForm.get('initial_fee').value + "€" }})
                  </ng-container>
                </button>
                <!-- Tokenize card -->
                <button mat-raised-button color="primary" type="button" (click)="openCardForm('verify')">
                  {{ "CASES.single.verify" | translate }}
                </button>
                <!-- Submit Form -->
                <div class="d-flex justify-content-end">
                  <app-spinner-btn type="submit" [loading]="submittingPaymentConfiguration"
                                   [name]="'SHARED.save' | translate"
                                   (click)="submitPaymentConfigurationForm(paymentConfigurationForm)">
                  </app-spinner-btn>
                </div>
                <div *ngIf="sendLinksError" class="alert alert-warning mt-3">
                  <strong class="text-danger mx-1">{{ sendLinksError }}</strong>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Summary -->
    <div *ngIf="paymentPlanForm && paymentConfigurationForm" class="col-6">
      <div class="card shadow mb-4">
        <div class="card-header"><h5>{{ "CASES.single.summary" | translate }}</h5></div>
        <div class="card-body">
          <div class="row">
            <div [hidden]="!isLoading" class="col-12">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
            <div *ngIf="summaryResponse" class="col-12">
              <app-server-response [response]="summaryResponse"></app-server-response>
            </div>
          </div>
          <table class="table">
            <tbody>
            <tr>
              <td>{{ 'CASES.financial_overview.table.disposable_income' | translate }}</td>
              <td>{{ (financialOverview?.income - financialOverview?.expenses) | number: '1.2-2' }}€</td>
              <td>
                {{ 'CASES.financial_overview.table.income' | translate }}:
                {{ financialOverview?.income | number: '1.2-2' }}€ <br/>
                {{ 'CASES.financial_overview.table.expenses' | translate }}:
                {{ financialOverview?.expenses | number: '1.2-2' }}€ <br/>
              </td>
              <td *ngIf="phaseOnePaid || phaseTwoPaid"></td>
            </tr>
            <tr class="{{ phaseOnePaid ? 'row-success' : '' }}">
              <td>{{ "CASES.single.phase-one" | translate }}</td>
              <td>{{ (paymentPlanForm.get('phase_one_value').value / taxRate) | number: '1.2-2' }}€ + 21% VAT</td>
              <td>{{ (+paymentPlanForm.get('phase_one_value').value) | number: '1.2-2' }}€ (inc VAT)</td>
              <td *ngIf="phaseOnePaid || phaseTwoPaid">
                <i *ngIf="phaseOnePaid" class="fal fa-check ms-1 text-success"></i>
              </td>
            </tr>
            <tr *ngIf="selectedProduct.group_slug !== 'cajaplus'" class="{{ phaseTwoPaid ? 'row-success' : '' }}">
              <td>{{ "CASES.single.phase-two" | translate }}</td>
              <td>{{ (paymentPlanForm.get('phase_two_value').value / taxRate) | number: '1.2-2' }}€ + 21% VAT</td>
              <td>{{ +paymentPlanForm.get('phase_two_value').value | number: '1.2-2' }}€ (inc VAT)</td>
              <td *ngIf="phaseOnePaid || phaseTwoPaid">
                <i *ngIf="phaseTwoPaid" class="fal fa-check ms-1 text-success"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'CASES.single.total' | translate }}</td>
              <td>{{
                  ((+paymentPlanForm.get('phase_one_value').value + +paymentPlanForm.get('phase_two_value').value) /
                    taxRate) | number: '1.2-2'
                }}€ + 21% VAT
              </td>
              <td>{{
                  (+(paymentPlanForm.get('phase_one_value').value) +
                    +(paymentPlanForm.get('phase_two_value').value)) | number: '1.2-2'
                }}€ (inc VAT)
              </td>
              <td *ngIf="phaseOnePaid || phaseTwoPaid"></td>
            </tr>
            </tbody>
          </table>
          <div class="form-group pt-3">
            <!-- Preview Contract -->
            <a target="_blank" [routerLink]="['/client', 'sign-contract']"
               [queryParams]="{uuid: case.uuid, request: case?.latest_contract_request?.uuid}" mat-button
               color="primary" type="button">
              {{ "CASES.single.contract-preview" | translate }}
            </a>
            <!-- Send contract button -->
            <button [disabled]="contractButtonDisabled()" mat-raised-button color="primary" class="btn" type="button"
                    [matMenuTriggerFor]="sendContractMenu">
              {{ "CASES.single.send-contract" | translate }}
              <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_right</mat-icon>
            </button>
            <mat-menu #sendContractMenu="matMenu" xPosition="after" class="sendContractMenu">
              <a class="dropdown-item" (click)="sendContract($event, 'email')">Email</a>
              <a class="dropdown-item" (click)="sendContract($event, 'sms')">SMS</a>
              <a class="dropdown-item" (click)="sendContract($event, 'all')">Email & SMS</a>
            </mat-menu>
            <!-- Resign contract button -->
            <div
              *ngIf="authUser.id === 1 || authUser.id === 27 || authUser.id === 36 || authUser.id === 3 || authUser.id === 6 || authUser.id === 967 || authUser.id === 235517 || authUser.id === 211750
                  || authUser.id === 582 || authUser.id === 513023 || authUser.id === 34 || authUser.id === 26 || authUser.id === 2 || authUser.id === 12 || authUser.id === 667649"
              class="dropright d-inline-block ms-2">
              <button [disabled]="!case?.latest_contract_request" mat-raised-button color="primary" class="btn"
                      [matMenuTriggerFor]="resignContractMenu"
                      type="button" [title]="!case?.latest_contract_request ? ('DOCUMENTS.resend-contract-disabled' | translate) :
                   ('DOCUMENTS.resend-contract' | translate)">
                {{ "DOCUMENTS.resend-contract" | translate }}
                <mat-icon style="">chevron_right</mat-icon>
              </button>
              <mat-menu #resignContractMenu="matMenu" class="resignContractMenu" xPosition="after">
                <a mat-menu-item (click)="resignContract($event, 'email', 'client')" href="#"> Client Email</a>
                <a mat-menu-item (click)="resignContract($event, 'sms', 'client')" href="#"> Client SMS</a>
                <a mat-menu-item (click)="resignContract($event, 'all', 'client')" href="#"> Client Email & SMS</a>
                <a *ngIf="case.joint_application"
                   mat-menu-item (click)="resignContract($event, 'email', 'partner')" href="#"> Client 2 Email</a>
                <a *ngIf="case.joint_application"
                   mat-menu-item (click)="resignContract($event, 'sms', 'partner')" href="#"> Client 2 SMS</a>
                <a *ngIf="case.joint_application"
                   mat-menu-item (click)="resignContract($event, 'all', 'partner')" href="#"> Client 2 Email &
                  SMS</a>
              </mat-menu>
            </div>

            <div *ngIf="!paymentPlanForm.get('phase_one_monthly_fee').value ||
             !paymentConfigurationForm.get('payment_day').value ||
              !paymentPlanForm.get('phase_two_monthly_fee').value" class="alert alert-warning mt-3">
              {{ 'CASES.single.please-set' | translate }}
              <strong class="text-danger mx-1" *ngIf="!paymentPlanForm.get('phase_one_monthly_fee').value">
                {{ 'CASES.single.set-phase-one-fee' | translate }},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!paymentPlanForm.get('phase_two_monthly_fee').value">
                {{ 'CASES.single.set-phase-two-fee' | translate }},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!paymentConfigurationForm.get('payment_day').value">
                {{ 'CASES.single.set-payment-day' | translate }},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!validIdCards(case)">
                {{ 'CASES.single.set-id-card' | translate }},
              </strong>
              <strong class="text-danger mx-1" *ngIf="!case.product_assigned_at">
                {{ 'CASES.single.set-product-outcome' | translate }},
              </strong>
              <strong class="text-danger mx-1">{{ 'CASES.single.and-save-case' | translate }}</strong>
              {{ 'CASES.single.to-enable' | translate }}
              <strong class="text-info">{{ 'CASES.single.send-contract' | translate }}</strong>
              {{ 'CASES.single.option' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
