<div class="row">
  <div class="col-12">
    <h4>{{"CONFIG.notifications.triggers" | translate}}</h4>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="configure" type="button">{{"CONFIG.notifications.new-trigger" | translate}}</button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5">
    <app-input type="text" [formControl]="search" [label]="'SHARED.search' | translate"
               [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true"
               [prefix]="'search'" [prefixType]="'icon'" (ngModelChange)="onChange()"></app-input>
  </div>
</div>

<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<div *ngIf="!isLoading" class="mat-elevation-z8 mt-5">
  <table mat-table matSort [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="to">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.when" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.on}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.status" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.status.name}} </td>
    </ng-container>

    <ng-container matColumnDef="notifications">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.notifications" | translate}}</th>
      <td mat-cell *matCellDef="let element" style="align-content: center">{{ element.notification_message.title }}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.staff" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.role.name}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pe-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
          <a href="#" (click)="openDeleteDialog(element.id, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
