import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { Status } from '../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../_base-shared/models/Status/StatusCategory';

export class StatusPickerTrait {
  public form: UntypedFormGroup;
  public statusFormControlName: string;
  public statusMultipleSelection: boolean;
  public statusIncludesCategory: boolean;
  public statusControl: UntypedFormControl                      = new UntypedFormControl([]);
  public statusCategoryControl: UntypedFormControl              = new UntypedFormControl([]);
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];
  public allStatuses: Array<Status>                      = [];

  protected subscriptions: Array<Subscription> = [];

  constructor(statusFormControlName: string, multipleSelection: boolean, statusIncludesCategory: boolean) {
    this.statusFormControlName   = statusFormControlName;
    this.statusMultipleSelection = multipleSelection;
    this.statusIncludesCategory  = statusIncludesCategory;
    this.statusControl           = new UntypedFormControl(this.statusMultipleSelection ? [] : null);
    this.statusCategoryControl   = new UntypedFormControl(this.statusMultipleSelection ? [] : null);
  }

  public statusCategoryChanged($event: MatSelectChange) {
    this.updateSelectedStatusCategories($event.value);
  }

  public statusChanged($event: MatSelectChange) {
    this.updateSelectedStatuses($event.value);
  }

  protected setStatusControls(selectedIds = []) {
    if (typeof selectedIds === 'number') {
      selectedIds = [selectedIds];
    }
    const selectedCategoryIds      = [];
    const selectedStatusIds        = [];
    const filteredStatusCategories = [];

    selectedIds.forEach(selectedId => {
      const status = this.allStatuses.find(findStatus => findStatus.id === selectedId);
      if (status && status.status_id) {
        selectedStatusIds.push(status.id);

        const category = this.statusCategories.find(findCategory => findCategory.id === status.status_id);
        if (category && !selectedCategoryIds.find(selectedCategoryId => selectedCategoryId === category.id)) {
          filteredStatusCategories.push(category);
          selectedCategoryIds.push(category.id);
        }
      }
    });
    this.filteredStatusCategories = filteredStatusCategories.length ?
        filteredStatusCategories :
        this.filteredStatusCategories;
    this.statusCategoryControl.patchValue(this.statusMultipleSelection ? selectedCategoryIds : selectedCategoryIds[0]);
    this.statusControl.patchValue(this.statusMultipleSelection ? selectedStatusIds : selectedStatusIds[0]);
  }

  public updateSelectedStatusCategories(selectedStatusCategoryIds: Array<number> | number) {
    const selectedStatuses = this.statusControl.value ?
        (this.statusMultipleSelection ? this.statusControl.value : [this.statusControl.value]) :
        [];

    if (typeof selectedStatusCategoryIds === 'number') {
      selectedStatusCategoryIds = [selectedStatusCategoryIds];
    }

    let filteredStatusCategories = [];
    const toSelectStatuses       = [];

    if (selectedStatusCategoryIds.length) {
      selectedStatusCategoryIds.forEach(selectedCategoryId => {
        const statusCategory            = this.statusCategories.find(category => category.id === selectedCategoryId);
        let categoryHasStatusesSelected = false;
        filteredStatusCategories.push(statusCategory);

        statusCategory.statuses.forEach(status => {
          if (selectedStatuses.find(findStatusId => findStatusId === status.id)) {
            categoryHasStatusesSelected = true;
            toSelectStatuses.push(status.id);
          }
        });
        if (!categoryHasStatusesSelected && this.statusIncludesCategory) {
          toSelectStatuses.push(selectedCategoryId);
        }
      });
    } else {
      filteredStatusCategories = this.statusCategories;
    }

    this.filteredStatusCategories = filteredStatusCategories;

    this.form.get(this.statusFormControlName)
        .patchValue(this.statusMultipleSelection ? toSelectStatuses : toSelectStatuses[0]);
  }

  private updateSelectedStatuses(selectedStatusIds: Array<number> | number) {
    const selectedStatusCategories = this.statusCategoryControl.value ?
        (this.statusMultipleSelection ? this.statusCategoryControl.value : [this.statusCategoryControl.value]) :
        [];
    const toSelectStatuses         = this.statusIncludesCategory ? [...selectedStatusCategories] : [];

    if (typeof selectedStatusIds === 'number') {
      selectedStatusIds = [selectedStatusIds];
    }

    selectedStatusIds.forEach(statusId => {
      toSelectStatuses.push(statusId);
      const status        = this.allStatuses.find(findStatus => findStatus.id === statusId);
      const categoryIndex = toSelectStatuses.findIndex(findStatusId => findStatusId === status.status_id);
      if (categoryIndex >= 0) {
        toSelectStatuses.splice(categoryIndex, 1);
      }
    });

    this.form.get(this.statusFormControlName)
        .patchValue(this.statusMultipleSelection ? toSelectStatuses : toSelectStatuses[0]);
  }

  public clearStatusControls($event) {
    this.clearStatusControl($event, this.statusControl, 'status');
    this.clearStatusControl($event, this.statusCategoryControl, 'category');
  }

  public clearStatusControl($event, control: UntypedFormControl, type: 'category' | 'status') {
    $event.stopPropagation();
    if (type === 'category') {
      this.updateSelectedStatusCategories([]);
    } else {
      this.updateSelectedStatuses([]);
    }

    control.patchValue(this.statusMultipleSelection ? [] : null);
  }
}
