import { Injectable } from '@angular/core';
import { SystemEventConfiguration } from '../../../../../../_base-shared/models/SystemEventConfiguration';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SystemEventService extends MainBaseApiService{
  public index(): Observable<LaravelResourceResponse<SystemEventConfiguration>> {
    return this.http.get<LaravelResourceResponse<SystemEventConfiguration>>(this.apiUrl + '/system-events')
      .pipe(catchError(response => this.handleError(response)));
  }

  get(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/system-events`)
            .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/system-events`, data)
            .pipe(catchError(response => this.handleError(response)));
  }
}
