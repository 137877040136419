<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h5>{{'CASES.case-snapshot.assets.heading' | translate}}</h5>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0">
    <app-empty-dataset
      [show]="!(bankAccounts.length > 0) && !(properties.length > 0) && !(vehicles.length > 0) && !(other.length > 0)"
      [message]="'CASES.case-snapshot.assets.empty-assets' | translate">
    </app-empty-dataset>
    <div>
      <h5 class="mt-2" *ngIf="bankAccounts.length > 0"><b>{{'CASES.case-snapshot.assets.bank_accounts' | translate}}</b>
      </h5>
      <mat-card class="my-3" *ngFor="let asset of bankAccounts">
        <div class="row">
          <ng-container>
            <div class="col" style="padding: 0px;border-right: 1px solid #e9e9e9;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">    {{'CASE_ASSET.model.age' | translate}}</th>
                  <td>{{asset.age}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.make' | translate}}</th>
                  <td>{{asset.make}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.model' | translate}}</th>
                  <td>{{asset.model}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.notes' | translate}}</th>
                  <td>{{asset.notes}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.value' | translate}}</th>
                  <td>{{ asset.value | currency }}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.entity' | translate}}</th>
                  <td>{{asset?.entity?.name}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.office' | translate}}</th>
                  <td>{{asset.office}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{"AEP-SIGNATURE.travel_fuel" | translate }}</th>
                  <td> {{ asset.balance | currency }}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.exposed' | translate}}</th>
                  <td>{{asset.exposed | currency }}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.mortgage' | translate}}</th>
                  <td>{{asset.mortgage | currency }}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.address' | translate}}</th>
                  <td>{{asset.address}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.home_type' | translate}}</th>
                  <td>{{asset.home_type}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.ownership' | translate}}</th>
                  <td>{{asset.ownership}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col" style="padding: 0px;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.post_code' | translate}}</th>
                  <td>{{asset.post_code}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.liquidable' | translate}}</th>
                  <td>{{asset.liquidable | currency }}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.on_finance' | translate}}</th>
                  <td>{{asset.on_finance}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.registration' | translate}}</th>
                  <td>{{asset.registration}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.account_number' | translate}}</th>
                  <td>{{asset.account_number}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.estimated_value' | translate}}</th>
                  <td>{{asset.estimated_value | currency }}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.acquisition_value' | translate}}</th>
                  <td>{{asset.acquisition_value | currency }}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.other_assets_type' | translate}}</th>
                  <td>{{asset.other_assets_type}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.additional_partner' | translate}}</th>
                  <td>{{asset.additional_partner}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.securities_account' | translate}}</th>
                  <td>{{asset.securities_account}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.outstanding_finance' | translate}}</th>
                  <td>{{asset.outstanding_finance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.property_registration' | translate}}</th>
                  <td>{{asset.property_registration}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.client_documents_visible' | translate}}</th>
                  <td>{{asset.client_documents_visible}}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </mat-card>
      <h5 *ngIf="properties.length > 0"><b>{{'CASES.case-snapshot.assets.properties' | translate}}</b></h5>
      <mat-card class="my-3" *ngFor="let property of properties">
        <div class="row">
          <ng-container>
            <div class="col" style="padding: 0px;border-right: 1px solid #e9e9e9;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">    {{'CASE_ASSET.model.age' | translate}}</th>
                  <td>{{property.age}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.make' | translate}}</th>
                  <td>{{property.make}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.model' | translate}}</th>
                  <td>{{property.model}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.notes' | translate}}</th>
                  <td>{{property.notes}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.value' | translate}}</th>
                  <td>{{property.value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.entity' | translate}}</th>
                  <td>{{property?.entity?.name}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.office' | translate}}</th>
                  <td>{{property.office}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{"AEP-SIGNATURE.travel_fuel" | translate }}</th>
                  <td> {{property.balance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.exposed' | translate}}</th>
                  <td>{{property.exposed | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.mortgage' | translate}}</th>
                  <td>{{property.mortgage | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.address' | translate}}</th>
                  <td>{{property.address}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.home_type' | translate}}</th>
                  <td>{{property.home_type}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.ownership' | translate}}</th>
                  <td>{{property.ownership}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col" style="padding: 0px;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.post_code' | translate}}</th>
                  <td>{{property.post_code}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.liquidable' | translate}}</th>
                  <td>{{property.liquidable | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.on_finance' | translate}}</th>
                  <td>{{property.on_finance}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.registration' | translate}}</th>
                  <td>{{property.registration}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.account_number' | translate}}</th>
                  <td>{{property.account_number}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.estimated_value' | translate}}</th>
                  <td>{{property.estimated_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.acquisition_value' | translate}}</th>
                  <td>{{property.acquisition_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.other_assets_type' | translate}}</th>
                  <td>{{property.other_assets_type}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.additional_partner' | translate}}</th>
                  <td>{{property.additional_partner}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.securities_account' | translate}}</th>
                  <td>{{property.securities_account}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.outstanding_finance' | translate}}</th>
                  <td>{{property.outstanding_finance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.property_registration' | translate}}</th>
                  <td>{{property.property_registration}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.client_documents_visible' | translate}}</th>
                  <td>{{property.client_documents_visible}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </mat-card>
      <h5 *ngIf="vehicles.length > 0"><b>{{'CASES.case-snapshot.assets.vehicles' | translate}}</b></h5>
      <mat-card class="my-3" *ngFor="let vehicle of vehicles">
        <div class="row">
          <ng-container>
            <div class="col" style="padding: 0px;border-right: 1px solid #e9e9e9;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">    {{'CASE_ASSET.model.age' | translate}}</th>
                  <td>{{vehicle.age}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.make' | translate}}</th>
                  <td>{{vehicle.make}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.model' | translate}}</th>
                  <td>{{vehicle.model}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.notes' | translate}}</th>
                  <td>{{vehicle.notes}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.value' | translate}}</th>
                  <td>{{vehicle.value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.entity' | translate}}</th>
                  <td>{{vehicle?.entity?.name}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.office' | translate}}</th>
                  <td>{{vehicle.office}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{"AEP-SIGNATURE.travel_fuel" | translate }}</th>
                  <td> {{vehicle.balance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.exposed' | translate}}</th>
                  <td>{{vehicle.exposed | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.mortgage' | translate}}</th>
                  <td>{{vehicle.mortgage | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.address' | translate}}</th>
                  <td>{{vehicle.address}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.home_type' | translate}}</th>
                  <td>{{vehicle.home_type}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.ownership' | translate}}</th>
                  <td>{{vehicle.ownership}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col" style="padding: 0px;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.post_code' | translate}}</th>
                  <td>{{vehicle.post_code}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.liquidable' | translate}}</th>
                  <td>{{vehicle.liquidable | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.on_finance' | translate}}</th>
                  <td>{{vehicle.on_finance}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.registration' | translate}}</th>
                  <td>{{vehicle.registration}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.account_number' | translate}}</th>
                  <td>{{vehicle.account_number}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.estimated_value' | translate}}</th>
                  <td>{{vehicle.estimated_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.acquisition_value' | translate}}</th>
                  <td>{{vehicle.acquisition_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.other_assets_type' | translate}}</th>
                  <td>{{vehicle.other_assets_type}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.additional_partner' | translate}}</th>
                  <td>{{vehicle.additional_partner}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.securities_account' | translate}}</th>
                  <td>{{vehicle.securities_account}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.outstanding_finance' | translate}}</th>
                  <td>{{vehicle.outstanding_finance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.property_registration' | translate}}</th>
                  <td>{{vehicle.property_registration}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.client_documents_visible' | translate}}</th>
                  <td>{{vehicle.client_documents_visible}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </mat-card>
      <h5 *ngIf="other.length > 0"><b>{{'CASES.case-snapshot.assets.other' | translate}}</b></h5>
      <mat-card class="my-3" *ngFor="let asset of other">
        <div class="row">
          <ng-container>
            <div class="col" style="padding: 0px;border-right: 1px solid #e9e9e9;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">    {{'CASE_ASSET.model.age' | translate}}</th>
                  <td>{{asset.age}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.make' | translate}}</th>
                  <td>{{asset.make}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.model' | translate}}</th>
                  <td>{{asset.model}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.notes' | translate}}</th>
                  <td>{{asset.notes}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.value' | translate}}</th>
                  <td>{{asset.value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.entity' | translate}}</th>
                  <td>{{asset?.entity?.name}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.office' | translate}}</th>
                  <td>{{asset.office}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{"AEP-SIGNATURE.travel_fuel" | translate }}</th>
                  <td> {{asset.balance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.exposed' | translate}}</th>
                  <td>{{asset.exposed | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.mortgage' | translate}}</th>
                  <td>{{asset.mortgage | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.address' | translate}}</th>
                  <td>{{asset.address}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.home_type' | translate}}</th>
                  <td>{{asset.home_type}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.ownership' | translate}}</th>
                  <td>{{asset.ownership}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col" style="padding: 0px;">
              <table class="table table-striped">
                <tbody>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.post_code' | translate}}</th>
                  <td>{{asset.post_code}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.liquidable' | translate}}</th>
                  <td>{{asset.liquidable | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.on_finance' | translate}}</th>
                  <td>{{asset.on_finance}}</td>
                </tr>
                <tr>
                  <th scope="row">{{'CASES.case-snapshot.assets.registration' | translate}}</th>
                  <td>{{asset.registration}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.account_number' | translate}}</th>
                  <td>{{asset.account_number}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.estimated_value' | translate}}</th>
                  <td>{{asset.estimated_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.acquisition_value' | translate}}</th>
                  <td>{{asset.acquisition_value | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.other_assets_type' | translate}}</th>
                  <td>{{asset.other_assets_type}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.additional_partner' | translate}}</th>
                  <td>{{asset.additional_partner}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.securities_account' | translate}}</th>
                  <td>{{asset.securities_account}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.outstanding_finance' | translate}}</th>
                  <td>{{asset.outstanding_finance | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <th scope="row">  {{'CASES.case-snapshot.assets.property_registration' | translate}}</th>
                  <td>{{asset.property_registration}}</td>
                </tr>
                <tr>
                  <th scope="row"> {{'CASES.case-snapshot.assets.client_documents_visible' | translate}}</th>
                  <td>{{asset.client_documents_visible}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>
</div>
