import {Component, OnInit} from '@angular/core';
import {Case} from '../../../../../../../_base-shared/models/Case/Case';
import {ActivatedRoute} from '@angular/router';
import {CaseService} from '../../case.service';
import {debounce, finalize} from 'rxjs/operators';
import {MiscConfigService} from '../../../config/misc-config/misc-config.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CaseLoan} from '../../../../../../../_base-shared/models/Case/CaseLoan';
import {CaseCreditorService} from '../../case-creditor.service';
import {CaseCreditorPivot} from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import {interval} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-case-loan-calculator',
  templateUrl: './case-loan-calculator.component.html',
  styleUrls: ['./case-loan-calculator.component.scss']
})
export class CaseLoanCalculatorComponent implements OnInit {
  public case: Case;
  public isLoading = 0;
  public aprRate: number;
  public debtReductionFee: number;
  public totalLoaned: number;
  public caseCreditors: Array<CaseCreditorPivot>;
  public form: UntypedFormGroup;
  public isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
    private configService: MiscConfigService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['case_loan'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.fetchAprRate();
        });
    });
  }

  private fetchAprRate(): void {
    this.isLoading++;

    this.configService.getConfigData()
      .pipe(finalize(() => this.isLoading--))
      .subscribe(response => {
        this.aprRate = response.data.find(configData => configData.key === 'apr_rate').value;
        this.debtReductionFee = response.data.find(configData => configData.key === 'debt_reduction_fee').value;
        this.isLoading++;
        this.caseCreditorService.indexCaseCreditorsLegacy(this.case.id, {}).pipe(finalize(() => this.isLoading--))
          .subscribe(result => {
            this.caseCreditors = result.data.unsecured ? result.data.unsecured : [];

            this.buildForm(this.case.case_loan);
          });
      });
  }

  private buildForm(caseLoan: CaseLoan): void {
    const debtAmount = this.caseCreditors.reduce((a, b) => a + (+b.pivot.debt_amount || 0), 0);
    const negotiatedAmount = this.caseCreditors.reduce((a, b) => a + (+b.pivot.negotiated_amount || 0), 0);

    this.form = this.fb.group({
      original_debt_amount: [caseLoan ? caseLoan.original_debt_amount : debtAmount, [Validators.min(1), Validators.required]],
      debt_settlement_agreed: [caseLoan ? caseLoan.debt_settlement_agreed : negotiatedAmount, [Validators.min(0), Validators.required]],
      total_reduction: [null, Validators.required],
      debt_reduction_fee: [caseLoan ? caseLoan.debt_reduction_fee : null, [Validators.min(0), Validators.required]],
      new_loan_amount: [null, Validators.required],
      phase_one_duration:     [caseLoan ? caseLoan.phase_one_duration : 36, [Validators.min(1), Validators.required]],
      phase_one_value:        [caseLoan ? caseLoan.phase_one_value : null, [Validators.required]],
      phase_one_monthly_fee:  [caseLoan ? caseLoan.phase_one_monthly_fee : null, [Validators.required]],
      repayable_amount:       [caseLoan ? caseLoan.repayable_amount : null, [Validators.required]]
    });

    this.subscribeToLoanChanges();
  }

  private handleLoanCalculation(): void {
    const originalDebtAmount = this.form.get('original_debt_amount').value;
    const debtSettlementAgreed = this.form.get('debt_settlement_agreed').value;

    if (!originalDebtAmount || !debtSettlementAgreed) {
      return;
    }

    this.form.markAsPristine();
    this.calculateLoan();
  }

  private calculateLoan(): void {
    const interestRate = +this.aprRate;
    const monthlyInterestRate = +(interestRate / 1200).toFixed(10);

    const originalDebtAmount = +this.form.get('original_debt_amount').value;
    const debtSettlementAgreed = +this.form.get('debt_settlement_agreed').value;
    const debtReduction = originalDebtAmount - debtSettlementAgreed;
    const debtReductionFee = (debtReduction * (+this.debtReductionFee / 100));
    const newLoanAmount = debtSettlementAgreed + (+debtReductionFee * 1.21);
    const newLoanTerm = +this.form.get('phase_one_duration').value;
    let monthlyPayment = +newLoanAmount * (monthlyInterestRate / (1 - Math.pow((1 + monthlyInterestRate), -newLoanTerm)));
    monthlyPayment = +monthlyPayment.toFixed(2);

    this.form.get('phase_one_monthly_fee').patchValue(monthlyPayment);
    this.totalLoaned = +(monthlyPayment * newLoanTerm).toFixed(10);
    this.form.get('phase_one_value').patchValue(newLoanAmount.toFixed(2));
    this.form.get('repayable_amount').patchValue(this.totalLoaned);
    this.form.get('debt_reduction_fee').patchValue(debtReductionFee.toFixed(2));
    this.form.get('total_reduction').patchValue(debtReduction.toFixed(2));
    this.form.get('new_loan_amount').patchValue(newLoanAmount.toFixed(2));
  }

  private subscribeToLoanChanges(): void {
    this.handleLoanCalculation();

    this.form.get('phase_one_duration').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.handleLoanCalculation());

    this.form.get('original_debt_amount').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.handleLoanCalculation());

    this.form.get('debt_settlement_agreed').valueChanges
      .pipe(debounce(() => interval(500)))
      .subscribe(value => this.handleLoanCalculation());
  }

  public saveLoan(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
    }

    this.isSubmitting = true;

    this.caseService.saveLoan(this.case.id, form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(() => {
        this.toastr.success(this.translate.instant('CASES.loan.success'));
      }, err => {
        this.toastr.error(this.translate.instant('CASES.loan.error'));
      });
  }
}
