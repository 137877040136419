<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h5>
        {{ 'DISTRIBUTION.batch.model_name.singular' | translate }}:
        <span *ngIf="distributionBatch">{{ distributionBatch.name }}</span>
        <small *ngIf="distributionBatch">
          <span class="badge badge-info">{{ distributionBatch.status }}</span>
          ({{ 'DISTRIBUTION.batch.detail.subheading_date_label' | translate}}:
          {{ distributionBatch.submitted_at ? (distributionBatch.submitted_at | date) : 'N/A' }})
        </small>
      </h5>
      <div *ngIf="!batchTotalAmounts">
        <mat-progress-bar class="cash-hurdle-progress" mode="indeterminate"></mat-progress-bar>
      </div>
      <div *ngIf="batchTotalAmounts" class="d-flex justify-content-center mt-1">
        <div>
          <div class="text-center">
            <small>{{ 'DISTRIBUTION.batch.detail.total_progress_bar.cash_hurdle' | translate }}</small>
          </div>
          <mat-progress-bar class="cash-hurdle-progress" mode="determinate"
                            [value]="batchTotalAmounts.total_distributed_amount / (batchTotalAmounts.total_cash_hurdle_amount / 100)">
          </mat-progress-bar>
          <div class="text-center">
            <small>
              {{ batchTotalAmounts.total_distributed_amount | currency: 'EUR' }} /
              {{ batchTotalAmounts.total_cash_hurdle_amount | currency: 'EUR' }}
            </small>
          </div>
        </div>
        <div *ngIf="componentType === 'admin'" class="ms-3">
          <div class="text-center">
            <small>{{ 'DISTRIBUTION.batch.detail.total_progress_bar.fees_retained' | translate }}</small>
          </div>
          <mat-progress-bar class="fees-retained-progress" mode="determinate" color="accent"
                            [value]="batchTotalAmounts.total_fees_retained / (batchTotalAmounts.total_fees_obtainable / 100)">
          </mat-progress-bar>
          <div class="text-center">
            <small>
              {{ batchTotalAmounts.total_fees_retained | currency: 'EUR' }} /
              {{ batchTotalAmounts.total_fees_obtainable | currency: 'EUR' }}
            </small>
          </div>
        </div>
        <div *ngIf="componentType === 'distribution'" class="ms-3">
          <div class="text-center">
            <small>{{ 'DISTRIBUTION.batch.detail.total_progress_bar.fees_retained' | translate }}</small>
          </div>
          <mat-progress-bar class="fees-retained-progress" mode="determinate" color="accent"
                            [value]="batchTotalAmounts.total_provider_fees_retained / (batchTotalAmounts.total_provider_fees_obtainable / 100)">
          </mat-progress-bar>
          <div class="text-center">
            <small>
              {{ batchTotalAmounts.total_provider_fees_retained | currency: 'EUR' }} /
              {{ batchTotalAmounts.total_provider_fees_obtainable | currency: 'EUR' }}
            </small>
          </div>
        </div>
      </div>
    </mat-card-title>
    <div class="header-actions">
      <a mat-raised-button [routerLink]="componentType === 'admin' ?
      ['/distribution', 'batches'] : ['/distribution-provider', 'batches']">
        {{ 'SHARED.back' | translate }}
      </a>
      <a *ngIf="componentType === 'admin' && distributionBatch" mat-raised-button color="primary" class="ms-3"
         [routerLink]="['/distribution', 'batches', distributionBatch.id, 'edit']">
        {{ "SHARED.edit" | translate }}
      </a>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ms-3"
              (click)="changeComponentType('distribution')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_provider_view' | translate }}
      </button>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'distribution'" mat-raised-button class="ms-3"
              (click)="changeComponentType('admin')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <mat-expansion-panel class="mb-3">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "PAYMENTS.advanced-filters" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <!-- Payment Status -->
        <div class="col-4">
          <app-input type="select" [formControl]="paymentStatusFilter" appearance="fill" fullWidth="false" multiple
                     [label]="'CASES.single.general.status_editor.payment_status' | translate" [searchable]="true"
                     [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
        </div>
        <!-- Case Status -->
        <div class="col-4">
          <app-input type="select" [formControl]="caseStatusFilter" appearance="fill" [fullWidth]="true"
                     [label]="'CONFIG.drip-campaign.editor.statusable_id.label' | translate" multiple
                     [selectOptions]="statusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                     [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
          </app-input>
        </div>
        <!-- Search -->
        <div class="col-4">
          <mat-form-field appearance="fill" class="input-full-width">
            <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [formControl]="searchFilter" type="text">
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- Table List -->
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <mat-table [dataSource]="caseDistributions" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!-- Case Distribution Batch Index -->
          <ng-container matColumnDef="batch_index">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Index
            </th>
            <td mat-cell *matCellDef="let element">
              <span [matTooltip]="element.entered_batch_at | date:'d MMM y, HH:mm'">{{ element.batch_index }}</span>
            </td>
          </ng-container>
          <!-- Case Ref Number -->
          <ng-container matColumnDef="case_ref_number">
            <th mat-header-cell *matHeaderCellDef>
              {{ "CASES.model.ref_number" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a *ngIf="componentType === 'admin'" [routerLink]="['/cases', element.case.id]">
                {{ element.case.ref_number }}
              </a>
              <a *ngIf="componentType === 'distribution'" [routerLink]="[element.case.uuid, 'payments']">
                {{ element.case.ref_number }}
              </a>
            </td>
          </ng-container>
          <!-- Status -->
          <ng-container matColumnDef="case_status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.case_status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.case.status?.name }}
            </td>
          </ng-container>
          <!-- Status Updated At -->
          <ng-container matColumnDef="case_status_updated_at">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.case_status_updated_at' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.case.latest_case_status_log?.created_at | date }}
            </td>
          </ng-container>
          <!-- Payment Status -->
          <ng-container matColumnDef="case_payment_status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.case_payment_status' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" >
              {{ element.case.payment_status?.name }}
            </td>
          </ng-container>
          <!-- Monthly Fee Amount -->
          <ng-container matColumnDef="monthly_fee_amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.cases.list.table.phase_2_installment_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.monthly_fee_amount | currency: 'EUR' }}
            </td>
          </ng-container>
          <!-- Batch Contract Amount -->
          <ng-container matColumnDef="batch_contract_amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.cases.list.table.contract_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              {{ element.batch_contract_amount  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Distributed Amount -->
          <ng-container matColumnDef="distributed_amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.distributed_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              {{ element.distributed_amount  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Funded Amount -->
          <ng-container matColumnDef="funded_amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.funding_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              {{ element.funded_amount  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Cash Hurdle Amount -->
          <ng-container matColumnDef="cash_hurdle_amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.cash_hurdle_amount' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              {{ element.cash_hurdle_amount  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Fees Retained -->
          <ng-container matColumnDef="fees_retained">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.fees_retained' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              <span *ngIf="componentType ==='admin'">{{ element.fees_retained  | currency:'EUR' }}</span>
              <span *ngIf="componentType ==='distribution'">
                {{ element.provider_fees_retained  | currency:'EUR' }}
              </span>
            </td>
          </ng-container>
          <!-- Contract Download -->
          <ng-container matColumnDef="contract_download">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.contract_download' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              <a *ngIf="element.case.latest_contract?.pdf_location" mat-raised-button target="_blank" class="mt-1 mb-1"
                 href="{{storageUrl + element.case.latest_contract.pdf_location}}">
                {{ 'DISTRIBUTION.batch.detail.cases_table.contract_download' | translate }}
              </a>
            </td>
          </ng-container>
          <!-- Exoneration Download -->
          <ng-container matColumnDef="exoneration_download">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.epi_doc' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              <a *ngIf="element.case.client_auto_exoneration_document?.files[0]?.path" mat-raised-button target="_blank" class="mt-1 mb-1"
                 href="{{storageUrl + element.case?.client_auto_exoneration_document?.files[0]?.path}}">
                {{ 'DISTRIBUTION.batch.detail.cases_table.document' | translate }}
              </a>
            </td>
          </ng-container>
          <!-- Progress -->
          <ng-container matColumnDef="progress_bar">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.progress' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pe-2">
              <div class="d-flex justify-content-center mt-3">
                <mat-progress-bar class="cash-hurdle-progress" mode="determinate"
                                  [matTooltip]="(element.distributed_amount | currency:'EUR') + ' / ' +
                                   (element.cash_hurdle_amount | currency:'EUR')"
                                  [value]="element.distributed_amount / (element.cash_hurdle_amount / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'admin'" class="fees-retained-progress" mode="determinate"
                                  color="accent" [matTooltip]="(element.fees_retained | currency:'EUR') + ' / ' +
                                   (element.fees_obtainable | currency:'EUR')"
                                  [value]="element.fees_retained / (element.fees_obtainable / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'distribution'" class="fees-retained-progress"
                                  mode="determinate" color="accent"
                                  [matTooltip]="(element.provider_fees_retained | currency:'EUR') + ' / ' +
                                   (element.provider_fees_obtainable | currency:'EUR')"
                                  [value]="element.provider_fees_retained / (element.provider_fees_obtainable / 100)">
                </mat-progress-bar>
              </div>
            </td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && caseDistributions && caseDistributions.data.length < 1" style="padding: 50px">
          <h5 class="text-center">{{ "CASES.no-cases" | translate }}</h5>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="footer-actions ms-3">
          <!--<button *ngIf="componentType === 'admin'" mat-raised-button color="primary"-->
          <!--        [disabled]="selection.selected.length === 0" (click)="downloadContracts(distributionBatch)">-->
          <!--  {{ 'DISTRIBUTION.batch.detail.table_actions.download_all_contracts' | translate }}-->
          <!--</button>-->
        </div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[1, 5, 10, 20, 50, 100]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
