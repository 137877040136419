import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppSelectOption } from '../../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { AppDocument } from '../../../../../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentType } from '../../../../../../../../../_base-shared/models/Document/AppDocumentType';
import { MainGlobalEventService } from '../../../../../../_shared/services/main-global-event.service';
import { DocumentTypeService } from '../../../../../document/document-type.service';
import { CaseAssetService } from '../../../../case-asset.service';
import { CaseCreditorService } from '../../../../case-creditor.service';
import {
  BaseDocumentFileUploaderComponent
} from '../base-document-file-uploader/base-document-file-uploader.component';

@Component({
  selector:    'app-case-document-agent-file-uploader-v3',
  templateUrl: './case-document-agent-file-uploader-v3.component.html',
  styles:      []
})
export class CaseDocumentAgentFileUploaderV3Component extends BaseDocumentFileUploaderComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() filterDocumentableType!: 'case_creditor' | 'case_public_debt' | 'case_asset';
  public prependContractTypes = true;

  public selectedDocumentType!: AppDocumentType;
  public clientRoleOptions!: Array<AppSelectOption>;

  public form: FormGroup;
  public isUploading         = false;
  public selectedUploadFiles = [];

  public uploadedDocument: AppDocument;

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected toastr: ToastrService,
    protected cookieService: CookieService,
    protected translate: TranslateService,
    protected globalEventsService: MainGlobalEventService,
    protected documentTypeService: DocumentTypeService,
    protected caseCreditorService: CaseCreditorService,
    protected caseAssetService: CaseAssetService,
  ) {
    super(fb, dialog, toastr, cookieService, translate, globalEventsService, documentTypeService, caseCreditorService, caseAssetService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.buildClientRoleOptions();
    const requestData: any = {};
    const relations        = ['document_types', 'document_types.documents'];

    if (this.filterDocumentableType) {
      requestData.types = ['client_' + this.filterDocumentableType];
    } else {
      requestData.types = ['client_case', 'client_case_creditor', 'client_case_public_debt', 'client_case_asset', 'client_case_custom'];
    }

    this.fetchDocumentTypeCategories(this.case, requestData, relations);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  protected handleUploadSuccess(result: LaravelResourceResponse, documentType: AppDocumentType = null, document: AppDocument = null): void {
    this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
    this.uploadedDocument = result.data;
  }

  private buildClientRoleOptions(): void {
    this.clientRoleOptions = [
      {value: 'client', label: this.translate.instant('CASES.single.client')},
    ]
    if (this.case.joint_application) {
      this.clientRoleOptions.push(
        {value: 'partner', label: this.translate.instant('CASES.single.partner')},
      )
    }
  }

  protected buildForms(): void {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      app_document_type_id: [null, [Validators.required]],
      client_role:          ['client', [Validators.required]],
      app_document_id:      [null],
      documentable_type:    [null],
      documentable_id:      [null],
      files:                [[]],
    });
    this.form.get('app_document_type_id').valueChanges.subscribe(documentTypeId => {
      this.docTypeSelected(documentTypeId)
    });
  }

  public docTypeSelected(documentTypeId: number): void {
    this.selectedDocumentType = this.caseDocumentTypes.find(docType => docType.id === documentTypeId);
    if (this.selectedDocumentType?.custom_input_logic === 'case_creditor_selection' ||
      this.selectedDocumentType?.custom_input_logic === 'case_public_debt_selection' ||
      this.selectedDocumentType?.custom_input_logic === 'case_asset_selection'
    ) {
      this.form.get('documentable_type').patchValue(this.selectedDocumentType.custom_input_logic.replace('_selection', ''));
      this.form.get('documentable_type').setValidators([Validators.required])
      this.form.get('documentable_id').setValidators([Validators.required])
    } else {
      this.form.get('documentable_type').patchValue(null);
      this.form.get('documentable_type').setValidators([])
      this.form.get('documentable_id').setValidators([])
    }
    this.form.get('client_role').patchValue('client');
    this.form.get('documentable_id').patchValue(null);
    this.form.get('app_document_id').patchValue(null);
    this.form.get('documentable_type').updateValueAndValidity();
    this.form.get('documentable_id').updateValueAndValidity();

    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}
