import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { DatabaseNotification } from '../../../../../../_base-shared/models/Notification/DatabaseNotification';
import { NotificationDetailComponent } from '../notification-detail/notification-detail.component';
import { NotificationService } from '../notification.service';

@Component({
  selector:    'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls:   ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements OnInit, OnChanges {
  @ViewChild('sound') audioPlayerRef: ElementRef;
  @Input() notifications;

  public user;

  public notify    = false; // TODO: change to true will trigger bell animation
  public showCount = false; // TODO: change to true will show unread messages box
  public unreadCount: number; // TODO: number of unread messages

  constructor(public dialog: MatDialog,
              private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notifications) {
      this.checkForNewNotifications(changes);
    }
  }

  showUnreadInfo(value: boolean) {
    this.showCount = value;
  }

  newMessageAnimation() {
    this.notify = true;
    this.playNewMessageAudio();

    //  Need to remove class after animation is over
    setTimeout(() => {
      this.notify = false;
    }, 1500);
  }

  playNewMessageAudio() {
    this.audioPlayerRef.nativeElement.play();
  }

  public markAllAsRead($event) {
    $event.preventDefault();

    const data = this.notifications.map(obj => obj.id);
    this.notificationService.markAsRead({notification_ids: data}).subscribe(next => {
      this.showUnreadInfo(false);
      this.notifications = [];
    });
  }

  public markAsRead($event: MouseEvent, notification: DatabaseNotification) {
    $event.preventDefault();
    $event.stopPropagation();

    this.notificationService.markAsRead({notification_ids: [notification.id]}).subscribe(result => {
      notification.read_at = new Date();
      this.unreadCount--;
    });
  }

  public openModal($event, notification) {

    const dialogRef = this.dialog.open(NotificationDetailComponent, {
      width: '50%',
      data:  {notification},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public checkForNewNotifications(changes) {
    const newNotifications = changes.notifications.currentValue.map(notification => notification.id);
    const oldNotifications = changes.notifications.previousValue ?
        changes.notifications.previousValue.map(notification => notification.id) :
        [];
    this.unreadCount       = newNotifications.length;
    if (this.unreadCount === 0) {
      this.showCount = false;
    }

    const result = newNotifications.some(not => {
      return oldNotifications.indexOf(not) === -1;
    });

    if (result) {
      // this.unreadCount = newNotifications.length;
      this.newMessageAnimation();
      this.showUnreadInfo(true);
    }
  }
}
