import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { MainGlobalEventService } from '../services/main-global-event.service';

@Injectable()
export class MainAuthGuard  {
  constructor(
      private globalEventsService: MainGlobalEventService,
      public router: Router,
      private authService: AuthService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('canActivate');
    return this.checkLogin(state);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot,
                          state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('canActivateChild');
    return this.checkLogin(state);
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('canLoad');
    return this.checkLogin(segments);
  }

  private checkLogin(routeState): Observable<boolean> {
    return this.globalEventsService.authStatus$.pipe(
        filter(result => (result === true || result === false)),  // If null, ignore it
        tap(res => {
          if (!res) {
            this.authService.logout()
                .subscribe(next => {
                  this.globalEventsService.setAuthUser(null);
                  this.globalEventsService.setPreviousRoute(routeState.url);
                  this.router.navigateByUrl('/login');
                });
          }
        }));
  }

}
