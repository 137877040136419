<h3 class="pb-5">{{ 'DISTRIBUTION.report.heading' | translate }}</h3>
<div class="row d-flex flex-row justify-content-center">
  <div class="col-md-8 mb-4">
    <h4 class="text-center mb-4">{{ "DISTRIBUTION.report.subheadings.performance_reports" | translate }}</h4>
    <table mat-table [dataSource]="performanceDataSource">
      <!-- Report Title Column -->
      <ng-container matColumnDef="reportName">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.title' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.title | translate }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.description' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.description | translate }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.actions' | translate }}</th>
        <td mat-cell *matCellDef="let report">
          <button mat-raised-button color="primary" [disabled]="!report.enabled"
                  (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </td>
      </ng-container>

      <!-- Table Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<div class="row d-flex flex-row justify-content-center">
  <div class="col-md-8 mt-5 mb-4">
    <h4 class="text-center mb-4">{{ "DISTRIBUTION.report.subheadings.funding_reports" | translate }}</h4>
    <table mat-table [dataSource]="fundingReports">
      <!-- Report Title Column -->
      <ng-container matColumnDef="reportName">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.title' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.title | translate }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.description' | translate }}</th>
        <td mat-cell *matCellDef="let report">{{ report.description | translate }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'REPORTS.financial-reports.actions' | translate }}</th>
        <td mat-cell *matCellDef="let report">
          <button mat-raised-button color="primary" [disabled]="!report.enabled"
                  (click)="openDistributionModal($event, report.reportType, report.reportGroup)">
            {{ 'DISTRIBUTION.report.submit_label' | translate }}
          </button>
        </td>
      </ng-container>

      <!-- Table Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
</div>
