<h5 mat-dialog-title>{{ "REPORTS.financial-reports.list.run-off-report.title" | translate }}</h5>
<div class="pt-3">
  <form [formGroup]="form" (ngSubmit)="sendRunOffReportData()">
    <app-input type="mat-datepicker" formControlName="last_term_start_date" appearance="fill" class="me-1"
               [label]="'REPORTS.run-off-reports.last_term_start_date' | translate" >
    </app-input>
    <app-input type="mat-datepicker" formControlName="last_term_end_date" appearance="fill" class="me-1"
               [label]="'REPORTS.run-off-reports.last_term_end_date' | translate" >
    </app-input>
    <div class='pt-2 text-end'>
      <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
        {{ "REPORTS.financial-reports.list.run-off-report.actions.close" | translate }}
      </button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">
        {{ "REPORTS.financial-reports.list.run-off-report.actions.submit" | translate }}
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
      </button>
    </div>
  </form>
</div>
