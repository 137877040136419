<mat-dialog-content>
  <div class="d-flex flex-column h-100">
    <h4>{{ "CASES.details.signature-verify" | translate }}</h4>
    <div *ngIf="verifyCreditor">
      <mat-divider></mat-divider>
      <div class="p-2 d-flex justify-content-center align-items-center">
        <mat-icon class="mat-icon-size mat-icon-warning">warning</mat-icon>
        <p class="m-0 ps-2" style="color: #856404"><b>{{ "CASES.details.verify-signature-creditor" | translate }}</b></p>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div *ngIf="user.signature_verified_at">
      <mat-divider></mat-divider>
      <div class="p-2 d-flex justify-content-center align-items-center">
        <mat-icon class="mat-icon-size" color="primary">check_circle</mat-icon>
        <p class="m-0 ps-2"><b>{{ "CASES.details.signature-verified-note" | translate }}</b></p>
      </div>
      <mat-divider></mat-divider>
    </div>
    <h5 *ngIf="!case.joint_application">{{ "CASES.single.client" | translate }}</h5>
    <h5 *ngIf="case.joint_application">{{ "CASES.single.client-1" | translate }}</h5>
    <div class="row">
      <!-- Legacy DNI Document -->
      <div class="col-6" *ngIf="documentHandler === 'document'">
        <ng-container *ngIf="legacyDniDocument">
          <a [href]="storageUrl + legacyDniDocument.location" target="_blank">
            <img class="img-fluid" [src]="storageUrl + legacyDniDocument.location">
          </a>
        </ng-container>
        <div *ngIf="!legacyDniDocument">
          <p>{{ "CASES.details.no-dni-client" | translate }}</p>
        </div>
      </div>
      <!-- AppDocument DNI -->
      <div class="col-6" *ngIf="documentHandler === 'app_document'">
        <ng-container *ngIf="dniDocument?.files.length">
          <div *ngFor=" let file of dniDocument.files" class="pb-3">
            <a [href]="storageUrl + file.path" target="_blank">
              <img class="img-fluid" [src]="storageUrl + file.path" [alt]="file.name">
            </a>
          </div>
        </ng-container>
        <div *ngIf="!dniDocument?.files.length">
          <p>{{ "CASES.details.no-dni-client" | translate }}</p>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-center" [class.align-items-center]="userSignature">
        <div *ngIf="userSignature">
          <a [href]="storageUrl + userSignature" target="_blank">
            <img class="img-fluid" [src]="storageUrl + userSignature" alt="user-signature">
          </a>
        </div>
        <div *ngIf="!userSignature">
          <p>{{ "CASES.details.no-signature-client" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="text-end mt-auto mb-5">
      <button type="button" mat-stroked-button color="primary" class="me-3"
              (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}
      </button>
      <button *ngIf="!user.signature_verified_at" type="button" mat-raised-button color="primary"
              [disabled]="!userSignature" (click)="verifySignature()">
        {{ "CASES.details.verify-signature" | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
