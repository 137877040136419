import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { RedsysOrder } from '../../../../../../../../_base-shared/models/Payment/Transaction/RedsysOrder';
import { DistributionService } from '../../../../distribution/distribution.service';
import { CaseService } from '../../../case.service';

@Component({
  selector: 'app-case-redsys-orders-list',
  templateUrl: './case-redsys-orders-list.component.html',
  styles: [],
})
export class CaseRedsysOrdersListComponent implements OnInit {
  @Input() case: Case;
  @Input() reFetchOrders: EventEmitter<any>;
  @Input() componentType: 'distribution' | 'admin';
  public redsysOrders: Array<RedsysOrder> = [];

  constructor(private caseService: CaseService,
              private distributionService: DistributionService) {
  }

  ngOnInit(): void {
    this.reFetchOrders.subscribe(next => this.fetchOrders(this.componentType));
    this.fetchOrders(this.componentType);
  }

  public updateComponentType(type: 'distribution' | 'admin'): void {
    this.componentType = type;
    this.fetchOrders(type);
  }

  private fetchOrders(componentType: 'admin' | 'distribution'): void {
    const data = {case_id: this.case.id, all_results: 1, success_status: null};

    if (componentType === 'distribution') {
      data.success_status = 1;
    }

    const observable: Observable<any> = componentType === 'admin' ?
      this.caseService.getPaymentOrders(this.case.id, data, ['author', 'payment_processor']) :
      this.distributionService.getPaymentOrders(this.case.uuid, data, ['author', 'payment_processor']);

    observable.subscribe(result => {
      this.redsysOrders = result.data;
      //  Format response string to show in prettier
      this.redsysOrders.map(order => {
        return {
          ...order,
          // details: {
          //   redsys
          // }
        };
      });
    });
  }
}
