import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { AppDocument } from '../../../../../../_base-shared/models/Document/AppDocument';
import { User } from '../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../environments/environment';
import { CaseDocumentService } from '../../case/case-document.service';
import { CaseService } from '../../case/case.service';
import { DocumentTypeService } from '../document-type.service';

@Component({
  selector:    'app-verify-signature',
  templateUrl: './verify-signature.component.html',
  styleUrls:   ['./verify-signature.component.scss'],
})
export class VerifySignatureComponent implements OnInit {
  public isLoading  = 0;
  public case: Case;
  public clientRole: 'client' | 'partner';
  public user: User;
  public userSignature: string;
  public legacyDniDocument: any;
  public dniDocument: AppDocument;
  public documentHandler: 'document' | 'app_document';
  public verifyCreditor: boolean;
  public storageUrl = environment.STORAGE_URL + '/';

  constructor(
    private toast: ToastrService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<VerifySignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private documentTypeService: DocumentTypeService) {
  }

  ngOnInit(): void {
    this.case            = this.data.case;
    this.user            = this.data.user;
    this.clientRole      = this.case.user_id === this.user.id ? 'client' : 'partner';
    this.verifyCreditor  = this.data.verifyCreditor;
    this.documentHandler = 'document'; // TODO: update when docs V3 is tested
    this.fetchUserSignature(this.case);

    if (this.documentHandler === 'document') {
      this.fetchUserLegacyDniDocument(this.case, this.user);
    } else {
      this.fetchUserDniDocument(this.case, this.user);
    }
  }

  public closeDialog(value = null): void {
    this.dialogRef.close(value);
  }

  public verifySignature(): void {
    this.caseService.verifySignature(this.data.case.id, {signature_verified: true}).subscribe(
      result => {
        this.toast.success(this.translate.instant('CASES.details.signature-verified'));
        this.closeDialog(result.data);
      },
      error => this.toast.error(this.translate.instant('CASES.details.signature-verified-error'))
    );
  }

  private fetchUserLegacyDniDocument(clientCase: Case, client: User): void {
    this.isLoading++;
    this.caseDocumentService.getLegacyCaseDocument(clientCase.id, 'dni', client.id)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.legacyDniDocument = result.data);
  }

  private fetchUserDniDocument(clientCase: Case, client: User): void {
    this.isLoading++;
    this.caseDocumentService.getCaseDocument(clientCase.id, 'dni', client.id, ['files'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.dniDocument = result.data);
  }

  private fetchUserSignature(clientCase: Case): void {
    this.isLoading++;
    this.caseService.get(clientCase.id, ['contracts']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.userSignature = this.clientRole === 'client' ?
        result.data.contracts[0]?.signature :
        result.data.contracts[0]?.signature_1;
    });
  }
}
