import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../../case.service';
import { ScheduleDialerCase } from '../../../../../../../../../_base-shared/models/Dialer/ScheduleDialerCase';

@Component({
  selector: 'app-dialer-history-log',
  templateUrl: './dialer-history-log.component.html',
  styles: []
})
export class DialerHistoryLogComponent implements OnInit, OnDestroy{
  @Input() case: Case;
  public isLoading = 0;
  public dialerCallRequests: Array<ScheduleDialerCase>;
  private subscriptions: Array<Subscription> = [];

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.getDialerHistoryLogs(caseId, { select_all: 1, only_completed: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.dialerCallRequests = result.data,
      ),
    );

  }
}
