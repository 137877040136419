import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { DocumentType } from '../../../../../../../../../_base-shared/models/DocumentType';
import { LegacyDocumentTypeService } from '../../../../../document/legacy-document-type.service';
import { CaseDocumentService } from '../../../../case-document.service';

@Component({
  selector:    'app-required',
  templateUrl: './required.component.html',
  styleUrls:   ['./required.component.scss'],
})
export class RequiredComponent implements OnInit {
  @Input() case: Case;
  @Input() selectedTypes;
  @Input() clientType: string;
  @Output() closeAccordion: EventEmitter<any> = new EventEmitter<any>();

  public documentType: DocumentType[];
  public initialDocuments    = {
    personal:  [],
    income:    [],
    financial: [],
  };
  public expiryDocuments     = {
    personal:  [],
    income:    [],
    financial: [],
    custom:    [],
  };
  public requestingDocuments = false;
  public customDocName       = new UntypedFormControl('');

  constructor(private documentTypeService: LegacyDocumentTypeService,
              private route: ActivatedRoute,
              private documentService: CaseDocumentService,
              private toastr: ToastrService,
              private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.documentTypeService.get()
      .subscribe(res => {
        this.documentType     = res;
        this.initialDocuments = {
          personal:  res.filter(doc => doc.parentType === 'initial' && doc.type === 'personal'),
          income:    res.filter(doc => doc.parentType === 'initial' && doc.type === 'income'),
          financial: res.filter(doc => doc.parentType === 'initial' && doc.type === 'financial'),
        };
        this.selectRequiredRequests(this.initialDocuments.personal);
        this.expiryDocuments = {
          personal:  res.filter(doc => doc.parentType === 'expiry-documents' && doc.type === 'personal'),
          income:    res.filter(doc => doc.parentType === 'expiry-documents' && doc.type === 'income'),
          financial: res.filter(doc => doc.parentType === 'expiry-documents' && doc.type === 'financial'),
          custom:    this.selectedTypes.filter(doc => doc.custom),
        };
      });
  }

  selectRequiredRequests(documents: Array<any>) {
    documents.forEach(document => {
      if (document.name === 'dni') {
        this.selectedTypes.push(document);
      }
    });
  }

  addOrRemoveDocument(docType: DocumentType) {
    if (this.selectedTypes?.length > 0 && this.selectedTypes.find(type => type.name === docType.name)) {
      this.selectedTypes = this.selectedTypes.filter(type => type.name !== docType.name);
    } else {
      this.selectedTypes.push(docType);
    }
  }

  addCustomDocument($event) {
    $event.preventDefault();

    this.expiryDocuments.custom.push({name: this.customDocName.value, type: 'new-custom'});
    this.selectedTypes.push({name: this.customDocName.value, type: 'new-custom'});
    this.customDocName.setValue('');
  }

  shouldBeChecked(type) {
    if (type.type === 'new-custom') {
      return true;
    }
    return this.selectedTypes?.length > 0 && !!this.selectedTypes.find(t => t.name === type.name);
  }

  requestDocuments($event, types) {
    $event.preventDefault();
    this.requestingDocuments = true;
    const requestedDocuments = [];
    this.selectedTypes.forEach(type => {
      if (type.type === 'new-custom') {
        requestedDocuments.push(type.name);
      } else {
        requestedDocuments.push(type.id);
      }
    });
    if (requestedDocuments.length === 0) {
      this.closeAccordion.emit(false);
      return;
    }
    const data: any = {
      requested_types: requestedDocuments,
      type:            types,
      client_type:     this.clientType,
    };
    this.documentService.request(this.case.id, data)
      .pipe(finalize(() => this.requestingDocuments = false))
      .subscribe(res => {
          this.closeAccordion.emit(false);
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'),
            this.translate.instant('SHARED.success'));
        },
        error => {
          this.closeAccordion.emit(false);
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'),
            this.translate.instant('SHARED.error'));
        });
  }

}
