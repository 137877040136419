import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../../_shared/guards/main-auth-guard.service';
import { SalesLeaderboardPageComponent } from './sales-leaderboard-page.component';

const routes: Routes = [
  {
    path:      'sales-leaderboard',
    component: SalesLeaderboardPageComponent,
    canActivate: [MainAuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class SalesLeaderboardPageRoutingModule {
}
