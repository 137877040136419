<div class="d-flex flex-row justify-content-between align-items-center">
  <h4>{{ 'CASES.single.general.relation_list.packager_status_logs.heading' | translate }}</h4>
</div>
<div class="pt-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !packagerStatusLogs?.length"
                   [message]="'CASES.single.general.relation_list.packager_status_logs.empty_dataset' | translate">
</app-empty-dataset>
<div class="row m-2" *ngFor="let statusLog of packagerStatusLogs" [hidden]="isLoading">
  <div class="card col-12">
    <div class="card-body">
      <span *ngIf="statusLog.from_packager_status">
        {{ 'CASES.single.general.relation_list.packager_status_logs.status_changed_from' | translate }} {{ statusLog.from_packager_status.name }}
      </span>
      <span *ngIf="!statusLog.from_packager_status">{{ 'CASES.single.general.relation_list.packager_status_logs.no_old_status' | translate }}</span>
      <span *ngIf="statusLog.status_id">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.packager_status_logs.status_changed_to' | translate }} {{ statusLog.to_packager_status.name }}
      </span>
      <span *ngIf="statusLog.user_id">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.packager_status_logs.status_changed_by' | translate }} {{ statusLog.to_packager_status.name }}
      </span>
      <p>{{ statusLog.created_at| toDateObject | date: 'medium'}}</p>
    </div>
  </div>
</div>
