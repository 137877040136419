<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>
<div *ngIf="!isLoading" class="row" [formGroup]="form">
  <div class="col-12">
    <h5 *ngIf="editorType === 'edit'" class="ps-2">{{"CONFIG.email-template.edit-email-template" | translate}}</h5>
    <h5 *ngIf="editorType !== 'edit'" class="ps-2">{{"CONFIG.email-template.add-email-template" | translate}}</h5>
  </div>
  <div class="col-md-8">
    <div class="card shadow mb-4">
      <div class="card-body">
        <div class="row pb-4">
          <!-- Fromable Type -->
          <div class="col-6">
            <app-input type="select" formControlName="fromable_type" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_type.label' | translate"
                       [selectOptions]="fromableTypes" [selectLabel]="'label'" [selectValue]="'value'"
                       (ngModelChange)="fromableTypeChanged()" [searchable]="true">
            </app-input>
          </div>
          <!-- Fromable ID -->
          <div class="col-6">
            <ng-template [ngIf]="form.get('fromable_type').value === 'user'">
              <app-input type="select" formControlName="fromable_id" appearance="fill" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_id.label' | translate"
                         [selectOptions]="users" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'"
                         [searchable]="true">
              </app-input>
            </ng-template>
            <ng-template [ngIf]="form.get('fromable_type').value === 'department'">
              <app-input type="select" formControlName="fromable_id" appearance="fill" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.drip-notification.fromable_id.label' | translate"
                         [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'"
                         [optGroupProperty]="'departments'" [searchable]="true">
              </app-input>
            </ng-template>
          </div>
        </div>
        <div class="row pb-4">
          <!-- Label -->
          <div class="col-6">
            <app-input type="text" formControlName="label" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.sms-template.label' | translate">
            </app-input>
          </div>
          <!-- Active -->
          <div class="col-6">
            <app-input type="select" formControlName="active" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.sms-template.status' | translate"
                       [selectOptions]="templateStatuses" selectLabel="label" selectValue="value">
            </app-input>
          </div>
        </div>
        <!-- Description -->
        <div class="row pb-4">
          <div class="col-12">
            <app-input type="text" formControlName="description" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.sms-template.description' | translate">
            </app-input>
          </div>
        </div>
        <div class="row pb-4">
          <!-- Subject -->
          <div class="col-6">
            <app-input type="text" formControlName="subject" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.sms-template.subject' | translate">
            </app-input>
          </div>
          <!-- BCC -->
          <div class="col-3">
            <app-input type="text" formControlName="bcc" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.email-template.bcc' | translate">
            </app-input>
          </div>
          <!-- Sendgrid Template ID -->
          <div class="col-3">
            <app-input type="text" formControlName="sendgrid_template_id" appearance="fill" [fullWidth]="true"
                       [label]="'CONFIG.email-template.sendgrid_template_id' | translate">
            </app-input>
          </div>
          <!-- Include unsubscribe -->
          <div class="col-3">
            <app-input type="switch" formControlName="include_unsubscribe" [fullWidth]="true"
                       [label]="'CONFIG.drip-campaign.editor.include_unsubscribe' | translate">
            </app-input>
          </div>
        </div>
        <!-- Content -->
        <div class="row pb-4">
          <div class="col-12">
            <label>{{"CONFIG.sms-template.content" | translate}}</label>
            <quill-editor
                    formControlName="content"
                    (onEditorCreated)="getEditorInstance($event)"
                    [modules]="quillModules"
                    [styles]="{height: '350px'}">
            </quill-editor>
            <mat-error *ngIf="submitted && form.get('content').invalid">
              {{"SHARED.field-required" | translate}}
            </mat-error>
          </div>
        </div>
        <!-- Attachments -->
        <div class="row pb-4">
          <div class="col-12">
            <div class="form-group">
              <label for="new_attachments">{{"CONFIG.sms-template.attachments.label" | translate}}</label>
              <div class="input-group">
                <div class="custom-file">
                  <input multiple type="file" class="custom-file-input" name="file" id="new_attachments"
                         (change)="onAttachmentsChange($event)">
                  <label class="custom-file-label">{{"CONFIG.sms-template.attachments.placeholder" | translate}}</label>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center"
                   *ngFor="let file of pickedFiles; let i=index">
                <mat-form-field appearance="fill">
                  <input type="text" matInput [value]="file.name" (change)="updateFileName($event, i)">
                  <span matSuffix>
                    {{ '.' + file.extension + ' ' }}<small>{{ file.size / 1048576 | number:'1.2-2' }}MB</small>
                  </span>
                </mat-form-field>
                <button mat-icon-button color="warn" (click)="removeLocalAttachment(i)">
                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12" *ngFor="let attachment of attachmentsArray.controls; let i=index"
               formArrayName="attachments">
            <div [formGroupName]="i" class="d-flex justify-content-end align-items-center">
              <mat-form-field appearance="fill">
                <input type="text" matInput formControlName="name">
                <span matSuffix>
                    {{ '.' + attachment.get('extension').value + ' ' }}
                  <small>{{ attachment.get('size').value / 1048576 | number:'1.2-2' }}MB</small>
                </span>
              </mat-form-field>
              <button mat-icon-button color="warn" (click)="removeAttachment(i)">
                <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">
                  remove_circle
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button mat-raised-button color="primary" (click)="submit(form)">{{"SHARED.submit" | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Variables -->
  <div class="col-4 mt-sm-4 mt-md-0">
    <div class="card shadow">
      <div class="card-header">{{"CONFIG.sms-template.variables" | translate}}</div>
      <div class="card-body">
        <h5 class="text-center" *ngFor="let template of templateVariables">
          <code (click)="addVariable($event)">{{ template.label }}</code>
          <span class="small" *ngIf="template.description"> - {{ template.description }}</span>
        </h5>
      </div>
    </div>
  </div>
</div>
