import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { Note } from '../../../../../../../_base-shared/models/Note';
import { UploadService } from '../../../app-file/upload.service';
import { StatusService } from '../../../status/status.service';
import { CaseService } from '../../case.service';
import { NoteService } from '../../note.service';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';

@Component({
  selector:    'app-floating-note',
  templateUrl: './floating-note.component.html',
  styleUrls:   ['./floating-note.component.scss']
})
export class FloatingNoteComponent implements OnInit, OnDestroy {
  @Input() public case: Case;
  public isLoading                           = 0;
  public showfloatingNote                    = false;
  public showNoteBody                        = true;
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public notes: Array<Note>                  = [];
  public newNote                             = false;
  public quillModules                        = {
    toolbar: false
  };
  private subscriptions: Array<Subscription> = [];
  public noteTypeOptions: Array<AppSelectOption>;

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private statusService: StatusService,
              private uploadService: UploadService,
              private noteService: NoteService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.getNoteTypeOptions();
    this.translate.onLangChange.subscribe(val => this.getNoteTypeOptions());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public toggleShowFloatingNote() {
    /*if (this.newNote === true){
     this.toggleMinimizeNoteBody();
     }
     else{
     this.showfloatingNote = !this.showfloatingNote;
     this.addNewNote();
     }
     if (this.newNote === false){
     this.newNote = true;
     }*/
    if (this.newNote === true) {
      return;
    } else {
      this.showfloatingNote = ! this.showfloatingNote;
      this.addNewNote();
    }
    if (this.newNote === false) {
      this.newNote = true;
    }
  }

  public toggleMinimizeNoteBody() {
    this.showNoteBody = ! this.showNoteBody;
  }

  private buildForm(note?) {
    this.form = this.fb.group({
      note: [null, [Validators.required]],
      type: [null, [Validators.required]],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const noteData    = form.value;
    noteData.type     = form.get('type').value;
    this.isSubmitting = true;

    this.subscriptions.push(
      this.noteService.store(this.case.id, noteData, ['user'])
        .pipe(finalize(() => this.isSubmitting = false))
        .subscribe(result => {
          this.showfloatingNote = false;
          this.newNote          = false;
          this.notes.unshift(result.data);
          this.form = null;
          this.toastr.success(this.translate.instant('CASES.single.add-note-success'));
        }, error => this.toastr.error(this.translate.instant('SHARED.went-wrong'))),
    );
  }

  public cancelNote() {
    this.showfloatingNote = false;
    this.newNote          = false;
  }

  public addNewNote() {
    const defaultNote = this.buildDefaultNote();
    this.buildForm(defaultNote);
  }

  private buildDefaultNote(): Note {
    const note = new Note();

    return note;
  }

  private getNoteTypeOptions() {
    this.noteTypeOptions = [
      {value: 'note', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {value: 'payment', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment')},
      {value: 'draft', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_draft')},
      {
        value: 'distribution',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_distribution')
      },
    ];
  }
}
