import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../../_shared/guards/main-auth-guard.service';
import { CaseManagerDashboardComponent } from './case-manager-dashboard.component';
import { CaseManagerDetailsComponent } from './case-manager-details/case-manager-details.component';

const routes: Routes = [
  { path: 'case-manager-dash', component: CaseManagerDashboardComponent,  canActivate: [MainAuthGuard]},
  {
    path: 'case-manager-dash/:agentId/details',
    component: CaseManagerDetailsComponent,
    canActivate: [MainAuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class CaseManagerDashboardRoutingModule {
}
