<div class="d-inline-block pb-2">
  <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
    <mat-radio-button checked value="lso">LSO</mat-radio-button>
    <mat-radio-button value="dm">DM</mat-radio-button>
    <mat-radio-button value="dgs">DGS</mat-radio-button>
    <mat-radio-button value="cajaplus">Cajaplus</mat-radio-button>
    <mat-radio-button value="unified">Unifye</mat-radio-button>
  </mat-radio-group>
</div>
<div class="row">
  <div class="col-lg-8">
    <div class="mat-elevation-z8 mb-5">
      <table style="width: 100%" mat-table matSort [dataSource]="dataSource">

        <ng-container matColumnDef="agent_name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
        </ng-container>

        <ng-container matColumnDef="cases_assigned">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Cases Assigned</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.grouped_accomplishments['cases-assigned-manager']?.length || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{getTotal('cases-assigned-manager')}}</b></td>
        </ng-container>

        <ng-container matColumnDef="documentacion_completa">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Documentacion completa</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.grouped_accomplishments['full-documentation']?.length || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{getTotal('full-documentation')}}</b></td>
        </ng-container>

        <ng-container matColumnDef="revisar_documents">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Revisar documents</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.grouped_accomplishments['review-documents']?.length || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{getTotal('review-documents')}}</b></td>
        </ng-container>

        <ng-container matColumnDef="dda_pte_revision">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>DDA pte revision</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.grouped_accomplishments['dda-pte-revision']?.length || 'N/A' }}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{getTotal('dda-pte-revision')}}</b></td>
        </ng-container>

        <ng-container matColumnDef="target_demandas">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Target demandas</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.sale_target?.monthly_completed_documentations || 'N/A'}}</td>
          <td mat-footer-cell *matFooterCellDef><b>{{getTotalTarget()}}</b></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

      <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h5 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div style="display: block; position: relative" *ngIf="!isLoading">
      <h4 class="text-center m-0 pt-3">{{"DASHBOARD.documentacion-completa-progress" | translate}}</h4>
      <canvas
        baseChart
        [data]="doughnutCharts.documentacionCompleta"
        [options]="doughnutCharts.documentacionCompleta.options"
        [labels]="doughnutCharts.documentacionCompleta.labels"
        [plugins]="doughnutCharts.documentacionCompleta.plugins"
        [type]="'doughnut'">
      </canvas>
      <div class="report bottom"><b>{{this.totalNumberOfDocumentacionCompleta}}/{{this.monthlyQuotaDocCompleta}}</b>
      </div>
    </div>
  </div>
</div>
