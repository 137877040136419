import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertComponent } from './components/alert/alert.component';
import { AppInputComponent } from './components/app-input/app-input.component';
import { BaseClientInstallmentListComponent } from './components/client/base-client-installment-list.component';
import { EmptyDatasetComponent } from './components/empty-dataset/empty-dataset.component';
import { HtmlShadowDomComponent } from './components/html-shadow-dom/html-shadow-dom.component';
import { InputValidationErrorsComponent } from './components/input-validation-errors/input-validation-errors.component';
import { BasePaymentHandlerComponent } from './components/payment/base-payment-handler/base-payment-handler.component';
import { PaymentOutcomeComponent } from './components/payment/payment-outcome/payment-outcome.component';
import { PaymentSuccessPageComponent } from './components/payment/payment-success-page/payment-success-page.component';
import { ServerResponseComponent } from './components/server-response/server-response.component';
import { SpinnerBtnComponent } from './components/spinner-btn/spinner-btn.component';
import { DotCaseToSnakePipe } from './pipes/dot-case-to-snake.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SnakeCaseToCamelPipe } from './pipes/snake-case-to-camel.pipe';
import { ToDateObjectPipe } from './pipes/to-date-object.pipe';
import {
  BaseArcopayWidgetHandlerComponent
} from './components/payment/base-arcopay-widget-handler/base-arcopay-widget-handler.component';

@NgModule({
  imports:      [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    // Mat Modules
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
  ],
  declarations: [
    // Pipes
    ToDateObjectPipe,
    SafePipe,
    DotCaseToSnakePipe,
    SnakeCaseToCamelPipe,
    // Components
    AlertComponent,
    ServerResponseComponent,
    SpinnerBtnComponent,
    InputValidationErrorsComponent,
    AppInputComponent,
    HtmlShadowDomComponent,
    EmptyDatasetComponent,
    BasePaymentHandlerComponent,
    BaseArcopayWidgetHandlerComponent,
    PaymentOutcomeComponent,
    PaymentSuccessPageComponent,
    BaseClientInstallmentListComponent
  ],
  exports:      [
    // Modules
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    // Mat Modules
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatMenuModule,
    // Pipes
    ToDateObjectPipe,
    SafePipe,
    DotCaseToSnakePipe,
    SnakeCaseToCamelPipe,
    // Components
    ServerResponseComponent,
    SpinnerBtnComponent,
    AppInputComponent,
    HtmlShadowDomComponent,
    EmptyDatasetComponent,
    BasePaymentHandlerComponent,
    BaseArcopayWidgetHandlerComponent,
    BaseClientInstallmentListComponent
  ],
})
export class BaseSharedModule {
}
