import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import {
  IsDirtyGuardCheckInterface,
} from '../contracts/is-dirty-guard-check.interface';

@Injectable()
export class CheckIfComponentIsDirty  {
  constructor(private translate: TranslateService,
              public router: Router) {
  }

  canDeactivate(
    component: IsDirtyGuardCheckInterface,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.router.getCurrentNavigation()?.extras?.state?.bypassFormGuard) {
      return true;
    }
    if (component.isComponentDirty()) {
      return this.discardChangesPrompt(component);
    }
    return true;
  }

  private discardChangesPrompt(component: IsDirtyGuardCheckInterface): Promise<boolean> {
    return Swal.fire({
      title:              this.translate.instant('CASES.single.discard-changes-title'),
      text:               this.translate.instant('CASES.single.discard-changes-text'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.save'),
      cancelButtonText:   this.translate.instant('SHARED.discard'),
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        if (!component.isComponentValid()) {
          return false;
        }
        component.submitComponent();
        return true;
      } else {
        return true;
      }
    });
  }

}
