import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-creditor-general-detail',
  templateUrl: './case-creditor-general-detail.component.html',
  styleUrls:   []
})
export class CaseCreditorGeneralDetailComponent implements OnInit, OnDestroy {
  public case: Case;
  public caseCreditor: CaseCreditor;
  public isLoading                           = 0;
  public serverResponse: LaravelResourceResponse;
  private subscriptions: Array<Subscription> = [];
  private authUser: User;

  constructor(
    private route: ActivatedRoute,
    private globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.route.parent.parent.parent.paramMap.subscribe(params => {
        const caseId = +params.get('id');
        this.isLoading++;
        this.subscriptions.push(
          this.caseService.get(caseId, ['packager'])
            .pipe(finalize(() => this.isLoading--))
            .subscribe(result => {
              this.case = result.data;
              this.route.parent.paramMap.subscribe(p => {
                this.fetchCaseCreditor(this.case.id, +p.get('id'));
              });

            }, error => {
              this.serverResponse = error.error;
            }),
        );
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public updateCaseClients(updatedCase: Case) {
    this.case.lead            = updatedCase.lead;
    this.case.client          = updatedCase.client;
    this.case.partner         = updatedCase.partner;
    this.case.linked_by_cases = updatedCase.linked_by_cases;
    this.case.linked_cases    = updatedCase.linked_cases;
  }

  private fetchCaseCreditor(caseId: number, caseCreditorId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.showCaseCreditor(caseId, caseCreditorId, ['creditor'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseCreditor = result.data),
    );
  }
}
