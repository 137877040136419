import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AppSelectOption } from '../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { environment } from '../../../environments/environment';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { AuthService } from '../auth.service';

@Component({
  selector:    'app-login',
  templateUrl: './login.component.html',
  styleUrls:   ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public appName                           = environment.APP_NAME;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public languages: Array<AppSelectOption> = [];
  public selectedLanguage                  = new UntypedFormControl();
  private currentLanguage: 'en' | 'es';

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.appName          = environment.APP_NAME;
    const storageLanguage = this.cookieService.get('lang');
    this.currentLanguage  = (storageLanguage === 'es' || storageLanguage === 'en') ? storageLanguage : 'es';
    this.cookieService.set('lang', this.currentLanguage, 365, '/');
    this.selectedLanguage.patchValue(this.currentLanguage);
    this.translate.use(this.currentLanguage);

    this.getCSRFToken();
    if (this.globalEventService.authUser) {
      this.router.navigate(['/']);
      return;
    }
    this.getLanguages();
    this.buildForm();
    localStorage.setItem('splashScreen', '1');
  }

  public buildForm() {
    this.form = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, Validators.required],
    });
  }

  public submitForm(form) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.authService.login(form.value, ['departments']).subscribe(
      result => {
        // const intendedUrl = this.authService.redirectUrl ? this.authService.redirectUrl : '/home/dashboard';
        // this.storeAuthUser(result.data);
        const authUser  = result.data;
        const nextRoute = authUser.temporary_password ?
          '/new-password' :
          (this.globalEventService.previousRoute || '/');
        this.globalEventService.setPreviousRoute(null);
        this.router.navigate([nextRoute], {state: {redirect: true}});
      },
      error => {
        this.serverResponse = error.error;
        this.toastr.error(this.translate.instant('AUTH.login.response.error'));
        this.form.get('password').setValue('');
        this.form.get('password').markAsUntouched();
      },
    );
  }

  private getLanguages() {
    this.languages = [
      {label: 'English', value: 'en'},
      {label: 'Español', value: 'es'},
    ];
  }

  private getCSRFToken() {
    this.authService.getCSRFCookie().subscribe(r => {
    }, e => console.error(e));
  }

  public changeLanguage(language: 'en' | 'es') {
    this.translate.use(language);
    this.cookieService.set('lang', language, 365, '/');
  }
}
