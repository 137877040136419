import { Injectable } from '@angular/core';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends MainBaseApiService {

  public quillImgUpload(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      this.uploadContentFile(formData).subscribe(
        res => resolve(res.data),
        err => {
          console.error(err);
          alert('Image upload failed');
          reject();
        }
      );
    });
  }

  public uploadContentFile(formData: FormData) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/upload-image', formData)
      .pipe(catchError(response => this.handleError(response)));
  }

}
