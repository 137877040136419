<div class="row">
  <div class="col col-md-10 col-lg-8 col-xl-6 m-auto">
    <mat-card class="text-center">
      <div style="max-width: 100%; margin: auto; padding-left: 15px; padding-right: 20px">
        <p class="mt-5">
          Welcome Affiliate, you have submitted X number of cases
        </p>
        <button mat-raised-button color="primary" routerLink="/affiliate/cases">
          View Cases
        </button>
      </div>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col col-md-10 col-lg-8 col-xl-6 m-auto">
    <!--<mat-spinner diameter="50" *ngIf="isLoadingOnboarding" class="m-0 m-auto"></mat-spinner>-->
    <!--<app-onboarding-preview *ngIf="onboardingEnabled && authUser && case" [hidden]="isLoadingOnboarding"-->
    <!--                        [caseRelationValidation]="caseRelationValidation"-->
    <!--                        [case]="case" [user]="authUser">-->
    <!--</app-onboarding-preview>-->
  </div>
</div>
