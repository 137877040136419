import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { PaymentPlanSchedule } from '../../../../../../../../_base-shared/models/Payment/PaymentPlanSchedule';
import { PaymentService } from '../../../../payment/payment.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';
import { PaymentTerm } from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { User } from '../../../../../../../../_base-shared/models/User/User';

@Component({
  selector:    'app-scheduled-payment-plan-modal',
  templateUrl: './scheduled-payment-plan-modal.component.html',
  styleUrls:   ['./scheduled-payment-plan-modal.component.scss']
})
export class ScheduledPaymentPlanModalComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() paymentPlanSchedule: PaymentPlanSchedule;

  public editorType: 'create' | 'edit';

  public isLoading = 0;
  public isSubmitting: boolean;

  public authUser: User;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public upcomingInstallments: Array<PaymentTerm>;
  public intervalUnitOptions: Array<AppSelectOption> = [];
  private loadRelations: Array<string>               = [];
  private subscriptions: Array<Subscription>         = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      case: Case,
      paymentPlanSchedule: PaymentPlanSchedule,
      loadRelations: Array<string>
    },
    @Optional() public dialogRef: MatDialogRef<ScheduledPaymentPlanModalComponent>,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private paymentService: PaymentService,
    private casePaymentPlanService: CasePaymentPlanService,
    private globalEventsService: MainGlobalEventService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    if (this.data) {
      this.case                = this.data.case;
      this.paymentPlanSchedule = this.data.paymentPlanSchedule;
      this.loadRelations       = this.data.loadRelations;
      this.editorType          = this.paymentPlanSchedule ? 'edit' : 'create';
      this.buildForm(this.paymentPlanSchedule);
    }
    this.fetchInstallments();
    this.buildIntervalUnitOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    const observable = this.editorType === 'create' ?
      this.casePaymentPlanService.generateScheduledPaymentPlan(this.case.id, form.value, this.loadRelations) :
      this.casePaymentPlanService.updateScheduledPaymentPlan(this.case.id, this.paymentPlanSchedule.id, form.value, this.loadRelations);

    this.subscriptions.push(
      observable.pipe(finalize(() => this.isSubmitting = false))
        .subscribe({
          next:  result => {
            this.toastr.success(this.translate.instant('SHARED.success'));
            this.dialogRef.close({dismissed: false, data: result.data});
          },
          error: err => {
            this.serverResponse = err.error;
            this.toastr.error(this.translate.instant('SHARED.error'));
            this.dialogRef.close({dismissed: true, data: null});
          }
        })
    );
  }

  private buildForm(paymentPlanSchedule?: PaymentPlanSchedule): void {
    this.form = this.fb.group({
      start_installment_id: [paymentPlanSchedule?.start_installment_id, [Validators.required]],
      start_from:           [paymentPlanSchedule?.start_from],
      installment_amount:   [paymentPlanSchedule?.installment_amount, [Validators.required, Validators.min(0.01)]],
      installments_count:   [paymentPlanSchedule?.installments_count, [Validators.required, Validators.min(1)]],
      interval:             [1, [Validators.required]],
      interval_unit:        ['month', [Validators.required]],
      send_to_client:       [false, [Validators.required]]
    });

    this.form.get('start_installment_id').valueChanges.subscribe({
      next: newInstallmentIdSelected => {
        console.log('installment changed');
        const selectedInstallment = this.upcomingInstallments.find(sInstallment => sInstallment.id === newInstallmentIdSelected);
        const installmentAmount   = selectedInstallment ?
          (selectedInstallment.amount - selectedInstallment.amount_paid) :
          0;
        this.form.get('installment_amount').patchValue(installmentAmount);
        this.form.get('start_from').patchValue(selectedInstallment ? selectedInstallment.term_date : null);
        this.casePaymentPlanService.getMaxAmountOfInstallmentsForSchedule(this.case.id, {installment_id: newInstallmentIdSelected}).subscribe({
          next:  result => {
            const maxInstallmentsCount = result.data.length || 1;
            this.form.get('installments_count').patchValue(maxInstallmentsCount);
            this.form.get('installments_count').setValidators([Validators.required, Validators.max(maxInstallmentsCount)]);
            this.form.get('installments_count').updateValueAndValidity();
          },
          error: err => console.error(err.errors)
        })
      }
    });
  }

  private buildIntervalUnitOptions(): void {
    this.intervalUnitOptions = [
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ];
  }

  private fetchInstallments(): void {
    this.isLoading++;
    this.upcomingInstallments = [];
    const requestData         = {
      exclude_scheduled: 1,
      select_all:        1
    }
    this.subscriptions.push(
      this.paymentService.indexCaseInstallments(this.case.id, requestData, []).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  res => {
            this.upcomingInstallments = res.data.map(installment => {
              return {
                ...installment,
                name:           installment.name + ' - ' + installment.amount + '/' + installment.amount_paid,
                optionDisabled: installment.amount === installment.amount_paid
              };
            });
            console.log(res.data);
          },
          error: error => console.error(error)
        })
    );
  }
}
