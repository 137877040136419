<div *ngIf="serverResponse" class="row">
  <div class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<div *ngIf="case">
  <div class="d-flex justify-content-end mb-1">
    <div class="shared-payment-actions">
      <button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ms-3"
              (click)="updateComponentType('distribution')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_provider_view' | translate }}
      </button>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'distribution'" mat-raised-button class="ms-3"
              (click)="updateComponentType('admin')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
      </button>
    </div>
  </div>
  <app-case-installment-list [case]="case" [fetchInstalments]="fetchInstalments$" [componentType]="componentType">
  </app-case-installment-list>
  <app-case-payment-list [case]="case" [fetchHistory]="fetchInstalments$"
                         [componentType]="componentType">
  </app-case-payment-list>
  <app-case-redsys-orders-list [case]="case" [reFetchOrders]="fetchInstalments$" [componentType]="componentType">
  </app-case-redsys-orders-list>
  <app-case-transaction-list [case]="case" [reFetchTransactions]="fetchInstalments$"
                             [componentType]="componentType">
  </app-case-transaction-list>
  <app-case-payment-request-list [case]="case" [componentType]="componentType"></app-case-payment-request-list>
</div>
