import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-nav',
  templateUrl: './nav.component.html',
  styleUrls:   ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() notifications;
  @Input() selectedCase: Case;
  public authUser: User;
  public desktopVersion: boolean;
  public showNav = true;

  constructor(public globalEventsService: MainGlobalEventService,
              private breakpointObserver: BreakpointObserver,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.globalEventsService.navStatus$.subscribe(result => this.showNav = result);
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

}
