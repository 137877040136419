import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';

@Component({
  selector:    'app-sales-report-modal',
  templateUrl: './sales-report-modal.component.html',
  styleUrls:   ['./sales-report-modal.component.scss'],
})
export class SalesReportModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(
      private toast: ToastrService,
      private reportService: ReportService,
      private fb: UntypedFormBuilder,
      public dialogRef: MatDialogRef<SalesReportModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  sendReportData() {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      start_date: this.form.value.start_date.toISOString(),
      end_date:   this.form.value.end_date.toISOString(),
    };
    this.isLoading++;

    this.reportService.generateSalesReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(res => {
              // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              this.dialogRef.close(true);
              this.toast.success(res.message);

            },
            err => console.error(err),
        );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
