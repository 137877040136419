<h4 mat-dialog-title *ngIf="editorType === 'create'">{{ 'TASK.editor.title.create' | translate }}</h4>
<h4 mat-dialog-title *ngIf="editorType === 'edit'">{{ 'TASK.editor.title.edit' | translate }}</h4>
<mat-dialog-content>
  <form class="mt-2" *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="row pb-4">
      <div class="col-12">
        <!-- Name -->
        <app-input type="text" formControlName="name" appearance="fill" [fullWidth]="true"
                   [label]="'TASK.editor.name.label' | translate">
        </app-input>
      </div>
    </div>
    <div class="row pb-4">
      <!-- Assigned Users -->
      <div class="col-6" *ngIf="!quickTask">
        <app-input type="select" formControlName="assigned_users_ids" appearance="fill" multiple
                   [label]="'TASK-TEMPLATE.editor.assigned_users.label' | translate" [fullWidth]="true"
                   [selectOptions]="users" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'"
                   (ngModelChange)="assignableChanged('user')" [searchable]="true" >
        </app-input>
      </div>
      <!-- Assigned Departments -->
      <div class="col-6" *ngIf="!quickTask">
        <app-input type="select" formControlName="assigned_departments_ids" appearance="fill" multiple
                   [label]="'TASK-TEMPLATE.editor.assigned_departments.label' | translate" [fullWidth]="true"
                   [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true"
                   [optGroupProperty]="'departments'" (ngModelChange)="assignableChanged('department')">
        </app-input>
      </div>
    </div>
    <div class="row">
      <!-- Due Date-->
      <div class="col-6 date-time-picker">
        <mat-form-field color="primary" appearance="fill" class="input-full-width">
          <mat-label>{{ 'TASK.editor.due_date' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="from" formControlName="due_date">
          <button *ngIf="form.get('due_date').value" matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearDatePicker($event)">
            <mat-icon>{{ 'SHARED.close' | translate }}</mat-icon>
          </button>
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #from></ngx-mat-datetime-picker>
        </mat-form-field>
        <div *ngIf="form.get('due_date').errors && (form.get('due_date').touched || formSubmitted)">
          <mat-error [hidden]="!form.get('due_date').errors.required">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </div>
      <div class="col-6" *ngIf="!quickTask">
        <!-- Notify On Completion -->
        <app-input type="select" formControlName="notify_on_completion_via" appearance="fill" multiple
                   [label]="'TASK-TEMPLATE.editor.notify_on_completion.label' | translate" [fullWidth]="true"
                   [selectOptions]="notificationChannels" [selectLabel]="'name'" [selectValue]="'slug'" [searchable]="true">
        </app-input>
      </div>
      <!-- Require Completion Note -->
      <div class="col-6 d-flex align-items-end" *ngIf="!quickTask">
        <app-input type="switch" formControlName="require_completion_note" [fullWidth]="true"
                   [label]="'TASK-TEMPLATE.editor.require_completion_note.label' | translate">
        </app-input>
      </div>
      <!-- Notes -->
      <div class="col-12 mt-3">
        <div>
          <label for="notes">{{ 'TASK.editor.notes' | translate }}</label>
        </div>
        <quill-editor id="notes" [modules]="quillModules" formControlName="notes" [styles]="{height: '250px'}">
        </quill-editor>
        <div *ngIf="form.get('notes').errors && (form.get('notes').touched || formSubmitted)">
          <mat-error [hidden]="!form.get('notes').errors.required">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </div>
      <!-- Task Template Notifications Array -->
      <div class="col-12 mt-2">
        <mat-accordion multi>
          <mat-expansion-panel *ngFor="let notification of templateNotificationsArray.controls; let i=index"
                               class="mt-2" formArrayName="template_notifications">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                  {{ 'TASK-TEMPLATE.editor.task-template-notification.header' | translate }} {{ i + 1 }}
                  <button mat-stroked-button color="warn" (click)="removeTemplateNotification(i)">
                    {{ 'TASK-TEMPLATE.editor.task-template-notification.remove' | translate }}
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row mt-2 task-template-{{ i }}" [formGroupName]="i">
              <!-- Channel -->
              <!--<div class="col-6">-->
              <!--  <app-input type="select" formControlName="channel" appearance="fill"-->
              <!--             [label]="'TASK-TEMPLATE.editor.task-template-notification.channel.label' | translate"-->
              <!--             [selectOptions]="notificationChannels" [selectLabel]="'name'" [selectValue]="'slug'"-->
              <!--             (change)="notificationChannelChanged(i)">-->
              <!--  </app-input>-->
              <!--</div>-->
              <!-- Notify On -->
              <div class="col-6">
                <app-input type="select" formControlName="notify_on" appearance="fill" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.task-template-notification.notify_on.label' | translate"
                           [selectOptions]="notifyOnOptions" [selectLabel]="'label'" [selectValue]="'value'"
                           (ngModelChange)="notifyOnChanged(i)">
                </app-input>
              </div>
              <!-- Delay -->
              <div class="col-3" *ngIf="templateNotificationsArray.at(i).get('notify_on').value === 'before_due_date' ||
                templateNotificationsArray.at(i).get('notify_on').value === 'after_due_date'">
                <app-input type="number" formControlName="delay" appearance="fill" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.task-template-notification.delay.label' | translate">
                </app-input>
              </div>
              <!-- Delay Unit -->
              <div class="col-3" *ngIf="templateNotificationsArray.at(i).get('notify_on').value === 'before_due_date' ||
                templateNotificationsArray.at(i).get('notify_on').value === 'after_due_date'">
                <app-input type="select" formControlName="delay_unit" appearance="fill" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                           [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- Add new notification row action -->
      <div class="col mt-2">
        <button mat-raised-button color="primary" (click)="addTemplateNotification($event)">
          + {{ 'TASK-TEMPLATE.editor.task-template-notification.add-new' | translate }}
        </button>
      </div>

    </div>
    <div class="d-flex justify-content-end" style="margin-top: 60px">
      <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.submit' | translate"></app-spinner-btn>
    </div>
  </form>
</mat-dialog-content>
