<h4>Changelog</h4>
<div class="mat-elevation-z8 p-5">
  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngFor="let build of appBuilds">
    <h5><strong>{{ build.version }} - {{ build.release_date | toDateObject | date }}</strong></h5>
    <ul>
      <li *ngFor="let changelog of build.changelogs">
        <h5>{{ changelog.title }}</h5>
        <p><small>{{ changelog.description }}</small></p>
      </li>
    </ul>
  </div>
</div>
