<div *ngIf="paymentPlanSchedules?.data?.length" class="mb-4" style="box-shadow: none">
  <div class="card-header" style="border: 1px solid #e4e4e4; position: relative">
    <h2 class="text-center m-0">{{'PAYMENTS.scheduled-payments.scheduled_payment_plans' | translate}}</h2>
  </div>

  <div class="card-body p-0">
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
      <table style="width: 100%" mat-table matSort (matSortChange)="sortData($event)"
             [dataSource]="paymentPlanSchedules">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Created At -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAYMENTS.scheduled-payments.created_at' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{ element.created_at | date }}</td>
        </ng-container>
        <!-- Start From -->
        <ng-container matColumnDef="start_from">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAYMENTS.scheduled-payments.start_from' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{ element.start_from | date }}</td>
        </ng-container>
        <!-- Installment Amount -->
        <ng-container matColumnDef="installment_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAYMENTS.scheduled-payments.installment_amount' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{ element.installment_amount | currency: 'EUR' }}</td>
        </ng-container>
        <!-- Installment Count -->
        <ng-container matColumnDef="installment_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAYMENTS.scheduled-payments.installment_count' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.installments_count }}</td>
        </ng-container>
        <!-- Total Amount -->
        <ng-container matColumnDef="total_amount_sum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAYMENTS.scheduled-payments.total_amount' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ (element.installments_count * +element.installment_amount) | currency: 'EUR' }}
          </td>
        </ng-container>
        <!-- Payments Total Amount Sum -->
        <ng-container matColumnDef="payments_total_amount_sum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PAYMENTS.scheduled-payments.payment_sum' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.payments_total_amount_sum | currency: 'EUR' }}</td>
        </ng-container>
        <!-- Author -->
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef>{{ 'PAYMENTS.scheduled-payments.created_by' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.author?.first_name + ' ' + element.author?.last_name }}
          </td>
        </ng-container>
        <!-- Notified At -->
        <ng-container matColumnDef="notified_at">
          <th mat-header-cell *matHeaderCellDef>{{'PAYMENTS.scheduled-payments.notified_at' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.notified_at | date }}
          </td>
        </ng-container>
        <!-- Activated At -->
        <ng-container matColumnDef="activated_at">
          <th mat-header-cell *matHeaderCellDef>{{'PAYMENTS.scheduled-payments.activated_at' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.cancelled_at" class="badge badge-danger">{{'PAYMENTS.scheduled-payments.cancelled' | translate}}</span>
            <span *ngIf="!element.cancelled_at && !element.activated_at" class="badge badge-info">{{'PAYMENTS.scheduled-payments.pending' | translate}}</span>
            <span *ngIf="!element.cancelled_at && element.activated_at">
              {{ element.activated_at | date }}
            </span>
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button [matMenuTriggerFor]="menu">
              {{ "SHARED.actions" | translate }}
            </button>
            <mat-menu #menu="matMenu">
              <button [disabled]="element.activated_at" mat-menu-item class="dropdown-item"
                      (click)="editPlanSchedule(element)">
                {{'PAYMENTS.scheduled-payments.edit' | translate}}
              </button>
              <button [disabled]="element.activated_at" mat-menu-item class="dropdown-item"
                      (click)="deletePlanSchedule(element.id)">
                {{'PAYMENTS.scheduled-payments.delete' | translate}}
              </button>
              <button *ngIf="element.activated_at && !element.cancelled_at" mat-menu-item class="dropdown-item"
                      (click)="cancelPlanSchedule(element.id)">
                {{'PAYMENTS.scheduled-payments.cancel' | translate}}
              </button>
              <button *ngIf="!element.activated_at && !element.cancelled_at && !element.notified_at" mat-menu-item
                      class="dropdown-item" (click)="sendPlanScheduleToClient(element.id)">
                Send to client
              </button>
            </mat-menu>
          </td>
        </ng-container>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && paymentPlanSchedules && paymentPlanSchedules.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "PAYMENTS.no-payments" | translate }}</h3>
      </div>

      <!--  <mat-paginator
         [length]="paginatorConfig.length"
         [pageSize]="paginatorConfig.pageSize"
         [pageSizeOptions]="[5, 10, 20, 25]"
         showFirstLastButtons
       ></mat-paginator> -->
    </div>
  </div>
</div>
