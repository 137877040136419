<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<div *ngIf="!isLoading">
  <h4 *ngIf="editorType === 'create'">{{ "AFFILIATES.add-new" | translate }}</h4>
  <h4 *ngIf="editorType === 'edit'">{{ "AFFILIATES.edit-aff" | translate }}</h4>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="form" (submit)="formSubmit()">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <!--Packagers-->
                <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2"
                           [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true"
                           searchable="true" [searchable]="true"
                           [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
                </app-input>
                <!--Name-->
                <app-input type="text" formControlName="name" [label]="'AFFILIATES.name' | translate"
                           fullWidth="true"></app-input>
                <!--Activity-->
                <app-input type="select" formControlName="active" appearance="outline" class="mt-2" [searchable]="true"
                           [label]="'AFFILIATES.activity' | translate" showLabel="true" fullWidth="true"
                           [selectOptions]="statusTypes" [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
                <!--Overload intitial case status-->
                <app-input type="select" formControlName="overload_status_id" appearance="outline" class="mt-2"
                           [label]="'AFFILIATES.editor.overload_status_id.label' | translate" showLabel="true"
                           fullWidth="true" [searchable]="true"
                           [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
                <!--Overwrite client regreso status-->
                <app-input type="select" formControlName="overwrite_cliente_regreso_status_id" appearance="outline"
                           class="mt-2" [searchable]="true" fullWidth="true" [selectOptions]="statuses"
                           [selectLabel]="'name'"
                           [label]="'AFFILIATES.editor.overload_regreso_status.label' | translate" showLabel="true"
                           [selectValue]="'id'">
                </app-input>
                <!--Users-->
                <app-input type="select" formControlName="user_ids" appearance="outline" multiple class="mt-2"
                           [label]="'CONFIG.misc.users' | translate" showLabel="true" fullWidth="true" searchable="true"
                           [selectOptions]="affiliateUsers" [selectLabel]="['first_name', 'last_name']"
                           [selectValue]="'id'" [searchable]="true">
                </app-input>
              </div>
              <div class="col-md-6">
                <app-input type="checkbox" formControlName="allow_under_5000" appearance="fill"
                           [label]="'AFFILIATES.allow_under_5000' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_without_debt" appearance="fill"
                           [label]="'AFFILIATES.allow_without_debt' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_duplicates" appearance="fill"
                           [label]="'AFFILIATES.allow_duplicates' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="is_df_affiliate" appearance="fill"
                           [label]="'AFFILIATES.is_df_affiliate' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_overwrite_df_affiliates" appearance="fill"
                           [label]="'AFFILIATES.allow_overwrite_df_affiliates' | translate">
                </app-input>
                <app-input type="checkbox" formControlName="allow_overwrite_non_df_affiliates" appearance="fill"
                           [label]="'AFFILIATES.allow_overwrite_non_df_affiliates' | translate">
                </app-input>
              </div>
            </div>
            <!-- Campaigns -->
            <div class="row mt-5">
              <div class="col-md-12 pt-md-0 pt-5">
                <form *ngIf="form" [formGroup]="form">
                  <div>
                    <div class="row">
                      <div class="col-12 table-container">
                        <div class="d-flex justify-content-between">
                          <h5>{{ 'AFFILIATES.editor.affiliate_campaign.heading' | translate }}</h5>
                          <!-- Add -->
                          <button mat-raised-button color="primary" (click)="addCampaign(affiliate)">
                            + {{ 'AFFILIATES.editor.affiliate_campaign.add_campaign' | translate }}
                          </button>
                        </div>
                        <table *ngIf="getCampaignFormArray().controls.length" formArrayName="affiliate_campaigns"
                               class="table">
                          <thead>
                          <tr>
                            <th scope="col">{{ 'AFFILIATES.editor.affiliate_campaign.marketing_channel' | translate }}
                            </th>
                            <th scope="col">{{ 'AFFILIATES.editor.affiliate_campaign.name' | translate }}</th>
                            <th scope="col">{{ 'AFFILIATES.editor.affiliate_campaign.tracking_id' | translate }}</th>
                            <th scope="col"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <ng-container *ngFor="let formGroup of getCampaignFormArray().controls let i = index">
                            <tr class="first-row border-top" [formGroupName]="i" style="width: 100%">
                              <!-- Channel -->
                              <td>
                                <app-input type="select" formControlName="marketing_channel_id" [searchable]="true"
                                           [selectLabel]="'name'" [selectValue]="'id'" [fullWidth]="true"
                                           [label]="'AFFILIATES.editor.affiliate_campaign.marketing_channel' | translate"
                                           [selectOptions]="channelOptions" [showClear]="false">
                                </app-input>
                              </td>
                              <!-- Name -->
                              <td>
                                <app-input type="text" formControlName="name"
                                           [label]="'AFFILIATES.editor.affiliate_campaign.name' | translate"
                                           [fullWidth]="true">
                                </app-input>
                              </td>
                              <!-- Tracking ID -->
                              <td>
                                <app-input type="text" formControlName="tracking_id" [readonly]="true"
                                           *ngIf="getCampaignFormArray().at(i).get('tracking_id').value"
                                           [label]="'AFFILIATES.editor.affiliate_campaign.tracking_id' | translate"
                                           [fullWidth]="true">
                                </app-input>
                              </td>
                              <!-- Remove Record -->
                              <td>
                                <button mat-icon-button color="warn" class="ms-2"
                                        (click)="removeCampaign(i)"
                                        *ngIf="!getCampaignFormArray().at(i).get('default').value">
                                  <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!--Postbacks-->
            <div class="row mt-5">
              <div class="col-12 my-3">
                <div class="d-flex justify-content-between mb-2">
                  <h5>{{ 'AFFILIATES.editor.postback_form.heading' | translate }}</h5>
                  <!-- Add -->
                  <button mat-raised-button color="primary" type="button" (click)="addPostback(null)">
                    + {{ 'AFFILIATES.editor.postback_form.add_postback' | translate }}
                  </button>
                </div>
                <div *ngIf="getPostbacksFormArray().controls.length" formArrayName="postbacks">
                  <mat-accordion>
                    <div class="first-row border-top"
                         *ngFor="let formGroup of getPostbacksFormArray().controls let postbackIndex = index"
                         style="width: 100%">
                      <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{ 'AFFILIATES.editor.postback_form.heading_singular' | translate }} {{ postbackIndex }}
                          </mat-panel-title>
                          <button mat-icon-button color="warn" class="ms-2"
                                  (click)="removePostback(postbackIndex)">
                            <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                          </button>
                        </mat-expansion-panel-header>
                        <div class="d-flex flex-column" [formGroupName]="postbackIndex" style="width: 100%">
                          <div class="d-flex flex-row postback-form-wrapper" style="width: 100%">
                            <div class="me-2">
                              <app-input type="select" formControlName="lead_conversion_type_id" [searchable]="true"
                                         [label]="'AFFILIATES.editor.postback_form.conversion_type' | translate"
                                         [fullWidth]="true" readonly="true" [selectOptions]="leadConversionTypes"
                                         [selectValue]="'id'" [selectLabel]="'name'">
                              </app-input>
                              <app-input type="textarea" formControlName="destination"
                                         [label]="getPostbacksFormArray().at(postbackIndex).get('type').value === 'email' ?
                                         ('USERS.model.email' | translate) :
                                         ('AFFILIATES.editor.postback_form.destination' | translate)"
                                         [showLabel]="true" [extraLabel]="false" appearance="outline"
                                         [fullWidth]="true">
                              </app-input>
                            </div>
                            <div>
                              <app-input type="select" formControlName="type" [searchable]="true"
                                         (ngModelChange)="changePostbackType(postbackIndex, $event)"
                                         [label]="'AFFILIATES.editor.postback_form.type' | translate"
                                         [fullWidth]="true" readonly="true" [selectOptions]="type"
                                         [selectValue]="'value'" [selectLabel]="'label'">
                              </app-input>
                            </div>
                          </div>
                          <!-- Post Data -->
                          <div class="table-container"
                               *ngIf="getPostbacksFormArray().at(postbackIndex).get('type').value === 'http-post'">
                            <table *ngIf="getPostData(postbackIndex).controls.length" formArrayName="post_data"
                                   class="table">
                              <thead>
                              <tr>
                                <th
                                  scope="col">{{ "AFFILIATES.editor.postback_form.post_data_form.post_data" | translate }}
                                </th>
                                <th
                                  scope="col">{{ "AFFILIATES.editor.postback_form.post_data_form.post_value" | translate }}
                                </th>
                                <th></th>
                              </tr>
                              </thead>
                              <tbody>
                              <ng-container
                                *ngFor="let formGroup of getPostData(postbackIndex).controls let postDataIndex = index">
                                <tr class="first-row border-top" [formGroupName]="postDataIndex" style="width: 100%">
                                  <!-- Post data -->
                                  <td>
                                    <app-input type="text" formControlName="data_field"
                                               [label]="'AFFILIATES.editor.postback_form.post_data_form.post_data' | translate"
                                               [fullWidth]="true">
                                    </app-input>
                                  </td>
                                  <!-- Post value -->
                                  <td>
                                    <app-input type="text" formControlName="data_value"
                                               [label]="'AFFILIATES.editor.postback_form.post_data_form.post_value' | translate"
                                               [fullWidth]="true">
                                    </app-input>
                                  </td>
                                  <!-- Remove Post Data -->
                                  <td>
                                    <button mat-icon-button color="warn" class="ms-2" type="button"
                                            (click)="removePostData(postbackIndex, postDataIndex)">
                                      <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                                    </button>
                                  </td>
                                </tr>
                              </ng-container>
                              </tbody>
                            </table>
                            <button mat-icon-button color="primary" type="button"
                                    (click)="addPostData(null,postbackIndex)">
                              <mat-icon>add_circle</mat-icon>
                            </button>
                          </div>
                          <!-- Mail Subject -->
                          <div *ngIf="getPostbacksFormArray().at(postbackIndex).get('type').value === 'email'">
                            <label>{{ "AFFILIATES.editor.postback_form.mail_subject" | translate }}</label>
                            <app-input type="text" formControlName="mail_subject" [appearance]="'outline'"
                                       [fullWidth]="true"
                                       [label]="'SHARED.model.name' | translate" [extraLabel]="false">
                            </app-input>
                          </div>
                          <!-- Mail Content -->
                          <div *ngIf="getPostbacksFormArray().at(postbackIndex).get('type').value === 'email'">
                            <label>{{ "AFFILIATES.editor.postback_form.mail_content" | translate }}</label>
                            <quill-editor
                              formControlName="mail_content"
                              (onEditorCreated)="getEditorInstance($event)"
                              [modules]="quillModules"
                              [styles]="{height: '350px'}">
                            </quill-editor>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </mat-accordion>
                </div>
              </div>
            </div>


            <div class="row pt-5">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <button *ngIf="editorType === 'create'" mat-raised-button color="primary" type="submit">
                    {{ "AFFILIATES.create-aff" | translate }}
                  </button>
                  <button *ngIf="editorType === 'edit'" mat-raised-button color="primary" type="submit">
                    {{ "SHARED.update" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6">
      <div class="card shadow">
        <div class="card-header">{{ "CONFIG.sms-template.variables" | translate }}</div>
        <div class="card-body">
          <h5 class="text-center mt-3" *ngFor="let variable of affiliateVariables"><code>{{ variable }}</code></h5>
        </div>
      </div>

      <div class="card shadow mt-5">
        <div class="card-header">Volume bonus</div>
        <div class="card-body">
          <form *ngIf="form" [formGroup]="form">
            <app-input type="select" appearance="outline" [searchable]="true" [fullWidth]="true"
                       formControlName="volume_bonus_conversion_status_id" [extraLabel]="false"
                       [selectOptions]="statuses"
                       [label]="'AFFILIATES.editor.conversion_status.default_selected' | translate"
                       [selectLabel]="['name']" [selectValue]="'id'">
            </app-input>
            <table *ngIf="getVolumeBonusFormArray().controls.length" formArrayName="volume_bonuses"
                   class="lead-tracking-table">
              <thead>
              <tr>
                <th>{{ 'AFFILIATES.editor.tier' | translate }}</th>
                <th>{{ 'AFFILIATES.editor.number_of_cases' | translate }}</th>
                <th>{{ 'AFFILIATES.editor.cost' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let formGroup of getVolumeBonusFormArray().controls let i = index">
                <tr [formGroupName]="i">
                  <td>
                    <app-input type="text" formControlName="name" appearance="outline"
                               [label]="'SHARED.model.name' | translate" [fullWidth]="true" [showClear]="false">
                    </app-input>
                  </td>
                  <td>
                    <app-input type="number" formControlName="number_of_cases" appearance="outline"
                               [label]="'AFFILIATES.editor.number_of_cases' | translate" [fullWidth]="true"
                               [showClear]="false">
                    </app-input>
                  </td>
                  <td>
                    <app-input type="number" formControlName="volume_bonus_cost" appearance="outline"
                               [label]="'AFFILIATES.editor.cost' | translate" [fullWidth]="true" [showClear]="false">
                    </app-input>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </form>
        </div>
      </div>

      <div class="card shadow mt-5">
        <div class="card-header">{{ "AFFILIATES.lead_tracking" | translate }}</div>
        <div class="card-body">
          <form *ngIf="form" [formGroup]="form">
            <table *ngIf="getTrackingFormArray().controls.length" formArrayName="lead_tracking"
                   class="lead-tracking-table">
              <thead>
              <tr>
                <th>{{ "AFFILIATES.editor.product_type.label" | translate }}</th>
                <th>{{ "AFFILIATES.editor.tracking-type.label" | translate }}</th>
                <th>{{ "AFFILIATES.editor.cost" | translate }}</th>
                <th>{{ "AFFILIATES.editor.conversion_status.label" | translate }}</th>
                <th>{{ 'AFFILIATES.editor.secondary_cost' | translate }}</th>
                <th>{{ 'AFFILIATES.editor.secondary_status' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let formGroup of getTrackingFormArray().controls let i = index">
                <tr [formGroupName]="i">
                  <td>
                    <app-input type="text" formControlName="name" appearance="outline"
                               [label]="'AFFILIATES.editor.product_type.label' | translate"
                               [fullWidth]="true" [showClear]="false">
                    </app-input>
                  </td>
                  <td>
                    <app-input type="select" appearance="outline" [searchable]="false" [fullWidth]="true"
                               formControlName="tracking_type" [extraLabel]="false" [selectOptions]="trackingOptions"
                               [label]="'AFFILIATES.editor.tracking-type.default_selected' | translate"
                               [selectLabel]="['label']"
                               [selectValue]="'value'">
                    </app-input>
                  </td>
                  <td>
                    <app-input type="number" formControlName="cost" appearance="outline"
                               [label]="'AFFILIATES.editor.cost' | translate"
                               [fullWidth]="true" [showClear]="false">
                    </app-input>
                  </td>
                  <td>
                    <app-input *ngIf="form.value?.lead_tracking[i]?.tracking_type === 'cpa'"
                               type="select" appearance="outline" [searchable]="true" [fullWidth]="true"
                               formControlName="status_id" [extraLabel]="false" [selectOptions]="statuses"
                               [label]="'AFFILIATES.editor.conversion_status.default_selected' | translate"
                               [selectLabel]="['name']"
                               [selectValue]="'id'">
                    </app-input>
                  </td>
                  <td>
                    <app-input *ngIf="form.value?.lead_tracking[i]?.tracking_type === 'cpa'"
                               type="number" formControlName="secondary_cost" appearance="outline"
                               [label]="'AFFILIATES.editor.secondary_cost' | translate"
                               [fullWidth]="true" [showClear]="false">
                    </app-input>
                  </td>
                  <td>
                    <app-input *ngIf="form.value?.lead_tracking[i]?.tracking_type === 'cpa'"
                               type="select" appearance="outline" [searchable]="true" [fullWidth]="true"
                               formControlName="secondary_status_id" [extraLabel]="false" [selectOptions]="statuses"
                               [label]="'AFFILIATES.editor.secondary_status' | translate" [selectLabel]="['name']"
                               [selectValue]="'id'">
                    </app-input>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
