<div *ngIf="isLoading" class="p-1" style="height: 90px">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<form *ngIf="form" [formGroup]="form" (ngSubmit)="uploadFiles(form, null, null)" class="form">
  <div class="row pb-4">
    <!-- File select -->
    <div class="col-3 pt-2">
      <div class="form-group">
        <div class="input-group">
          <div class="custom-file">
            <input multiple type="file" class="custom-file-input" [lang]="currentLanguage" id="uploadFile"
                   formControlName="files" (change)="fileSelected($event, null, null)">
            <label class="custom-file-label" for="uploadFile">
              {{ 'DOCUMENTS.choose-file' | translate }}
            </label>
          </div>
        </div>
        <div *ngFor="let file of selectedUploadFiles"
             class="d-flex justify-content-between align-items-center">
          <p class="m-0 uploaded-file-name">{{ file.name }}</p>
          <button type="button" mat-icon-button color="warn" (click)="removeSelectedFile(file.index)">
            <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!-- Client/Partner selection-->
    <div class="col-2 d-flex">
      <app-input *ngIf="clientRoleOptions?.length" type="select"
                 formControlName="client_role" appearance="outline" [searchable]="true"
                 [label]="'Client'" [selectOptions]="clientRoleOptions"
                 [selectLabel]="'label'" [selectValue]="'value'" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <!-- Document Type Selector -->
    <div class="col-3">
      <app-input *ngIf="caseDocumentTypeCategories?.length" type="select"
                 formControlName="app_document_type_id" appearance="outline" [searchable]="true"
                 [label]="'DOCUMENTS.select-category' | translate" [selectOptions]="caseDocumentTypeCategories"
                 [optGroupProperty]="'document_types'" [optGroupLabel]="'name_' + currentLanguage"
                 [selectLabel]="'name_' + currentLanguage" [selectValue]="'id'" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <!-- Documentable ID -->
    <div *ngIf="selectedDocumentType?.custom_input_logic === 'case_creditor_selection'" class="col-3">
      <app-input *ngIf="caseCreditorOptions?.length" type="select"
                 formControlName="documentable_id" appearance="outline" [searchable]="true"
                 [label]="'Case creditor'" [selectOptions]="caseCreditorOptions"
                 selectLabel="label" selectValue="value" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <div *ngIf="selectedDocumentType?.custom_input_logic === 'case_public_debt_selection'" class="col-3">
      <app-input *ngIf="casePublicDebtOptions?.length" type="select"
                 formControlName="documentable_id" appearance="outline" [searchable]="true"
                 [label]="'Public debt'" [selectOptions]="casePublicDebtOptions"
                 selectLabel="label" selectValue="value" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <div *ngIf="selectedDocumentType?.custom_input_logic === 'case_asset_selection'" class="col-3">
      <app-input *ngIf="caseAssetOptions?.length" type="select"
                 formControlName="documentable_id" appearance="outline" [searchable]="true"
                 [label]="'Bank account'" [selectOptions]="caseAssetOptions"
                 selectLabel="label" selectValue="value" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <!-- Custom Document ID -->
    <div *ngIf="selectedDocumentType?.custom_input_logic === 'custom_selection'" class="col-3">
      <app-input *ngIf="customDocuments?.length" type="select"
                 formControlName="app_document_id" appearance="outline" [searchable]="true"
                 [label]="'Custom document'" [selectOptions]="customDocuments"
                 selectLabel="name" selectValue="id" class="full-width"
                 [showClear]="false" [fullWidth]="true">
      </app-input>
    </div>
    <div class="col-1 pt-1">
      <app-spinner-btn [loading]="isUploading" [name]="'SHARED.submit' | translate"></app-spinner-btn>
    </div>
  </div>
</form>
