import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { DialerService } from '../dialer.service';
import { DialerStatusTriggerListComponent } from './dialer-status-trigger-list/dialer-status-trigger-list.component';

@Component({
  selector:    'app-dialer-trigger-list-wrapper',
  templateUrl: 'dialer-trigger-list-wrapper.component.html',
  styles:      []
})
export class DialerTriggerListWrapperComponent implements OnInit {
  @Input() statusCategories: Array<Status>;
  @Input() paymentStatuses: Array<PaymentStatus>;
  @ViewChild('statusTriggersTemplate') statusComponent: DialerStatusTriggerListComponent;
  @ViewChild('paymentStatusTriggersTemplate') paymentStatusComponent: DialerStatusTriggerListComponent;
  @ViewChild('callStatusTriggersTemplate') callStatusComponent: DialerStatusTriggerListComponent;

  public isLoading = 0;
  public isSubmitting: boolean;
  public serverResponse: LaravelResourceResponse;

  constructor(
    private toastr: ToastrService,
    private dialerService: DialerService,
  ) {
  }

  ngOnInit(): void {
  }

  public submitForm(): void {
    const statusData        = this.statusComponent.gatherFormData();
    const paymentStatusData = this.paymentStatusComponent.gatherFormData();
    const callStatusData    = this.callStatusComponent.gatherFormData();

    const statusRawData        = statusData.getRawValue();
    const paymentStatusRawData = paymentStatusData.getRawValue();
    const callStatusRawData    = callStatusData.getRawValue();

    const combinedData = {
      dialer_triggers: [
        ...(statusRawData.dialer_triggers || []).filter(trigger => trigger.statusable_type === 'status'),
        ...(paymentStatusRawData.dialer_triggers || []).filter(trigger => trigger.statusable_type === 'payment_status'),
        ...(callStatusRawData.dialer_triggers || []).filter(trigger => trigger.statusable_type === 'call_status')
      ]
    };
    this.isSubmitting  = true;
    this.dialerService.upsertTriggers(combinedData)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        res => {
          this.toastr.success('Dialer config saved');
        },
        err => {
          this.serverResponse = err.error;
          this.toastr.error('Dialer config did not save');
        },
      );
  }
}
