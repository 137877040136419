import { Case } from '../Case/Case';

export class PackagerStatus {
  id?: number;
  uuid?: string;

  slug?: string;
  name_en?: string;
  name_es?: string;
  removable?: boolean | 0 | 1;
  name?: string;
  created_at?: string | Date;
  updated_at?: string | Date;

  cases?: Array<Case>;
}
