<div class="card-header" style="border: 1px solid #e4e4e4;">
  <h4 class="text-center m-0">{{ "CASES.single.transaction-history" | translate }}</h4>
</div>
<div class="card-body p-0" *ngIf="transactions.length">
  <mat-accordion multi>
    <mat-expansion-panel class="bg-light m-1" *ngFor="let transaction of transactions">
      <mat-expansion-panel-header>
        <mat-panel-title style="justify-content: space-between">
          <p class="m-0">Created at: <b>{{transaction.created_at | toDateObject | date: 'medium'}}</b></p>
          <p class="m-0">Amount: <b>{{transaction.amount | number }}</b></p>
          <p class="m-0">Provider:
            <b>{{ transaction.payment_processor ? transaction.payment_processor.name : transaction.provider }}</b>
          </p>
          <p *ngIf="transaction.success" class="m-0 badge badge-success">Success</p>
          <p *ngIf="!transaction.success" class="m-0 badge badge-danger">Failed</p>
          <p class="m-0">Status Code: <b>{{transaction.status_code}}</b></p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <pre class="prettyprint">{{ transaction | jsonPrettyPrint }}</pre>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div *ngIf="!transactions.length" style="border: 1px solid #e4e4e4; border-top: none;">
  <h5 class="text-center pt-3 pb-3">{{ "CASES.single.no-payments" | translate }}</h5>
</div>
