import { Component, Input, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { PaymentRequest } from '../../../../../../../../_base-shared/models/Payment/PaymentRequest';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-payment-request-list',
  templateUrl: './case-payment-request-list.component.html',
  styles:      [],
})
export class CasePaymentRequestListComponent implements OnInit {
  @Input() case: Case;
  @Input() componentType: 'distribution' | 'admin';

  public dataSource: MatTableDataSource<PaymentRequest>;
  public isLoading                  = 0;
  public authUser;
  public displayedColumns: string[] = ['created_at', 'amount', 'success'];
  public paginatorConfig            = {pageIndex: 0, pageSize: 10, length: 1};

  constructor(
      private dialog: MatDialog,
      private translate: TranslateService,
      private globalEventService: MainGlobalEventService,
      private caseService: CaseService,
      private distributionService: DistributionService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      this.fetchPaymentLinks(this.componentType);
    });
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.fetchPaymentLinks(type);
  }

  private fetchPaymentLinks(componentType: 'admin' | 'distribution') {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentRequest>([]);

    const data = {
      caseId:   this.case.id,
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
    };

    const observable = componentType === 'admin' ?
        this.caseService.getPaymentLinks(data) :
        this.distributionService.indexPaymentRequests(this.case.uuid, data);

    observable.pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.dataSource             = new MatTableDataSource<PaymentRequest>(result.data);
          this.paginatorConfig.length = result.meta.total;
        },
        error => console.log(error),
    );
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchPaymentLinks(this.componentType);
  }

}
