import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { interval } from 'rxjs';
import { debounce, finalize, pairwise } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { BankAccount } from '../../../../../../../../../_base-shared/models/Payment/BankAccount';
import { PaymentMethod } from '../../../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentTerm } from '../../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { PaymentPlanType } from '../../../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { UploadService } from '../../../../../app-file/upload.service';
import { DistributionService } from '../../../../../distribution/distribution.service';
import { PaymentMethodService } from '../../../../../payment/payment-method.service';
import { PaymentPlanTypeService } from '../../../../../payment/payment-plan-type.service';
import { PaymentService } from '../../../../../payment/payment.service';
import { CaseService } from '../../../../case.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector:    'app-record-payment-modal',
  templateUrl: './record-payment-modal.component.html',
  styles:      [
    `
      .record-row:hover {
        background-color: #e0efce;
      }
    `
  ],
})
export class RecordPaymentModalComponent implements OnInit {
  public isLoading                                = 0;
  public paymentMethods: Array<PaymentMethod>;
  public paymentPlanTypes: Array<PaymentPlanType>;
  public incomeBankAccounts: Array<BankAccount>;
  public unallocated                              = 0;
  public phase_one_allocated                      = 0;
  public phase_two_allocated                      = 0;
  public error                                    = '';
  public relocateNoteError                        = false;
  public serverError                              = '';
  public splitType                                = 'custom';
  public showNotes                                = false;
  public isRecordingPayment                       = 0;
  public sortOrder                                = null;
  public sortBy                                   = null;
  public form: UntypedFormGroup;
  public upcomingInstallments: Array<PaymentTerm> = [];
  public quillModules                             = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };
  public paginatorConfig                          = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };

  constructor(
    public translateService: TranslateService,
    public uploadService: UploadService,
    public dialogRef: MatDialogRef<RecordPaymentModalComponent>,
    public caseService: CaseService,
    private paymentMethodService: PaymentMethodService,
    private paymentPlanTypeService: PaymentPlanTypeService,
    private paymentService: PaymentService,
    private distributionService: DistributionService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      formA: UntypedFormGroup, relocate?: boolean, forAdditionalPlan?: boolean,
      payment_plan_type: string, case: Case, componentType: 'distribution' | 'admin'
    }) {
  }

  ngOnInit(): void {
    this.fetchInstallments(this.data.componentType);
  }

  public getFormArray(formControlName: string) {
    return this.form.get(formControlName) as UntypedFormArray;
  }

  private fetchPaymentMethods(): void {
    this.isLoading++;
    this.paymentMethodService.index({}, ['billing_bank_accounts']).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentMethods = result.data;
        this.buildForm();
        const selectedMethod = this.paymentMethods.find(m => m.slug === this.form.get('payment_method').value);
        this.paymentMethodChanged(selectedMethod.slug);
      });
  }

  private fetchPaymentPlanTypes() {
    this.isLoading++;
    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.paymentPlanTypes = result.data);
  }

  onTermDiminishChange(amount) {
    let split = 0;
    this.form.value.terms.forEach(fee => {
      split += fee.amount_paid;
      if (fee.type === 'phase_one' || fee.type === 'deferred_one') {
        this.phase_one_allocated += fee.amount_paid;
      } else if (fee.type === 'phase_two' || fee.type === 'deferred_two') {
        this.phase_two_allocated += fee.amount_paid;
      }
    });
    this.unallocated = +(+amount - split).toFixed(2);
  }

  public getPercent(paid, amount) {
    if ((paid / amount) * 100) {
      return ((paid / amount) * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  validate(): boolean {
    this.error             = '';
    this.relocateNoteError = false;
    if (this.unallocated > 0) {
      this.error = this.translateService.instant('CASES.single.error-money-left');
      return false;
    }
    if (this.unallocated < 0) {
      this.error = this.translateService.instant('CASES.single.error-diminish');
      return false;
    }
    this.form.value.terms.forEach((installment: PaymentTerm) => {
      if (installment.amount < +(+(installment.amount_paid + +(installment.old_amount_paid)).toFixed(2))) {
        console.log("Amount paid is:", installment.amount, "Old payment amount is:", installment.old_amount_paid);
        this.error = this.translateService.instant('CASES.single.error-diminish-greater');
        return false;
      }
    });
    if ( ! this.data.relocate) {
      if ( ! this.form.value.payment_method) {
        this.error = this.translateService.instant('CASES.single.error-payment-method');
        return false;
      }
      if ( ! this.form.value.payment_date) {
        this.error = this.translateService.instant('CASES.single.error-payment-date');
        return false;
      }
    }
    if (this.form.value.amount < 0) {
      this.form.value.terms.forEach((installment: PaymentTerm) => {
        if (installment.old_amount_paid < ((-1) * installment.amount_paid)) {
          console.log("Amount paid is:", installment.amount, "Old payment amount is:", installment.old_amount_paid);
          this.error = this.translateService.instant('CASES.single.error-refund-greater');
          return false;
        }
      });
    }
    if (this.data.relocate && ! this.form.value.note) {
      this.error             = this.translateService.instant('CASES.single.payment-note-required');
      this.relocateNoteError = true;
      return false;
    }
    return this.error === '';
  }

  changeType(splitType) {
    // this.resetPayment();
    this.form.get('split_type').setValue(splitType);
    this.splitType = splitType;
  }

  resetPayment() {
    this.phase_one_allocated = 0;
    this.phase_two_allocated = 0;
    this.unallocated         = +this.form.value.amount;
    this.setDiminish(0);
  }

  calculateDiminish() {
    const formValues = this.form.value;
    if (this.unallocated && this.splitType === 'even') {
      if (formValues.phase_one_amount && (formValues.phase_one_amount - this.phase_one_allocated) > 0) {
        this.splitEven('phase_one');
      } else if (((formValues.phase_two_amount - this.phase_two_allocated) > 0) || formValues.amount < 0) {
        this.splitEven('phase_two');
      }
    } else if (this.unallocated && this.splitType === 'reduce') {
      if (formValues.phase_one_amount && (formValues.phase_one_amount - this.phase_one_allocated) > 0) {
        this.reduceFromLast('phase_one');
      } else if (((formValues.phase_two_amount - this.phase_two_allocated) > 0) || formValues.amount < 0) {
        this.reduceFromLast('phase_two');
      }
    } else {
      // if (formValues.phase_one_amount && (formValues.phase_one_amount - this.phase_one_allocated) > 0) {
      //   this.reduceFromStart('phase_one');
      // } else if (((formValues.phase_two_amount - this.phase_two_allocated) > 0) || formValues.amount < 0) {
      //   this.reduceFromStart('phase_two');
      // }
    }
  }

  reduceFromStart(phaseName: string) {
    const formArray = this.form.get('terms') as UntypedFormArray;
    if (this.form.value.amount < 0) {
      formArray.controls.map(control => {
        if (this.unallocated === 0 || control.value.old_amount_paid === 0) {
          control.setValue({...control.value, amount_paid: 0});
        } else {
          if (-this.unallocated >= control.value.old_amount_paid) {
            control.setValue({
              ...control.value,
              amount_paid: -control.value.old_amount_paid,
            });
            this.unallocated -= control.value.amount_paid;
          } else {
            control.setValue({...control.value, amount_paid: this.unallocated});
            this.unallocated = 0;
          }
        }
      });
      if (phaseName === 'phase_two' && this.unallocated) {
        this.reduceFromStart('phase_one');
      }
    } else {
      if (phaseName === 'phase_one' || formArray.value.phase_one_amount === 0) {
        this.unallocated = this.form.value.amount;
      }
      formArray.controls.map(control => {
        if (control.value.type !== phaseName && control.value.type !== phaseName.replace('phase', 'deferred')) {
          return;
        }
        if (this.unallocated === 0) {
          control.setValue({...control.value, amount_paid: 0});
        } else {
          if (this.unallocated >= control.value.amount - control.value.old_amount_paid) {
            control.setValue({
              ...control.value,
              amount_paid: control.value.amount - control.value.old_amount_paid,
            });
            this.unallocated -= control.value.amount - control.value.old_amount_paid;
          } else {
            control.setValue({...control.value, amount_paid: this.unallocated});
            this.unallocated = 0;
          }
        }
      });
      if (phaseName === 'phase_one' && this.unallocated && this.form.value.phase_one_amount <
        this.form.value.amount) {
        this.reduceFromStart('phase_two');
      }
    }
  }

  reduceFromLast(phaseName: string) {
    const formArray = this.form.get('terms') as UntypedFormArray;
    if (this.form.value.amount < 0) {
      for (let i = formArray.controls.length - 1; i >= 0; i--) {
        if (this.unallocated === 0 || formArray.controls[i].value.old_amount_paid === 0) {
          formArray.controls[i].setValue({...formArray.controls[i].value, amount_paid: 0});
        } else {
          if (-this.unallocated >= formArray.controls[i].value.old_amount_paid) {
            formArray.controls[i].setValue({
              ...formArray.controls[i].value,
              amount_paid: -formArray.controls[i].value.old_amount_paid,
            });
            this.unallocated -= formArray.controls[i].value.amount_paid;
          } else {
            formArray.controls[i].setValue({...formArray.controls[i].value, amount_paid: this.unallocated});
            this.unallocated = 0;
          }
        }
      }
      if (phaseName === 'phase_two' && this.unallocated) {
        this.reduceFromLast('phase_one');
      }
    } else {
      if (phaseName === 'phase_one' || formArray.value.phase_one_amount === 0) {
        this.unallocated = this.form.value.amount;
      }
      for (let i = formArray.controls.length - 1; i >= 0; i--) {
        if (formArray.controls[i].value.type !== phaseName && formArray.controls[i].value.type !==
          phaseName.replace('phase', 'deferred')) {
          continue;
        }
        if (this.unallocated === 0) {
          formArray.controls[i].setValue({...formArray.controls[i].value, amount_paid: 0});
        } else {
          if (this.unallocated >= formArray.controls[i].value.amount - formArray.controls[i].value.old_amount_paid) {
            formArray.controls[i].setValue({
              ...formArray.controls[i].value,
              amount_paid: formArray.controls[i].value.amount - formArray.controls[i].value.old_amount_paid,
            });
            this.unallocated -= formArray.controls[i].value.amount - formArray.controls[i].value.old_amount_paid;
          } else {
            formArray.controls[i].setValue({...formArray.controls[i].value, amount_paid: this.unallocated});
            this.unallocated = 0;
          }
        }
      }
      if (phaseName === 'phase_one' && this.unallocated && this.form.value.phase_one_amount <
        this.form.value.amount) {
        this.reduceFromLast('phase_two');
      }
    }
  }

  splitEven(phaseName: string) {
    const formArray = this.form.get('terms') as UntypedFormArray;
    let part;
    if (this.form.value.amount < 0) {
      part = this.unallocated / formArray.value.filter(term =>
        (term.type === phaseName || term.type === phaseName.replace('phase', 'deferred')) && term.old_amount_paid >
        0).length;
      part = Math.ceil(part);
      formArray.controls.map(control => {
        if (control.value.type !== phaseName) {
          return;
        }
        if (control.value.old_amount_paid >= -part) {
          control.setValue({...control.value, amount_paid: part});
          this.unallocated -= part;
        } else {
          control.setValue({...control.value, amount_paid: -control.value.old_amount_paid});
          this.unallocated -= -control.value.old_amount_paid;
        }
      });
      if (phaseName === 'phase_two' && this.unallocated) {
        this.splitEven('phase_one');
      }
    } else {
      part = this.unallocated / formArray.value.filter(term =>
        term.type === phaseName && (term.amount - term.old_amount_paid) > 0).length;
      part = Math.floor(part);
      formArray.controls.map(control => {
        if (control.value.type !== phaseName) {
          return;
        }
        if (control.value.amount - control.value.old_amount_paid >= part) {
          control.setValue({...control.value, amount_paid: part});
          this.unallocated -= part;
        } else {
          control.setValue({...control.value, amount_paid: control.value.amount - control.value.old_amount_paid});
          this.unallocated -= control.value.amount - control.value.old_amount_paid;
        }
      });
      if (phaseName === 'phase_one' && this.unallocated && this.form.value.phase_one_amount <
        this.form.value.amount) {
        this.splitEven('phase_two');
      }
    }
  }

  setDiminish(diminishPerRate) {
    const formArray   = this.form.get('terms') as UntypedFormArray;
    const poFormArray = this.form.get('terms_phase_one') as UntypedFormArray;
    const ptFormArray = this.form.get('terms_phase_two') as UntypedFormArray;
    formArray.controls.map(control => {
      control.setValue({...control.value, amount_paid: diminishPerRate});
    });
    poFormArray.controls.map(control => {
      control.setValue({...control.value, amount_paid: diminishPerRate});
    });
    ptFormArray.controls.map(control => {
      control.setValue({...control.value, amount_paid: diminishPerRate});
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public submitForm($event): void {
    $event.preventDefault();
    if ( ! this.validate()) {
      return;
    }
    this.isRecordingPayment++;
    const data = {
      ...this.form.value,
      relocate:            !! this.data.relocate,
      for_additional_plan: !! this.data.forAdditionalPlan,
    };

    data.terms = data.terms.map(term => {
      if ( ! term.amount_paid) {
        term.amount_paid = 0;
      }
      return term;
    });

    data.payment_date = DateTime.fromJSDate(data.payment_date).toSQLDate();
    data.amount       = +data.amount;

    this.caseService.recordPayment(data)
      .pipe(finalize(() => this.isRecordingPayment--))
      .subscribe(
        res => {
          this.dialogRef.close(true);
        },
        err => {
          console.error(err);
          this.serverError = this.translateService.instant('CASES.single.payment-record-error');
        },
      );
  }

  public paymentMethodChanged(methodSlug: string) {
    const selectedMethod       = this.paymentMethods.find(m => m.slug === methodSlug);
    const defaultIncomeAccount = selectedMethod.billing_bank_accounts.find(account => account.default === true);

    this.incomeBankAccounts = selectedMethod.billing_bank_accounts;

    if (this.form.get('income_account_id').value !== defaultIncomeAccount.id) {
      this.form.get('income_account_id').patchValue(defaultIncomeAccount.id);
    }
  }

  private fetchInstallments(componentType: 'admin' | 'distribution'): void {
    const data = {
      per_page:        this.paginatorConfig.pageSize,
      page:            this.paginatorConfig.pageIndex + 1,
      case_id:         this.data.case.id,
      select_all:      1,
      order:           this.sortOrder || 'asc',
      sort_by:         this.sortBy || 'term_date',
      plan_type_slugs: this.data.payment_plan_type,
    };

    const withRelations = [
      'case.client',
      'case.debt_payment_plan',
      'case.payment_status',
      'case_payment_plan.type',
    ];
    const observable    = componentType === 'admin' ?
      this.paymentService.indexCaseInstallments(this.data.case.id, data, withRelations) :
      this.distributionService.indexInstallments(this.data.case.uuid, data, withRelations);

    this.isLoading++;
    /*this.dataSource           = new MatTableDataSource<PaymentTerm>([]);
     this.upcomingInstallments = [];*/
    observable.pipe(finalize(() => this.isLoading--))
      .subscribe({
        next:  res => {
          this.upcomingInstallments = res.data;
          this.fetchPaymentPlanTypes();
          this.fetchPaymentMethods();
        },
        error: error => console.log(error)
      });
  }

  public buildForm(): void {
    const defaultPaymentMethod = this.paymentMethods.find(m => m.slug === 'sepa');
    const defaultIncomeAccount = defaultPaymentMethod.billing_bank_accounts.find(account => account.default === true);
    const defaultPlanId        = this.paymentPlanTypes?.find(planType => planType.slug === 'debt_plan');

    this.form = this.fb.group({
      case_id:           this.data.case.id,
      payment_plan_id:   defaultPlanId?.id ? defaultPlanId.id : null,
      note:              '',
      payment_date:      [new Date()],
      payment_method_id: [defaultPaymentMethod.id],
      payment_method:    [defaultPaymentMethod.slug],
      income_account_id: [defaultIncomeAccount.id, Validators.required],
      split_type:        ['custom', null],
      amount:            [0, Validators.required],
      amount_paid:       [null, Validators.required],
      phase_one_amount:  [0, null],
      phase_two_amount:  [0, null],
      phase_one_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') && term.amount >
            term.amount_paid).length === 0, null],
      phase_two_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === 'phase_two' || term.type === 'deferred_two') && term.amount >
            term.amount_paid).length === 0, null],
      terms:             this.fb.array([]),
      terms_phase_one:   this.fb.array(
        this.upcomingInstallments.filter(
          term => term.type === null || term.type === 'phase_one' || term.type === 'deferred_one'),
      ),
      terms_phase_two:   this.fb.array(this.upcomingInstallments.filter(term => term.type === 'phase_two')),
    });
    this.addInstalments();
    this.calculateAmountPaid();
    this.form.get('terms').valueChanges
      .pipe(
        debounce(() => interval(20)),
      )
      .subscribe(res => {
        this.onTermDiminishChange(this.form.value.amount);
      });

    this.form.get('split_type').valueChanges
      .pipe(
        debounce(() => interval(20)),
      )
      .subscribe(res => {
        this.resetPayment();
        this.onTermDiminishChange(this.form.value.amount);
        this.calculateDiminish();
      });

    this.form.get('amount').valueChanges
      .pipe(
        debounce(() => interval(100)),
        pairwise(),
      )
      .subscribe(next => {
        if (next[0] === next[1]) {
          return;
        }
        this.error = '';
        if ((next[1] * (-1)) > this.form.get('amount_paid').value) {
          this.error = this.translateService.instant('CASES.single.error-total-refund-greater');
        }
        this.resetPayment();
        this.onTermDiminishChange(next[1]);
        this.calculateDiminish();
      });
  }

  addInstalments(): void {
    const instalments = this.form.get('terms') as UntypedFormArray;
    this.upcomingInstallments.forEach(term => {
      instalments.push(
        this.fb.group({
          id:              term.id,
          term_date:       term.term_date,
          date_paid:       term.date_paid,
          type:            term.type,
          name:            term.name,
          amount:          term.amount,
          amount_paid:     0,
          old_amount_paid: term.amount_paid,
        }),
      );
    });
  }

  private calculateAmountPaid(): void {
    let phaseOneAmount = 0;
    let phaseTwoAmount = 0;
    let amountPaid     = 0;
    let amountPayable  = 0;
    this.upcomingInstallments.forEach(term => {
      amountPayable += term.amount - term.amount_paid;
      amountPaid += term.amount_paid;
      if (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') {
        phaseOneAmount += term.amount - term.amount_paid;
      } else if (term.type === 'phase_two' || term.type === 'deferred_two') {
        phaseTwoAmount += term.amount - term.amount_paid;
      }
    });
    amountPayable = +amountPayable.toFixed(2);
    this.form.get('phase_one_amount').setValue(phaseOneAmount);
    this.form.get('phase_two_amount').setValue(phaseTwoAmount);
    this.form.get('amount_paid').setValue(amountPaid);
    this.form.get('amount').setValidators([Validators.required, Validators.max(Math.min(amountPayable, 4000))]);
  }
}
