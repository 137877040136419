import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseAsset, CaseAssetType } from '../../../../../../../_base-shared/models/Case/CaseAsset';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../../creditor/creditor.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseAssetService } from '../../case-asset.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-asset-editor',
  templateUrl: './case-asset-editor.component.html',
  styleUrls:   ['./case-asset-editor.component.scss'],
})
export class CaseAssetEditorComponent implements OnInit {
  public case: Case;
  public caseAssets: Array<CaseAsset>             = [];
  public isLoading                                = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public creditors: Array<Creditor>               = [];
  public ownershipOptions: Array<AppSelectOption> = [];
  public homeTypes: Array<AppSelectOption>        = [];

  public totalNetBankAccounts = 0;
  public totalNetVehicles     = 0;
  public totalNetProperties   = 0;
  public totalNetOther        = 0;

  constructor(
      private route: ActivatedRoute,
      private fb: UntypedFormBuilder,
      private toastr: ToastrService,
      private translate: TranslateService,
      private caseService: CaseService,
      private caseAssetService: CaseAssetService,
      private creditorService: CreditorService,
      private financialOverviewService: FinancialOverviewService,
  ) {
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case = result.data;
        this.isLoading++;
        this.caseAssetService.index(caseId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
          this.caseAssets = res.data;
          this.buildForm(this.caseAssets);
        });
      });
    });
    this.fetchCreditors();
    this.buildSelectOptions();
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseAssetService.upsert(this.case.id, form.getRawValue()).pipe(finalize(() => this.isSubmitting = false))
        .subscribe(
            () => {
              this.form.markAsPristine();
              this.toastr.success(this.translate.instant('CASES.editor.assets.result.success'));
            },
            error => {
              this.serverResponse = error.error;
              this.toastr.error(this.translate.instant('CASES.editor.assets.result.error'), error.error.message);
            },
        );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  public updateAssetTotalNetValue(assetType: CaseAssetType, updateFinancialOverview = true) {
    let totalNet = 0;

    if (assetType === 'bank_accounts') {
      this.getFormArray('bank_accounts').controls.forEach(control => {
        totalNet += +control.value.balance - +control.value.exposed;
      });
      this.totalNetBankAccounts = totalNet;
    }

    if (assetType === 'properties') {
      this.getFormArray('properties').controls.forEach(control => {
        totalNet += +control.value.value - +control.value.mortgage;
      });
      this.totalNetProperties = totalNet;
    }

    if (assetType === 'vehicles') {
      this.getFormArray('vehicles').controls.forEach(control => {
        if (control.value.on_finance) {
          console.log(control.value);
          console.log(+control.value.outstanding_finance);
        }
        totalNet += +control.value.value - (control.value.on_finance ? +control.value.outstanding_finance : 0);
      });
      this.totalNetVehicles = totalNet;
    }

    if (assetType === 'other') {
      this.getFormArray('other').controls.forEach(control => {
        totalNet += +control.value.estimated_value - +control.value.outstanding_finance;
      });
      this.totalNetOther = totalNet;
    }

    if (updateFinancialOverview) {
      this.updateTotalNet();
    }
  }

  public getFormArray(creditorType: string) {
    return this.form.get(creditorType) as UntypedFormArray;
  }

  public addNewCaseAsset(assetType: CaseAssetType) {
    const caseAsset     = new CaseAsset();
    caseAsset.ownership = 'applicant';
    if (assetType === 'bank_accounts') {
      this.addBankAccountToForm(caseAsset);
    }
    if (assetType === 'properties') {
      caseAsset.home_type = 'home';
      this.addPropertyToForm(caseAsset);
    }
    if (assetType === 'vehicles') {
      this.addVehicleToForm(caseAsset);
    }
    if (assetType === 'other') {
      this.addOtherToForm(caseAsset);
    }
  }

  public removeCaseAsset(assetType: CaseAssetType, index: number) {
    this.getFormArray(assetType).removeAt(index);
  }

  public toggleOutstandingFinance(onFinance: boolean, index: number) {
    const control = this.getFormArray('vehicles').at(index).get('outstanding_finance');

    if (onFinance) {
      control.enable();
    } else {
      control.patchValue(0);
      control.disable();
    }

    control.updateValueAndValidity();
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.creditors = result.data,
        err => console.error(err),
    );
  }

  private buildSelectOptions() {
    this.ownershipOptions = [
      {value: 'applicant', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant_joint')},
      {value: 'partner', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.partner')},
      {value: 'joint', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.joint')},
    ];
    this.homeTypes        = [
      {value: 'home', label: this.translate.instant('CASE_ASSET.model.home_type.options.home')},
      {value: 'investment', label: this.translate.instant('CASE_ASSET.model.home_type.options.investment')},
      {value: 'land', label: this.translate.instant('CASE_ASSET.model.home_type.options.land')},
      {value: 'other', label: this.translate.instant('CASE_ASSET.model.home_type.options.other')},
    ];
  }

  private buildForm(caseAssets: Array<CaseAsset>) {
    this.form = this.fb.group({
      bank_accounts: this.fb.array([]),
      vehicles:      this.fb.array([]),
      properties:    this.fb.array([]),
      other:         this.fb.array([]),
    });

    caseAssets.forEach(caseAsset => {
      if (caseAsset.type === 'bank_accounts') {
        this.addBankAccountToForm(caseAsset);
      }
      if (caseAsset.type === 'properties') {
        this.addPropertyToForm(caseAsset);
      }
      if (caseAsset.type === 'vehicles') {
        this.addVehicleToForm(caseAsset);
      }
      if (caseAsset.type === 'other') {
        this.addOtherToForm(caseAsset);
      }
    });
    this.updateAssetTotalNetValue('bank_accounts', false);
    this.updateAssetTotalNetValue('properties', false);
    this.updateAssetTotalNetValue('vehicles', false);
    this.updateAssetTotalNetValue('other', false);
  }

  private addBankAccountToForm(bankAccount: CaseAsset): void {
    const bankAccounts = this.form.get('bank_accounts') as UntypedFormArray;

    bankAccounts.push(
        this.fb.group({
          id:             [bankAccount.id],
          type:           [bankAccount.type || 'bank_accounts', [Validators.required]],
          creditor_id:    [bankAccount.entity_id, [Validators.required]],
          ownership:      [
            {value: bankAccount.ownership, disabled: !this.case.joint_application},
            [Validators.required],
          ],
          office:         [bankAccount.office, []],
          account_number: [bankAccount.account_number, []],
          balance:        [bankAccount.balance, []],
          exposed:        [bankAccount.exposed, []],
          liquidable:     [bankAccount.liquidable, []],
        }),
    );
  }

  private addPropertyToForm(caseProperty: CaseAsset): void {
    const properties = this.form.get('properties') as UntypedFormArray;

    properties.push(
        this.fb.group({
          id:                    [caseProperty.id],
          type:                  [caseProperty.type || 'properties', [Validators.required]],
          ownership:             [
            {value: caseProperty.ownership, disabled: !this.case.joint_application},
            [Validators.required],
          ],
          additional_partner:    [caseProperty.additional_partner],
          home_type:             [caseProperty.home_type],
          address_1:             [caseProperty.address_1],
          post_code:             [caseProperty.post_code],
          acquisition_value:     [caseProperty.acquisition_value],
          value:                 [caseProperty.value],
          property_registration: [caseProperty.property_registration],
          mortgage:              [caseProperty.mortgage],
          liquidable:            [caseProperty.liquidable],
        }),
    );
  }

  private addVehicleToForm(caseVehicle: CaseAsset): void {
    const vehicles = this.form.get('vehicles') as UntypedFormArray;

    vehicles.push(
        this.fb.group({
          id:                  [caseVehicle.id],
          type:                [caseVehicle.type || 'vehicles', [Validators.required]],
          ownership:           [
            {value: caseVehicle.ownership, disabled: !this.case.joint_application},
            [Validators.required],
          ],
          additional_partner:  [caseVehicle.additional_partner],
          on_finance:          [caseVehicle.on_finance],
          registration:        [caseVehicle.registration],
          make:                [caseVehicle.make],
          model:               [caseVehicle.model],
          age:                 [caseVehicle.age],
          acquisition_value:   [caseVehicle.acquisition_value],
          value:               [caseVehicle.value],
          outstanding_finance: [{value: caseVehicle.outstanding_finance, disabled: !caseVehicle.on_finance}],
          liquidable:          [caseVehicle.liquidable],
        }),
    );
  }

  private addOtherToForm(caseOtherAsset: CaseAsset): void {
    const other = this.form.get('other') as UntypedFormArray;

    other.push(
        this.fb.group({
          id:                  [caseOtherAsset.id],
          type:                [caseOtherAsset.type || 'other', [Validators.required]],
          ownership:           [
            {value: caseOtherAsset.ownership, disabled: !this.case.joint_application},
            [Validators.required],
          ],
          additional_partner:  [caseOtherAsset.additional_partner],
          other_assets_type:   [caseOtherAsset.other_assets_type, [Validators.required]],
          office:              [caseOtherAsset.office, [Validators.required]],
          securities_account:  [caseOtherAsset.securities_account, [Validators.required]],
          acquisition_value:   [caseOtherAsset.acquisition_value],
          estimated_value:     [caseOtherAsset.estimated_value, [Validators.required]],
          outstanding_finance: [caseOtherAsset.outstanding_finance, [Validators.required]],
          notes:               [caseOtherAsset.notes],
          liquidable:          [caseOtherAsset.liquidable],
        }),
    );
  }

  private updateTotalNet() {
    const totalNet = this.totalNetBankAccounts + this.totalNetProperties + this.totalNetVehicles + this.totalNetOther;
    this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
      if (overview) {
        overview.assets = totalNet;
        this.financialOverviewService.updateFinancialOverviewLocally(overview);
      }
    });
  }
}
