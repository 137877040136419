import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../main/src/environments/environment';
import { ArcopayGlobalParameters } from '../../../models/GlobalRef';
import { ArcopayOrder } from '../../../models/Payment/Transaction/ArcopayOrder';
import { ScriptLoaderService } from '../../../services/script-loader.service';
import { DateTime } from 'luxon';

declare global {
  interface Window {
    parameters: ArcopayGlobalParameters;
  }
}

@Component({
  selector:    'app-base-arcopay-widget-handler',
  templateUrl: './base-arcopay-widget-handler.component.html',
  styleUrls:   []
})
export class BaseArcopayWidgetHandlerComponent implements OnInit {
  public isLoading     = 0;
  public arcopayOrder: ArcopayOrder;
  public arcopayWrapperActive: boolean;
  protected orderUuid: string;
  public arcopayShownBanks: string;

  constructor (
    protected route: ActivatedRoute,
    protected scriptLoaderService: ScriptLoaderService,
  ) {
  }

  ngOnInit (): void {
    this.orderUuid = this.route.snapshot.queryParamMap?.get('orderUuid');
    this.isLoading++;
    this.fetchArcopayOrder(this.orderUuid);
  }

  @HostListener('window:message', ['$event'])
  public onMessage (event) {
    if (event.data != '' && event.data.code != undefined && event.data.message != undefined) {
      let codigo          = event.data.code;
      let mensaje         = event.data.message;
      let widgetContainer = document.getElementById('arcopayContainer');
      console.warn(`${ codigo } - ${ mensaje }`);
      switch (codigo) {
      case 200:
        if (window.parameters.action == 'read') {
          document.getElementById('frameResponse').innerHTML = 'Lectura finalizada correctamente.';
        }
        break;
      case 9999: // Cambio en el tamaño del widget
        widgetContainer.style.height = mensaje + 'px';
        window.scrollTo(0, 0);
        let iframeArcopay = document.getElementById('iframeArcopay') as HTMLIFrameElement;
        iframeArcopay.contentWindow.postMessage({'action': 'changeIframeHeight'}, 'https://www.afterbanks.com/');
        break;
      case 99999999: // Cambio en el tamaño del widget
        break;
      default:
        document.getElementById('frameResponse').innerHTML = `${ codigo } - ${ mensaje }`;
        break;
      }
    }
  }

  public closeArcoModal (): void {
    this.scriptLoaderService.unloadScriptByName('ArcopayWidget');
    this.arcopayWrapperActive = false;
    window.parameters         = null;
  }

  public reloadArcoModal (): void {
    this.initArcopayOrderPayment(this.arcopayOrder);
  }

  protected initArcopayOrderPayment (arcopayOrder: ArcopayOrder): void {
    window.parameters         = {
      id:     arcopayOrder.arco_uid,
      action: 'payment',
      // Widget configuration
      countryCode: 'ES',
      //  banksShown:  'ALL',
      // 'banksShownV3': 'bbva,caixa,bankia,bankia_emp,ingdirect',
      // 'banksShownPSD2': 'N26,bankinter,sandbox',
      banksShownPSD2:     this.arcopayShownBanks,
      defaultBank:        '',
      autoStart:          0,
      defaultLanguage:    'ES',
      showSandbox:        1,
      showSplashScreen:   0,
      showCheckTerms:     1,
      URLredirectAfterOK: arcopayOrder.redirect_url_success,
      URLredirectAfterKO: arcopayOrder.redirect_url_error,
      // Parameters READ PSD2
      // 'dailyFrequency': 4,
      // 'validUntil': '31-12-2024',
      // Parameters PAYMENT
      paymentType:             arcopayOrder.payment_type,
      amount:                  arcopayOrder.amount,
      currency:                'eur',
      sourceIBAN:              arcopayOrder.customer_iban || '',
      sourceCreditorName:      arcopayOrder.customer_full_name || '',
      destinationIBAN:         arcopayOrder.destination_iban,
      destinationCreditorName: arcopayOrder.destination_account_holder,
      paymentDescription:      arcopayOrder.product_description,
      // Parameters PAYMENT PERIODIC
      firstQuotaDay:  arcopayOrder.installment_start_date ?
                        DateTime.fromISO(arcopayOrder.installment_start_date as string).toFormat('dd-mm-yyyy') :
                        '',
      frequency:      arcopayOrder.installment_frequency ? arcopayOrder.installment_frequency : '',
      numberOfQuotas: arcopayOrder.installment_duration ? arcopayOrder.installment_duration.toString() : ''
    };
    this.arcopayWrapperActive = true;
    this.scriptLoaderService.loadScriptByName('ArcopayWidget')
      .subscribe({
        next: scriptResult => {
          console.log(scriptResult);
        }
      });
  }

  protected fetchArcopayOrder (orderUuid: string): void {
    throw new Error('Implement');
  }
}
