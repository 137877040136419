<div class="card shadow">
  <!--  <div class="card-header">-->
  <!--    <div class="d-flex">-->
  <!--      <h1>{{ "CASES.single.documents.heading" | translate }}</h1>-->
  <!--    </div>-->
  <!-- Actions -->
  <!--  </div>-->
  <div class="card-body">
    <app-case-document-list-v3 *ngIf="case && caseCreditor" [documentableType]="'case_creditor'"
                               [documentableId]="caseCreditor.id">
    </app-case-document-list-v3>
  </div>
</div>
