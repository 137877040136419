<mat-dialog-content>
  <div class="container">
    <mat-card class="mat-elevation-z3">
      <mat-card-header>
        <mat-card-title class="mb-3">
          <h5 *ngIf="selectedProduct.slug === 'lso-te'">{{'CASES.single.draft.epi-request.label-lso-te' | translate}}</h5>
          <h5 *ngIf="selectedProduct.slug === 'lso-lq'">{{'CASES.single.draft.epi-request.label-lso-lq' | translate}}</h5>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <form *ngIf="form" [formGroup]="form" class="p-2" (ngSubmit)="submitForm(form)">
          <!--Number of cars -->
          <app-input type="text" formControlName="number_of_cars" [label]="'CASES.single.draft.epi-request.form.number_of_cars' | translate" [fullWidth]="true">
          </app-input>
          <!-- Procurator -->
          <app-input type="text" formControlName="procurator"
                     [label]="'CASES.single.draft.demand_generator.procurator' | translate" [fullWidth]="true">
          </app-input>
          <!--Public debt -->
          <app-input type="text" formControlName="public_debt_information" [label]="'CASES.single.draft.epi-request.form.public_debt_information' | translate" [fullWidth]="true">
          </app-input>
          <!-- City -->
          <app-input type="text" formControlName="city" [label]="'ADDRESS.city' | translate" [fullWidth]="true">
          </app-input>
          <app-input type="mat-datepicker" formControlName="signature_date" appearance="fill"
                     [label]="'CASES.single.draft.demand_generator.signature_date' | translate" [fullWidth]="true">
          </app-input>
          <!-- Lawyer  -->
          <app-input type="text" formControlName="lawyer"
                     [label]="'CASES.single.draft.demand_generator.lawyer' | translate" [fullWidth]="true">
          </app-input>
          <!-- Submit -->
          <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" class="ms-2" [matMenuTriggerFor]="assetList"
                    aria-label="Send notification menu" type="button">
              {{'CASES.single.draft.epi-request.form.generate.label' | translate}}
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #assetList="matMenu">
              <button mat-menu-item (click)="chooseDocsType('doc', form)"
                      value="word">
                <span>  {{'CASES.single.draft.epi-request.form.generate.label' | translate}} word</span> </button>
              <button mat-menu-item (click)="chooseDocsType('pdf', form)"
                      value="word">
                <span>  {{'CASES.single.draft.epi-request.form.generate.label' | translate}} PDF</span> </button>
            </mat-menu>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
