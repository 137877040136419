import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditorPivot } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { MiscConfigService } from '../../../config/misc-config/misc-config.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseService } from '../../case.service';
import { ProductService } from '../../product.service';

@Component({
  selector: 'app-case-outcome',
  templateUrl: './case-outcome.component.html',
  styleUrls: ['./case-outcome.component.scss'],
})
export class CaseOutcomeComponent implements OnInit {
  public case: Case;
  public isLoading                                  = 0;
  public isSubmitting: boolean;
  public selectedProductControl: UntypedFormControl = new UntypedFormControl(null);
  public financialOverview: FinancialOverview;
  public expectedTerm: number;
  public unsecuredCreditorsCount: number;
  public claimCreditorsCount: number;
  public creditorAmountBalance: number;
  public serverResponse: LaravelResourceResponse;
  public minDisposableIncome: number;
  public isCajaplusSelectable                       = true;
  public disposableIncome: number;
  public legalFees: number;
  public renegotiatedDebt: number;
  public monthlyRepayable: number;

  public currentLocale: 'en' | 'es' | string;
  public products: Array<Product>                    = [];
  public potentialAzcarate: Array<CaseCreditorPivot> = [];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private caseService: CaseService,
    private productService: ProductService,
    private financialOverviewService: FinancialOverviewService,
    private configService: MiscConfigService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['creditors', 'public_debts'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.getMinDisposableIncome();
          this.fetchProducts();
          this.getCreditorStats(this.case);
        });
    });
    this.financialOverviewService.financialOverview$.subscribe(
      overview => {
        this.financialOverview = overview;
        this.renegotiatedDebt  = this.calculatePercentage(this.financialOverview?.unsecured_debt, 80);
        this.monthlyRepayable  = this.renegotiatedDebt / 60;
        if (overview) {
          this.expectedTerm = overview.unsecured_debt / (overview.income - overview.expenses);
          if (!isFinite(this.expectedTerm) || Number.isNaN(this.expectedTerm)) {
            this.expectedTerm = 0;
          }
        } else {
          this.expectedTerm = 0;
        }
      },
    );

    this.currentLocale = this.cookieService.get('lang') || 'en';
    this.translate.onLangChange.subscribe(next => this.currentLocale = next.lang);
  }

  private getCreditorStats(clientCase: Case) {
    let unsecuredCount = 0;
    let claimCount     = 0;
    let amount         = 0;
    clientCase.creditors.forEach(caseCreditor => {
      if (caseCreditor.pivot.type === 'unsecured') {
        unsecuredCount++;
      }
      if (caseCreditor.pivot.type === 'claim') {
        claimCount++;
      }
      if (caseCreditor.pivot.total_repaid > caseCreditor.pivot.initial_balance) {
        this.potentialAzcarate.push(caseCreditor);
      }
      if (caseCreditor.pivot.total_repaid >= 0 && caseCreditor.pivot.initial_balance >= 0) {
        amount += caseCreditor.pivot.initial_balance - caseCreditor.pivot.total_repaid;
      }
    });
    this.unsecuredCreditorsCount = unsecuredCount;
    this.claimCreditorsCount     = claimCount;
    this.creditorAmountBalance   = amount;
  }

  public selectOutcome(product: Product) {
    Swal.fire({
      title: this.translate.instant('SHARED.warning'),
      text: this.translate.instant('CASES.editor.outcome.request_confirmation'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('SHARED.no'),
      confirmButtonText: this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.selectedProductControl.patchValue(product.id);
        this.isSubmitting = true;
        this.productService.updateCaseProduct(this.case.id, {product_id: this.selectedProductControl.value})
          .pipe(finalize(() => this.isSubmitting = false))
          .subscribe(
            result => {
              this.toastr.success(this.translate.instant('CASES.editor.outcome.result.success'));
              this.case.product_id          = result.data.id;
              this.case.product_assigned_at = result.data.product_assigned_at;
            },
            error => {
              this.serverResponse = error.error;
              this.toastr.error(this.translate.instant('CASES.editor.outcome.result.error'), error.error.message);
              this.selectedProductControl.patchValue(this.case.product_id);
            },
          );
      }
    });
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.products = result.data.sort((productOne, productTwo) => (productOne.order > productTwo.order) ?
          1 :
          ((productTwo.order > productOne.order) ? -1 : 0));
        this.selectedProductControl.patchValue(this.products.find(product => product.id === this.case.product_id).id);
      },
    );
  }

  public isProductSelectable(product: Product): boolean {
    return this.areProductRequirementsFulfilled(this.case, product) &&
           (this.selectedProductControl.value !== product.id || !this.case.product_assigned_at);
  }

  private areProductRequirementsFulfilled(clientCase: Case, product: Product): boolean {
    let productRequirementsFulfilled = false;
    if (product.group_slug === 'lso') {
      if (product.slug === 'lso-pp') {
        productRequirementsFulfilled = this.isProductRequirementFulfilled(clientCase, product, 1) &&
                                       this.isProductRequirementFulfilled(clientCase, product, 2);
        if (!productRequirementsFulfilled) {
          return productRequirementsFulfilled;
        }
      }
      if (clientCase.require_lso_qualifiers &&
          (clientCase.client.criminal_record === true || clientCase.client.spanish_resident === false ||
           clientCase.client.lso_in_five_years === true || clientCase.client.more_than_one_creditor === false)) {
        productRequirementsFulfilled = false;
      } else {
        productRequirementsFulfilled = true;
      }
    } else {
      productRequirementsFulfilled = true;
    }
    if (product.slug === 'cajaplus') {
      this.disposableIncome = this.financialOverview.income - this.financialOverview.expenses;
      this.legalFees        = this.disposableIncome * 0.2 * 1.21;
      if (this.disposableIncome < this.minDisposableIncome) {
        this.isCajaplusSelectable    = false;
        productRequirementsFulfilled = false;
      }
    }
    return productRequirementsFulfilled;
  }

  public isProductRequirementFulfilled(clientCase: Case, product: Product, requirement: number): boolean {
    let result             = false;
    const disposableIncome = this.financialOverview.income - this.financialOverview.expenses;
    if (product.slug === 'lso-pp') {
      if (requirement === 1) {
        result = disposableIncome >= this.financialOverview.seizable_amount;
      }

      if (requirement === 2) {
        return this.areAssetsPayable() || this.isUnsecuredDebtPayable();
      }
    }

    return result;
  }

  public areAssetsPayable() {
    return (this.financialOverview.amortisation_with_disposable_income + this.financialOverview.liquidable_assets) >
           this.financialOverview.assets;
  }

  public isUnsecuredDebtPayable() {
    return (this.financialOverview.amortisation_with_disposable_income + this.financialOverview.liquidable_assets) >
           this.financialOverview.unsecured_debt;
  }

  public getRepaymentPlan(): number {
    const disposableIncome = this.financialOverview.income - this.financialOverview.expenses;

    return Math.ceil(this.financialOverview.unsecured_debt / disposableIncome);
  }

  private getMinDisposableIncome(): void {
    this.configService.getMinDisposableIncome().subscribe(
      result => {
        this.minDisposableIncome = result.data.value;
      },
    );
  }

  private calculatePercentage(num, percentage): number{
    return num * percentage / 100;
  }
}
