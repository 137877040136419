<div [formGroup]="form">
  <div class="row pb-3">
    <div class="col-12">
      <mat-form-field color="primary" appearance="fill">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'from')">
        <button
          type="button"
          *ngIf="form.value.from"
          matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'from')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from color="primary"></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="primary" appearance="fill">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
        <button
          type="button"
          *ngIf="form.value.to"
          matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'to')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to color="primary"></mat-datepicker>
      </mat-form-field>

      <div class="d-inline-block ps-5">
        <mat-radio-group formControlName="dateRadio" aria-label="Select an option" (change)="onRadioChange($event)">
          <mat-radio-button value="today">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="this-week">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="this-month">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>

<div class="d-inline-block pb-2">
  <mat-radio-group [formControl]="typeForm" aria-label="Select an option" (change)="onRadioChangeType($event)">
    <mat-radio-button value="lso">LSO</mat-radio-button>
    <mat-radio-button value="dm">DM</mat-radio-button>
    <mat-radio-button value="dgs">DGS</mat-radio-button>
  </mat-radio-group>
</div>

<div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'combined'">
  <table style="width: 100%" mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element" [attr.rowspan]="3" [style.display]="element.type !== 'DM' ? '' : 'none'">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="case_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.case_count_lso}}</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.case_count_dm}}</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal('cases')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Enviar</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.enviar_contrato_lso}}</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.enviar_contrato_dm}}</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal(this.status_ids[0])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_percent">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.enviar_contrato_percent_lso | number:'1.2-2'}}%</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.enviar_contrato_percent_dm | number:'1.2-2'}}%</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firmado</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.contrato_firmado_lso}}</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.contrato_firmado_dm}}</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal(this.status_ids[1])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_percent">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.contrato_firmado_percent_lso | number:'1.2-2'}}%</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.contrato_firmado_percent_dm | number:'1.2-2'}}%</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pago">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>Pago</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.pago_lso}}</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.pago_dm}}</ng-container>
      </td>
      <td mat-cell *matCellDef="let element">{{ element.pago }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal(this.status_ids[2])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="pago_percent">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.pago_percent_lso | number:'1.2-2'}}%</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.pago_percent_dm | number:'1.2-2'}}%</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cancelado</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.caso_cancelado_lso}}</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.caso_cancelado_dm}}</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotal(this.status_ids[3])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_percent">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.caso_cancelado_percent_lso | number:'1.2-2'}}%</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.caso_cancelado_percent_dm | number:'1.2-2'}}%</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="amount_period">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees-paid" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.type === 'LSO'">{{element.amount_period_lso | number:'1.2-2'}}€</ng-container>
        <ng-container *ngIf="element.type === 'DM'">{{element.amount_period_dm | number:'1.2-2'}}€</ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>{{getTotal('all')}}€</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h5 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h5>
  </div>
</div>

<div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'lso'">
  <table style="width: 100%" mat-table matSort #lsoSort="matSort" [dataSource]="dataSourceDmLso">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="case_count_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.case_count_lso }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('cases')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Enviar</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_lso }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[0])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_percent_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_percent_lso | number:'1.2-2'}}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firmado</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_lso }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[1])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_percent_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_percent_lso | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pago_lso">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>Pago</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_lso }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[2])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="pago_percent_lso">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_percent_lso | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cancelado</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_lso }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[3])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_percent_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_percent_lso | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="amount_period_lso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees-paid" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.amount_period_lso | number:'1.2-2' }}€</td>
      <td mat-footer-cell *matFooterCellDef>{{getTotalDmLso('all')}}€</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsLso"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsLso;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsLso"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSourceDmLso && dataSourceDmLso.data.length < 1" style="padding: 50px">
    <h5 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h5>
  </div>
</div>

<div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'dm'">
  <table style="width: 100%" mat-table matSort #dmSort="matSort" [dataSource]="dataSourceDmLso">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="case_count_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.case_count_dm }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('cases')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Enviar</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_dm }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[0])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_percent_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_percent_dm | number:'1.2-2'}}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firmado</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_dm }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[1])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_percent_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_percent_dm | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pago_dm">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>Pago</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_dm }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[2])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="pago_percent_dm">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_percent_dm | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cancelado</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_dm }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[3])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_percent_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_percent_dm | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="amount_period_dm">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees-paid" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.amount_period_dm | number:'1.2-2' }}€</td>
      <td mat-footer-cell *matFooterCellDef>{{getTotalDmLso('all')}}€</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDm"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDm;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsDm"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSourceDmLso && dataSourceDmLso.data.length < 1" style="padding: 50px">
    <h5 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h5>
  </div>
</div>
<!--DGS-->
<div class="mat-elevation-z8 mb-5" [hidden]="typeForm.value !== 'dgs'">
  <table style="width: 100%" mat-table matSort #dgsSort="matSort" [dataSource]="dataSourceDgs">

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name || 'N/A' }}</td>
      <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>{{"CASES.single.type" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ typeForm.value.toUpperCase() }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="case_count_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.cases_assigned" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.case_count_dgs }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso('cases')}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Enviar</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_dgs }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[0])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="enviar_contrato_percent_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.enviar_contrato_percent_dgs | number:'1.2-2'}}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Firmado</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_dgs }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[1])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="contrato_firmado_percent_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.contrato_firmado_percent_dgs | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="pago_dgs">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>Pago</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_dgs }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[2])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="pago_percent_dgs">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.pago_percent_dgs | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Cancelado</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_dgs }}</td>
      <td mat-footer-cell *matFooterCellDef><b>{{getTotalDmLso(this.status_ids[3])}}</b></td>
    </ng-container>

    <ng-container matColumnDef="caso_cancelado_percent_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>%</th>
      <td mat-cell *matCellDef="let element">{{ element.caso_cancelado_percent_dgs | number:'1.2-2' }}%</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="amount_period_dgs">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees-paid" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.amount_period_dgs | number:'1.2-2' }}€</td>
      <td mat-footer-cell *matFooterCellDef>{{getTotalDmLso('all')}}€</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDGs"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDGs;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsDGs"></tr>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSourceDgs && dataSourceDgs.data.length < 1" style="padding: 50px">
    <h5 class="text-center">{{ "DASHBOARD.no-agents" | translate }}</h5>
  </div>
</div>
