import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Creditor } from '../../../../../_base-shared/models/Entity/Creditor';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CreditorService extends MainBaseApiService {

  public index(requestData = {}, relations: Array<string> = [], relationsCount: Array<string> = []): Observable<LaravelResourceResponse<Array<Creditor>>> {
    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData, with: relations, with_count: relationsCount}, 'get',
    );

    return this.http.get<LaravelResourceResponse<Array<Creditor>>>(`${this.apiUrl}/creditors`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  get(creditorId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/creditors/${creditorId}`)
      .pipe(catchError(response => this.handleError(response)));
  }

  store(newCreditor: Creditor): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/creditors`, newCreditor)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(creditorId: number, creditor: Creditor): Observable<LaravelResourceResponse> {
    return this.http.put<LaravelResourceResponse>(`${this.apiUrl}/creditors/${creditorId}`, creditor)
      .pipe(
        catchError(response => this.handleError(response)),
        tap(response => {
          this.globalEvents.setUnapprovedCreditors(response.data.unapproved_creditors);
        }));
  }

  public delete(creditorId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${this.apiUrl}/creditors/${creditorId}`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public deleteAndReassign(requestData: {
    delete_creditor_id: number,
    reassign_creditor_id: number,
    case_creditor_notes: string
  }): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${this.apiUrl}/creditors/reassign`, requestData)
      .pipe(catchError(response => this.handleError(response)));
  }

  showReport(creditorId: number, data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/creditors/${creditorId}/show-report`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  showReportAll(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/creditors/show-full-report`)
      .pipe(catchError(response => this.handleError(response)));
  }

  downloadReport(creditorId: number): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/creditors/${creditorId}/download-report`)
      .pipe(catchError(response => this.handleError(response)));
  }

  verifySendMandates(caseCreditorId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/verify-send-mandate`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendMandatesRecoveryAgent(caseCreditorId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/verify-send-mandate-recovery-agent`,
      data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendProposalAndAcoord(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-proposal-and-accord', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendRightOfAccess(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-right-of-access', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendRightOfAccessPpi(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-ppi', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendPpiClaim(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/case-creditors/send-ppi-claim', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateLoanLetter(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-azcarate-loan', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateRevolvingLetter(caseId: number, data: { selected_ids: Array<number> }): Observable<object> {
    return this.http.post(this.apiUrl + '/cases/' + caseId + '/case-creditors/send-azcarate-revolving', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAepEmails(caseId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/cases/${caseId}/creditors/send-aep`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendLastChanceEmail(caseCreditorId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/send-last-chance`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendDeleteNumberEmail(caseCreditorId, data): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/send-creditor-delete-phone`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAntiHarassmentEmail(caseCreditorId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/send-azkarate-acoso`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendLoanCancellationEmail(caseCreditorId, data = null): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/send-azkarate-cancelar`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateEmail(caseCreditorId): Observable<object> {
    return this.http.post(`${this.apiUrl}/case-creditors/${caseCreditorId}/send-azcarate`, null)
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAzcarateEmailUnsecured(caseId: number, creditorIds: Array<number>): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${this.apiUrl}/cases/${caseId}/case-creditors/actions/send-azcarate`, {case_creditor_ids: creditorIds})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendDeleteNumberUnsecured(caseId: number, creditorIds: Array<number>): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${this.apiUrl}/cases/${caseId}/case-creditors/actions/send-creditor-delete-phone`,
      {case_creditor_ids: creditorIds})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendAntiHarassmentUnsecured(caseId: number, creditorIds: Array<number>): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${this.apiUrl}/cases/${caseId}/case-creditors/actions/send-azkarate-acoso`, {case_creditor_ids: creditorIds})
      .pipe(catchError(response => this.handleError(response)));
  }

  sendLoanCancellationEmailUnsecured(caseId: number, creditorIds: Array<number>): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${this.apiUrl}/cases/${caseId}/case-creditors/actions/send-azkarate-cancelar`,
      {case_creditor_ids: creditorIds})
      .pipe(catchError(response => this.handleError(response)));
  }
}
