import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Solicitor } from '../../../../../../../../_base-shared/models/Entity/Solicitor';
import { SolicitorsService } from '../../../../address-book/solicitors/solicitors.service';

@Component({
  selector:    'app-solicitor-modal',
  templateUrl: './solicitor-modal.component.html',
  styles:      [],
})
export class SolicitorModalComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public case: Case;
  public selectedSolicitor: Solicitor;
  public form: UntypedFormGroup;
  public searchControl: UntypedFormControl = new UntypedFormControl();
  public dataSource: MatTableDataSource<any>;
  public isLoading                   = 0;
  public isSending                   = 0;
  public searchFocus                 = false;
  public displayedColumns: string[]  = ['select', 'name', 'address'];
  public selection                   = new SelectionModel<any>(true, []);

  public paginatorConfig = {
    pageIndex: 0,
    pageSize:  6,
    length:    1,
  };

  constructor(private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<SolicitorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private solicitorsService: SolicitorsService) {
    this.case              = this.data.case;
    this.selectedSolicitor = this.data.selectedSolicitor;
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchSolicitors();

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.fetchSolicitors();
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      solicitor_id: [null, Validators.required],
    });
  }

  public paginatorChange($event: PageEvent) {
    this.dataSource                = new MatTableDataSource<Solicitor>([]);
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchSolicitors();
  }

  private fetchSolicitors() {
    this.selection.clear();
    this.form.get('solicitor_id').patchValue(null);
    this.form.markAsUntouched();
    this.dataSource = new MatTableDataSource<Solicitor>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.form.value.search,
    };

    this.solicitorsService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.dataSource             = new MatTableDataSource<Solicitor>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.meta.total;
    });
  }

  public closeModal(value) {
    this.dialogRef.close(value);
  }

  public selectSolicitor(solicitor) {
    this.form.get('solicitor_id').patchValue(solicitor.id);
    this.form.get('solicitor_id').updateValueAndValidity();
    this.selection.clear();
    this.selection.toggle(solicitor);
  }

  public submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.solicitorsService.saveSolicitor(this.case.id, this.form.value).subscribe(
      next => {
        this.toast.success(
          this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.success', {
            entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
          }));
        this.dialogRef.close({data: this.selection.selected[0]});
      },
      error => {
        this.toast.error(
          this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
            entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
          }));
      });
  }

}
