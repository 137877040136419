import { TownHall } from '../Entity/TownHall';
import { Case } from './Case';

export class CasePublicDebt {
  id?: number;
  case_id?: number;
  town_hall_id?: number;
  public_organisation: 'estate' | 'social-security' | 'town-hall';
  debt_type: 'capital-gain' | 'tax' | 'penalty-fee';
  initial_balance: number;
  current_balance: number;
  monthly_payments: number;
  making_payments: number;
  ownership: string;
  interested_third_party: number;
  reference_number: string;
  response_received: string;
  verified: number;
  city: string; // deprecated
  judicial_claim: string;
  type_of_guarantee: string;
  notes: any;
  client_documents_visible: boolean | 0 | 1;

  // Relations
  case?: Case;
  town_hall?: TownHall;

  // App Helpers
  name?: string;
}
