<mat-dialog-content>
  <div class="d-flex flex-column h-100">
    <h4>{{"CASES.details.additional-data" | translate}}</h4>

    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="formActive">
      <div *ngIf="data.type !== 'number'">
        <ng-container *ngFor="let formGroup of getFormArray().controls let i = index" formArrayName="emails">
          <div class="row pb-3">
            <div class="col-md-4"  *ngIf="formGroup.get('name')?.value">
              <ng-container *ngIf="formGroup.get('name')?.value === 'social-security'">
                <label>Public debt name:</label>
                <p>{{ "CASE_CREDITOR.model.public_organization.options.social-security" | translate }}</p>
              </ng-container>
              <ng-container *ngIf="formGroup.get('name')?.value === 'estate'">
                <label>Public debt name:</label>
                <p>{{ "CASE_CREDITOR.model.public_organization.options.estate" | translate }}</p>
              </ng-container>
              <ng-container *ngIf="formGroup.get('name')?.value === 'town-hall'">
                <label>Public debt name:</label>
                <p>{{ "CASE_CREDITOR.model.public_organization.options.town-hall" | translate }}</p>
              </ng-container>
            </div>
            <div class="{{ formGroup.get('name')?.value ? 'col-md-8' : 'col-md-12' }}">
              <div class="form-group" [formGroupName]="i">
                <label >{{"AUTH.email" | translate}}</label>
                <input type="email" class="form-control" formControlName="email" [placeholder]="translateService.instant('AUTH.email')" autocomplete="off">
                <div *ngIf="formGroup.get('email').touched && formGroup.get('email').errors?.required" class="alert alert-danger">{{"AUTH.enter-email" | translate}}</div>
                <div *ngIf="formGroup.get('email').touched && formGroup.get('email').errors?.email" class="alert alert-danger">{{"AUTH.valid-email" | translate}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="data.type === 'number'">
        <div class="row pb-3">
          <div class="col-md-8">
            <div class="form-group">
              <label >{{"AUTH.number" | translate}}</label>
              <input type="text" class="form-control" formControlName="phone" [placeholder]="translateService.instant('AUTH.number')" autocomplete="off">
              <div *ngIf="form.get('phone').touched && form.get('phone').errors?.required" class="alert alert-danger">{{"AUTH.enter-number" | translate}}</div>
            </div>
          </div>
        </div>
      </div>

    </form>

    <div class="text-end mt-auto mb-5 d-flex justify-content-end align-items-center">

      <button type="button" mat-stroked-button color="primary" class="me-3"
              (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
      <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()">
        {{"SHARED.submit" | translate}}
      </button>
      <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
    </div>
  </div>
</mat-dialog-content>
