<h5>{{ "USER.settings.title" | translate }}</h5>
<ng-container *ngIf="formActive">
  <form [formGroup]="form" (ngSubmit)="submit(form)" class="form">
    <div class="row">
      <div class="col-6">
        <!-- User Details Form -->
        <div class="card shadow mb-5">
          <div class="card-title m-3"><h4>{{ "USER.settings.form-details.title" | translate }}</h4></div>
          <div *ngIf="isLoading" class="row">
            <div class="col-12 pt-5 text-center">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
          </div>
          <div class="card-body" [hidden]="isLoading">
            <!-- First Name -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-details.first_name" | translate }}</mat-label>
                <input matInput type="text" formControlName="first_name">
                <div *ngIf="form.get('first_name').errors && (form.get('first_name').touched || formSubmitted)">
                  <mat-error [hidden]="!form.get('first_name').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <!-- Last Name -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-details.last_name" | translate }}</mat-label>
                <input matInput type="text" formControlName="last_name">
                <div *ngIf="form.get('last_name').errors && (form.get('last_name').touched || formSubmitted)">
                  <mat-error [hidden]="!form.get('last_name').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <!-- Email -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-details.email" | translate }}</mat-label>
                <input matInput type="email" formControlName="email">
                <div *ngIf="form.get('email').errors && (form.get('email').touched || formSubmitted)">
                  <mat-error [hidden]="!form.get('email').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                  <mat-error [hidden]="!form.get('email').errors.email">
                    {{"USERS.email-validation" | translate}}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <!-- Phone -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USERS.editor.phone" | translate }}</mat-label>
                <span matPrefix>+{{ form.get('phone_country_code').value }}&nbsp;</span>
                <input matInput formControlName="phone" type="number">
                <div *ngIf="form.get('phone').errors && (form.get('phone').touched || formSubmitted)">
                  <mat-error [hidden]="!form.get('phone').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                  <mat-error [hidden]="!form.get('phone').errors.pattern">
                    {{ "CASES.single.mobile-invalid" | translate }}
                  </mat-error>
                  <mat-error [hidden]="!(form.get('phone').errors.minlength)">
                    {{ "CASES.single.mobile-length" | translate }}
                  </mat-error>
                  <mat-error [hidden]="!(form.get('phone').errors.maxlength)">
                    {{ "CASES.single.mobile-length" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Change Password Form-->
        <div class="card shadow mb-5">
          <div class="card-title m-3"><h4>{{ "USER.settings.form-password.title" | translate }}</h4></div>
          <div *ngIf="isLoading" class="row">
            <div class="col-12 pt-5 text-center">
              <mat-spinner class="m-0 m-auto"></mat-spinner>
            </div>
          </div>
          <div class="card-body" [hidden]="isLoading">
            <!-- Current Password -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-password.current_password" | translate }}</mat-label>
                <input matInput type="password" formControlName="current_password">
                <div *ngIf="form.get('current_password').errors && (form.get('current_password').touched || formSubmitted)">
                  <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                     [fieldName]="'current_password'">
                  </app-server-errors>
                  <mat-error [hidden]="!form.get('current_password').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <!-- New Password -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-password.password" | translate }}</mat-label>
                <input matInput type="password" formControlName="password">
                <div *ngIf="form.get('password').errors && (form.get('password').touched || formSubmitted)">
                  <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                     [fieldName]="'password'">
                  </app-server-errors>
                  <mat-error [hidden]="!form.get('password').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <!-- New Password Confirmation -->
            <div class="form-group">
              <mat-form-field appearance="fill" class="input-full-width">
                <mat-label>{{ "USER.settings.form-password.password_confirmation" | translate }}</mat-label>
                <input matInput type="password" formControlName="password_confirmation">
                <div *ngIf="form.get('password_confirmation').errors && (form.get('password_confirmation').touched || formSubmitted)">
                  <app-server-errors *ngIf="serverResponse" [errorResponse]="this.serverResponse"
                                     [fieldName]="'password_confirmation'">
                  </app-server-errors>
                  <mat-error [hidden]="!form.get('password_confirmation').errors.required">
                    {{ "SHARED.field-required" | translate }}
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <div class="d-flex m-3 justify-content-end">
          <app-spinner-btn [loading]="submitting" name="Submit"></app-spinner-btn>
        </div>
      </div>
      <!-- Connections Form -->
      <div class="col-6">
        <div class="card shadow">
          <div class="card-title m-3"><h4>{{ "USER.settings.form-connections.title" | translate }}</h4></div>
          <div class="card-body">
            <div *ngIf="isLoadingIntegrations" class="row">
              <div class="col-12 pt-5 text-center">
                <mat-spinner class="m-0 m-auto"></mat-spinner>
              </div>
            </div>
            <ng-container *ngIf="!isLoadingIntegrations">
              <div class="row align-items-center" *ngFor="let service of externalServices">
                <ng-container>
                  <div class="col-3">{{ service.name }}</div>
                  <div class="col-9">
                    <div class="d-flex m-3 justify-content-end align-items-center">
                      <button *ngIf="!service.integrated_id" class="ms-2" mat-raised-button color="primary"
                              (click)="authorize(service)">
                        {{ "USER.settings.form-connections.actions.authorize" | translate }}
                      </button>
                      <button *ngIf="service.integrated_id > 0 && service.type !== 'mymoid'" class="ms-2"
                              mat-raised-button color="primary"
                              (click)="testIntegration(service, service.integrated_id)">
                        Test
                      </button>
                      <button *ngIf="service.integrated_id > 0" class="ms-2" mat-raised-button color="danger"
                              (click)="unauthorize(service, service.integrated_id)">
                        {{ "USER.settings.form-connections.actions.unauthorize" | translate }}
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div class="row" *ngIf="testResponse">
              <div class="col-9"><h5>Test response</h5></div>
              <div class="col-3">
                <button class="ms-2" mat-raised-button color="primary"
                        (click)="testResponse = null">
                  Clear Response
                </button>
              </div>
              <div class="col-12">
                <pre>{{ testResponse | json }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
