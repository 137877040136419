import { Component, Input, OnInit } from '@angular/core';
import {
  OppwaOrder
} from '../../../../../../../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import {
  TransactionResponse
} from '../../../../../../../../../_base-shared/models/Payment/Transaction/TransactionResponse';

@Component({
  selector:    'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls:   ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {
  @Input() transaction: OppwaOrder;

  ngOnInit(): void {
  }
}
