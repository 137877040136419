<table class="table table-bordered">
  <tbody>
  <tr>
    <th style="width: 20%;">Order code</th>
    <td>{{unnaxOrder.order_code}}</td>
  </tr>
  <tr>
    <th>Payment Processor</th>
    <td>{{unnaxOrder.payment_processor?.slug}}</td>
  </tr>
  <tr>
    <th>Bank order code</th>
    <td>{{unnaxOrder.bank_order_code}}</td>
  </tr>
  <tr>
    <th>Amount</th>
    <td>{{(unnaxOrder.amount_100 / 100) | currency: "EUR" }}</td>
  </tr>
  <tr>
    <th>Currency</th>
    <td>{{unnaxOrder.currency}}</td>
  </tr>
  <tr>
    <th>Customer full name</th>
    <td>{{unnaxOrder.customer_full_name}}</td>
  </tr>
  <tr>
    <th>Product description</th>
    <td>{{unnaxOrder.product_description}}</td>
  </tr>
  <tr>
    <th>IBAN</th>
    <td>{{unnaxOrder.iban}}</td>
  </tr>
  <tr>
    <th>Card PAN</th>
    <td>{{unnaxOrder.card_pan}}</td>
  </tr>
  <tr>
    <th>Card expiry</th>
    <td>{{unnaxOrder.card_expiry}}</td>
  </tr>
  <tr>
    <th>Status</th>
    <td>{{unnaxOrder.status}}</td>
  </tr>
  <tr>
    <th>Success</th>
    <td>{{unnaxOrder.success}}</td>
  </tr>
  <tr>
    <th>Error</th>
    <td>{{unnaxOrder.error}}</td>
  </tr>
  <tr>
    <th>Completed at</th>
    <td>{{unnaxOrder.completed_at}}</td>
  </tr>
  </tbody>
</table>
