<div class="d-flex flex-row justify-content-between align-items-center">
  <h4>Call Status Log History</h4>
</div>
<div class="pt-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !caseCallStatusLogs?.length"
                   [message]="'CASES.single.general.relation_list.status_logs.empty_dataset' | translate">
</app-empty-dataset>
<div class="row m-2" *ngFor="let statusLog of caseCallStatusLogs" [hidden]="isLoading">
  <div class="card col-12">
    <div class="card-body">
      <span *ngIf="statusLog.from_call_status">
        {{ 'CASES.single.general.relation_list.status_logs.status_changed_from' | translate }} {{ statusLog.from_call_status.name }}
      </span>
      <span *ngIf="!statusLog.to_call_status">{{ 'CASES.single.general.relation_list.status_logs.no_old_status' | translate }}</span>
      <span>
        {{ ' ' }} {{ 'CASES.single.general.relation_list.status_logs.status_changed_to' | translate }} {{ statusLog.to_call_status.name }}
      </span>
      <span *ngIf="statusLog.author">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.status_logs.status_changed_by' | translate }} {{ statusLog.author.name }}
      </span>
      <p>{{ statusLog.created_at| toDateObject | date: 'medium'}}</p>
    </div>
  </div>
</div>
