import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../../_shared/guards/main-auth-guard.service';
import { PackagerDashboardComponent } from './packager-dashboard.component';

const routes: Routes = [
  { path: 'mi-dash-packager', component: PackagerDashboardComponent, canActivate: [MainAuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class PackagerDashboardRoutingModule {
}
