import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { User } from '../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends MainBaseApiService {

  public login(user: User,  relations: Array<string> = []): Observable<LaravelResourceResponse<User>> {
    const request = MainBaseApiService.convertFiltersForRequest({...user, with: relations}, 'post');
    return this.http.post<LaravelResourceResponse<User>>(this.apiUrl + '/login', request).pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public newPassword(data): Observable<LaravelResourceResponse<User>> {
    return this.http.post<LaravelResourceResponse<User>>(`${ this.apiUrl }/auth/new-password`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public forgotPassword(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/password/id_card`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public resetPassword(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/password/reset`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public checkLogin(): Observable<LaravelResourceResponse<User>> {
    return this.http.get<LaravelResourceResponse<User>>(this.apiUrl + '/auth-user').pipe(
      tap(response => this.globalEvents.setAuthUser(response.data)),
      catchError(response => this.handleError(response)),
    );
  }

  public logout(): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/logout`, {})
      .pipe(
        catchError(response => this.handleError(response)),
        finalize(() => localStorage.setItem('splashScreen', '0')),
      );
  }
}
