<div class="w-100 d-flex flex-column justify-content-center align-items-center">
  <div class="pop-up-desktop pt-2 d-flex flex-column justify-content-center align-items-center success-tick">
    <p style="color:#479D84" class="d-flex flex-row justify-content-center align-items-center">
      <mat-icon class="me-2">check</mat-icon>
      {{'CLIENT.auth-payment.success_msg' | translate}}.</p>
    <button class="home-btn mt-3">
      {{"SHARED.home" | translate }}
    </button>
  </div>
</div>
