import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionProvider } from '../../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { MainBaseApiService } from '../../../../_shared/services/main-base-api.service';
import { CaseService } from '../../../case/case.service';
import { DistributionProviderService } from '../../distribution-provider.service';
import { DistributionService } from '../../distribution.service';

@Component({
  selector:    'app-distribution-provider-list',
  templateUrl: './distribution-provider-list.component.html',
  styles:      [
    `
        mat-table {
            display: table;
            width: 100%;
        }
    `,
  ],
})
export class DistributionProviderListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public filtersReady = false;
  public requestFilters;
  public distributionProviders: MatTableDataSource<DistributionProvider>;
  public isLoading    = 0;
  public serverResponse: LaravelResourceResponse;
  public displayedColumns: Array<string>;
  public selection    = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;

  private subscriptions: Array<Subscription> = [];

  constructor(private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private caseService: CaseService,
              private distributionService: DistributionService,
              private distributionProviderService: DistributionProviderService,
  ) {
  }

  ngOnInit(): void {
    this.distributionProviders  = new MatTableDataSource<DistributionProvider>([]);
    this.displayedColumns       = this.getTableColumns();
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.requestFilters         = {select_all: 0};
    this.fetchProviders();
  }

  ngAfterViewInit(): void {
    this.distributionProviders.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchProviders();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchProviders();
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    delete this.requestFilters.cases;
  }

  private fetchProviders() {

    this.clearSelection();
    const relations = [];
    const withCount = [];

    const requestData = MainBaseApiService.convertFiltersForRequest({...this.requestFilters}, 'get');

    this.isLoading++;
    this.subscriptions.push(
        this.distributionProviderService.index(requestData, relations, withCount)
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
                result => {
                  this.clearSelection();
                  this.distributionProviders      = new MatTableDataSource<DistributionProvider>(result.data);
                  this.distributionProviders.sort = this.sort;
                  this.paginatorConfig.length     = result.meta.total;
                  this.totalResults               = result.meta.total;
                  this.totalPages                 = result.meta.last_page;
                },
                err => console.error(err),
            ),
    );
  }

  private getTableColumns(): Array<string> {
    return [
      'company_name',
      'min_contract_value',
      'min_monthly_payment',
      'max_installments',
      'funding_amount_percentage',
      'cash_hurdle_percentage',
      'actions',
    ];
  }
}
