import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {
  transform(statusDate: Date): number | string{
    if (!statusDate) { return 'N/A'; }

    const date1: any = new Date(statusDate);
    const date2: any = new Date();
    return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
  }

}
