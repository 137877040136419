import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector:    'app-payment-received-modal',
  templateUrl: './payment-received-modal.component.html',
  styleUrls:   ['./payment-received-modal.component.scss'],
})
export class PaymentReceivedModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(
      private reportService: ReportService,
      private fb: UntypedFormBuilder,
      private toastr: ToastrService,
      public dialogRef: MatDialogRef<PaymentReceivedModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      start_date:         [null, Validators.required],
      end_date:           [null, Validators.required],
      first_payment_only: [null],
    });
  }

  sendReportData() {
    if (!this.form.valid) {
      return;
    }
    const requestData = {
      start_date:         this.form.value.start_date.toISOString(),
      end_date:           this.form.value.end_date.toISOString(),
      first_payment_only: this.form.value.first_payment_only,
    };
    this.isLoading++;
    this.reportService.generatePaymentReceivedReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
              this.toastr.success(result.message);
              // const fileName = 'payment_received_report_' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              this.dialogRef.close(true);
            },
            err => console.error(err),
        );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
