<div class="card-header" style="border: 1px solid #e4e4e4;">
  <h4 class="text-center m-0">{{ "CASES.single.transaction-history" | translate }}</h4>
</div>
<div class="card-body p-0" *ngIf="redsysOrders.length">
  <mat-accordion multi>
    <mat-expansion-panel class="bg-light m-1" *ngFor="let order of redsysOrders">
      <mat-expansion-panel-header>
        <mat-panel-title style="justify-content: space-between">
          <p class="m-0">Created at: <b>{{ order.created_at | toDateObject | date: 'medium' }}</b></p>
          <p class="m-0">Amount: <b>{{ (order.amount_100 / 100) | number }}</b></p>
          <p class="m-0">Provider:
            <b>{{ order.payment_processor?.name }}</b>
          </p>
          <p *ngIf="order.success" class="m-0 badge badge-success">Success</p>
          <p *ngIf="!order.success" class="m-0 badge badge-danger">Failed</p>
          <p class="m-0">Status: <b>{{ order.error }}</b></p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="prettyprint">
        <p>Redsys Order No.: {{ order.redsys_uid }}</p>
        <p>Author: {{ order.author?.full_name }}</p>
        <p>
          Type:
          <span *ngIf="order.initial_cof">Tokenization</span>
          <span *ngIf="!order.initial_cof && order.cof_type">Recurring</span>
        </p>
        <p>Is moto payment: {{ order.direct_payment === 'MOTO' ? 'Yes' : 'No' }}</p>
        <p>Bizum mobile: {{ order.bizum_mobilenumber ? order.bizum_mobilenumber : 'N/A' }}</p>
        <p>Card Pan: {{ order.card_pan }}</p>
        <p>Card Expiry: {{ order.card_expiry }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div *ngIf="!redsysOrders.length" style="border: 1px solid #e4e4e4; border-top: none;">
  <h5 class="text-center pt-3 pb-3">{{ "CASES.single.no-payments" | translate }}</h5>
</div>
