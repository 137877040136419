import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Product } from '../../../../../../../../_base-shared/models/Product';
import { PackagerStatus } from '../../../../../../../../_base-shared/models/Status/PackagerStatus';
import { PaymentStatus } from '../../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../../_base-shared/models/Status/Status';
import { CaseInvoiceStatus } from '../../../../../../../../_base-shared/models/Status/CaseInvoiceStatus';
import { TaskTemplate } from '../../../../../../../../_base-shared/models/Task/TaskTemplate';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { PackagerStatusService } from '../../../../admin-packager/packager-status/packager-status.service';
import { PaymentStatusService } from '../../../../payment-status/payment-status.service';
import { StatusPickerTrait } from '../../../../status/status-picker.trait';
import { StatusService } from '../../../../status/status.service';
import { TaskEditorComponent } from '../../../../task/task-editor/task-editor.component';
import { CaseService } from '../../../case.service';
import { NoteService } from '../../../note.service';
import { ProductService } from '../../../product.service';
import { AppointCourtModalComponent } from '../../case-draft/appoint-court-modal/appoint-court-modal.component';
import { CallStatus } from '../../../../../../../../_base-shared/models/Status/CallStatus';
import { CallStatusService } from '../../../../call-status/call-status.service';

@Component({
  selector:    'app-case-status-editor',
  templateUrl: './case-status-editor.component.html',
  styles:      []
})
export class CaseStatusEditorComponent extends StatusPickerTrait implements OnInit, OnDestroy {
  @Input() case: Case;
  @Output() caseUpdate: EventEmitter<Case> = new EventEmitter<Case>();
  public authUser: User;
  public serverResponse: LaravelResourceResponse;
  public isLoading                         = 0;
  public isSubmitting: boolean;
  public isEditing                         = false;
  public form: UntypedFormGroup;

  public statusFormControlName                         = 'status_id';
  public products: Array<Product>                      = [];
  public packagerStatuses: Array<PackagerStatus>       = [];
  public paymentStatuses: Array<PaymentStatus>         = [];
  public callStatuses: Array<CallStatus>               = [];
  public caseInvoiceStatuses: Array<CaseInvoiceStatus> = [];
  public showInvoiceStatus: boolean;
  public amountPaid                                    = 0;
  public amountLeftToPay                               = 0;
  public monthsActive: number;
  public nextPaymentDate: Date | string;
  public nextPaymentAmount: number;
  private taskTemplateWatchers: Array<TaskTemplate>    = [];
  public digitalSignatureOptions: Array<AppSelectOption> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private productService: ProductService,
    private caseService: CaseService,
    private statusService: StatusService,
    private paymentStatusService: PaymentStatusService,
    private callStatusService: CallStatusService,
    private packagerStatusService: PackagerStatusService,
    private noteService: NoteService
  ) {
    super('status_id', false, false);
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (((this.authUser.packager.master && !this.case.original_packager.master) || this.case.id === 195392) &&
        (this.authUser?.id === 1 || this.authUser?.id === 22 || this.authUser?.id === 27 || this.authUser?.id === 34 ||
          this.authUser?.id === 36 || this.authUser?.id === 2497 || this.authUser?.id === 211750)
      ) {
        this.showInvoiceStatus = true;
      }

      if (this.showInvoiceStatus) {
        this.fetchCaseInvoiceStatuses();
      }
      this.fetchProducts();
      this.fetchPackagerStatuses();
      this.fetchStatuses();
      this.fetchPaymentStatus();
      this.fetchCallStatuses();
      this.fetchTaskTemplateWatchers(this.case);
      this.loadCaseRelations(this.case);
      this.buildForm();
      this.buildDigitalSignatureOptions();
    });

    this.monthsActive = Math.ceil(DateTime.fromISO(this.case.created_at.toString()).diffNow('months').toObject().months);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public switchEdit($event = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.isEditing = ! this.isEditing;
    this.enableDisableStatusForm(this.isEditing);
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    let caseWasUpdated = false;
    this.isSubmitting  = true;
    this.caseService.changeStatus(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.switchEdit();
        this.toastr.success(this.translate.instant('CASES.single.general.status_editor.result.status_updated'));
        const oldCase     = { ...this.case };
        const updatedCase = result.data;

        if (oldCase.status_id !== updatedCase.status_id) {
          this.case.status_id = updatedCase.status_id;
          this.case.status    = this.allStatuses.find(
            fStatus => fStatus.status_id && (fStatus.id === updatedCase.status_id)
          );
          caseWasUpdated      = true;
        }
        if (oldCase.payment_status_id !== updatedCase.payment_status_id) {
          this.case.payment_status_id = updatedCase.payment_status_id;
          this.case.payment_status    = this.paymentStatuses.find(
            findPaymentStatus => findPaymentStatus.id === updatedCase.payment_status_id
          );
          caseWasUpdated              = true;
        }
        if (oldCase.product_id !== updatedCase.product_id) {
          this.case.product_id = updatedCase.product_id;
          this.case.product    = this.products.find(product => product.id === updatedCase.product_id);
          caseWasUpdated       = true;
        }
        if (oldCase.call_status_id !== updatedCase.call_status_id) {
          this.case.call_status_id = updatedCase.call_status_id;
          caseWasUpdated           = true;
        }
        if (oldCase.payment_status_id !== updatedCase.payment_status_id){
          this.case.payment_status_id = updatedCase.payment_status_id;
          if (updatedCase.payment_status.name === 'Replace case'){
            this.statusChangedToReplaceDialog();
          }
        }
        if (oldCase.digital_signature !== updatedCase.digital_signature) {
          this.case.digital_signature = updatedCase.digital_signature;
          caseWasUpdated = true;
        }
        if (oldCase.product_id !== updatedCase.product_id) {
          this.case.product_id = updatedCase.product_id;
          this.case.product    = this.products.find(product => product.id === updatedCase.product_id);
          caseWasUpdated       = true;
        }
        if (caseWasUpdated) {
          this.caseUpdate.emit(this.case);
          this.checkStatusChangeWatchers(oldCase, this.case);
          this.checkTaskTemplateWatchers(oldCase, this.case);
        }
      },
      err => {
        this.serverResponse = err.error;
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      }
    );
  }

  private statusChangedToReplaceDialog(): void {
    Swal.fire({
      text:              this.translate.instant('CASES.single.general.status_editor.enter_df_reference'),
      input:             'text',
      showCancelButton:  true,
      showConfirmButton: true,
      inputValidator:    (value) => {
        return new Promise((resolve) => {
          if (value.length === 0) {
            resolve(this.translate.instant('CASES.single.general.status_editor.df_reference_required'));
          } else {
            resolve('');
          }
        });
      }
    }).then((res) => {
      if (res.value != null) {
        const noteData = {
          type:                  'note',
          note:                  res.value,
          contacted_at:          '',
          customer_contact_type: ''
        };
        this.storeNote(noteData);
      }
    });
  }

  private storeNote(noteData): void {
    this.noteService.store(this.case.id, noteData, ['user']).subscribe(res => {
      this.toastr.success(this.translate.instant('SHARED.item-added'));
    }, err => this.toastr.error(this.translate.instant('SHARED.went-wrong')));
  }

  public updateSelectedStatusCategories(selectedStatusCategoryIds: Array<number> | number) {
    this.filteredStatusCategories = [];
    setTimeout(() => super.updateSelectedStatusCategories(selectedStatusCategoryIds), 0);
  }

  private checkStatusChangeWatchers(oldCase: Case, updatedCase: Case) {
    const watchedStatuses        = ['Demanda repartida', 'Demanda admitida', 'DDA Admitida + 15 EPI'];
    const watchedPaymentStatuses = [];
    if (oldCase.status_id !== updatedCase.status_id && updatedCase.status &&
      watchedStatuses.includes(updatedCase.status.name)
    ) {
      this.openCourtModal();
    }

    if (oldCase.payment_status_id !== updatedCase.payment_status_id && updatedCase.payment_status &&
      watchedPaymentStatuses.includes(updatedCase.payment_status.name)
    ) {
      this.openCourtModal();
    }
  }

  private checkTaskTemplateWatchers(clientCase: Case, updatedCase: Case) {
    this.taskTemplateWatchers.forEach(taskTemplate => {
      if (clientCase.status_id !== updatedCase.status_id &&
        taskTemplate.statusable_type === 'status' && taskTemplate.statusable_id === updatedCase.status_id) {
        // Status changed and template is observing the new status
        this.addTask(updatedCase, taskTemplate, updatedCase.status, !! taskTemplate.force_task_creation);
      }

      if (clientCase.payment_status_id !== updatedCase.payment_status_id &&
        taskTemplate.statusable_type === 'payment_status' &&
        taskTemplate.statusable_id === updatedCase.payment_status_id) {
        // Payment Status changed and template is observing the new status
        this.addTask(updatedCase, taskTemplate, updatedCase.payment_status, !! taskTemplate.force_task_creation);
      }
    });
  }

  private addTask(clientCase: Case, taskTemplate: TaskTemplate, status: Status | PaymentStatus, force = false) {
    this.toastr.info(clientCase.ref_number + ' requires a task for ' + status.name);
    this.dialog.open(TaskEditorComponent, {
      width:             '40%',
      autoFocus:         true,
      closeOnNavigation: ! force,
      disableClose:      force,
      data:              {
        editorType: 'create',
        case:       clientCase,
        taskTemplate,
        prefill:    true,
        quickTask:  true
      }
    });
  }

  private enableDisableStatusForm(isEnabled: boolean) {
    if (isEnabled) {
      this.form.get('product_id').enable();
      this.statusCategoryControl.enable();
      this.form.get(this.statusFormControlName).enable();
      this.statusControl.enable();
      this.form.get('payment_status_id').enable();
      this.form.get('case_invoice_status_id').enable();
      this.form.get('digital_signature').enable();
      if (this.authUser.role_id === 5 || [3, 6, 967, 2497, 2592, 2595, 130100].includes(this.authUser.id)) {
        this.form.get('call_status_id').enable();
      }
      if (this.authUser?.packager?.master) {
        this.form.get('packager_status_id').enable();
      }
    } else {
      this.form.get('product_id').disable();
      this.statusCategoryControl.disable();
      this.form.get(this.statusFormControlName).disable();
      this.statusControl.disable();
      this.form.get('packager_status_id').disable();
      this.form.get('payment_status_id').disable();
      this.form.get('call_status_id').disable();
      this.form.get('case_invoice_status_id').disable();
      this.form.get('digital_signature').disable();
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      product_id:                   [{ value: this.case.product_id, disabled: true }, [Validators.required]],
      packager_status_id:           [{ value: this.case.packager_status_id, disabled: true }],
      [this.statusFormControlName]: [{ value: this.case.status_id, disabled: true }, [Validators.required]],
      payment_status_id:            [{ value: this.case.payment_status_id, disabled: true }],
      call_status_id:               [{ value: this.case.call_status_id, disabled: true }],
      case_invoice_status_id:       [{ value: this.case.case_invoice_status_id, disabled: true }],
      digital_signature:            [{ value: this.case.digital_signature, disabled: true }]
    });
    this.statusControl.disable();
    this.statusCategoryControl.disable();
  }

  private calculateTotalPaid(clientCase: Case) {
    this.amountPaid      = 0;
    this.amountLeftToPay = 0;
    clientCase.terms.forEach(term => this.amountPaid += +term.amount_paid);
    clientCase.terms.forEach(term => this.amountLeftToPay += +(term.amount - term.amount_paid));
  }

  private getNextPayment(clientCase: Case) {
    const nextTerm = clientCase.terms.sort((a, b) => new Date(a.term_date).getTime() - new Date(b.term_date).getTime())
      .find(term => term.amount !== term.amount_paid);

    if (nextTerm) {
      this.nextPaymentDate   = nextTerm.term_date;
      this.nextPaymentAmount = nextTerm.amount - nextTerm.amount_paid;
    }
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.statusCategories         = result.data;
          this.filteredStatusCategories = result.data;
          this.statusCategories.forEach(category => {
            this.allStatuses.push(category);
            category.statuses.forEach(status => this.allStatuses.push(status));
          });
          this.setStatusControls(this.form.get(this.statusFormControlName).value);
        }
      )
    );
  }

  private fetchPaymentStatus() {
    this.isLoading++;
    this.paymentStatusService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchTaskTemplateWatchers(clientCase: Case) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.indexTaskTemplateWatchers(clientCase.id).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.taskTemplateWatchers = result.data)
    );
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.callStatusService.index({ all: 1 }).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.callStatuses = result.data)
    );
  }

  private fetchCaseInvoiceStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexInvoiceStatuses({ select_all: 1 }).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseInvoiceStatuses = result.data)
    );
  }

  private fetchPackagerStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerStatusService.index({ all: 1 }).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.packagerStatuses = result.data)
    );
  }

  private loadCaseRelations(clientCase: Case) {
    const relations = [
      'terms',
      'case_entities.notary',
      'case_entities.administrator',
      'distribution.batch',
      'appointed_court.court'
    ];
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, relations).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.case.terms           = result.data.terms;
          this.case.case_entities   = result.data.case_entities;
          this.case.distribution    = result.data.distribution;
          this.case.appointed_court = result.data.appointed_court;
          this.calculateTotalPaid(this.case);
          this.getNextPayment(this.case);
        }
      )
    );
  }

  public openCourtModal() {
    this.dialog.open(AppointCourtModalComponent, {
      width: '50%',
      data:  {
        case: this.case
      }
    });
  }

  private buildDigitalSignatureOptions(): void {
    this.digitalSignatureOptions = [
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.digital_certificate'),
        value: 'digital_certificate',
      },
      {label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.clave_pin'), value: 'clave_pin'},
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.ask'),
        value: 'ask',
      },
      {
        label: this.translate.instant('CASES.single.general.status_editor.digital_signature.options.none'),
        value: null,
      }
    ];
  }
}
