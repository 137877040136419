import { Injectable } from '@angular/core';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Note, NoteType } from '../../../../../_base-shared/models/Note';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class NoteService extends MainBaseApiService {
  public index(caseId: number, type: NoteType = null, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({type, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<Note>>>(this.apiUrl + '/cases/' + caseId + '/notes', {params});
  }

  public store(caseId: number, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.post<LaravelResourceResponse<Note>>(this.apiUrl + '/cases/' + caseId + '/notes', data, {params});
  }

  public update(noteId: number, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.put<LaravelResourceResponse<Note>>(this.apiUrl + '/notes/' + noteId, data, {params});
  }

  public delete(noteId: number) {
    return this.http.delete<LaravelResourceResponse<Note>>(this.apiUrl + '/notes/' + noteId);
  }

  public unsticky(caseId: number, noteId: number, isSticky: boolean) {
    return this.http.post<LaravelResourceResponse<Note>>(
      this.apiUrl + '/cases/' + caseId + '/notes/' + noteId + '/update-sticky-property', {sticky: isSticky});
  }
}
