import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { CaseListComponent } from '../case/case-list/case-list.component';

const routes: Routes = [
  {
    path:     'customer-contact',
    children: [
      {path: '', component: CaseListComponent, data: {filterType: 'customer_contact'}, canActivate: [MainAuthGuard]},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class CustomerContactRoutingModule {
}
