import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { CaseModule } from '../admin/case/case.module';
import { AuthModule } from '../auth/auth.module';
import { SharedClientModule } from './_shared/shared-client.module';
import { ClientRoutingModule } from './client-routing.module';
import {
  RedirectToClientAppDynamicComponent
} from './redirect-to-client-app-dynamic/redirect-to-client-app-dynamic.component';
import { RedirectToClientAppComponent } from './redirect-to-client-app/redirect-to-client-app.component';

@NgModule({
  imports:      [
    SharedClientModule,
    CaseModule,
    MatStepperModule,
    MatSidenavModule,
    AuthModule,
    ClientRoutingModule,
    AngularSignaturePadModule
  ],
  exports:      [],
  declarations: [
    RedirectToClientAppComponent,
    RedirectToClientAppDynamicComponent,
  ],
  providers:    [{provide: Window, useValue: window}],
})
export class ClientModule {
}
