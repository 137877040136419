import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import {CallStatusEditorComponent} from './call-status-editor/call-status-editor.component';
import {CallStatusListComponent} from './call-status-list/call-status-list.component';
import {CallStatusRoutingModule} from './call-status-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CallStatusListComponent,
    CallStatusEditorComponent
  ],
  imports: [
    CallStatusRoutingModule,
    TranslateModule,
    MatProgressSpinnerModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ]
})

export class CallStatusModule{
}
