import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CaseVariable } from '../../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { EmailTemplate } from '../../../../../../_base-shared/models/Notification/EmailTemplate';
import { SmsTemplate } from '../../../../../../_base-shared/models/Notification/SmsTemplate';
import { PaymentListFilter } from '../../../../../../_base-shared/models/Payment/PaymentListFilter';
import { UploadService } from '../../app-file/upload.service';
import { CaseService } from '../../case/case.service';

type ChannelSlug = 'email' | 'sms';

interface DialogData {
  templatableType: 'case' | 'term';
  channels: Array<ChannelSlug>;
  filters: CaseListFilter | PaymentListFilter;
}

@Component({
  selector:    'app-notification-message',
  templateUrl: './notification-message.component.html',
  styles:      [],
})
export class NotificationMessageComponent implements OnInit, OnDestroy {
  @Input() channels: Array<ChannelSlug> = [];
  @Input() filters: CaseListFilter | PaymentListFilter;
  @Input() templatableType: 'case' | 'term';

  public templatablesCount: number;
  public templateVariables: Array<CaseVariable>;
  public isLoading = 0;
  public isSending: boolean;
  public loadingParsedTemplate: boolean;
  public variablesOpen: boolean;

  public form: UntypedFormGroup;
  public selectedTemplate: EmailTemplate | SmsTemplate;
  public previewNotification: { subject: string, body: string; };
  public emailTemplates: Array<EmailTemplate> = [];
  public smsTemplates: Array<SmsTemplate>     = [];
  public quillModules                         = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private uploadService: UploadService,
              private caseService: CaseService,
              private toast: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<NotificationMessageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit(): void {
    this.channels        = this.data.channels ? this.data.channels : this.channels;
    this.filters         = this.data.filters ? this.data.filters : this.filters;
    this.templatableType = this.data.templatableType ? this.data.templatableType : this.templatableType;
    if (this.filters.select_all) {
      this.templatablesCount = this.filters.expected_count;
    } else {
      this.templatablesCount      = this.templatableType === 'case' ?
          this.filters.cases.length :
          this.filters.terms.length;
      this.filters.expected_count = this.filters.expected_count ? this.filters.expected_count : this.templatablesCount;
    }
    this.fetchEmailTemplates();
    this.fetchSmsTemplates();
    this.fetchVariables();
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public sendNotification(form: UntypedFormGroup) {
    if (form.invalid) {
      return;
    }
    const mergedData = {...form.value, ...this.filters};
    const observable = this.templatableType === 'case' ?
        this.caseService.sendCaseNotifications(mergedData) :
        this.caseService.sendTermNotifications(mergedData);

    this.isSending = true;
    this.subscriptions.push(
        observable.pipe(finalize(() => this.isSending = false)).subscribe(
            result => {
              let message = this.translateService.instant(
                  'NOTIFICATION.notification-modal.form.success_count', {count: result.data.success_count},
              );

              if (result.data.error_count) {
                message += '. ';
                message += this.translateService.instant(
                    'NOTIFICATION.notification-modal.form.error_count', {count: result.data.error_count},
                );
              }
              this.toast.success(message);
              this.dialogRef.close();
            },
            errorResponse => {
              if (errorResponse.status === 422) {
                const errorBag  = errorResponse.error.errors;
                const errorKeys = Object.keys(errorBag);
                errorKeys.forEach(key => {
                  this.toast.error(errorBag[key], errorResponse.error.message,
                      {closeButton: true, disableTimeOut: true},
                  );
                });
              } else {
                this.toast.error(this.translateService.instant('NOTIFICATION.notification-modal.form.error'));
              }
              this.dialogRef.close();
            },
        ),
    );
  }

  private buildForm() {
    this.form = this.fb.group({
      channels:      [this.channels],
      template_type: [null],
      template_id:   [null],
      recipient:     ['client'],
      subject:       [null],
      body:          [null],
    });
  }

  private fetchSmsTemplates() {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.getSmsTemplates().pipe(finalize(() => this.isLoading--))
            .subscribe(next => this.smsTemplates = next.data),
    );
  }

  private fetchEmailTemplates(): void {
    this.isLoading++;
    const data = {
      sort_by:    'label',
      sort_order: 'asc',
    };
    this.subscriptions.push(
        this.caseService.getEmailTemplates(data).pipe(finalize(() => this.isLoading--))
            .subscribe(next => this.emailTemplates = next.data),
    );
  }

  private fetchVariables() {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.indexCaseVariables().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.templateVariables = result.data),
    );
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public channelChanged($event: MatSelectChange) {
    this.form.get('template_type').patchValue(null);
    this.form.get('template_id').patchValue(null);
  }

  public templateChanged(value: number, templateModel: 'email_template' | 'sms_template' | null) {
    if (templateModel && value) {
      this.form.get('template_type').patchValue(templateModel);
      this.selectedTemplate    = this.findTemplate(templateModel, value);
      this.previewNotification = {subject: this.selectedTemplate.subject, body: this.selectedTemplate.content};
      this.form.get('subject').patchValue(this.selectedTemplate.subject);
      this.form.get('body').patchValue(this.selectedTemplate.content);
    } else {
      this.selectedTemplate    = null;
      this.previewNotification = null;
      this.form.get('subject').patchValue(null);
      this.form.get('body').patchValue(null);
      this.form.get('template_type').patchValue(null);
    }
  }

  public editTemplate($event, previewNotification: { subject: string, body: string }) {
    $event.stopPropagation();
    this.form.get('template_type').patchValue(null);
    this.form.get('template_id').patchValue(null);
    this.form.get('subject').patchValue(previewNotification.subject);
    this.form.get('body').patchValue(previewNotification.body);
    this.selectedTemplate    = null;
    this.previewNotification = null;
  }

  public parseNotificationTemplate($event, type: 'case' | 'term', template: EmailTemplate | SmsTemplate | null) {
    $event.stopPropagation();
    if ((type === 'case' && this.filters.cases.length !== 1) || type === 'term' && this.filters.terms.length !== 1) {
      console.error('Templatable not parsable.');
      return;
    }
    const requestData = {
      templatable_type: type,
      templatable_id:   type === 'case' ? this.filters.cases[0] : this.filters.terms[0],
      template_type:    template?.template_type,
      template_id:      template?.id,
      subject:          this.form.get('subject').value,
      body:             this.form.get('body').value,
    };

    this.loadingParsedTemplate = true;
    return this.subscriptions.push(
        this.caseService.parseNotificationVariables(requestData)
            .pipe(finalize(() => this.loadingParsedTemplate = false)).subscribe(result => {
              const parsedNotification = result.data;
              if (template) {
                this.previewNotification = parsedNotification;
              } else {
                this.form.get('subject').patchValue(parsedNotification.subject);
                this.form.get('body').patchValue(parsedNotification.body);
              }
            },
        ),
    );
  }

  private findTemplate(templateModel: 'email_template' | 'sms_template', value: number) {
    if (templateModel === 'email_template') {
      return this.emailTemplates.find(template => template.id === value);
    }
    return this.smsTemplates.find(template => template.id === value);
  }

  public addVariable($event) {
    const content = this.form.get('body').value || '';
    this.form.get('body').setValue(content + $event.target.innerText + ' ');
  }
}
