<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
  <div class="d-flex justify-content-between align-items-center ms-2 my-2" style="height: 40px">
    <div>
      <h5>{{'CASES.case-snapshot.creditors.heading' | translate}}</h5>
    </div>
  </div>
  </div>
  <!--Public debts-->
  <div *ngIf="publicDebts">
    <h5 class="my-2 ms-2"><b>{{'CASE_CREDITOR.model.type.options.public' | translate}}</b></h5>
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">{{'CASES.case-snapshot.creditors.city' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.notes' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.debt_type.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.current_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.initial_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.making_payments' | translate}}</th>
          <th scope="col">{{'CASE_CREDITOR.model.reference_number' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.response_received.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.type_of_guarantee.label' | translate}}</th>
          <th scope="col">  {{'CASE_CREDITOR.model.public_organization.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let publicDebt of publicDebts">
          <th scope="row">{{publicDebt?.town_hall?.name}}</th>
          <td>{{publicDebt?.notes}}</td>
          <td>{{"CASE_CREDITOR.model.debt_type.options." + publicDebt?.debt_type | translate}}</td>
          <td>{{publicDebt?.judicial_claim}}</td>
          <td>{{publicDebt?.current_balance}}</td>
          <td>{{publicDebt?.initial_balance}}</td>
          <td>{{publicDebt?.making_payments}}</td>
          <td>{{publicDebt?.monthly_payments}}</td>
          <td>{{publicDebt?.reference_number}}</td>
          <td>{{"CASE_CREDITOR.model.response_received.options." + publicDebt?.response_received | translate}}</td>
          <td>{{"CASE_CREDITOR.model.type_of_guarantee.options." + publicDebt?.type_of_guarantee | translate}}</td>
          <td>{{"CASE_CREDITOR.model.public_organization.options." + publicDebt?.public_organisation | translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Secured-->
  <div *ngIf="securedCreditors?.length > 0">
    <h5 class="my-2 ms-2"><b>{{'CASE_CREDITOR.model.type.options.secured' | translate}}</b></h5>
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col"> {{'SHARED.model.name' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.notes' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.debt_type.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.current_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.initial_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.making_payments' | translate}}</th>
          <th scope="col">{{'CASE_CREDITOR.model.reference_number' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.response_received.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.type_of_guarantee.label' | translate}}</th>
          <th scope="col">  {{'CASE_CREDITOR.model.public_organization.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let securedCreditor of securedCreditors">
          <td>{{securedCreditor?.name}}</td>
          <td>{{securedCreditor?.pivot.notes}}</td>
          <td>{{"CASE_CREDITOR.model.debt_type.options." + securedCreditor?.pivot.debt_type | translate}}</td>
          <td>{{securedCreditor?.pivot.judicial_claim}}</td>
          <td>{{securedCreditor?.pivot.current_balance}}</td>
          <td>{{securedCreditor?.pivot.initial_balance}}</td>
          <td>{{securedCreditor?.pivot.making_payments}}</td>
          <td>{{securedCreditor?.pivot.monthly_payments}}</td>
          <td>{{securedCreditor?.pivot.reference_number}}</td>
          <td>{{"CASE_CREDITOR.model.response_received.options." + securedCreditor?.pivot.response_received | translate}}</td>
          <td>{{"CASE_CREDITOR.model.type_of_guarantee.options." + securedCreditor?.pivot.type_of_guarantee | translate}}</td>
          <td>{{"CASE_CREDITOR.model.public_organization.options." + securedCreditor?.pivot.public_organisation | translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Unsecured-->
  <div  *ngIf="unsecuredCreditors?.length > 0">
    <h5 class="my-2 ms-2"><b>{{'CASE_CREDITOR.model.type.options.unsecured' | translate}}</b></h5>
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col"> {{'SHARED.model.name' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.notes' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.debt_type.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.current_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.initial_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.making_payments' | translate}}</th>
          <th scope="col">{{'CASE_CREDITOR.model.reference_number' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.response_received.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.type_of_guarantee.label' | translate}}</th>
          <th scope="col">  {{'CASE_CREDITOR.model.public_organization.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let unsecuredCreditor of unsecuredCreditors">
          <td>{{unsecuredCreditor?.name}}</td>
          <td>{{unsecuredCreditor?.notes}}</td>
          <td>{{"CASE_CREDITOR.model.debt_type.options." + unsecuredCreditor?.pivot.debt_type | translate}}</td>
          <td>{{unsecuredCreditor?.pivot.judicial_claim}}</td>
          <td>{{unsecuredCreditor?.pivot.current_balance}}</td>
          <td>{{unsecuredCreditor?.pivot.initial_balance}}</td>
          <td>{{unsecuredCreditor?.pivot.making_payments}}</td>
          <td>{{unsecuredCreditor?.pivot.monthly_payments}}</td>
          <td>{{unsecuredCreditor?.pivot.reference_number}}</td>
          <td>{{"CASE_CREDITOR.model.response_received.options." + unsecuredCreditor?.pivot.response_received | translate}}</td>
          <td>{{"CASE_CREDITOR.model.type_of_guarantee.options." + unsecuredCreditor?.pivot.type_of_guarantee | translate}}</td>
          <td>{{"CASE_CREDITOR.model.public_organization.options." + unsecuredCreditor?.pivot.public_organisation | translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Claim-->
  <div *ngIf="claimCreditors?.length > 0">
    <h5><b>{{'CASE_CREDITOR.model.type.options.claim' | translate}}</b></h5>
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col"> {{'SHARED.model.name' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.notes' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.debt_type.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.current_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.initial_balance' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.making_payments' | translate}}</th>
          <th scope="col">{{'CASE_CREDITOR.model.reference_number' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.response_received.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.type_of_guarantee.label' | translate}}</th>
          <th scope="col">  {{'CASE_CREDITOR.model.public_organization.label' | translate}}</th>
          <th scope="col"> {{'CASE_CREDITOR.model.judicial_claim' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let claimCreditor of claimCreditors">
          <td>{{claimCreditor?.name}}</td>
          <td>{{claimCreditor?.pivot.notes}}</td>
          <td>{{"CASE_CREDITOR.model.debt_type.options." + claimCreditor?.pivot.debt_type | translate}}</td>
          <td>{{claimCreditor?.pivot.judicial_claim}}</td>
          <td>{{claimCreditor?.pivot.current_balance}}</td>
          <td>{{claimCreditor?.pivot.initial_balance}}</td>
          <td>{{claimCreditor?.pivot.making_payments}}</td>
          <td>{{claimCreditor?.pivot.monthly_payments}}</td>
          <td>{{claimCreditor?.pivot.reference_number}}</td>
          <td>{{"CASE_CREDITOR.model.response_received.options." + claimCreditor?.pivot.response_received | translate}}</td>
          <td>{{"CASE_CREDITOR.model.type_of_guarantee.options." + claimCreditor?.pivot.type_of_guarantee | translate}}</td>
          <td>{{"CASE_CREDITOR.model.public_organization.options." + claimCreditor?.pivot.public_organisation | translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
