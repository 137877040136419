import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector:    'app-case-manager-details',
  templateUrl: './case-manager-details.component.html',
  styleUrls:   ['./case-manager-details.component.scss']
})
export class CaseManagerDetailsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  public isLoading                = 0;
  public status_ids                 = [];

  public achievement: 'cases_assigned' | 'documentacion_completa' | 'revisar_documents' | 'dda_pte_revision' = 'cases_assigned';
  public sortBy                     = 'id';
  public sortOrder                  = 'desc';
  public dataSource: MatTableDataSource<any>;
  public version: 1 | 2 | number;
  public form: UntypedFormGroup;
  public agentId: number;

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.agentId = Number(this.route.snapshot.paramMap.get('agentId'));
    this.buildForm();
    this.getData();
  }

  buildForm() {
    this.form = this.fb.group({
      from: [new Date()],
      to:   [new Date()]
    });
  }

  clearDatePicker($event, name) {
    this.form.patchValue({[name]: ''});
  }

  onDateChange($event, type) {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  getData() {
    this.isLoading++;

    const data = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date:   this.form.value.to ? this.form.value.to.toDateString() : '',
      selectedProduct: 'lso',
      achievement: this.achievement
    }

    this.dashboardService.caseManagerDetail(data, this.agentId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe({
        next:  res => {
          this.dataSource = new MatTableDataSource(res.data);
        },
        error: err => console.log(err),
      });
  }

  public onRadioChange($event): void {
    this.achievement       = $event.value;
    this.getData();
  }
}
