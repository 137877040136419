<div class="mb-4 mt-4">
    <div class="card-header" style="border: 1px solid #e4e4e4;">
        <h4 class="text-center mb-0">{{ "CASES.single.payment-links" | translate }}</h4>
    </div>
    <div class="card-body p-0">
        <div class="mat-elevation-z5" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
            <table style="width: 100%" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.date" | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at | toDateObject | date}} </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{"CASES.single.amount" | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.amount | number: '1.2-2'}} </td>
                </ng-container>
                <ng-container matColumnDef="success">
                    <th mat-header-cell *matHeaderCellDef>{{"PAYMENTS.list.status.options.paid" | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element.success ? 'Yes' : 'No'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div *ngIf="isLoading" class="text-center" style="padding: 50px">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div *ngIf="!isLoading && !dataSource?.data.length" >
                <h5 class="text-center pt-3">{{ "CASES.single.no-payments" | translate }}</h5>
            </div>

            <mat-paginator
                    [length]="paginatorConfig.length"
                    [pageSize]="paginatorConfig.pageSize"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="paginatorChange($event)"
                    showFirstLastButtons
            ></mat-paginator>
        </div>
    </div>
</div>
