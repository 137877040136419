import { NgModule } from '@angular/core';
import { PublicRoutingModule } from './public-routing.module';
import { PublicUnsubscribeComponent } from './public-unsubscribe/public-unsubscribe.component';
import { MainArcopayWidgetHandlerComponent } from './main-arcopay-widget-handler/main-arcopay-widget-handler.component';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
  imports:      [
    SharedModule,
    PublicRoutingModule,
  ],
  declarations: [
    PublicUnsubscribeComponent,
    MainArcopayWidgetHandlerComponent,
  ],

})
export class PublicModule {
}
