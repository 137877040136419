import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-case-aep-creator',
  templateUrl: './case-aep-creator.component.html',
  styles:      [
    `
        .mat-radio-button ~ .mat-radio-button {
            display: block;
        }

        .mat-radio-group {
            margin-top: 8px;
        }
    `],
  animations:  [
    trigger(
        'inOutAnimation',
        [
          transition(
              ':enter',
              [
                style({height: 0, opacity: 0}),
                animate('0.3s ease-out',
                    style({height: '*', opacity: 1})),
              ],
          ),
          transition(
              ':leave',
              [
                animate('0.3s ease-in',
                    style({height: 0, opacity: 0})),
              ],
          ),
        ],
    ),
  ],
})
export class CaseAepCreatorComponent implements OnInit {
  public caseId;
  public form: UntypedFormGroup;
  public formData;
  public formActive;
  public download     = false;
  public isSubmitting = 0;
  public storageUrl   = environment.STORAGE_URL + '/';
  public currentDate  = new Date();

  public modules = {
    toolbar: [
      ['italic', 'underline'],        // toggled buttons
      [{list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}],          // outdent/indent
      [{align: []}],
    ],
  };

  constructor(
      private fb: UntypedFormBuilder,
      private route: ActivatedRoute,
      private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.caseId = this.route.parent.snapshot.paramMap.get('id');

    this.caseService.getAEPData(this.caseId).subscribe(next => {
      this.formData = next.data;
      this.buildForm();
    });

  }

  buildForm() {
    this.form = this.fb.group({
      change_of_address:                 ['no', Validators.required],
      place_of_birth:                    ['', Validators.required],
      nationality:                       [''],
      spouses_are_owners:                ['no'],
      people_in_charge:                  ['no', Validators.required],
      not_married_partner:               [''],
      insolvency_type:                   ['current'],
      insolvency_derives:                this.fb.group({
        unemployment:                  [false],
        overIndebtedness:              [false],
        businessLosses:                [false],
        decreaseInSales:               [false],
        increaseInExpenses:            [false],
        increaseInFinancialCost:       [false],
        increaseInCustomerDelinquency: [false],
        increaseOther:                 [false],
      }),
      convicted:                         ['no'],
      out_of_court_payment:              ['no'],
      negotiating_refinancing_agreement: ['no'],
      application_for_bankruptcy:        ['no'],
      seizures:                          [''],
      bank_account_holder:               ['yes'],
      owner_of_movable_capital:          ['no'],
      owner_of_real_estate:              ['no'],
      owner_of_personal_properties:      ['no'],
      number_of_workers:                 [0],
      presented_in:                      ['', Validators.required],
      presented_date:                    ['', Validators.required],
    });

    this.addDynamicFields();
    this.subscribeToFormFields();

    this.formActive = true;
  }

  addDynamicFields() {
    if (this.formData.marital_status === 'divorced') {
      this.form.addControl('civil_status', this.fb.control('divorced_with_agreement', Validators.required));
    }

    //  Add vehicle checkbox
    if (this.formData.vehicles.length) {
      const fields = {};
      this.formData.vehicles.map(vehicle => {
        fields[vehicle.id] = [false];
      });
      this.form.addControl('car_assets', this.fb.group(fields));
    }

    //  Add secured debts table
    if (this.formData.secured_debts.length) {
      this.form.addControl('identification_of_credits', this.fb.array([]));

      //  Populate form array with data
      const credits = this.form.get('identification_of_credits') as UntypedFormArray;
      this.formData.secured_debts.map(credit => {
        credits.push(this.fb.group({
              company_name:    [credit.company_name],
              address:         [credit.address],
              email:           [credit.email],
              current_balance: [credit.pivot.current_balance],
              type:            ['mortgage', Validators.required],
              type_date:       ['', Validators.required],
            }),
        );
      });
    }

    //  Add unsecured debts table
    if (this.formData.debts.length) {
      this.form.addControl('debts', this.fb.array([]));

      //  Populate form array with data
      const debts = this.form.get('debts') as UntypedFormArray;
      this.formData.debts.map(debt => {
        let companyName = '';
        if (debt.company_name) {
          companyName = debt.company_name;
        } else {
          if (debt.public_organisation === 'estate') {
            companyName = 'Agencia Estatal de Administración Tributaria (AEAT)';
          }
          if (debt.public_organisation === 'social-security') {
            companyName = 'Tesorería General de la Seguridad Social (TGSS)';
          }
          if (debt.public_organisation === 'town-hall') {
            companyName = '';
          }
        }

        debts.push(this.fb.group({
              company_name:    [companyName, null],
              address:         [debt.pivot ? debt.address : '', null],
              email:           [debt.pivot ? debt.email : '', null],
              current_balance: [debt.pivot ? debt.pivot.current_balance : debt.current_balance],
              expiration_date: ['VENCIDO', Validators.required],
              repayments:      ['NO', Validators.required],
              type:            [debt.pivot ? 'unsecured' : debt.public_organisation],
            }),
        );
      });
    }

    //  Add secured debts table
    if (this.formData.home_owner === 'tenant') {
      this.form.addControl('list_of_contracts', this.fb.array([]));

      //  Populate form array with data
      const credits = this.form.get('list_of_contracts') as UntypedFormArray;
      credits.push(this.fb.group({
            date_of_contract:         ['', null],
            landlord_name:            ['', null],
            contract_type:            ['Alquiler'],
            debtor_obligations:       ['No'],
            counterparty_obligations: ['No'],
          }),
      );
    }
  }

  subscribeToFormFields() {
    this.form.controls.spouses_are_owners.valueChanges.subscribe(next => {
      if (next === 'yes') {
        this.form.addControl('spouses_are_owners_if_so', this.fb.control('consent-accompanied'));
      } else {
        this.form.removeControl('spouses_are_owners_if_so');
      }
    });

    this.form.controls.people_in_charge.valueChanges.subscribe(next => {
      if (next === 'yes') {
        this.form.addControl('people_in_charge_names', this.fb.control('', Validators.required));
      } else {
        this.form.removeControl('people_in_charge_names');
      }
    });

    this.form.controls.owner_of_real_estate.valueChanges.subscribe(next => {
      if (next === 'yes') {
        //  Add form array
        this.form.addControl('properties_table', this.fb.array([]));

        //  Populate form array with data
        const propertiesTable = this.form.get('properties_table') as UntypedFormArray;
        this.formData.properties.map(property => {
          propertiesTable.push(this.fb.group({
                type:         [property.home_type, Validators.required],
                situation:    [property.address_1 + ' ' + property.post_code],
                registration: ['', Validators.required],
                value:        ['', Validators.required],
              }),
          );
        });
      } else {
        this.form.removeControl('properties_table');
      }
    });

    this.form.controls.owner_of_movable_capital.valueChanges.subscribe(next => {
      if (next === 'yes') {
        //  Add form array
        this.form.addControl('other_assets', this.fb.array([]));
        this.form.addControl('value_date', this.fb.control('', Validators.required));

        //  Populate form array with data
        const propertiesTable = this.form.get('other_assets') as UntypedFormArray;
        this.formData.other_assets.map(asset => {
          propertiesTable.push(this.fb.group({
                company_name:       [asset.company_name, Validators.required],
                office:             [asset.office],
                securities_account: [asset.securities_account],
                estimated_value:    [asset.estimated_value],
              }),
          );
        });
      } else {
        this.form.removeControl('other_assets');
        this.form.removeControl('value_date');
      }
    });

    //  Number of workers
    this.form.controls.number_of_workers.valueChanges.subscribe(next => {
      if (+next > 0) {
        this.form.addControl('worker_names', this.fb.array([]));

        const workers = this.form.get('worker_names') as UntypedFormArray;
        workers.clear();
        for (let i = 0; i < +next; i++) {
          workers.push(this.fb.group({
            name:    ['', Validators.required],
            address: ['', Validators.required],
            email:   ['', [Validators.required, Validators.email]],
          }));
        }
      } else {
        this.form.removeControl('worker_names');
      }
    });
  }

  public getFormArray(creditorType: string) {
    return this.form.get(creditorType) as UntypedFormArray;
  }

  submitForm() {
    if (this.form.valid) {
      this.isSubmitting++;
      this.caseService.sendAEPData(this.caseId, this.form.value).pipe(finalize(() => this.isSubmitting--))
          .subscribe(next => {
            window.open(
                this.storageUrl + next.data.location,
                '_blank', // <- This is what makes it open in a new window.
            );
          });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
