<mat-dialog-content>
  <form [formGroup]="form" (submit)="submit()">
    <h5 class="modal-title">{{ 'USERS.editor.change-user-password' | translate }}</h5>
    <div class="mb-2">
      <app-input type="text" formControlName="password" [label]="'USERS.editor.change-user-password' | translate" fullWidth></app-input>
    </div>
    <div class="form-group">
      <button mat-raised-button mat-button color="primary" type="submit">{{'SHARED.save' | translate}}</button>
    </div>
  </form>
</mat-dialog-content>
