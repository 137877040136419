import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorReassignComponent } from '../creditor-editor/creditor-reassign/creditor-reassign.component';
import { CreditorService } from '../creditor.service';

@Component({
  selector:    'app-creditor-list',
  templateUrl: './creditor-list.component.html',
  styleUrls:   ['./creditor-list.component.scss'],
})
export class CreditorListComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public displayedColumns: string[] = [
    'id',
    'name',
    'active',
    'approved',
    'email',
    'phone_1',
    'phone_2',
    'address',
    'contact_1',
    'contact_2',
    'company_name',
    'actions',
  ];
  public isLoading                  = 0;
  public creditorsDataSource: MatTableDataSource<Creditor>;
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search                     = new UntypedFormControl('');

  constructor(
    public dialog: MatDialog,
    private creditorService: CreditorService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.getCreditors();
    this.search.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => this.getCreditors());
  }

  public openDeleteDialog(creditor: Creditor, $event): void {
    $event.stopPropagation();
    $event.preventDefault();
    const dialogRef = this.dialog.open(CreditorReassignComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '500px',
      data:      {
        deleteCreditor: creditor,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCreditors();
      }
    });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.getCreditors();
  }

  private getCreditors() {
    this.creditorsDataSource = new MatTableDataSource<Creditor>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };

    this.creditorService.index(data, [], ['case_creditors', 'case_assets']).pipe(finalize(() => this.isLoading--)).subscribe({
      next: res => {
        this.creditorsDataSource      = new MatTableDataSource<Creditor>(res.data);
        this.creditorsDataSource.sort = this.sort;
        this.paginatorConfig.length   = res.meta.total;
      }
    });
  }
}
