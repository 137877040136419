<div class="row">
  <div class="col col-md-6 m-auto">
    <mat-card>
      <mat-card-header>
        <div class="text-center w-100">
          <h5>{{ "AUTH.new-password.heading" | translate }}</h5>
        </div>
      </mat-card-header>
      <mat-card-content>
        <app-server-response [response]="serverResponse"></app-server-response>
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <!-- Current Password -->
          <app-input *ngIf="temporaryPasswordUpdate" type="password" formControlName="current_password"
                     [label]="'AUTH.new-password.form.current_password' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline">
          </app-input>
          <!-- New Password -->
          <app-input type="password" formControlName="password"
                     [label]="'AUTH.new-password.form.new_password' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline">
          </app-input>
          <mat-error *ngIf="form.get('password').value !== form.get('password_confirmation').value">
            {{ 'AUTH.new-password.form.errors.password_confirmation' | translate }}
          </mat-error>
          <!-- Password Confirmation -->
          <app-input type="password" formControlName="password_confirmation"
                     [label]="'AUTH.new-password.form.password_confirmation' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline">
          </app-input>
          <mat-error *ngIf="form.get('password').value !== form.get('password_confirmation').value">
            {{ 'AUTH.new-password.form.errors.password_confirmation' | translate }}
          </mat-error>
          <!-- Submit -->
          <div class="text-center pb-3">
            <button *ngIf="!sendingRequest" class="ms-2 btn client-button-correct" type="submit">
              {{ 'AUTH.new-password.form.submit' | translate }}
            </button>
          </div>
          <mat-spinner *ngIf="sendingRequest" diameter="25" class="ms-3"></mat-spinner>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
