import { Component, Input, OnInit } from '@angular/core';
import { CaseExpense } from '../../../../../../../_base-shared/models/Case/CaseExpense';

@Component({
  selector: 'app-expances-snapshot-list',
  templateUrl: './expances-snapshot-list.component.html',
  styleUrls: ['./expances-snapshot-list.component.scss']
})
export class ExpancesSnapshotListComponent implements OnInit{
  @Input() expenses: CaseExpense;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
