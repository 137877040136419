<mat-dialog-content>
  <h5 mat-dialog-title>{{ "CASES.single.request-payment" | translate }}</h5>
  <p>{{"CASES.single.request-payment-note" | translate}}</p>
  <div mat-dialog-content class="custom-card-wrap">
    <form *ngIf="formActive" [formGroup]="form">
      <div class="row">
        <div class="col-lg-6">
          <app-input type="number" formControlName="amount" [label]="'PAYMENTS.amount' | translate" [suffix]="'€'"
                     [fullWidth]="true">
          </app-input>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <div *ngIf="formActive" class="mb-3">
      <button mat-button color="primary" class="me-3" (click)="onNoClick()">{{'SHARED.cancel' | translate}}</button>
      <div class="dropleft d-inline-block">
        <button type="button" style="min-width: 120px" mat-raised-button color="primary" class="btn"
                [matMenuTriggerFor]="sendMenu">
          <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_left</mat-icon>
          {{"SHARED.send" | translate}}
        </button>
        <mat-menu #sendMenu="matMenu" class="sendMenu" yPosition="above">
          <a class="dropdown-item" (click)="sendRequest($event, ['email'])" href="#">Email</a>
          <a class="dropdown-item" (click)="sendRequest($event, ['sms'])" href="#">SMS</a>
          <a class="dropdown-item" (click)="sendRequest($event, ['email', 'sms'])" href="#">Email & SMS</a>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-dialog-content>
