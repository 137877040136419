<div class="card-body">
  <div class="row">
    <div class="col-6">
      <app-case-document-agent-file-uploader-v3 *ngIf="case" [case]="case">
      </app-case-document-agent-file-uploader-v3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-case-document-request-list *ngIf="case" [case]="case"
                                      [clientRole]="'client'"
                                      (requestedDocuments)="reloadRequestedDocList('client')">
      </app-case-document-request-list>
      <app-case-document-request-list *ngIf="case?.joint_application" [case]="case"
                                      [clientRole]="'partner'"
                                      (requestedDocuments)="reloadRequestedDocList('partner')">
      </app-case-document-request-list>
    </div>
  </div>
  <div class="row">
    <!-- Document Upload / Document List-->
    <div class="col-12 col-lg-6">
      <app-case-client-requested-document-list-v3 #clientRequestedDocs *ngIf="case" [case]="case"
                                                  [clientRole]="'client'"
                                                  [documentableType]="documentableType"
                                                  [documentableId]="documentableId"
                                                  (filePreviewRequested)="previewFile($event)"
                                                  [inputType]="'default'">
                                                  (fileChangedClientRole)="addUpdatedFileToDocList($event, 'clientList', 'partner')"
                                                  (filePreviewRequested)="previewFile($event)">
      </app-case-client-requested-document-list-v3>
      <app-case-client-requested-document-list-v3 #partnerRequestedDocs *ngIf="case?.joint_application" [case]="case"
                                                  [clientRole]="'partner'"
                                                  [documentableType]="documentableType"
                                                  [documentableId]="documentableId"
                                                  (filePreviewRequested)="previewFile($event)"
                                                  [inputType]="'default'">
                                                  (fileChangedClientRole)="addUpdatedFileToDocList($event, 'clientList', 'client')"
                                                  (filePreviewRequested)="previewFile($event)">
      </app-case-client-requested-document-list-v3>
      <app-case-court-document-list-v3 #courtClientDocs *ngIf="case" [case]="case"
                                       [clientRole]="'client'"
                                       [title]="'Court documents client'"
                                       [documentableType]="documentableType"
                                       [documentableId]="documentableId"
                                       (filePreviewRequested)="previewFile($event)"
                                       [inputType]="'dropzone'">
                                       (fileChangedClientRole)="addUpdatedFileToDocList($event, 'courtList', 'partner')"
                                       >
      </app-case-court-document-list-v3>
      <app-case-court-document-list-v3 #courtPartnerDocs *ngIf="case?.joint_application" [case]="case"
                                       [clientRole]="'partner'"
                                       [title]="'Court documents client 2'"
                                       [documentableType]="documentableType"
                                       [documentableId]="documentableId"
                                       (filePreviewRequested)="previewFile($event)"
                                       [inputType]="'dropzone'">
                                       (fileChangedClientRole)="addUpdatedFileToDocList($event, 'courtList', 'client')"
                                       (filePreviewRequested)="previewFile($event)">
      </app-case-court-document-list-v3>
      <app-case-application-document-list-v3 *ngIf="case" [case]="case" [clientRole]="'partner'"
                                             [title]="'CASES.single.court-documents' | translate"
                                             [documentableType]="documentableType"
                                             [documentableId]="documentableId"
                                             (filePreviewRequested)="previewFile($event)"
                                             [inputType]="'default'">
      </app-case-application-document-list-v3>
    </div>
    <!-- File Preview -->
    <div class="col-12 col-lg-6" *ngIf="previewFileType === 'object'; else photo" style="height: auto">
      <div *ngIf="previewFileUrl" style="height: 100%">
        <div [ngClass]="{'height-50' : true, 'sticky' : previewIsSticky}" style="height: 100%">
          <object [data]="previewFileUrl" type="application/pdf" width="100%" height="100%">
            <iframe [src]="previewFileUrl" width="100%" height="100%" style="border: none;">
              <p>
                Your browser does not support PDFs.
                <a [href]="previewFileUrl">Download the PDF</a>
              </p>
            </iframe>
          </object>
        </div>
      </div>
    </div>
    <ng-template #photo>
      <div class="col-12 col-lg-6 d-flex justify-content-center">
        <div [ngClass]="{'height-50' : true, 'sticky' : previewIsSticky}">
          <img [src]="previewFileUrl" alt="" class="img-fluid" style="max-height: 83vh">
        </div>
      </div>
    </ng-template>
  </div>
</div>

