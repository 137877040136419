<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h4>{{ 'CONFIG.dialer.trigger.header' | translate }}</h4>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <!-- Status trigger -->
      <div class="col-12">
        <app-dialer-status-trigger-list #statusTriggersTemplate [componentType]="'status'">
        </app-dialer-status-trigger-list>
      </div>
      <!-- Payment status trigger -->
      <div class="col-12 mt-3">
        <app-dialer-status-trigger-list #paymentStatusTriggersTemplate [componentType]="'payment_status'">
        </app-dialer-status-trigger-list>
      </div>
      <!-- Call status trigger -->
      <div class="col-12 mt-3">
        <app-dialer-status-trigger-list #callStatusTriggersTemplate [componentType]="'call_status'">
        </app-dialer-status-trigger-list>
      </div>

      <div class="col-12">
        <hr>
        <div class="d-flex justify-content-end">
          <app-spinner-btn class="ml-2 mt-1" type="button" (click)="submitForm()" [loading]="isSubmitting"
                           [name]="'SHARED.save' | translate">
          </app-spinner-btn>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
