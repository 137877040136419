import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { addMinutes, endOfDay, isSameMonth, startOfDay } from 'date-fns';
import { BehaviorSubject, pipe, Subject } from 'rxjs';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { TaskDetailComponent } from '../../task/task-detail/task-detail.component';
import { TaskService } from '../../task/task.service';
import { finalize } from 'rxjs/operators';

const colors: any = {
  red:    {
    primary:   '#ad2121',
    secondary: '#FAE3E3',
  },
  blue:   {
    primary:   '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary:   '#e3bc08',
    secondary: '#FDF1BA',
  },
  green:  {
    primary: '#04373d',
    // secondary: '#79a303',
  },
};

@Component({
  selector:    'app-task-dashboard',
  templateUrl: './task-dashboard.component.html',
  styleUrls:   ['./task-dashboard.component.scss'],
})
export class TaskDashboardComponent implements OnInit {
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;

  public authUser: User;
  public view: CalendarView             = CalendarView.Month;
  public CalendarView                   = CalendarView;
  public viewDate: Date                 = new Date();
  public modalData: {
    action: string;
    event: CalendarEvent;
  };
  public actions: CalendarEventAction[] = [
    {
      label:     '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick:   ({event}: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label:     '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick:   ({event}: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  public refresh: Subject<void>          = new Subject<void>(); // Used in calendar
  public reFetchTasks = new BehaviorSubject('');  //  Used to update tasks
  public events: CalendarEvent[]        = [];
  public activeDayIsOpen                = true;
  public selectedDayEvents;
  public isLoadingTasks = 0;

  constructor(private taskService: TaskService,
              private dialog: MatDialog,
              private globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.getUserTasks();

    this.reFetchTasks.subscribe(next => {
      if (next === 'reFetch') {
        this.getUserTasks(); // Fetch new tasks after task is completed
        // Still user needs to change days on calendar to proper show tasks (no refresh needed)
      }
    });
  }

  getUserTasks() {
    this.isLoadingTasks++;
    this.events = [];
    this.taskService.indexAllUserTasks(this.authUser.id, {select_all: true}).pipe(finalize(() => this.isLoadingTasks--)).subscribe(res => {
      res.data.forEach(event => {
        this.events.push({
          ...{
            start:   new Date(event.due_date),
            end:     addMinutes(new Date(event.due_date), 30),
            title:   event.name,
            color:   colors.green,
            actions: this.actions,
          },
          ...event,
        });
      });
      this.refresh.next(); // Refresh events in calendar
    });
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      // if (
      //   (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      //   events.length === 0
      // ) {
      //   this.activeDayIsOpen = false;
      // } else {
      //   this.activeDayIsOpen = true;
      // }
      this.viewDate          = date;
      this.selectedDayEvents = events;
    }
  }

  openTask(task) {
    const dialogRef = this.dialog.open(TaskDetailComponent, {
      width:     '40%',
      autoFocus: false,
      // minHeight: '500px',
      data: {
        task,
        reFetchTasks$: this.reFetchTasks
      },
    });
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end:   newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = {event, action};
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title:     'New event',
        start:     startOfDay(new Date()),
        end:       endOfDay(new Date()),
        color:     colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd:    true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
