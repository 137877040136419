import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseAsset } from '../../../../../_base-shared/models/Case/CaseAsset';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseAssetService extends MainBaseApiService {
  public index(caseId: number, relations: Array<string> = [], requestData = {}): Observable<LaravelResourceResponse<Array<CaseAsset>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseAsset>>>(this.apiUrl + '/cases/' + caseId + '/assets', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, caseAssets: Array<CaseAsset>) {
    return this.http.put<LaravelResourceResponse<Array<CaseAsset>>>(
      this.apiUrl + '/cases/' + caseId + '/assets', caseAssets,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
