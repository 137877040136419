<mat-dialog-content>
  <div class="container">
    <mat-card class="mat-elevation-z3">
      <mat-card-header>
        <mat-card-title class="mb-3">
          <h5>
            <span *ngIf="selectedProduct">{{ selectedProduct.name }}</span>
            {{ 'CASES.single.draft.demand_generator.heading' | translate }}
          </h5>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!-- Product Control -->
        <!--<app-input type="select" appearance="fill" class="m-2" [formControl]="productControl" [fullWidth]="false"
                   [label]="'Product'" [selectOptions]="products" selectValue="id" selectLabel="name"
                   (ngModelChange)="rebuildFormBasedOnProduct($event)">
        </app-input>-->
        <form *ngIf="form" [formGroup]="form" class="p-2" (ngSubmit)="submitForm(form)">
          <!-- Document Type -->
          <app-input type="select" appearance="fill" class="m-2" formControlName="document_type_slug"
                     [fullWidth]="true"
                     [label]="'CASES.single.draft.demand_generator.document_type' | translate"
                     [selectOptions]="productOptionOptions" selectValue="value"
                     selectLabel="label"
                     (ngModelChange)="changeFormForDocumentType($event)">
          </app-input>
          <!-- City -->
          <app-input type="text" formControlName="city" [label]="'ADDRESS.city' | translate" [fullWidth]="true">
          </app-input>
          <!-- Procurator -->
          <app-input type="text" formControlName="procurator"
                     [label]="'CASES.single.draft.demand_generator.procurator' | translate" [fullWidth]="true">
          </app-input>
          <mat-radio-group formControlName="attorney_accredited">
            <mat-radio-button value="yes">
              según se acreditará mediante apoderamiento “apud acta” en sede judicial cuando seamos requerido para ello
            </mat-radio-button>
            <mat-radio-button value="no">
              según consta acreditado con apoderamiento electrónico que aportamos
            </mat-radio-button>
          </mat-radio-group>
          <div [formGroupName]="'personal_circumstances'">
            <!-- Profession  -->
            <app-input type="text" formControlName="profession"
                       [label]="'CASES.single.draft.demand_generator.profession' | translate" [fullWidth]="true">
            </app-input>
          </div>
          <div [formGroupName]="'state_of_insolvency'">
            <div [formGroupName]="'overdue_obligations'">
              <mat-radio-group formControlName="ongoing_proceedings">
                <mat-radio-button value="no">
                  A fecha de la presentación de esta solicitud de concurso, no existen procedimientos
                  judiciales en curso en los que el deudor sea parte. <br> Aunque consideramos inminente la
                  notificación de Procedimientos Judiciales de reclamación de cantidad, así mismo como
                  las comunicaciones<br> de apremio de Organismos Públicos.
                </mat-radio-button>
                <mat-radio-button value="yes">
                  A fecha de la presentación de esta solicitud de concurso, si existen procedimientos
                  judiciales en curso en los que el deudor <br> sea parte, relacionados en el listado de
                  acreedores adjunto.
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div *ngIf="form.get('document_type_slug').value === 'demand-lso-te-with-assets'"
               formGroupName="contest_without_mass">
            <h5>Concurso sin masa</h5>
            <div formGroupName="one_owner_of_assets">
              1) En el presente procedimiento el solicitante es titular del siguiente bien:
              <!-- Include -->
              <app-input type="switch" formControlName="include"
                         [label]="'CASES.single.draft.demand_generator.include' | translate"></app-input>
              <ng-container *ngIf="form.get('contest_without_mass.one_owner_of_assets.include').value">
                <div *ngFor="let formGroup of oneOwnedAssets.controls let i = index"
                     class="ps-5" formArrayName="assets">
                  <div [formGroupName]="i" class="row">
                    <app-input class="col-11" type="text" formControlName="description"
                               [label]="'CASES.single.draft.demand_generator.description' | translate"
                               [fullWidth]="true">
                    </app-input>
                    <button class="col-1" mat-icon-button color="warn" (click)="oneOwnedAssets.removeAt(i)">
                      <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-end pe-2">
                  <button type="button" mat-raised-button color="primary" (click)="addOneOwnedAsset()">
                    + {{'CASES.single.draft.demand_generator.add_asset' | translate}}
                  </button>
                </div>
              </ng-container>
            </div>
            <div formGroupName="two_owner_of_assets">
              2) En el presente procedimiento el solicitante es titular del siguiente bien:
              <!-- Include -->
              <app-input type="switch" formControlName="include"
                         [label]="'CASES.single.draft.demand_generator.include' | translate"></app-input>
              <ng-container *ngIf="form.get('contest_without_mass.two_owner_of_assets.include').value">
                <div *ngFor="let formGroup of twoOwnedAssets.controls let i = index"
                     class="ps-5" formArrayName="assets">
                  <div class="row" [formGroupName]="i">
                    <app-input class="col-8" type="text" formControlName="description"
                               [label]="'CASES.single.draft.demand_generator.description' | translate"
                               [fullWidth]="true">
                    </app-input>
                    <app-input class="col-3" type="text" formControlName="burden"
                               [label]="'CASES.single.draft.demand_generator.Burden' | translate" [fullWidth]="true">
                    </app-input>
                    <button class="col-1" mat-icon-button color="warn" (click)="twoOwnedAssets.removeAt(i)">
                      <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-end pe-2">
                  <button type="button" mat-raised-button color="primary" (click)="addTwoOwnedAsset()">
                    + {{'CASES.single.draft.demand_generator.add_asset' | translate}}
                  </button>
                </div>
              </ng-container>
            </div>
            <div formGroupName="three_owner_of_assets">
              3) En el presente procedimiento, el solicitante es titular del siguiente bien:
              <!-- Include -->
              <app-input type="switch" formControlName="include"
                         [label]="'CASES.single.draft.demand_generator.include' | translate"></app-input>
              <app-input type="number" formControlName="value" [label]="'CASES.single.value' | translate"
                         [fullWidth]="true"></app-input>
              <ng-container *ngIf="form.get('contest_without_mass.three_owner_of_assets.include').value">
                <div *ngFor="let formGroup of threeOwnedAssets.controls let i = index"
                     class="ps-5" formArrayName="assets">
                  <div class="row" [formGroupName]="i">
                    <app-input class="col-11" type="text" formControlName="description"
                               [label]="'CASES.single.draft.demand_generator.description' | translate"
                               [fullWidth]="true">
                    </app-input>
                    <button class="col-1" mat-icon-button color="warn" (click)="threeOwnedAssets.removeAt(i)">
                      <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-end pe-2">
                  <button type="button" mat-raised-button color="primary" (click)="addThreeOwnedAsset()">
                    + {{'CASES.single.draft.demand_generator.add_asset' | translate}}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- Non Liquidable Assets -->
          <div *ngIf="form.get('document_type_slug').value === 'demand-lso-lq' || form.get('document_type_slug').value === 'demand-lso-pp'"
               formGroupName="liquidation_of_assets">
            <h5>{{'CASES.single.draft.demand_generator.non_liquidable_assets' | translate}}</h5>
            <div class="ps-5 pe-2" *ngFor="let formGroup of nonLiquidableAssets.controls let i = index"
                 formArrayName="non_liquidable_assets">
              <ng-container [formGroupName]="i">
                <app-input type="text" formControlName="name" [label]="'CASES.single.assets' | translate"
                           [fullWidth]="true">
                </app-input>
              </ng-container>
            </div>
            <div class="d-flex justify-content-end pe-2">
              <button type="button" mat-raised-button color="primary" (click)="addNonLiquidableAsset()">
                + {{'CASES.single.draft.demand_generator.add_asset' | translate}}
              </button>
            </div>
          </div>
          <!-- Signature Date -->
          <app-input type="mat-datepicker" formControlName="signature_date" appearance="fill"
                     [label]="'CASES.single.draft.demand_generator.signature_date' | translate" [fullWidth]="true">
          </app-input>
          <!-- Submit -->
          <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" class="ms-2" [matMenuTriggerFor]="assetList"
                    aria-label="Send notification menu" type="button">
              Generate
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #assetList="matMenu">
              <button mat-menu-item (click)="chooseDocsType('doc', form)"
                      value="word">
                <span>{{"CASES.single.draft.demand_generator.generate_word" | translate}}</span> </button>
              <button mat-menu-item (click)="chooseDocsType('pdf', form)"
                      value="word">
                <span>{{"CASES.single.draft.demand_generator.generate_pdf" | translate}}</span> </button>
            </mat-menu>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
