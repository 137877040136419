import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { DialerList } from '../../../../../_base-shared/models/Dialer/DialerList';
import { DialerTrigger } from '../../../../../_base-shared/models/Dialer/DialerTrigger';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DialerService extends MainBaseApiService {

  public indexTriggers(relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<DialerTrigger>>>(this.apiUrl + '/dialer-triggers', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public upsertTriggers(params) {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/dialer-triggers/save-dialer-triggers', params)
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexLists(data){
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<DialerList>>>(this.apiUrl + '/dialer-lists', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public upsertLists(data) {
    return this.http.patch<LaravelResourceResponse>(this.apiUrl + '/dialer-lists', data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
