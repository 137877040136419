<mat-dialog-content>
  <h4 class="text-center">{{ 'NOTIFICATION.detail.title' | translate }}</h4>
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading && notification">
    <div>
      <div class="task-card-background mt-2 mb-2">
        <h5>{{ 'NOTIFICATION.notification-modal.form.subject' | translate }}<b>: {{ notification.data.subject }}</b></h5>
        <h5>{{ 'NOTIFICATION.notification-modal.form.body' | translate }}:</h5>
        <div [innerHTML]="notification.data.message"></div>
      </div>
    </div>
    <div class="task-actions text-end">
      <button *ngIf="!notification.read_at" mat-raised-button color="primary" mat-dialog-close
              (click)="markAsRead(notification)">
        {{ 'NOTIFICATION.detail.actions.mark-as-read' | translate }}
      </button>
      <small *ngIf="notification.read_at">
        {{ "NOTIFICATION.list.table.read_at" | translate }} {{ notification.read_at | date:'dd/MM/yy HH:mm' }}
      </small>
    </div>
  </div>
</mat-dialog-content>
