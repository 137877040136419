<div class="row">
  <div class="col-12">
    <h4>{{"CONFIG.notifications.notifications" | translate}}</h4>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      {{"CONFIG.notifications.add-notification" | translate}}</button>
    <button mat-raised-button color="primary" routerLink="trigger" type="button" class="ms-5">
      {{"CONFIG.notifications.triggers" | translate}}
    </button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5">
    <app-input type="text" [formControl]="search" [label]="'SHARED.search' | translate"
               [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true"
               [prefix]="'search'" [prefixType]="'icon'" (ngModelChange)="onChange()"></app-input>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.title" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="body">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.content" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.body}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.notifications.type" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
            <a class="pe-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
            <a href="#" (click)="openDeleteDialog(element.id, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1"  style="padding: 50px">
    <h5 class="text-center">{{ "CONFIG.notifications.no-notifications" | translate }}</h5>
  </div>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
