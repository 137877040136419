<div class="d-flex justify-content-between align-items-center pb-2 flex-wrap">
  <div class="row mt-3">
    <div class="col" style="min-width: 300px">
      <div>
        <span>{{ 'CASES.single.payments.balance_overview.filtered_balance.progress' | translate }}:</span>
        <span class="fw-bold">{{ +(filteredInstallmentsBalance.totalPaid) | number: '1.2-2' }}
          € / {{ +(filteredInstallmentsBalance.totalToPay) | number: '1.2-2' }} € </span>
      </div>
      <div>
        <span>{{ 'CASES.single.payments.balance_overview.filtered_balance.balance' | translate }}:</span>
        <span class="fw-bold">{{
            +(filteredInstallmentsBalance.totalToPay) -
            +(filteredInstallmentsBalance.totalPaid) | number: '1.2-2'
          }} €</span>
      </div>
      <!--<div class="progress total-progress" style="max-width: 300px">-->
      <!--  <div class="progress-bar progress-bar-striped" role="progressbar"-->
      <!--       [style]="{width: getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay) + '%'}"-->
      <!--       [attr.aria-valuenow]="getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay)"-->
      <!--       [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">-->
      <!--    {{ getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay) }}%-->
      <!--  </div>-->
      <!--</div>-->
    </div>
    <div *ngIf="componentType === 'admin'" class="col" style="min-width: 500px">
      <div class="row">
        <div class="col">
          <span>{{ 'CASES.single.payments.balance_overview.debt_plan_balance' |translate }}:</span>
          <span class="fw-bold">
            {{ (debtPlanInstallmentsBalance.totalToPay - debtPlanInstallmentsBalance.totalPaid) | number: '1.2-2' }} €
          </span>
        </div>
        <div class="col">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay) + '%'}"
                 [attr.aria-valuenow]="getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
              {{ getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay) }}%
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <span>{{ 'CASES.single.payments.balance_overview.additional_plans_balance' | translate }}:</span>
          <span class="fw-bold">
          {{
              (additionalPlansInstallmentsBalance.totalToPay -
                additionalPlansInstallmentsBalance.totalPaid) | number: '1.2-2'
            }} €
        </span>
        </div>
        <div class="col">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent(additionalPlansInstallmentsBalance.totalPaid, additionalPlansInstallmentsBalance.totalToPay) + '%'}"
                 [attr.aria-valuenow]="getPercent(additionalPlansInstallmentsBalance.totalPaid, additionalPlansInstallmentsBalance.totalToPay)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
              {{
                getPercent(additionalPlansInstallmentsBalance.totalPaid,
                  additionalPlansInstallmentsBalance.totalToPay)
              }}%
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <span>{{ 'CASES.single.payments.balance_overview.total_balance' | translate }}:</span>
          <span class="fw-bold">
          {{
              ((debtPlanInstallmentsBalance.totalToPay + additionalPlansInstallmentsBalance.totalToPay) -
                (debtPlanInstallmentsBalance.totalPaid +
                  additionalPlansInstallmentsBalance.totalPaid)) | number: '1.2-2'
            }} €
        </span>
        </div>
        <div class="col">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent(debtPlanInstallmentsBalance.totalPaid +
            additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
            additionalPlansInstallmentsBalance.totalToPay) + '%'}"
                 [attr.aria-valuenow]="getPercent(debtPlanInstallmentsBalance.totalPaid +
            additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
            additionalPlansInstallmentsBalance.totalToPay)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
              {{
                getPercent(debtPlanInstallmentsBalance.totalPaid +
                  additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
                  additionalPlansInstallmentsBalance.totalToPay)
              }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-end payment-action-buttons">
    <div *ngIf="componentType === 'admin'" class="admin-payment-actions">
      <button (click)="openPaymentPlanGenerationModal()" mat-raised-button color="primary" class="me-2">
        {{ 'CASES.single.payments.actions.create_additional_payment_plan' | translate }}
      </button>
      <div class="form-group d-inline-block me-2">
        <select name="payment_method" id="payment-method" class="form-control" (change)="changePaymentMethod($event)">
          <option *ngFor="let method of paymentMethods" value="{{method.slug}}"
                  style="font-family: 'Roboto', 'Helvetica', 'Neue', 'sans-serif'"
                  [selected]="paymentMethodIsSelected(method.id)">{{ method.name }}
          </option>
        </select>
      </div>
      <button *ngIf="upcomingInstallments" mat-raised-button color="primary" class="me-2"
              (click)="requestPaymentDialog('card', 'oppwa')"
              [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeSlugsControl.value.length > 1 && installmentsPlanTypeSlugsControl.value.includes('debt_plan'))">
        <span>{{ "CASES.single.payments.actions.request_payment.label" | translate }}</span>
      </button>
      <button (click)="rebuildInstallmentPlanModal()" mat-raised-button color="primary"
              class="me-2" [disabled]="!onlyDebtPlanSelected">
        {{ "CASES.single.rebuild-plan" | translate }}
      </button>
      <button *ngIf="upcomingInstallments" (click)="recordPaymentDialog(false)" mat-raised-button color="primary"
              class="me-2" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeSlugsControl.value.length > 1 && installmentsPlanTypeSlugsControl.value.includes('debt_plan'))">
        {{ "CASES.single.record-payment" | translate }}
      </button>
      <button *ngIf="upcomingInstallments" (click)="recordPaymentDialog(true)" mat-raised-button color="primary"
              class="me-2" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeSlugsControl.value.length > 1 && installmentsPlanTypeSlugsControl.value.includes('debt_plan'))">
        {{ "CASES.single.relocate-payment" | translate }}
      </button>
      <div class="d-inline-block" matTooltip="No upcoming payment available"
           [matTooltipDisabled]="case.terms.length > 0">
        <button *ngIf="upcomingInstallments" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeSlugsControl.value.length > 1 && installmentsPlanTypeSlugsControl.value.includes('debt_plan'))"
                (click)="openCardModalV2()" mat-raised-button color="primary">
          {{ "CASES.single.take-payment" | translate }}
        </button>
      </div>
      <button [matMenuTriggerFor]="remittanceRequestMenu" mat-raised-button color="primary" class="ms-2">
        {{ "CASES.single.remittance-request-text" | translate }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #remittanceRequestMenu>
        <button mat-menu-item (click)="generateRemittanceRequest()"><span>{{ "SHARED.generate" | translate }}</span>
        </button>
      </mat-menu>
      <button [matMenuTriggerFor]="clientInstallmentMenu" mat-raised-button color="primary" class="ms-2">
        {{ 'CASES.single.client-installment-list-btn' | translate }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #clientInstallmentMenu>
        <button mat-menu-item (click)="downloadClientInstallment()">
          <span>{{ 'CASES.single.download' | translate }}</span>
        </button>
        <a mat-menu-item [routerLink]="['/cases', case?.id, 'payments', case.uuid, 'preview-client-installment-list']"
           target="_blank">
          <span>{{ 'CASES.single.client-installment-list-preview' | translate }}</span>
        </a>
        <button mat-menu-item (click)="sendPaymentPlan()">
          <span>{{ 'CASES.single.client-installment-list-send' | translate }}</span>
        </button>
        <button mat-menu-item (click)="openScheduledPaymentPlan()"
                *ngIf="authUser.id === 1 || authUser.id === 22 || case?.id === 64684">
          <span>{{ 'PAYMENTS.scheduled-payments.heading' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="mb-4" style="box-shadow: none">
  <div class="card-header" style="border: 1px solid #e4e4e4; position: relative">
    <app-input type="select" [formControl]="installmentsPlanTypeGroupsControl" appearance="fill" multiple
               fullWidth="false" [label]="'PAYMENTS.list.filters.payment_plan_type.label' | translate"
               [selectOptions]="paymentPlanTypeGroups" [selectLabel]="'label'" [selectValue]="'value'"
               [showClear]="false"
               style="position: absolute; left: 10px; top: 0" [hidden]="componentType === 'distribution'">
    </app-input>
    <pre>{{ installmentsPlanTypeGroupsControl.value }}</pre>
    <pre>{{ installmentsPlanTypeSlugsControl.value }}</pre>
    <h4 class="text-center m-0">{{ "CASES.single.upcoming-payments" | translate }}</h4>
    <div style="position: absolute; right: 10px; top: 20px">
      <mat-slide-toggle color="primary" (change)="showHideUnpaid($event)">
        {{ "PAYMENTS.show-only-unpaid" | translate }}
      </mat-slide-toggle>
    </div>
  </div>
  <!--Bulk actions -->
  <button mat-raised-button color="primary" class="mb-2" [disabled]="selectionPaymentsDisabled"
          [matMenuTriggerFor]="menuPayment" [ngClass]="{'mt-4': selectionPaymentsDisabled}">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuPayment="matMenu">
    <!--Delete -->
    <div [title]="selectionPaymentsDisabled ? 'One or more selected payments does not have this action available' : ''">
      <a mat-menu-item class="dropdown-item" href="#" [disabled]="selectionPaymentsDisabled"
         (click)="deletePayment($event, selectionPayments)">
        Delete
      </a>
    </div>
  </mat-menu>

  <div class="card-body p-0">
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
      <table style="width: 100%" mat-table matSort (matSortChange)="sortData($event)"
             [dataSource]="showOnlyUnpaid ? dataSourceUnpaid : dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource) : null"
              color="primary"
              [checked]="selectionPayments.hasValue() && isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)"
              [indeterminate]="selectionPayments.hasValue() && !isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? selectionPayments.toggle(row) : null"
                          [checked]="selectionPayments.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="client-name">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{
              element.case.client.first_name + ' ' +
              element.case.client.last_name
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.email" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.case.client.email }}</td>
        </ng-container>

        <ng-container matColumnDef="next-payment">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.payment-schedule" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.active">
              <span>{{ element.term_date | toDateObject | date }}</span>
              <p class="m-0" style="color: red"
                 *ngIf="element.amount_paid < element.amount && (element.term_date | toDateObject) < now">
                <small>{{ "PAYMENTS.missed-payment" | translate }}</small>
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date-paid">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.date-paid" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ element.date_paid | toDateObject | date }}
            <ng-container *ngIf="element.date_paid">
              <p class="m-0" style="color: green" *ngIf="element.amount_paid === element.amount">
                <small>{{ "PAYMENTS.paid" | translate }}</small>
              </p>
              <p class="m-0" style="color: orange"
                 *ngIf="element.amount_paid < element.amount && element.amount_paid > 0">
                <small>{{ "PAYMENTS.partial-paid" | translate }}</small>
              </p>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <small class="font-weight-lighter">{{ +element.amount_paid | number: '1.2-2' }}€
              / {{ +element.amount | number: '1.2-2' }}€ </small>
            <span class="fw-bold">- {{ +(element.amount) - +(element.amount_paid) | number: '1.2-2' }}€</span>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercent(element.amount_paid, element.amount)  + '%'}"
                   [attr.aria-valuenow]="getPercent(element.amount_paid, element.amount)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{
                  getPercent(element.amount_paid,
                    element.amount)
                }}%
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.active" mat-stroked-button [matMenuTriggerFor]="menu">
              {{ "SHARED.actions" | translate }}
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item class="dropdown-item" (click)="saveTermId(element)"
                      [disabled]="element.plan_schedule_id">
                {{ 'PAYMENTS.change-date' | translate }}
              </button>
              <button *ngIf="element.case_payment_plan.type.default"
                      mat-menu-item class="dropdown-item" [disabled]="element.plan_schedule_id"
                      (click)="openChangeAmountModal(element)">
                {{ 'PAYMENTS.change-value' | translate }}
              </button>
              <button *ngIf="!element.case_payment_plan.type.default" mat-menu-item class="dropdown-item"
                      [disabled]="element.plan_schedule_id" (click)="openAdditionalAmountEditorModal(element)">
                {{ 'PAYMENTS.change-additional-value' | translate }}
              </button>
              <a *ngIf="authUser.role_id === 5 && !element.plan_schedule_id &&
               (!element.case_payment_plan.type.default && element.amount_paid === 0)"
                 mat-menu-item class="dropdown-item clickable" (click)="deleteInstallment(element.id)">
                {{ 'CASES.single.payments.installment_list.table.actions.delete' | translate }}
              </a>
              <a *ngIf="authUser.role_id === 5 && !element.plan_schedule_id &&
               (element.case_payment_plan.type.default && element.amount > 0 && (element.amount !== element.amount_paid))"
                 mat-menu-item class="dropdown-item clickable" (click)="markAsPromo(element)">
                {{ 'CASES.single.payments.installment_list.table.actions.mark_as_promo' | translate }}
              </a>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PAYMENTS.label" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.case_payment_plan.type.name }}<br>
            {{ element.name }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h5 class="text-center">{{ "PAYMENTS.no-payments" | translate }}</h5>
      </div>

      <mat-paginator
        [length]="paginatorConfig.length"
        [pageSize]="paginatorConfig.pageSize"
        [pageSizeOptions]="[5, 10, 20, 25]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
