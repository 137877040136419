import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as cardValidation from 'creditcards';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../contracts/common.interface';
import { Case } from '../../../models/Case/Case';
import { PaymentCard } from '../../../models/Payment/PaymentCard';
import { PaymentRequest } from '../../../models/Payment/PaymentRequest';
import { PaymentTerm } from '../../../models/Payment/PaymentTerm';
import { User } from '../../../models/User/User';
import { ScriptLoaderService } from '../../../services/script-loader.service';
import { CustomValidators } from '../../../validators/custom.validators';

@Component({
  selector:    'app-base-payment-handler',
  templateUrl: './base-payment-handler.component.html',
  styleUrls:   ['./base-payment-handler.component.scss']
})
export class BasePaymentHandlerComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() paymentRequest: PaymentRequest;
  @Input() amountToCharge                   = 0;
  @Input() paymentPlanType: 'debt_plan' | 'additional_plans';
  @Input() installment: PaymentTerm;
  @Input() paymentCards: Array<PaymentCard> = [];
  @Input() editableAmount: boolean;
  @Input() autoRedirect                     = true;
  @Input() redirectNewTab                   = true;
  @Input() clientRole: 'client' | 'partner';

  @ViewChild('paymentFormRef') paymentFormRef: ElementRef;

  public targetClient: User;
  public authUser: User;
  public desktopVersion: boolean;
  public form: FormGroup;
  public redirectConfirmForm: FormGroup;
  public restCardForm: FormGroup;
  public paymentProcessorSlug: 'redsys' | 'oppwa';
  public paymentMethods: Array<AppSelectOption>;
  public dueDate: Date;
  public isLoading                             = 0;
  public isSubmittingNewCardViaRest: boolean;
  public showResponseHandler: boolean;
  public showRestCardForm: boolean;
  public showOppwaScriptForm: boolean;
  public confirmFormTarget: string;
  public fetchingCards: boolean;
  public redirectSuccess: string;
  public redirectError: string;
  public redirectCancelled: string;
  protected currentLanguage: 'en' | 'es';
  protected subscriptions: Array<Subscription> = [];

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              protected dialog: MatDialog,
              protected breakpointObserver: BreakpointObserver,
              protected translate: TranslateService,
              protected toastr: ToastrService,
              protected scriptLoader: ScriptLoaderService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.paymentProcessorSlug = 'oppwa';

    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
      this.desktopVersion = result.matches;
    });

    this.translate.onLangChange.subscribe(next => {
      this.currentLanguage = next.lang === 'en' ? 'en' : 'es';
    });

    this.redirectConfirmForm = this.fb.group({
      merchant_parameters: [null, [Validators.required]],
      signature_version:   [null, [Validators.required]],
      signature:           [null, [Validators.required]],
    });

    if (this.paymentProcessorSlug === 'oppwa') {
      this.paymentMethods = [
        {label: 'Card', value: 'card'},
        // {label: 'Bizum', value: 'bizum'},
        // {label: 'Wire', value: 'unnax'}
        // {label: 'Arcopay', value: 'arcopay'}
      ];
    } else {
      this.paymentMethods = [
        {label: 'Card', value: 'card'},
        {label: 'Bizum', value: 'bizum'},
      ];
      if (this.authUser?.id === 1) {
        this.paymentMethods.push({label: 'Wire', value: 'unnax'});
        this.paymentMethods.push({label: 'Arcopay', value: 'arcopay'});
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public parseCardNumber(newCardNumber: string): void {
    console.log('parsing card number');
    // const allowedCardTypes = cardValidation.withTypes(['Visa', 'Mastercard']);
    const allowedCardTypes = ['VISA', 'MASTERCARD'];
    let cardType           = cardValidation.card.type(newCardNumber, true);
    cardType               = cardType ? cardType.toUpperCase() : cardType;
    console.log(cardType);
    if (cardType && allowedCardTypes.includes(cardType)) {
      this.restCardForm.get('brand').patchValue(cardType);
    } else {
      this.restCardForm.get('brand').patchValue(null);
    }
    const parseValue  = cardValidation.card.parse(newCardNumber);
    const formatValue = cardValidation.card.format(parseValue);
    this.restCardForm.get('card_number').patchValue(formatValue, {emitEvent: false});
    this.restCardForm.get('card_number').updateValueAndValidity({emitEvent: false});
    this.restCardForm.get('brand').updateValueAndValidity();
  }

  protected fetchPaymentCards(client: User, clientCase: Case, amount: number, maxPayableAmount: number, isMotoPayment: boolean): void {
    this.paymentCards = [];
    this.buildForm(client, amount, maxPayableAmount, true, '', '', '');
  }

  public submitForm(): void {
    if (this.form.invalid) {
      console.log(this.form.errors);
      this.form.markAllAsTouched();
      return;
    }
    this.submitFormToApi(this.case.client.uuid, this.case.uuid, this.form.value);
  }

  public submitNewCardPayment(): void {
    if (this.restCardForm.invalid) {
      this.restCardForm.markAllAsTouched();
      return;
    }
    this.submitNewCardFormToApi(this.case.client.uuid, this.case.uuid, this.restCardForm.value);
  }

  protected submitFormToApi(userUuid: string, caseUuid: string, formValue): void {
    throw new Error('Implement');
  }

  protected submitNewCardFormToApi(userUuid: string, caseUuid: string, formValue): void {
    throw new Error('Implement');
  }

  protected makePayment(observable: Observable<any>): void {
    this.isLoading++;
    this.subscriptions.push(
      observable.pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          console.log(result);
          if (result.data.action_type === 'redirect') {
            this.paymentProcessorSlug = 'redsys';
            if (this.form.get('payment_method_slug').value === 'unnax') {
              window.location.href = result.data.action_target;

              return;
            }
            if (this.form.get('payment_method_slug').value === 'arcopay') {
              window.location.href = result.data.action_target;

              return;
            }
            this.redirectConfirmForm = this.fb.group({
              merchant_parameters: [result.data.merchant_parameters, [Validators.required]],
              signature_version:   [result.data.signature_version, [Validators.required]],
              signature:           [result.data.signature, [Validators.required]],
            });
            this.confirmFormTarget   = result.data.action_target;
            this.handleRedirectForm();
          } else if (result.data.action_type === 'redirect_script') {
            this.paymentProcessorSlug = 'oppwa';
            this.scriptLoader.loadScriptByName('OppwaWidget', {src: result.data.action_target}).subscribe(
              () => {
                console.log('oppwa script loaded dynamically');
                this.confirmFormTarget   = result.data.result_url;
                this.autoRedirect        = false;
                this.showResponseHandler = true;
                this.showOppwaScriptForm = true;
              }
            );
          } else if (result.data.action_type === 'rest') {
            if (this.form.get('payment_method_slug').value === 'card') {
              if (this.form.get('selected_card_id').value) {
                this.handleComponentDestruction(this.redirectSuccess);
              } else {
                const transactionableType = result.data.transactionable_type;
                const transactionableUuid = result.data.transactionable_uuid;
                this.buildRestCardForm(this.case.client, transactionableType, transactionableUuid);
              }
            }
            if (this.form.get('payment_method_slug').value === 'bizum') {
              this.toastr.success(this.translate.instant('SHARED-COMPONENTS.payment_handler.payment_methods.bizum.response.awaiting_confirmation'));
            }
          }
        },
        error => {
          this.handlePaymentRequestErrorResponse(error, this.form);
        }
      )
    );
  }

  protected makeNewCardPayment(observable: Observable<any>): void {
    this.isSubmittingNewCardViaRest = true;
    this.restCardForm.disable();
    this.subscriptions.push(
      observable.pipe(finalize(() => this.isSubmittingNewCardViaRest = false)).subscribe(
        result => {
          if (result.data.success) {
            this.successfulPaymentMsg();
            if (this.form.get('redirect_url_success').value) {
              window.location.href = this.form.get('redirect_url_success').value;
            }
          } else {
            if (this.form.get('redirect_url_error').value) {
              window.location.href = this.form.get('redirect_url_error').value;
            } else {
              this.warnGenericError();
            }
          }
        },
        error => {
          this.restCardForm.enable();
          this.handlePaymentRequestErrorResponse(error, this.form);
        }
      )
    );
  }

  protected handleRedirectForm(): void {
    if (this.autoRedirect) {
      setTimeout(() => {
        this.paymentFormRef.nativeElement.submit();
      }, 100);
    } else {
      this.showResponseHandler = true;
    }
  }

  public resetCardForm(): void {
    this.showResponseHandler = false;
    this.redirectConfirmForm.reset();
  }

  protected buildForm(
    client: User,
    chargeAmount: number,
    maxPayableAmount: number,
    isMotoPayment: boolean,
    redirectSuccess: string,
    redirectError: string,
    redirectCancelled: string,
  ): void {
    let selectedCardId = null;
    if (this.paymentCards?.length) {
      const defaultCard = this.paymentCards.find(paymentCard => paymentCard.default);
      if (defaultCard) {
        selectedCardId = defaultCard.id;
      } else {
        selectedCardId = this.paymentCards[0].id;
      }
    }
    this.form = this.fb.group({
      plan_type:              [this.paymentPlanType, Validators.required],
      amount:                 [chargeAmount, [Validators.required, Validators.min(0.01), Validators.max(maxPayableAmount)]],
      payment_method_slug:    ['card', [Validators.required]],
      payment_request_uuid:   [this.paymentRequest?.uuid, []],
      moto_payment:           [isMotoPayment, [Validators.required]],
      selected_card_id:       [selectedCardId],
      save_card:              [true],
      bizum:                  this.fb.group({
        first_name: [client.first_name],
        last_name:  [client.last_name],
        phone:      [client.phone],
      }),
      unnax:                  this.fb.group({
        first_name: [client.first_name],
        last_name:  [client.last_name],
      }),
      arcopay:                this.fb.group({
        customer_full_name: [client.first_name + (client.last_name ? ' ' + client.last_name : '')],
        customer_iban:      [],
      }),
      privacy_policy:         [true, [Validators.requiredTrue]],
      redirect_url_success:   [redirectSuccess],
      redirect_url_error:     [redirectError],
      redirect_url_cancelled: [redirectCancelled],
    });


    this.form.get('amount').valueChanges.subscribe(newAmount => this.amountToCharge = newAmount);

    this.form.get('payment_method_slug').valueChanges.subscribe(
      result => {
        // Card defaults
        if (result === 'card') {
          this.form.get('save_card').patchValue(true);
          this.form.get('selected_card_id').patchValue(null);
        }

        // Bizum defaults
        if (result === 'bizum') {
          this.form.get('bizum.first_name').patchValue(client.first_name);
          this.form.get('bizum.last_name').patchValue(client.last_name);
          this.form.get('bizum.phone').patchValue(client.phone);
          this.form.get('bizum.first_name').setValidators([Validators.required]);
          this.form.get('bizum.last_name').setValidators([Validators.required]);
          this.form.get('bizum.phone').setValidators([
            Validators.required,
            Validators.maxLength(9),
            Validators.minLength(9),
            Validators.pattern('(6|7|9)([0-9])\\w+')
          ]);
        }

        // Unnax defaults
        if (result === 'unnax') {
          this.form.get('unnax.first_name').patchValue(client.first_name);
          this.form.get('unnax.last_name').patchValue(client.last_name);
        }

        // Arcopay defaults
        if (result === 'arcopay') {
          this.form.get('arcopay.customer_full_name')
            .patchValue(client.first_name + (client.last_name ? ' ' + client.last_name : ''));
          this.form.get('arcopay.customer_iban').patchValue(null);
          this.form.get('arcopay.customer_full_name').setValidators([Validators.required]);
        }

        // Cleanup
        // ------------
        // Card cleanup
        if (result !== 'card') {
          this.form.get('save_card').patchValue(false);
          this.form.get('selected_card_id').patchValue(null);
        }
        // Bizum cleanup
        if (result !== 'bizum') {
          this.form.get('bizum.first_name').setValidators([]);
          this.form.get('bizum.last_name').setValidators([]);
          this.form.get('bizum.phone').setValidators([]);
          this.form.get('bizum.first_name').patchValue(null);
          this.form.get('bizum.last_name').patchValue(null);
          this.form.get('bizum.phone').patchValue(null);
        }
        // Unnax cleanup
        if (result !== 'unnax') {
          this.form.get('unnax.first_name').setValidators([]);
          this.form.get('unnax.last_name').setValidators([]);
        }
        // Arcopay cleanup
        if (result !== 'arcopay') {
          this.form.get('arcopay.customer_full_name').setValidators([]);
        }
      }
    );
  }

  protected mapPaymentCards(paymentCards: Array<PaymentCard>): Array<PaymentCard> {
    return paymentCards.map(card => {
      const expDateObj         = DateTime.fromFormat(
        `${card.card_exp_month}${card.card_exp_year}`,
        'MMyyyy'
      ).startOf('month');
      card.expires_at          = expDateObj.endOf('month').toISO();
      const expirationDateDiff = expDateObj.diffNow('months').months;

      if (expirationDateDiff <= 4) {
        card.expire = 'soon';
      }

      if (expirationDateDiff < 0) {
        card.expire = 'expired';
      }

      card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin);

      return card;
    });
  }

  protected handlePaymentRequestErrorResponse(response, form: FormGroup): void {
    console.log(response);
    if (response.status >= 400 && response.status < 500) {
      if (form.get('payment_method_slug').value === 'card') {
        return this.warnCardNotAvailable();
      }
      if (form.get('payment_method_slug').value === 'bizum') {
        return this.warnBizumNotAvailable(form.get('bizum.phone').value);
      }
    }
    return this.warnGenericError();
  }

  protected warnGenericError(): void {
    Swal.fire({
      title:              this.translate.instant('CLIENT.auth-payment.error_msg'),
      text:               this.translate.instant('SHARED.went-wrong'),
      icon:               'error',
      showCancelButton:   false,
      showConfirmButton:  true,
      confirmButtonColor: '#886ab5',
    });
  }

  protected warnCardNotAvailable(): void {
    Swal.fire({
      title:              this.translate.instant('CLIENT.auth-payment.error_msg'),
      text:               this.translate.instant('SHARED-COMPONENTS.payment_handler.payment_methods.card.response.failed_to_charge'),
      icon:               'error',
      showCancelButton:   false,
      showConfirmButton:  true,
      confirmButtonColor: '#886ab5',
    });
  }

  protected warnBizumNotAvailable(phoneNumber: string): void {
    Swal.fire({
      title:              this.translate.instant('CLIENT.auth-payment.error_msg'),
      text:               this.translate.instant('SHARED-COMPONENTS.payment_handler.payment_methods.bizum.response.not_available', {mobile: phoneNumber}),
      icon:               'error',
      showCancelButton:   false,
      showConfirmButton:  true,
      confirmButtonColor: '#886ab5',
    });
  }

  protected successfulPaymentMsg(): void {
    Swal.fire({
      title:              this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.message'),
      icon:               'success',
      showCancelButton:   false,
      showConfirmButton:  true,
      confirmButtonColor: '#886ab5',
    });
  }

  protected handleComponentDestruction(redirectUrl: string = null): void {
    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    }
  }

  public makeDefault(card: PaymentCard): void {
    throw new Error('Implement');
  }

  public deleteCard(card: PaymentCard): void {
    throw new Error('Implement');
  }

  protected baseMakeDefault(paymentCard: PaymentCard, observable: Observable<any>): void {
    this.subscriptions.push(
      observable.subscribe(
        result => {
          this.paymentCards.map(card => card.default = card.id === paymentCard.id);
          this.toastr.success(this.translate.instant('SHARED-COMPONENTS.payment_handler.payment_methods.card.response.set_default'));
        },
        error => this.translate.instant('SHARED.went-wrong')
      )
    );
  }

  protected baseDeleteCard(paymentCard: PaymentCard, observable: Observable<any>): void {
    this.subscriptions.push(
      observable.subscribe(
        result => {
          const index = this.paymentCards.findIndex(card => card.id === paymentCard.id);
          if (index >= 0) {
            this.paymentCards.splice(index, 1);
          }
          this.toastr.success(this.translate.instant('SHARED-COMPONENTS.payment_handler.payment_methods.card.response.deleted_card'));
        },
        error => this.translate.instant('SHARED.went-wrong')
      )
    );
  }

  private buildRestCardForm(client: User, transactionableType: string, transactionableUuid: string): void {
    this.restCardForm = this.fb.group({
      transactionable_type: [transactionableType, [Validators.required]],
      transactionable_uuid: [transactionableUuid, [Validators.required]],
      brand:                [null, [Validators.required]],
      card_number:          [null, [Validators.required, CustomValidators.cardValidator]],
      holder:               [client.first_name + ' ' + client.last_name, [Validators.required]],
      expiry_month:         [null, [Validators.required, CustomValidators.expiryMonthValidator]],
      expiry_year:          [null, [Validators.required, CustomValidators.expiryYearValidator]],
      cvv:                  [null, [Validators.required, CustomValidators.cvcValidator]],
      save_card:            [true, [Validators.required]],
      set_default:          [true, [Validators.required]],
    });

    this.restCardForm.get('card_number').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe(cardNumber => this.parseCardNumber(cardNumber));

    this.showResponseHandler = true;
    this.showRestCardForm    = true;
    console.log(this.showResponseHandler);
    console.log(this.showRestCardForm);
    console.log(this.restCardForm.value);
  }

  public resetRestCardForm(): void {
    this.showResponseHandler = false;
    this.restCardForm.reset();
    this.resetCardForm();
  }
}
