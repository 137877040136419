import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateTime, DateTimeUnit } from 'luxon';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { StatusFilterComponent } from '../../../../_shared/components/status-filter/status-filter.component';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { DistributionBatch } from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { BankAccount } from '../../../../../../../_base-shared/models/Payment/BankAccount';
import { PaymentListFilter } from '../../../../../../../_base-shared/models/Payment/PaymentListFilter';
import { PaymentMethod } from '../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { PaymentPlanType } from '../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { ProductService } from '../../../case/product.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { DistributionBatchService } from '../../../distribution/distribution-batch.service';
import { StatusService } from '../../../status/status.service';
import { UserService } from '../../../user/user.service';
import { PaymentMethodService } from '../../payment-method.service';
import { PaymentPlanTypeService } from '../../payment-plan-type.service';
import {DepartmentService} from '../../../department/department.service';
import {Department} from '../../../../../../../_base-shared/models/Department/Department';
import * as luxon from 'luxon';

@Component({
  selector:    'app-payment-list-filters',
  templateUrl: './payment-list-filters.component.html',
  styles:      [],
})
export class PaymentListFiltersComponent implements OnInit, OnDestroy {
  @Input() onlyRelated: boolean    = null;
  @Output() filtersReady           = new EventEmitter<boolean>();
  @Output() submitFilters          = new EventEmitter<PaymentListFilter>();
  @Output() distributionVisibility = new EventEmitter<boolean>();
  @Output() wireTypeOfPayment      = new EventEmitter<boolean>();
  @ViewChild(StatusFilterComponent) statusFilterComponent: StatusFilterComponent;

  public isLoading                     = 0;
  public form: UntypedFormGroup;
  public showDistribution              = false;
  public dateRadioControl: UntypedFormControl = new UntypedFormControl();

  public paymentListFilter: PaymentListFilter;
  public paymentStatuses: Array<PaymentStatus>         = [];
  public paymentMethods: Array<PaymentMethod>          = [];
  public incomeBankAccounts: Array<BankAccount>        = [];
  public products: Array<Product>                      = [];
  public days: Array<number>                           = [];
  public distributionBatches: Array<DistributionBatch> = [];
  public paymentPlanTypes: Array<PaymentPlanType>      = [];
  public departments: Array<Department>                = [];
  public collectionDepartmentUsers: Array<User>        = [];
  public callStatuses: Array<CallStatus> = [];

  public hasDistributionOptions: Array<AppSelectOption> = [];
  private subscriptions: Array<Subscription>            = [];
  private formChangeSubscriber: Subscription;
  private localStorageName                              = 'payment-list-filters';
  public authUser: User;

  constructor(private fb: UntypedFormBuilder,
              private globalEventsService: MainGlobalEventService,
              private statusService: StatusService,
              private creditorService: CreditorService,
              private userService: UserService,
              private paymentStatusService: PaymentStatusService,
              private productService: ProductService,
              private paymentMethodService: PaymentMethodService,
              private distributionBatchService: DistributionBatchService,
              private paymentPlanTypeService: PaymentPlanTypeService,
              private departmentService: DepartmentService,
              private callStatusService: CallStatusService) {
  }

  ngOnInit(): void {
    // todo: get filters from query string
    this.buildSelectOptions();
    this.paymentListFilter              = this.getFiltersFromStorage();
    this.paymentListFilter.only_related = this.onlyRelated !== null ?
        this.onlyRelated :
        this.paymentListFilter.only_related;
    this.filtersReady.emit(false);
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (this.paymentListFilter.only_related) {
        this.paymentListFilter.user_department_assignments = this.authUser.department_assignments.map(
            departmentAssignment => departmentAssignment.department_id,
        );
      }
      this.buildForm(this.paymentListFilter, this.authUser);
      this.fetchPaymentMethods();
    }));
    this.fetchPaymentStatuses();
    this.fetchDistributionBatches();
    this.fetchPaymentPlanTypes();
    this.fetchProducts();
    this.fetchCollectionAgents();
    this.fetchCallStatuses();
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  private getFiltersFromStorage(): PaymentListFilter {
    const paymentFilters: PaymentListFilter = new PaymentListFilter();
    let data                                = JSON.parse(localStorage.getItem(this.localStorageName));
    data                                    = data ? data : {};

    paymentFilters.start_date                  = data.start_date ? new Date(data.start_date) : null;
    paymentFilters.end_date                    = data.end_date ? new Date(data.end_date) : null;
    paymentFilters.search                      = data.search ? data.search : null;
    paymentFilters.payment_method              = data.payment_method ? data.payment_method : null;
    paymentFilters.income_account_id           = null;
    paymentFilters.products                    = data.products ? data.products : [];
    paymentFilters.paid_status                 = data.paid_status ? data.paid_status : 'all';
    paymentFilters.billable_status             = data.billable_status ? data.billable_status : 'collections';
    paymentFilters.active                      = data.active !== undefined ? data.active : 1;
    paymentFilters.activity_status             = data.activity_status ? data.activity_status : 'active';
    paymentFilters.activity_status_filter_type = data.activity_status_filter_type ? data.activity_status_filter_type : 'currently';
    paymentFilters.statuses                    = data.statuses ? data.statuses : [];
    paymentFilters.payment_statuses            = data.payment_statuses ? data.payment_statuses : [];
    paymentFilters.pay_day                     = data.pay_day !== undefined ? data.pay_day : null;
    paymentFilters.call_statuses               = data.call_statuses ? data.call_statuses : [];
    paymentFilters.card_status                 = data.card_status ? data.card_status : null;
    paymentFilters.select_all                  = data.select_all !== undefined ? data.select_all : 0;
    paymentFilters.only_related                = data.only_related !== undefined ? data.only_related : 0;
    paymentFilters.case_distribution_status    = data.case_distribution_status ? data.case_distribution_status : null;
    paymentFilters.distribution_batch_ids      = data.distribution_batch_ids ? data.distribution_batch_ids : null;
    paymentFilters.plan_type_slugs             = data.plan_type_slugs ? data.plan_type_slugs : ['debt_plan'];
    paymentFilters.user_department_assignments = data.user_department_assignments ?
        data.user_department_assignments :
        [];
    paymentFilters.has_redsys_has_no_cashflows_token = data.has_redsys_has_no_cashflows_token ? data.has_redsys_has_no_cashflows_token : false;

    return paymentFilters;
  }

  private buildForm(paymentListFilter: PaymentListFilter, authUser: User): void {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }

    this.form = this.fb.group({
      start_date:                  [paymentListFilter.start_date],
      end_date:                    [paymentListFilter.end_date],
      search:                      [paymentListFilter.search],
      payment_method:              [paymentListFilter.payment_method],
      income_account_id:           [null],
      statuses:                    [paymentListFilter.statuses],
      pay_day:                     [paymentListFilter.pay_day],
      call_statuses:               [paymentListFilter.call_statuses],
      paid_status:                 [paymentListFilter.paid_status],
      billable_status:             [paymentListFilter.billable_status],
      active:                      [paymentListFilter.active],
      activity_status:             [paymentListFilter.activity_status],
      activity_status_filter_type: [paymentListFilter.activity_status_filter_type],
      payment_statuses:            [paymentListFilter.payment_statuses],
      card_status:                 [paymentListFilter.card_status],
      user_department_assignments: [paymentListFilter.user_department_assignments],
      date_radio:                  ['sign_up_date'],
      products:                    [paymentListFilter.products],
      case_distribution_status:    [paymentListFilter.case_distribution_status],
      distribution_batch_ids:      [paymentListFilter.distribution_batch_ids],
      plan_type_slugs:             [paymentListFilter.plan_type_slugs, [Validators.required]],
      select_all:                  [0],
      ignore_status_names:         [['Incapaz de Ayudar']],
      ignore_payment_status_names: [['Impago Definitivo', 'Pago Retrasado']],
      'collections-agent':          [paymentListFilter['collections-agent']],
      has_redsys_has_no_cashflows_token: [paymentListFilter.has_redsys_has_no_cashflows_token]
    });

    this.paymentMethodChanged(paymentListFilter.payment_method);

    this.paymentListFilter = this.form.value;

    this.subscribeToFormChanges();
  }

  private submitForm(form: UntypedFormGroup) {
    this.paymentListFilter = this.form.value;
    if (form.valid) {
      this.filtersReady.emit(true);
      this.submitFilters.emit(this.paymentListFilter);
    }
  }

  private subscribeToFormChanges() {
    this.form.get('pay_day').valueChanges.pipe(filter(next => !!next)).subscribe(
        next => {
          this.form.get('start_date').setValue(DateTime.local().set({ day: next }).startOf('day').toJSDate());
          this.form.get('end_date').setValue(DateTime.local().set({ day: next }).startOf('day').toJSDate());
        });

    this.form.get('payment_method').valueChanges.pipe(filter(next => !!next)).subscribe(
        next => this.paymentMethodChanged(next));

    this.formChangeSubscriber = this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }
      this.paymentListFilter = this.form.value;
      // TODO: update filters in query string
      this.submitForm(this.form);
      this.storeFiltersToStorage(this.paymentListFilter);
    });
  }

  public paymentMethodChanged(methodSlug: string) {
    if (methodSlug === 'wire'){
      this.wireTypeOfPayment.emit(true);
    } else {
      this.wireTypeOfPayment.emit(false);
    }
    if (!methodSlug && (this.form.get('income_account_id').value)) {
      this.form.get('income_account_id').patchValue(null);
    }
    if (!methodSlug) {
      return;
    }
    if (methodSlug && (methodSlug !== 'wire' || !this.paymentMethods.length)) {
      this.form.get('income_account_id').patchValue(null);
      return;
    }
    const selectedMethod = this.paymentMethods.find(m => m.slug === methodSlug);
    const defaultIncomeAccount = selectedMethod.billing_bank_accounts.find(account => account.default === true);

    this.incomeBankAccounts = selectedMethod.billing_bank_accounts;

    if (this.form.get('income_account_id').value !== defaultIncomeAccount?.id) {
      this.form.get('income_account_id').patchValue(defaultIncomeAccount?.id);
    }
  }

  private fetchPaymentMethods(): void {
    this.isLoading++;
    this.paymentMethodService.index({}, ['billing_bank_accounts']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.paymentMethods = result.data;
          this.submitForm(this.form);
          // this.buildForm(this.paymentListFilter, this.authUser);
          this.paymentMethodChanged(this.form.get('payment_method').value);
        });
  }

  private fetchPaymentStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
        this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.paymentStatuses = result.data),
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
        this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.products = result.data),
    );
  }

  private fetchDistributionBatches(): void {
    const requestFilters = {
      select_all: 1,
    };
    this.isLoading++;
    this.subscriptions.push(
        this.distributionBatchService.index(requestFilters).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.distributionBatches = result.data),
    );
  }

  private fetchPaymentPlanTypes() {
    this.isLoading++;
    this.subscriptions.push(
        this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.paymentPlanTypes = result.data;
        }),
    );
  }

  private fetchCollectionAgents() {
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.indexDepartmentUsersByType('collections-agent').pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.collectionDepartmentUsers = result.data;
      })
    );
  }

  public clearFilters(): void {
    localStorage.removeItem(this.localStorageName);
    this.paymentListFilter = this.getFiltersFromStorage();
    this.statusFilterComponent.clearControls();
    this.filtersReady.emit(false);
    this.buildForm(this.paymentListFilter, this.authUser);
    this.submitForm(this.form);
    this.dateRadioControl.patchValue(null);
  }

  public patchFilter(name: string, value, options = {}): void {
    this.form.get(name).patchValue(value, options);
  }

  private storeFiltersToStorage(paymentListFilter: PaymentListFilter) {
    return localStorage.setItem(this.localStorageName, JSON.stringify(paymentListFilter));
  }

  public clearFormControl($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  public clearMultiSelect($event, name: string) {
    $event.stopPropagation();
    this.form.get(name).patchValue([]);
  }

  public dateModifierChange($event) {
    this.form.get('start_date').setValue(DateTime.local().startOf($event.value).toJSDate());
    this.form.get('end_date').setValue(DateTime.local().endOf($event.value).toJSDate());
  }

  public onlyRelatedChange($event: any) {
    const myDepartmentAssignments = $event ?
        this.authUser.department_assignments.map(departmentAssignment => departmentAssignment.department_id) :
        [];
    this.form.get('user_department_assignments').patchValue(myDepartmentAssignments);
    if ($event) {
      this.form.get('user_department_assignments').disable({onlySelf: true, emitEvent: false});
    } else {
      this.form.get('user_department_assignments').enable({onlySelf: true, emitEvent: false});
    }
  }

  public toggleDistributionVisibility($event: any) {
    this.showDistribution = !this.showDistribution;
    this.distributionVisibility.emit(this.showDistribution);
  }

  public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false) {
    if (endDay) {
      const inputValue =  $event.value.toString();
      const dt = DateTime.fromJSDate(new Date(inputValue));
      const endOfDay = dt.endOf('day');
      const date = endOfDay.toJSDate();
      this.form.get(formControlName).patchValue(date);
    }
  }

  public clearDates() {
    this.form.patchValue({
      start_date: null,
      end_date:   null,
      pay_day:    null,
    });
  }

  public userIsAMami(): boolean {
    return environment.DISTRIBUTION_USER_IDS.includes(this.authUser.id);
  }

  private buildSelectOptions(): void {
    this.hasDistributionOptions = [
      {label: '/', value: null},
      {label: 'Yes', value: 'in_distribution'},
      {label: 'No', value: 'not_in_distribution_and_viable'},
      {label: 'Non-viable', value: 'nonviable'},
    ];
  }

  private fetchCallStatuses() {
    this.isLoading++;
    this.subscriptions.push(
    this.callStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.callStatuses = result.data));
  }
}
