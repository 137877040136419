import { Affiliate } from './Affiliate';
import { LeadConversionType } from './LeadConversionType';
import { LeadPostback } from './LeadPostback';

export class AffiliatePostbackPostData {
  id: number;
  data_field: any;
  data_value: any;
}

export class AffiliatePostback {
  id: number;
  lead_conversion_type_id: number;
  type: 'email' | 'http-post';
  destination: string;
  mail_subject: string;
  mail_content: string;
  post_data?: Array<AffiliatePostbackPostData>;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  affiliate?: Affiliate;
  lead_conversion_type?: LeadConversionType;
  lead_postbacks?: Array<LeadPostback>;
}
