<div class="container">
  <mat-card class="mat-elevation-z3">
    <mat-card-content>
      <div *ngIf="isLoadingDocumentPreview" class="d-flex justify-content-center align-items-center">
        <mat-spinner diameter="30" color="primary"></mat-spinner>
      </div>
      <app-html-shadow-dom [hidden]="isLoadingDocumentPreview" [unsafeHtml]="documentHtml"></app-html-shadow-dom>
    </mat-card-content>
  </mat-card>

  <ng-content></ng-content>
</div>
