<mat-dialog-content>
  <div class="d-flex flex-column h-100">
    <h4>{{"CASES.details.additional-data" | translate}}</h4>

    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="formActive">
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="fill">
              <mat-label>{{"CASE_CREDITOR.model.response_received.label" | translate}}</mat-label>
              <mat-select formControlName="status">
                <mat-option value="mandate_sent">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_sent" | translate}}
                </mat-option>
                <mat-option value="chase_required">
                  {{"CASE_CREDITOR.model.response_received.options.chase_required" | translate}}
                </mat-option>
                <mat-option value="mandate_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_rejected" | translate}}
                </mat-option>
                <mat-option value="balance_received">
                  {{"CASE_CREDITOR.model.response_received.options.balance_received" | translate}}
                </mat-option>
                <mat-option value="balance_outstanding">
                  {{"CASE_CREDITOR.model.response_received.options.balance_outstanding" | translate}}
                </mat-option>
                <mat-option value="proposal_sent">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_sent" | translate}}
                </mat-option>
                <mat-option value="proposal_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_rejected" | translate}}
                </mat-option>
                <mat-option value="modification_required">
                  {{"CASE_CREDITOR.model.response_received.options.modification_required" | translate}}
                </mat-option>
                <mat-option value="proposal_accepted">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_accepted" | translate}}
                </mat-option>
                <ng-container *ngIf="type === 'claim_creditors'">
                  <mat-option value="extrajudicial_claim_sent">
                    {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent' | translate }}
                  </mat-option>
                  <mat-option value="extrajudicial_claim_accepted">
                    {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted' | translate }}
                  </mat-option>
                  <mat-option value="extrajudicial_claim_rejected">
                    {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected' | translate }}
                  </mat-option>
                  <mat-option value="extrajudicial_claim_unanswered">
                    {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered' | translate }}
                  </mat-option>
                  <mat-option value="claim_filed">
                    {{ 'CASE_CREDITOR.model.response_received.options.claim_filed' | translate }}
                  </mat-option>
                  <mat-option value="claim_approved">
                    {{ 'CASE_CREDITOR.model.response_received.options.claim_approved' | translate }}
                  </mat-option>
                  <mat-option value="claim_rejected">
                    {{ 'CASE_CREDITOR.model.response_received.options.claim_rejected' | translate }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <div class="text-end mt-auto mb-5 d-flex justify-content-end align-items-center">

      <button type="button" mat-stroked-button color="primary" class="me-3"
              (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
      <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()">
        {{"SHARED.submit" | translate}}
      </button>
      <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
    </div>
  </div>
</mat-dialog-content>
