<div class="d-flex flex-row justify-content-between align-items-center">
  <h4 *ngIf="channel === 'email'">{{ 'CASES.single.general.relation_list.notification_list.heading_email' | translate }}</h4>
  <h4 *ngIf="channel === 'sms'">{{ 'CASES.single.general.relation_list.notification_list.heading_sms' | translate }}</h4>
  <h4 *ngIf="channel === 'call'">{{ 'CASES.single.general.relation_list.notification_list.heading_call' | translate }}</h4>
</div>
<mat-tab-group (selectedTabChange)="tabChange($event)" dynamicHeight>
  <mat-tab *ngFor="let tab of tabs" [label]="tab.label">
    <!-- Loader -->
    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- No data -->
    <app-empty-dataset [show]="!isLoading && !notifications?.length"
                       [message]="'CASES.single.general.relation_list.notification_list.no_data' | translate">
    </app-empty-dataset>
    <!-- Email History -->
    <div *ngIf="channel === 'email'" class="email-history">
      <mat-accordion multi *ngIf="!isLoading && notifications.length">
        <mat-expansion-panel class="bg-light m-1" *ngFor="let email of notifications"
                             [class.outgoing]="email.outgoing" [class.received]="!email.outgoing">
          <mat-expansion-panel-header style="min-height: 70px">
            <mat-panel-title style="display: block">
              <p class="m-0"><b>{{ email.label }}</b></p>
              <p class="m-0" style="font-size: 13px; color: #8a8888;">
                {{ email.sent_at | toDateObject | date: 'medium' }} {{ email.from }} ->To: {{ email.sent_to }}
              </p>
              <!--        <p class="m-0"><b>{{email.sent_at | toDateObject | date: 'medium'}}</b>:-->
              <!--          <span-->
              <!--            [innerHTML]="'CASES.single.general.relation_list.notification_list.msg_summary' | translate:{-->
              <!--            label: '<b>' + email.label + '</b>', from: email.from, to: '<b>' + email.sent_to + '</b>'} "></span>-->
              <!--        </p>-->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            {{ 'CASES.single.general.relation_list.notification_list.author.label' | translate }}:
            <b>{{ email.author?.name ||
            'CASES.single.general.relation_list.notification_list.author.placeholder' | translate}}</b>
          </p>
          <app-email-shadow-dom [emailContent]="email.content"></app-email-shadow-dom>
          <div *ngIf="email.attachments?.length">
            <div *ngFor="let attachment of email.attachments"
                 class="d-flex justify-content-between align-items-center">
              <p class="m-0 uploaded-file-name clickable" (click)="downloadAttachment(attachment)">
                {{ attachment.name + '.' + attachment.extension }}
              </p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!-- SMS History -->
    <div *ngIf="channel === 'sms'" class="sms-history" style="max-height: 500px; overflow: auto">
      <mat-accordion multi>
        <mat-expansion-panel class="bg-light m-1" *ngFor="let sms of notifications">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>
                <b>{{ sms.created_at | toDateObject | date: 'medium' }}</b>:
                <span [innerHTML]="'CASES.single.general.relation_list.notification_list.sms_summary' | translate:
                   { to:  '<b>' + sms.sent_to + '</b>'}">
                  </span>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            {{ 'CASES.single.general.relation_list.notification_list.author.label' | translate }}:
            <b>{{ sms.author?.name ||
            'CASES.single.general.relation_list.notification_list.author.placeholder' | translate}}</b>
          </p>
          <pre [innerHTML]="sms.content | safe: 'html'"></pre>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!-- Call History -->
    <div *ngIf="channel === 'call'" class="call-history">
      <div *ngFor="let callLog of notifications;" class="card mb-5">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <p class="call-log-item">
              {{ 'CASES.single.general.relation_list.notification_list.call.duration' | translate}}
              : {{ callLog.call_duration }}
            </p>
            <p class="call-log-item">
              {{ 'CASES.single.general.relation_list.notification_list.call.date' | translate }}
              : {{ callLog.call_date }}
            </p>
            <p class="call-log-item"
               *ngIf="callLog?.notification_history?.staff?.first_name || callLog?.notification_history?.staff?.last_name">
              {{ 'USERS.agent' | translate}}
              : {{ callLog?.notification_history?.staff?.first_name }} {{callLog?.notification_history?.staff?.last_name }}
            </p>
            <p class="call-log-item">
              <span *ngIf="callLog.success;else failed">
                <mat-icon class="text-success">done</mat-icon>
              </span>
              <ng-template #failed>
                <mat-icon class="text-danger">close</mat-icon>
              </ng-template>
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
