<h5 mat-dialog-title>{{'REPORTS.financial-reports.list.collections-report.title' | translate}}</h5>
<div class="pt-3" mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="sendReportData()">
    <mat-form-field appearance="fill" class="me-1">
      <mat-label>
        {{ "REPORTS.financial-reports.list.collections-report.fields.start-date" | translate }}
      </mat-label>
      <input matInput [matDatepicker]="start_date" formControlName="start_date">
      <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
      <mat-datepicker #start_date></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>
        {{ "REPORTS.financial-reports.list.collections-report.fields.end-date" | translate }}
      </mat-label>
      <input matInput [matDatepicker]="end_date" formControlName="end_date">
      <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
      <mat-datepicker #end_date></mat-datepicker>
    </mat-form-field>

    <div class='pt-2 text-end'>
      <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
        {{ "REPORTS.financial-reports.list.collections-report.actions.close" | translate }}
      </button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">
        {{ "REPORTS.financial-reports.list.collections-report.actions.submit" | translate }}
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
      </button>
    </div>
  </form>
</div>
