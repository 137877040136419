<div class="row">
  <div class="col-12">
    <!-- Loader -->
    <div *ngIf="isLoading" class="col-12 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <!-- Form -->
    <form class="mt-2" *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form.value)">
      <!-- Campaign Form -->
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex">
            <!-- Header -->
            <div class="flex-grow-1">
              <h5>
                {{ editorType === 'edit' ?
                  ('TASK-TEMPLATE.editor.header-edit' | translate) :
                  ('TASK-TEMPLATE.editor.header-create' | translate) }}
              </h5>
            </div>
            <div>
              <app-spinner-btn [loading]="isSubmitting" [name]="this.editorType === 'edit' ?
                        ('TASK-TEMPLATE.editor.submit-edit' | translate) :
                        ('TASK-TEMPLATE.editor.submit-create' | translate)">
              </app-spinner-btn>
            </div>
          </div>
          <div class="row mt-3">
            <!-- Name -->
            <div class="col-3">
              <app-input type="text" formControlName="name" appearance="fill" [fullWidth]="true"
                         [label]="'TASK-TEMPLATE.editor.name.label' | translate">
              </app-input>
            </div>
            <!-- Statusable Type -->
            <div class="col-3">
              <app-input type="select" formControlName="statusable_type" appearance="fill" [fullWidth]="true"
                         [label]="'TASK-TEMPLATE.editor.statusable_type.label' | translate" [searchable]="true"
                         [selectOptions]="statusableTypes" [selectLabel]="'label'" [selectValue]="'value'"
                         (ngModelChange)="statusableTypeChanged($event)">
              </app-input>
            </div>
            <!-- Statusable ID -->
            <div class="col-3">
              <ng-template [ngIf]="form.get('statusable_type').value === 'status'">
                <app-input type="select" formControlName="statusable_id" appearance="fill" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.statusable_id.label' | translate" [searchable]="true"
                           [selectOptions]="statusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                           [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </ng-template>

              <ng-template [ngIf]="form.get('statusable_type').value === 'payment_status'">
                <app-input type="select" formControlName="statusable_id" appearance="fill" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.statusable_id.label' | translate" [searchable]="true"
                           [selectOptions]="paymentStatuses" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </ng-template>

              <ng-template [ngIf]="form.get('statusable_type').value === 'call_status'">
                <app-input type="select" formControlName="statusable_id" appearance="fill" [fullWidth]="true"
                           [label]="'CONFIG.drip-campaign.list.table-data.statusable_type.call_status' | translate"
                           [selectOptions]="callStatuses" [searchable]="true"
                           [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </ng-template>
            </div>
            <!-- Case Ref -->
            <div class="col-3">
              <app-input type="text" formControlName="case_ref_number" appearance="fill" multiple [fullWidth]="true"
                         [label]="'TASK-TEMPLATE.editor.case_ref_number.label' | translate">
              </app-input>
            </div>
          </div>
          <div class="row mt-3">
            <!-- Assigned Users -->
            <div class="col-3">
              <app-input type="select" formControlName="assigned_users_ids" appearance="fill" multiple
                         [fullWidth]="true" [label]="'TASK-TEMPLATE.editor.assigned_users.label' | translate"
                         [selectOptions]="users" [selectLabel]="['first_name', 'last_name']" [selectValue]="'id'"
                         (ngModelChange)="assignableChanged('user')" [searchable]="true">
              </app-input>
            </div>
            <!-- Assigned Departments -->
            <div class="col-3">
              <app-input type="select" formControlName="assigned_departments_ids" appearance="fill" multiple
                         [fullWidth]="true" [label]="'TASK-TEMPLATE.editor.assigned_departments.label' | translate"
                         [selectOptions]="departmentCategories" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true"
                         [optGroupProperty]="'departments'" (ngModelChange)="assignableChanged('department')">
              </app-input>
            </div>
            <!-- Delay -->
            <div class="col-3">
              <app-input type="number" formControlName="delay" appearance="fill" [fullWidth]="true"
                         [label]="'TASK-TEMPLATE.editor.delay.label' | translate">
              </app-input>
            </div>
            <!-- Delay Unit -->
            <div class="col-3">
              <app-input type="select" formControlName="delay_unit" appearance="fill" [fullWidth]="true"
                         [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                         [selectOptions]="delayOptions" [selectLabel]="'label'">
              </app-input>
            </div>
          </div>
          <div class="row mt-3 pt-2 pb-2">
            <!-- Notify On Completion -->
            <div class="col-3">
              <app-input type="select" formControlName="notify_on_completion_via" appearance="fill" multiple
                         [fullWidth]="true" [label]="'TASK-TEMPLATE.editor.notify_on_completion.label' | translate"
                         [selectOptions]="notificationChannels" [selectLabel]="'name'" [selectValue]="'slug'">
              </app-input>
            </div>
            <div class="col-9 row">
              <!-- Active -->
              <div class="col-3 d-flex align-items-center">
                <app-input type="switch" formControlName="active" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.active.label' | translate">
                </app-input>
              </div>
              <!-- User Assisted -->
              <div class="col-3 d-flex align-items-center">
                <app-input type="switch" formControlName="user_assisted" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.user_assisted.label' | translate">
                </app-input>
              </div>
              <!-- Force Task Creation -->
              <div class="col-3 d-flex align-items-center">
                <app-input type="switch" formControlName="force_task_creation" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.force_task_creation.label' | translate">
                </app-input>
              </div>
              <!-- Require Completion Note -->
              <div class="col-3 d-flex align-items-center">
                <app-input type="switch" formControlName="require_completion_note" [fullWidth]="true"
                           [label]="'TASK-TEMPLATE.editor.require_completion_note.label' | translate">
                </app-input>
              </div>
            </div>
          </div>
          <!-- Notes and Variables -->
          <div class="row mt-3">
            <div class="col-8">
              <!-- Notes -->
              <div>
                <label for="notes">{{ 'TASK-TEMPLATE.editor.notes' | translate }}</label>
              </div>
              <quill-editor id="notes" [modules]="quillModules" formControlName="notes" [styles]="{height: '250px'}">
              </quill-editor>
              <div *ngIf="form.get('notes').errors && (form.get('notes').touched || formSubmitted)">
                <mat-error [hidden]="!form.get('notes').errors.required">
                  {{ "SHARED.field-required" | translate }}
                </mat-error>
              </div>
            </div>
            <div class="col-4 pt-4">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ 'TASK-TEMPLATE.editor.variables' | translate }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div style="max-height: 300px; overflow: auto">
                  <h5 class="text-center" *ngFor="let variable of templateVariables">
                    <code (click)="addVariable($event)">{{ variable.label }}</code>
                    <span class="small" *ngIf="variable.description"> - {{ variable.description }}</span>
                  </h5>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </div>
      </div>
      <!-- Task Template Notifications Array -->
      <mat-accordion multi class="mt-2">
        <mat-expansion-panel *ngFor="let notification of templateNotificationsArray.controls; let i=index" class="mt-2"
                             formArrayName="template_notifications">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex align-items-center justify-content-between" style="width: 100%">
                {{ 'TASK-TEMPLATE.editor.task-template-notification.header' | translate }} {{ i + 1 }}
                <button mat-stroked-button color="warn" (click)="removeTemplateNotification(i)">
                  {{ 'TASK-TEMPLATE.editor.task-template-notification.remove' | translate }}
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row task-template-{{ i }}" [formGroupName]="i">
            <div class="col-8">
              <div class="row mt-2">
                <!-- Channel -->
                <div class="col-3">
                  <!--<app-input type="select" formControlName="channel" appearance="fill"-->
                  <!--           [label]="'TASK-TEMPLATE.editor.task-template-notification.channel.label' | translate"-->
                  <!--           [selectOptions]="notificationChannels" [selectLabel]="'name'" [selectValue]="'slug'"-->
                  <!--           (change)="notificationChannelChanged(i)">-->
                  <!--</app-input>-->
                </div>
                <!-- Notify On -->
                <div class="col-3">
                  <app-input type="select" formControlName="notify_on" appearance="fill" [fullWidth]="true"
                             [label]="'TASK-TEMPLATE.editor.task-template-notification.notify_on.label' | translate"
                             [selectOptions]="notifyOnOptions" [selectLabel]="'label'" [selectValue]="'value'"
                             (ngModelChange)="notifyOnChanged(i)">
                  </app-input>
                </div>
                <!-- Delay -->
                <div class="col-3" *ngIf="templateNotificationsArray.at(i).get('notify_on').value === 'before_due_date' ||
                templateNotificationsArray.at(i).get('notify_on').value === 'after_due_date'">
                  <app-input type="number" formControlName="delay" appearance="fill" [fullWidth]="true"
                             [label]="'TASK-TEMPLATE.editor.task-template-notification.delay.label' | translate">
                  </app-input>
                </div>
                <!-- Delay Unit -->
                <div class="col-3" *ngIf="templateNotificationsArray.at(i).get('notify_on').value === 'before_due_date' ||
                templateNotificationsArray.at(i).get('notify_on').value === 'after_due_date'">
                  <app-input type="select" formControlName="delay_unit" appearance="fill" [fullWidth]="true"
                             [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                             [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                  </app-input>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- Add new notification row action -->
      <div class="row mt-2">
        <div class="col">
          <button mat-raised-button color="primary" (click)="addTemplateNotification($event)">
            + {{ 'TASK-TEMPLATE.editor.task-template-notification.add-new' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
