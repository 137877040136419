<table class="table table-bordered">
  <tbody>
  <tr>
    <th style="width: 20%;">Redsys Order No.</th>
    <td>{{ redsysOrder.redsys_uid }}</td>
  </tr>
  <tr>
    <th>Payment Processor</th>
    <td>{{redsysOrder.payment_processor?.slug}}</td>
  </tr>
  <tr>
    <th>Author</th>
    <td>{{ redsysOrder.author?.full_name }}</td>
  </tr>
  <tr>
    <th>Amount</th>
    <td>{{(redsysOrder.amount_100 / 100) | currency: "EUR"}}</td>
  </tr>
  <tr>
    <th>Type</th>
    <td>
      <span *ngIf="redsysOrder.initial_cof">Tokenization</span>
      <span *ngIf="!redsysOrder.initial_cof && redsysOrder.cof_type">Recurring</span>
    </td>
  </tr>
  <tr>
    <th>Is moto payment</th>
    <td>{{ redsysOrder.direct_payment === 'MOTO' ? 'Yes' : 'No' }}</td>
  </tr>
  <tr>
    <th>Bizum mobile</th>
    <td>{{ redsysOrder.bizum_mobilenumber ? redsysOrder.bizum_mobilenumber : 'N/A' }}</td>
  </tr>
  <tr>
    <th>Card Pan</th>
    <td>{{ redsysOrder.card_pan }}</td>
  </tr>
  <tr>
    <th>Card Expiry</th>
    <td>{{ redsysOrder.card_expiry }}</td>
  </tr>
  </tbody>
</table>
