import { EnvironmentInterface } from './EnvironmentInterface';

export const environment: EnvironmentInterface = {
  APP_NAME:              'Deudafix',
  APP_ENV:               'staging',
  production:            false,
  devMode:               true,
  API_DOMAIN:            'api.deudafix.softelm.dev',
  API_SECURE_PROTOCOL:   false,
  API_URL:               'https://api.deudafix.softelm.dev',
  STORAGE_URL:           'https://api.deudafix.softelm.dev/storage',
  APP_URL:               'https://app.deudafix.softelm.dev',
  CLIENT_APP_URL:        'https://clientes.deudafix.softelm.dev',
  GENERAL_SITE_URL:      'www.deudafix.es',
  REFRESH_NOTIFICATIONS: false,
  DISTRIBUTION_USER_IDS: [1, 27, 36, 2403, 2497],

  COMPANY_NAME:        'Deudafix',
  COMPANY_NAME_LEGAL:  'Deudafix Reparadora Legal Group, S.L.',
  COMPANY_ID_CIF:      'B88315890',
  COMPANY_ADDRESS:     'Avenida de la Vega, núm. 15, Edificio 2, planta 4ª, Alcobendas (Madrid), CP 28108]',
  COMPANY_BANK_NAME:   'Caixabank',
  COMPANY_BANK_IBAN:   'ES06 2100 4910 3613 0024 6367',
  COMPANY_PHONE_LEGAL: '910 053 393',

  VAT_PERCENTAGE: 21,

  EMAIL_INFO:                 'info@deudafix.es',
  EMAIL_LEGAL:                'legal@deudafix.es',
  EMAIL_ADMINISTRATION:       'administracion@deudafix.es',
  EMAIL_CANCELLATIONS:        'cancelaciones@deudafix.es',
  EMAIL_CONTRACT_CONTACT:     'contract@deudafix.com',
  EMAIL_SUPPORTING_DOCUMENTS: 'justificantes@deudafix.es',
  EMAIL_GDPR:                 'dataprotectionofficer@deudafix.es',

  GOOGLE_ANALYTICS_CODE:    'XXXXG-NPW7ZJLJHP',
  GOOGLE_ANALYTICS_ENABLED: false,
  ARCOPAY_SHOWN_BANKS:      'ruralvia_nuevacarteya,ruralvia_onda,ruralvia_orihuela,ruralvia_ruralnostra,ruralvia_salamanca,ruralvia_sanisidrovallduxo,ruralvia_sanjosealcora,ruralvia_sanjosedealmassora,ruralvia_soria,ruralvia_teruel,ruralvia_utrera,ruralvia_villamalea,ruralvia_vinaroz,ruralvia_zamora,sabadell,sandbox2,sandbox_cajalaboral,sandbox_cajamar,sandbox_sabadell,sandbox_unicaja,santander,selfbank,triodos,unicaja,wizink,ruralvia,ruralvia_adamuz,ruralvia_albal,ruralvia_alcudia,ruralvia_algemesi,ruralvia_asturias,ruralvia_baena,ruralvia_caixapopular,ruralvia_caixaruralbenicarlo,ruralvia_caixaruralgalega,ruralvia_cajaruralaragon,ruralvia_cajaruralsur,ruralvia_cajasiete,ruralvia_cajaviva,ruralvia_canetedelastorres,ruralvia_casasibanez,ruralvia_extremadura,ruralvia_fuentealamo,ruralvia_gijon,ruralvia_globalcaja,ruralvia_granada,ruralvia_jaen,ruralvia_lescoves,ruralvia_navarra,ruralvia_nuevacarteya,caixaruralnules,caixaruralvilafames,caixaruralvilavella,caixasantvicent,caixaturis,cajaalmendralejo,cajaingenieros,cajaingenieros,cajalaboral,cajamar,cajasur,cecabank,colonya,crcheste,crvillar,eurocajarural,evobanco,fiare,ibercaja,imaginbank,ingdirect,inversis,kutxabank,mediolanum,openbank,pichincha,renta4,revolut_sandbox,ruraltorrent,activobank,andbank,arquia,bancamarch,bancapueyo,bancocaminos,bancofar,bankinter,bbva,bfs,caixa,caixacallosa,caixaguissona,caixalqueries,caixaltea,caixaruralalginet,caixaruralalmenara,caixaruralburriana,caixaruraldexilxes,caixaruralvila-real',
};
