<div id="mdiv" (click)="closeModal()">
  <div class="mdiv">
    <div class="md"></div>
  </div>
</div>
<div class="text-center" style="margin-top: 55px">
  <img class="img-fluid" src="/assets/img/LogomarkArtboard 3.png" alt="">
</div>
<div>
  <h3>Empatia, Servicio, Respeto</h3>
</div>
