import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../_base-shared/contracts/common.interface';

import { Affiliate } from '../../../../../_base-shared/models/Affiliate/Affiliate';
import { AffiliateCaseListFilter } from '../../../../../_base-shared/models/Case/AffiliateCaseListFilter';
import { Product } from '../../../../../_base-shared/models/Product';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../_shared/services/main-global-event.service';
import { AffiliateService } from '../../admin/affiliate/affiliate.service';
import { ProductService } from '../../admin/case/product.service';

@Component({
  selector:    'app-affiliate-case-list-filters',
  templateUrl: './affiliate-case-list-filters.component.html',
  styleUrls:   ['./affiliate-case-list-filters.component.scss'],
})
export class AffiliateCaseListFiltersComponent implements OnInit, OnDestroy {
  @Output() filtersReady  = new EventEmitter<boolean>();
  @Output() submitFilters = new EventEmitter<AffiliateCaseListFilter>();
  @Input() affiliateIds: Array<number>;

  public authUser: User;
  public products: Array<Product>                  = [];
  public affiliates: Array<Affiliate>              = [];
  public affiliateUsers: Array<User>               = [];
  public productTypeGroups: Array<AppSelectOption> = [];

  public isLoading = 0;
  public form: UntypedFormGroup;

  public affiliateCaseListFilter: AffiliateCaseListFilter;
  public conversionStatusOptions: Array<AppSelectOption> = [];

  private subscriptions: Array<Subscription>        = [];
  private formChangeSubscriber: Subscription;
  private localStorageName                          = 'affiliate-case-list-filters';
  public volumeBonusOptions: Array<AppSelectOption> = [];

  constructor(private fb: UntypedFormBuilder,
              private globalEventService: MainGlobalEventService,
              private affiliateService: AffiliateService,
              private productService: ProductService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
    this.affiliateCaseListFilter = this.getFiltersFromStorage();
    this.buildForm(this.affiliateCaseListFilter);
    this.fetchProducts();
    this.fetchAffiliates();
    this.getAffiliateUsers();
    this.getProductTypeGroups();
    this.getVolumeBonusOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.formChangeSubscriber.unsubscribe();
  }

  public dateChanged($event: MatDatepickerInputEvent<any>, formControlName, endDay = false): void {
    if (endDay) {
      const inputValue = $event.value.toString();
      const dt         = DateTime.fromJSDate(new Date(inputValue));
      const endOfDay   = dt.endOf('day');
      const date       = endOfDay.toJSDate();
      this.form.get(formControlName).patchValue(date);
    }
  }

  public clearDates(): void {
    this.form.patchValue({
      start_date: null,
      end_date:   null,
    });
  }

  private getFiltersFromStorage(): AffiliateCaseListFilter {
    const filter = new AffiliateCaseListFilter();
    let data     = JSON.parse(localStorage.getItem(this.localStorageName));
    data         = data ? data : {};

    filter.search              = data.search ? data.search : null;
    filter.start_date          = data.start_date ? new Date(data.start_date) : null;
    filter.end_date            = data.end_date ? new Date(data.end_date) : null;
    filter.status_date_type    = data.status_date_type ? data.status_date_type : 'sign_up';
    filter.conversion_status   = data.conversion_status ? data.conversion_status : 'all';
    filter.affiliate_ids       = data.affiliate_ids ? data.affiliate_ids : this.affiliateIds;
    filter.affiliate_user_ids  = data.affiliate_user_ids ? data.affiliate_user_ids : this.affiliateUsers;
    filter.product_group_slugs = data.product_group_slugs ? data.product_group_slugs : null;
    filter.volume_filter       = data.volume_filter ? data.volume_filter : null;

    return filter;
  }

  private buildForm(affiliateCaseListFilter: AffiliateCaseListFilter): void {
    this.form = this.fb.group({
      search:              [affiliateCaseListFilter.search],
      start_date:          [affiliateCaseListFilter.start_date],
      end_date:            [affiliateCaseListFilter.end_date],
      status_date_type:    [affiliateCaseListFilter.status_date_type],
      conversion_status:   [affiliateCaseListFilter.conversion_status],
      affiliate_ids:       [
        affiliateCaseListFilter.affiliate_ids ?
          affiliateCaseListFilter.affiliate_ids :
          this.affiliateIds,
      ],
      affiliate_user_ids:  [affiliateCaseListFilter.affiliate_user_ids],
      product_group_slugs: [affiliateCaseListFilter.product_group_slugs],
      product_ids:         [[]],
      volume_filter:       [[affiliateCaseListFilter.volume_filter]],
    });
    this.productGroupUpdated(this.form.get('product_group_slugs').value || []);
    this.buildConversionStatusOptions(affiliateCaseListFilter.status_date_type);
    this.filtersReady.emit(true);
    this.submitFilters.emit(this.affiliateCaseListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this.formChangeSubscriber = this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(res => {
      if (this.form.invalid) {
        return;
      }

      this.affiliateCaseListFilter = this.form.value;
      this.submitFilters.emit(this.affiliateCaseListFilter);
    });
  }

  public clearFormControl($event, name): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.form.get(name).patchValue(null);
  }

  private buildConversionStatusOptions(dateType: 'sign_up' | 'conversion_date'): void {
    const selectOptions = [];

    if (dateType === 'sign_up') {
      selectOptions.push({label: 'All', value: 'all'});
    }

    selectOptions.push({label: 'Converted - Active', value: 'converted_active'});
    selectOptions.push({label: 'Converted - Inactive', value: 'converted_inactive'});
    selectOptions.push({label: 'Converted - All', value: 'converted_all'});

    if (dateType === 'sign_up') {
      selectOptions.push({label: 'Unconverted', value: 'unconverted'});
    }

    this.conversionStatusOptions = selectOptions;
  }

  public datePickTypeUpdated($event: any): void {
    this.buildConversionStatusOptions($event);
  }

  private getAffiliateUsers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getAffiliateUsers({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.affiliateUsers = result.data,
        error => console.log(error),
      ),
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.products = result.data),
    );
  }

  private fetchAffiliates(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.affiliateService.getAffiliateAffiliates({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.affiliates = result.data),
    );
  }

  private getProductTypeGroups(): void {
    this.productTypeGroups = [
      {label: 'LSO', value: 'lso'},
      {label: 'DM', value: 'cajaplus'},
      {label: 'DGS', value: 'dgs'},
    ];
  }

  public productGroupUpdated(productGroupSlugs: Array<string> = []): void {
    const productIds = [];
    productGroupSlugs.forEach(groupSlug => {
      this.products.forEach(product => {
        if (product.group_slug === groupSlug) {
          productIds.push(product.id);
        }
      });
    });
    this.form.get('product_ids').patchValue(productIds);
  }

  private getVolumeBonusOptions(): void {
    this.volumeBonusOptions = [
      {
        label: this.translate.instant('CASES.list.filters.volume_filter.options.primary_conversion'),
        value: 'primary_conversion'
      },
      {
        label: this.translate.instant('CASES.list.filters.volume_filter.options.secondary_conversion'),
        value: 'secondary_conversion'
      },
      {label: this.translate.instant('CASES.list.filters.volume_filter.options.volume_bonus'), value: 'volume_bonus'}
    ];
  }
}
