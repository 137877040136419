import { NgModule } from '@angular/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SharedModule } from '../../../_shared/shared.module';
import { EmailEditorComponent } from './email-editor/email-editor.component';
import { EmailListComponent } from './email-list/email-list.component';
import { EmailRoutingModule } from './email-routing.module';

@NgModule({
  declarations: [EmailListComponent, EmailEditorComponent],
  imports:      [
    SharedModule,
    MatPaginatorModule,
    EmailRoutingModule,
  ],
})
export class EmailModule {
}
