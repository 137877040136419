import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LegacyDocumentTypeService } from '../../admin/document/legacy-document-type.service';

@Pipe({
  name: 'getLabelFromName',
})
export class GetLabelFromNamePipe implements PipeTransform {
  public allFiles = [];

  constructor(private sanitized: DomSanitizer,
              private documentTypeService: LegacyDocumentTypeService) {
    this.documentTypeService.getLegalDocumentTypes()
      .subscribe(res => {
        this.allFiles = res;
      });
  }

  transform(value) {
    const label = this.allFiles.filter(file => file.name === value);
    return label.length ? label[0].label : value;
  }
}
