import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector:    'app-financial-report-generator',
  templateUrl: './financial-report-generator.component.component.html',
  styles:      []
})
export class FinancialReportGeneratorComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public isLoading                           = 0;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FinancialReportGeneratorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private buildForm(): void {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  public requestReport(): void {
    if ( ! this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const endOfDayISOStringV2 = DateTime.fromISO(this.form.value.end_date.toISOString()).endOf('day').toISO({includeOffset: false});
    const requestData         = {
      report_type: 'book_value_scheduled_fee',
      start_date:  this.form.value.start_date.toISOString(),
      end_date:    endOfDayISOStringV2,
    };

    this.isLoading++;
    this.subscriptions.push(
      this.reportService.generateFinancialReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  res => {
            // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
            // saveAs(res.body, fileName);
            this.dialogRef.close(true);
            this.toast.success(res.message);
          },
          error: err => console.error(err)
        })
    );
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
