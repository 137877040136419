<div class="row">
  <div class="col-12">
    <h4 class="text-center">{{ 'AUTH.greeting' | translate: {appName: appName} }}</h4>
  </div>
  <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
    <mat-card>
      <mat-card-header>
        <div class="row w-100">
          <div class="col-4 offset-4 text-center align-self-center" style="padding-top: 16px">
            <h5>{{ 'AUTH.login.heading' | translate }}</h5>
          </div>
          <div class="col-3 offset-1">
            <app-input type="select" appearance="fill" [formControl]="selectedLanguage" [showLabel]="false"
                       [showClear]="false" [selectOptions]="languages" [selectLabel]="'label'" [selectValue]="'value'"
                       [fullWidth]="true" (ngModelChange)="changeLanguage($event)" [searchable]="true">
            </app-input>
          </div>
        </div>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <!-- Server Errors -->
        <div class="m-4">
          <app-server-errors fieldName="email" [errorResponse]="serverResponse"></app-server-errors>
        </div>
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <!-- ID Card -->
          <app-input type="text" formControlName="username" [label]="'SHARED.dni_number' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true">
          </app-input>
          <!-- Password -->
          <app-input type="password" formControlName="password" [label]="'AUTH.login.form.password' | translate"
                     [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true">
          </app-input>
          <!-- Submit -->
          <div class="text-center pb-3">
            <button class="ms-2 btn client-button-correct" type="submit">
              {{ 'AUTH.login.form.submit' | translate }}
            </button>
          </div>
          <!-- Links -->
          <div class="text-center">
            <a routerLink="/forgot-password" style="color: #00B67A">
              {{ 'AUTH.login.links.forgot-password' | translate }}
            </a>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
