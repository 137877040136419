<div class="d-flex flex-row justify-content-between align-items-center">
  <h4>{{ 'CASES.single.general.relation_list.contracts.heading' | translate }}</h4>
</div>
<div class="pt-4 ps-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !contracts?.length"></app-empty-dataset>
<mat-accordion multi>
  <ng-container *ngFor="let contract of contracts">
    <mat-expansion-panel class="bg-light m-1" *ngIf="(!case.joint_application && contract.signed_by_client) ||
     (case.joint_application && contract.signed_by_client && contract.signed_by_partner)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="m-0">{{ 'SHARED.model.created_at' | translate }}:
            <b>{{ contract.created_at | toDateObject | date: 'medium' }}</b>
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex">
        <a mat-raised-button color="primary" target="_blank" href="{{ storageUrl + contract.pdf_location }}"
           [disabled]="!contract.pdf_location">
          {{ "CASES.single.general.relation_list.contracts.contract_download" | translate }}
        </a>
        <a mat-raised-button color="primary" class="ms-3" target="_blank"
           href="{{storageUrl + contract.redacted_pdf_location}}" [disabled]="!contract.redacted_pdf_location">
          {{ "CASES.single.general.relation_list.contracts.mandate_redacted_contract_download" | translate }}
        </a>
        <a mat-raised-button color="primary" class="ms-3" target="_blank"
           href="{{ storageUrl + contract.mandate_location }}" [disabled]="!contract.mandate_location">
          {{ "CASES.single.general.relation_list.contracts.mandate_download" | translate }}
        </a>
        <a mat-raised-button color="primary" class="ms-3" *ngIf="case.joint_application" target="_blank"
           href="{{storageUrl + contract.mandate_location_1}}" [disabled]="!contract.mandate_location_1">
          {{ "CASES.single.general.relation_list.contracts.mandate_partner_download" | translate }}
        </a>
      </div>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
