<mat-card>
  <mat-card-header>
    <!--<mat-card-title>-->
    <!--  <h1>{{ 'TEAM.list.heading' | translate }}</h1>-->
    <!--</mat-card-title>-->
    <div class="d-flex justify-content-end w-100">
      <a mat-raised-button color="primary" [routerLink]="['/teams', 'create']">
        + {{ "SHARED.create" | translate }}
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table [dataSource]="teams" matSort (matSortChange)="sortData($event)">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.id' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'TEAM.model.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <!-- Leaders -->
      <ng-container matColumnDef="leaders">
        <th mat-header-cell *matHeaderCellDef>{{ 'TEAM.relations.leaders' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <span *ngFor="let leader of element.leaders" class="badge badge-success ms-1">
              {{ leader.first_name + ' ' + leader.last_name }}
            </span>
        </td>
      </ng-container>
      <!-- Members Count -->
      <ng-container matColumnDef="members_count">
        <th mat-header-cell *matHeaderCellDef>{{ 'TEAM.counts.members' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.members_count }} </td>
      </ng-container>
      <!-- Created at -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.created_at | date }} </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex">
            <a class="pe-3" [routerLink]="[element.id, 'edit']">{{ 'SHARED.edit' | translate }}</a>
            <a class="pe-3" (click)="deleteTeam(element.id)">{{ 'SHARED.delete' | translate }}</a>
          </div>
        </td>
      </ng-container>
    </mat-table>
    <!-- No Data -->
    <div *ngIf="isLoading < 1 && teams && teams.data.length < 1" style="padding: 50px">
      <h5 class="text-center">{{ 'SHARED.no_data' | translate: {models: 'TEAM.model_name.plural' | translate} }}</h5>
    </div>
    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                   [pageSizeOptions]="[5, 10, 20]" [pageIndex]="paginatorConfig.pageIndex"
                   (page)="paginatorChange($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
