import { Case } from '../Case/Case';
import { DialerTrigger } from '../Dialer/DialerTrigger';

export class CallStatus {
  id?: number;
  uuid?: string;

  slug?: string;
  name_en?: string;
  name_es?: string;
  removable?: boolean | 0 | 1;
  editable?: boolean | 0 | 1;
  for_packager?: boolean | 0 | 1;

  created_at?: string | Date;
  updated_at?: string | Date;

  // Accessors
  name?: string;

  cases?: Array<Case>;
  dialer_triggers?: Array<DialerTrigger>;
}
