import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { UploadService } from '../../../app-file/upload.service';
import { NoteService } from '../../note.service';

@Component({
  selector:    'app-quick-note',
  templateUrl: './quick-note.component.html',
  styles:      [],
})
export class QuickNoteComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive   = false;
  public isLoading    = 0;
  public executingPayment: boolean;
  public paymentResponse: boolean;
  public quillModules = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  constructor(private fb: UntypedFormBuilder,
              private uploadService: UploadService,
              private noteService: NoteService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              public dialogRef: MatDialogRef<QuickNoteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(reFetch): void {
    this.dialogRef.close(reFetch);
  }

  buildForm() {
    this.form = this.fb.group({
      type:    ['note', Validators.required],
      content: ['', Validators.required],
    });

    this.formActive = true;
  }

  uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public onSubmit() {
    const {type} = this.form.value;

    if (this.form.valid) {
      const data = {
        type,
        note: this.form.value.content,
      };
      this.isLoading++;
      this.noteService.store(this.data.case.id, data).pipe(finalize(() => this.isLoading--)).subscribe(
          res => {
            this.dialogRef.close();
            this.toastr.success(this.translateService.instant('CASES.single.quick-note-success'));
          },
          err => {
            this.dialogRef.close();
            this.toastr.error(this.translateService.instant('CASES.single.quick-note-error'));
          },
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
}
