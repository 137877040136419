import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from '../../../_shared/shared.module';
import { TaskModule } from '../../task/task.module';
import { TaskDashboardRoutingModule } from './task-dashboard-routing.module';
import { TaskDashboardComponent } from './task-dashboard.component';

@NgModule({
  declarations: [
    TaskDashboardComponent,
  ],
  imports:      [
    SharedModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide:    DateAdapter,
      useFactory: adapterFactory,
    }),
    TaskModule,
    TaskDashboardRoutingModule,
  ],
})
export class TaskDashboardModule {
}
