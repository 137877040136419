import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EmailTemplate } from '../../../../../../../_base-shared/models/Notification/EmailTemplate';
import { NotificationFromEvent } from '../../../../../../../_base-shared/models/Notification/NotificationFromEvent';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { EmailService } from '../email.service';

@Component({
  selector:    'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls:   ['./email-list.component.scss'],
})
export class EmailListComponent implements OnInit {
  public authUser: User;
  public notifications: NotificationFromEvent[];
  displayedColumns: string[] = [
    'id',
    'label',
    'description',
    'fromable',
    'subject',
    'active',
    'actions',
  ];
  actions: string[]          = ['Edit', 'Delete'];
  public isLoading           = 0;
  public dataSource: MatTableDataSource<EmailTemplate>;
  public paginatorConfig     = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search              = new UntypedFormControl('');
  public searchFocus         = false;

  constructor(
    private emailService: EmailService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
  ) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    });

    this.fetchEmailTemplates();
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.fetchEmailTemplates());
  }

  fetchEmailTemplates(): void {
    this.dataSource = new MatTableDataSource<EmailTemplate>([]);
    this.isLoading++;
    const data = {
      per_page:   this.paginatorConfig.pageSize,
      page:       this.paginatorConfig.pageIndex + 1,
      search:     this.search.value,
      sort_by:    'label',
      sort_order: 'asc',
    };

    const relations = ['fromable'];

    this.emailService.index(data, relations)
      .pipe(
        finalize(() => this.isLoading--),
      )
      .subscribe(res => {
        this.notifications          = res.data;
        this.dataSource             = new MatTableDataSource<EmailTemplate>(res.data);
        this.dataSource.sort        = this.sort;
        this.paginatorConfig.length = res.meta.total;
      });
  }

  openDeleteDialog(emailId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('CONFIG.email-template.delete-email-template-confirm'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteEmail(emailId);
      }
    });
  }

  deleteEmail(emailId: number): void {
    this.emailService.delete(emailId)
      .subscribe(res => {
          this.fetchEmailTemplates();
          this.toastr.success(
            this.translate.instant('CONFIG.email-template.delete-email-template-success'),
            this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CONFIG.email-template.delete-email-template-error'),
            this.translate.instant('SHARED.error'),
          );
        });
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchEmailTemplates();
  }

  onChange(): void {
    this.fetchEmailTemplates();
  }

  toggleFocus(setFocus: boolean): void {
    this.searchFocus = setFocus;
  }
}
