import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { ReportService } from '../../report.service';

@Component({
  selector:    'app-packager-sales-report',
  templateUrl: './packager-sales-report.component.html',
  styleUrls:   ['./packager-sales-report.component.scss'],
})
export class PackagerSalesReportComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading                  = 0;
  public packagers: Array<Packager> = [];

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PackagerSalesReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private packagerService: AdminPackagerService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchPackagers();
  }

  buildForm(): void {
    this.form = this.fb.group({
      start_date:   [null, Validators.required],
      end_date:     [null, Validators.required],
      packager_ids: [null, Validators.required],
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      start_date: this.form.value.start_date.toISOString(),
      end_date:   this.form.value.end_date.toISOString(),
      'packager_ids[]': this.form.value.packager_ids
    };
    this.isLoading++;
    this.reportService.generatePackagerSalesReport(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
          // saveAs(res.body, fileName);
          this.dialogRef.close(true);
          this.toast.success(res.message);

        },
        err => console.error(err),
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
      );
  }
}
