import { AppFile } from '../File/AppFile';
import { Address } from '../Location/Address';
import { BankAccount } from '../Payment/BankAccount';
import { ConfigurationWithPivot } from '../System/Configuration';
import { User } from '../User/User';
import { ConfigurationPackager } from './ConfigurationPackager';

export class Packager {
  public id?: number;
  public uuid?: string;
  public logo_id?: number;
  public signature_id?: number;
  public slug?: string;
  public name_en?: string;
  public name_es?: string;
  public email: string;
  public master: boolean;
  public configurations?: Array<ConfigurationPackager>;
  public public_configurations?: Array<ConfigurationWithPivot>;

  created_at?: string | Date;
  updated_at?: string | Date;

  // Accessors
  public name?: string;

  // Relations
  logo?: AppFile;
  signature?: AppFile;
  users?: Array<User>;
  bank_account?: BankAccount;
  address?: Address;
}
