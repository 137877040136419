import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { PaymentPlanSchedule } from '../../../../../../../../_base-shared/models/Payment/PaymentPlanSchedule';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { PaymentService } from '../../../../payment/payment.service';
import {
  ScheduledPaymentPlanModalComponent
} from '../scheduled-payment-plan-modal/scheduled-payment-plan-modal.component';

@Component({
  selector:    'app-scheduled-payment-plan-list',
  templateUrl: './scheduled-payment-plan-list.component.html',
  styleUrls:   []
})
export class ScheduledPaymentPlanListComponent implements OnInit, OnDestroy {
  @Input() componentType: 'distribution' | 'admin';
  @Input() case: Case;
  @Input() fetchPlanSchedules: EventEmitter<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public paymentPlanSchedules: MatTableDataSource<PaymentPlanSchedule>;
  public displayedColumns: Array<string>     = [];
  private subscriptions: Array<Subscription> = [];

  public sortOrder = null;
  public sortBy    = null;
  public isLoading = 0;
  public authUser: User;

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private paymentService: PaymentService,
  ) {
  }

  ngOnInit(): void {
    this.getTableColumns();
    this.fetchScheduledPaymentPlans();
    this.fetchPlanSchedules.subscribe(next => this.fetchScheduledPaymentPlans());
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public sortData(sort) {
    this.sortOrder = sort.direction !== '' ? sort.direction : null;
    this.sortBy    = sort.direction !== '' ? sort.active : null;
  }

  public editPlanSchedule(paymentPlanSchedule: PaymentPlanSchedule) {
    const relations = ['author', 'installments', 'payments'];
    const dialogRef = this.dialog.open(ScheduledPaymentPlanModalComponent, {
      width: '60%',
      data:  {
        case:          this.case,
        paymentPlanSchedule,
        loadRelations: relations
      },
    });
    console.log('opened the modal');

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && ! result.dismissed && result.data) {
        this.updateLocalModel(result.data);
      }
    });
  }

  public cancelPlanSchedule(paymentPlanId: number) {
    const relations = ['author', 'installments', 'payments'];

    this.isLoading++;
    this.subscriptions.push(
      this.paymentService.cancelScheduledPaymentPlan(this.case.id, paymentPlanId, relations).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  result => {
            this.updateLocalModel(result.data);
            this.toastr.success(this.translate.instant('SHARED.success'));
          },
          error: err => console.error(err)
        })
    );
  }

  public sendPlanScheduleToClient(paymentPlanId: number) {
    const relations = ['author', 'installments', 'payments'];
    const request   = {channels: ['mail']};

    this.isLoading++;
    this.subscriptions.push(
      this.paymentService.sendPlanScheduleToClient(this.case.id, paymentPlanId, request, relations).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  result => {
            this.updateLocalModel(result.data);
            this.toastr.success(this.translate.instant('SHARED.success'));
          },
          error: err => console.error(err)
        })
    );
  }

  public deletePlanSchedule(paymentPlanId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.paymentService.deleteScheduledPaymentPlan(this.case.id, paymentPlanId).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  result => {
            const filteredScheduledPaymentPlans = this.paymentPlanSchedules.data;
            const planIndex                     = filteredScheduledPaymentPlans.findIndex(plan => plan.id === paymentPlanId);
            filteredScheduledPaymentPlans.splice(planIndex, 1);
            this.paymentPlanSchedules = new MatTableDataSource<PaymentPlanSchedule>(filteredScheduledPaymentPlans);
            this.toastr.success(this.translate.instant('SHARED.success'));
          },
          error: err => console.error(err)
        })
    );
  }

  private getTableColumns(): void {
    this.displayedColumns = [
      'created_at',
      'start_from',
      'installment_amount',
      'installment_count',
      'total_amount_sum',
      'payments_total_amount_sum',
      'author',
      'notified_at',
      'activated_at',
      'actions'
    ];
  }

  private fetchScheduledPaymentPlans(): void {
    const relations = ['author', 'installments', 'payments'];
    this.isLoading++;
    this.subscriptions.push(
      this.paymentService.indexScheduledPaymentPlans(this.case.id, {select_all: 1}, relations).pipe(finalize(() => this.isLoading--))
        .subscribe({
          next:  res => {
            const resultData                    = res.data.map(scheduledPaymentPlan => {
              return this.remapPlanSchedule(scheduledPaymentPlan);
            });
            this.paymentPlanSchedules           = new MatTableDataSource<PaymentPlanSchedule>(resultData);
            this.paymentPlanSchedules.paginator = this.paginator;
            this.paymentPlanSchedules.sort      = this.sort;
          },
          error: err => console.error(err)
        })
    );
  }

  private remapPlanSchedule(planSchedule: PaymentPlanSchedule): PaymentPlanSchedule {
    let totalAmountSum = 0;
    if (planSchedule.payments?.length) {
      planSchedule.payments.forEach(payment => totalAmountSum += +payment.amount);
    }

    return {...planSchedule, payments_total_amount_sum: totalAmountSum};
  }

  private updateLocalModel(planSchedule: PaymentPlanSchedule): void {
    const filteredScheduledPaymentPlans = this.paymentPlanSchedules.data;
    const planIndex                     = filteredScheduledPaymentPlans.findIndex(plan => plan.id === planSchedule.id);
    if (planIndex >= 0) {
      filteredScheduledPaymentPlans[planIndex] = this.remapPlanSchedule(planSchedule);
    } else {
      console.log('pushing');
      console.log(this.remapPlanSchedule(planSchedule));
      filteredScheduledPaymentPlans.push(this.remapPlanSchedule(planSchedule));
    }
    this.paymentPlanSchedules = new MatTableDataSource<PaymentPlanSchedule>(filteredScheduledPaymentPlans);
  }
}
