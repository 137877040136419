<div class="row" [formGroup]="parentForm">
  <div class="col-6">
    <app-input *ngIf="statusCategories?.length" type="select" [formControl]="statusCategoryControl"
               appearance="fill" [searchable]="true" [multiple]="statusMultipleSelection"
               [label]="'CASES.single.general.status_editor.status_category' | translate"
               [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
               (ngModelChange)="updateSelectedStatusCategories($event)"
               [showClear]="false" fullWidth="false">
    </app-input>
  </div>
  <div class="col-6">
    <app-input *ngIf="filteredStatusCategories?.length" type="select" [formControlName]="statusFormControlName"
               appearance="fill" [searchable]="true" [multiple]="statusMultipleSelection"
               [label]="'CASES.single.general.status_editor.status' | translate"
               [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
               [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
               [showClear]="false" fullWidth="false">
    </app-input>
  </div>
</div>
