import { Component, Input } from '@angular/core';
import { RedsysOrder } from '../../../../../../../../../_base-shared/models/Payment/Transaction/RedsysOrder';

@Component({
  selector: 'app-redsys-details',
  templateUrl: './redsys-details.component.html',
  styleUrls: ['./redsys-details.component.scss']
})
export class RedsysDetailsComponent {
  @Input() redsysOrder: RedsysOrder;
}
