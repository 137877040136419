<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h5>
          <span *ngIf="editorType === 'create'">{{ 'DISTRIBUTION.provider.editor.heading_create' | translate }}</span>
          <span *ngIf="editorType === 'edit'">{{ 'DISTRIBUTION.provider.editor.heading_edit' | translate }}</span>
        </h5>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
      </div>
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)" class="form">
        <div class="row">
          <!-- Company Name -->
          <app-input type="text" class="col-6" formControlName="company_name" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.company_name' | translate">
          </app-input>
          <!-- Products -->
          <app-input type="select" class="col-6" formControlName="product_ids" appearance="fill" multiple
                     [fullWidth]="true" [label]="'PRODUCT.model_name.plural' | translate" [searchable]="true"
                     [selectOptions]="products" [selectLabel]="'name'" [selectValue]="'id'">
          </app-input>
          <!-- Users -->
          <app-input type="select" class="col-6" formControlName="user_ids" appearance="fill" multiple
                     [fullWidth]="true" [label]="'USERS.model_name.plural' | translate" [searchable]="true"
                     [selectOptions]="distributionUsers" [selectLabel]="['first_name', 'last_name']"
                     [selectValue]="'id'" >
          </app-input>
          <!-- Payment Processor -->
          <app-input type="select" class="col-6" formControlName="payment_processor_id" appearance="fill"
                     [fullWidth]="true" [label]="'DISTRIBUTION.provider.model.payment_processor_id' | translate"
                     [selectOptions]="paymentProcessors" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
          </app-input>
          <!-- Min Monthly Payment -->
          <app-input type="number" class="col-6" formControlName="min_monthly_payment" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.min_monthly_payment' | translate">
          </app-input>
          <!-- Min Contract Value -->
          <app-input type="number" class="col-6" formControlName="min_contract_value" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.min_contract_value' | translate">
          </app-input>
          <!-- Max Installments -->
          <app-input type="number" class="col-6" formControlName="max_installments" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.max_installments' | translate">
          </app-input>
          <!-- Funding Amount Percentage -->
          <app-input type="number" class="col-6" formControlName="funding_amount_percentage" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.funding_amount_percentage' | translate">
          </app-input>
          <!-- Cash Hurdle Percentage -->
          <app-input type="number" class="col-6" formControlName="cash_hurdle_percentage" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.cash_hurdle_percentage' | translate">
          </app-input>
          <!-- Retention Percentage -->
          <app-input type="number" class="col-6" formControlName="retention_percentage" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.retention_percentage' | translate">
          </app-input>
          <!-- Client Account Iban -->
          <app-input type="text" class="col-6" formControlName="client_account_iban" appearance="fill"
                     fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.client_account_iban' | translate">
          </app-input>
          <!-- Contact Person -->
          <app-input type="text" class="col-6" formControlName="contact_person" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.contact_person' | translate">
          </app-input>
          <!-- Payment Iban -->
          <app-input type="text" class="col-6" formControlName="payment_iban" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.payment_iban' | translate">
          </app-input>
          <!-- Address -->
          <app-input type="text" class="col-6" formControlName="address" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.address' | translate">
          </app-input>
          <!-- Email -->
          <app-input type="email" class="col-6" formControlName="email" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.email' | translate">
          </app-input>
          <!-- Phone -->
          <app-input type="number" class="col-6" formControlName="phone" appearance="fill" fullWidth="true"
                     [label]="'DISTRIBUTION.provider.model.phone' | translate">
          </app-input>
        </div>
        <!-- Submit -->
        <div class="row mt-5 mb-2">
          <div class="col-12 text-end">
            <app-spinner-btn type="submit" [loading]="isSubmitting"
                             [name]="(editorType === 'create' ? 'SHARED.save' :  'SHARED.update') | translate">
            </app-spinner-btn>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
