import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DripCampaign } from '../../../../../../../_base-shared/models/Notification/DripCampaign';
import { DripCampaignService } from '../drip-campaign.service';

@Component({
  selector:    'app-drip-campaign-list',
  templateUrl: './drip-campaign-list.component.html',
  styles:      [
    `
      table {
        width: 100%;
      }
    `,
  ],
})
export class DripCampaignListComponent implements OnInit {
  public dripCampaigns: Array<DripCampaign>;
  public displayedColumns: Array<string> = [
    'id',
    'name',
    'active',
    'statusable_type',
    'statusable',
    'drip_notifications_count',
    'created_at',
    'actions',
  ];
  public actions: Array<string>          = ['Delete'];
  public isLoading                       = 0;
  public dataSource: MatTableDataSource<DripCampaign>;
  public paginatorConfig                 = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public search                          = new UntypedFormControl('');
  public searchFocus                     = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor (private toastr: ToastrService,
    public translateService: TranslateService,
    private dripCampaignService: DripCampaignService) {
  }

  ngOnInit (): void {
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.fetchDripCampaigns());
    this.fetchDripCampaigns();

  }

  public fetchDripCampaigns () {
    this.dataSource = new MatTableDataSource<DripCampaign>([]);
    this.isLoading++;
    const paginator = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };

    this.dripCampaignService.index(paginator, ['statusable']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.dripCampaigns          = result.data;
      this.dataSource             = new MatTableDataSource<DripCampaign>(this.dripCampaigns);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = result.meta.total;
    });
  }

  public openDeleteDialog (id: number, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteCampaign(id);
      }
    });
  }

  private deleteCampaign (id: number) {
    this.dripCampaignService.destroy(id).subscribe(
      result => {
        this.fetchDripCampaigns();
        this.toastr.success(this.translateService.instant('CONFIG.status.status-deleted'));
      },
      error => this.toastr.error(this.translateService.instant('SHARED.went-wrong')),
    );
  }

  public paginatorChange ($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchDripCampaigns();
  }
}
