<mat-dialog-content>
  <h4 class="text-center">{{ 'TASK.detail.title' | translate }}</h4>
  <div class="text-end">
    <button mat-raised-button color="primary" (click)="editTask(task)">
      {{ "SHARED.edit" | translate }}
    </button>
  </div>
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isLoading && task">
    <div>
      <div class="task-card-background mt-2 mb-2">
        <app-task-preview [task]="task"></app-task-preview>
      </div>
      <h5 *ngIf="task.notes">{{ 'TASK.detail.notes' | translate }}</h5>
      <div [innerHTML]="task.notes"></div>
      <form *ngIf="form" [formGroup]="form">
        <mat-form-field appearance="fill" class="input-full-width">
          <mat-label>{{ 'TASK-TEMPLATE.editor.completion_note' | translate }}</mat-label>
          <textarea matInput formControlName="completion_note" cdkTextareaAutosize rows="5" style="max-height: 358px"></textarea>
          <div *ngIf="form.get('completion_note').errors && (form.get('completion_note').touched || formSubmitted)">
            <mat-error [hidden]="!form.get('completion_note').hasError('required')">
              {{ "SHARED.field-required" | translate }}
            </mat-error>
          </div>
          <small *ngIf="task.require_completion_note">{{ 'TASK.detail.require_completion_note_info' | translate }}</small>
        </mat-form-field>
      </form>
    </div>
    <div class="task-actions text-end">
      <button *ngIf="!task.completed_at" mat-raised-button color="primary" (click)="confirmCompleteTask(task, form)">
        {{ 'TASK.detail.actions.confirm-complete.confirm-button' | translate }}
      </button>
      <button class="ms-4" mat-raised-button color="primary" mat-dialog-close (click)="viewCase(task)">
        {{ 'TASK.detail.actions.view-case' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
