<div class="row pb-3">
  <div class="col-12">
    <h4>{{"CONFIG.status.header" | translate}}</h4>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      + {{"CONFIG.status.add-status" | translate}}</button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5">
    <app-input type="text" [formControl]="search" [label]="'SHARED.search' | translate"
               [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true"
               [prefix]="'search'" [prefixType]="'icon'" (ngModelChange)="onChange()"></app-input>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource">
    <!-- Headers -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Position -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{ "SHARED.model.name" | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <!-- Flag Case Active -->
    <ng-container matColumnDef="flag_case_active">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ "CONFIG.status.editor.form.flag_case_active" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.flag_case_active" class="badge badge-success">
          {{ 'CASES.list.filters.activity_status.options.active' | translate }}
        </span>
        <span *ngIf="!element.flag_case_active" class="badge badge-danger">
          {{ 'CASES.list.filters.activity_status.options.inactive' | translate }}
        </span>
      </td>
    </ng-container>
    <!-- Parent Status -->
    <ng-container matColumnDef="parent_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"CONFIG.status.parent-status" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <span class="badge badge-primary">{{element.parent?.name}}</span>
      </td>
    </ng-container>
    <!-- Created at -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | toDateObject | date }}  </td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
            <a *ngIf="action === 'Edit'" [routerLink]="[element.id, 'edit']">{{"SHARED.edit" | translate}}</a>
            <a *ngIf="action === 'Delete'" (click)="openDeleteDialog(element.id, $event)">
              {{"SHARED.delete" | translate}}
            </a>
          </p>
        </div>
      </td>
    </ng-container>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{"SHARED.loading" | translate}}...</span>
    </div>
  </div>

  <mat-paginator
          [length]="paginatorConfig.length"
          [pageSize]="paginatorConfig.pageSize"
          [pageSizeOptions]="[5, 10, 20]"
          (page)="paginatorChange($event)"
          showFirstLastButtons
  ></mat-paginator>
</div>
