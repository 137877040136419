<div [formGroup]="form" (ngSubmit)="submitForm(form)">
    <div class="row pb-3">
        <div class="col-12 datepicker-container">
            <!-- Start Date -->
            <app-input type="mat-datepicker" formControlName="start_date" appearance="fill"
                       label='{{ "PAYMENTS.start-date" | translate }}'>
            </app-input>
            <!-- End Date -->
            <app-input type="mat-datepicker" formControlName="end_date" appearance="fill"
                       label='{{ "PAYMENTS.end-date" | translate }}'>
            </app-input>
            <div class="d-inline-block ps-5">
                <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                                 (change)="dateModifierChange($event)">
                    <mat-radio-button value="day">
                        {{ "DASHBOARD.heads_up_dash.filters.radio.today" | translate }}
                    </mat-radio-button>
                    <mat-radio-button class="ms-3" value="isoWeek">
                        {{ "DASHBOARD.heads_up_dash.filters.radio.this_week" | translate }}
                    </mat-radio-button>
                    <mat-radio-button class="ms-3" value="month">
                        {{ "DASHBOARD.heads_up_dash.filters.radio.this_month" | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <app-spinner-btn type="submit" [name]="'SHARED.apply' | translate" class="ms-2" [loading]="(isLoadingCollections +
             isLoadingDistribution + isLoadingLsoPerformance + isLoadingDmPerformance + isLoadingBookPerformance) > 0"
                             (click)="submitForm(form)">
            </app-spinner-btn>
        </div>
    </div>
</div>
<div class="row">
    <!-- Collections -->
    <div class="col-6">
        <div class="card shadow mb-4" style="height: 100%">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center" style="height: 40px">
                    <div>
                        <h5> {{"DASHBOARD.heads_up_dash.headings.collections_billing" | translate }}</h5>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex flex-column">
                <div [hidden]="!isLoadingCollections" class="col-12">
                    <mat-spinner class="m-0 m-auto"></mat-spinner>
                </div>
                <ng-container *ngIf="collectionsSource">
                    <!-- Total Book Value -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.amount_to_be_collected" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.amount_to_be_collected | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- Fees collected -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.payments_collected_amount" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.payments_collected_amount | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- Refunds -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.payments_refunded_amount" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.payments_refunded_amount | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- LSO Fees collected -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.lso_payments_collected_amount" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.lso_payments_collected_amount | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- DM Fees collected -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.dm_payments_collected_amount" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.dm_payments_collected_amount | currency: 'EUR' }}</p>
                        </div>
                    </div>
                  <!-- Dgs Fees collected -->
                  <div class="row align-items-baseline">
                    <mat-label class="col-6">
                      {{"DASHBOARD.heads_up_dash.collections.dgs_payments_collected_amount" | translate }}
                    </mat-label>
                    <div class="col-6">
                      <p class="text-end"
                         style="padding-top: 7px;">{{ collectionsSource.dgs_payments_collected_amount | currency: 'EUR' }}</p>
                    </div>
                  </div>
                    <!-- In time-frame arrears % -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.collections.arrears_percentage" | translate }}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ collectionsSource.arrears_percentage | number:'1.2-2' }}
                                %</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- Distribution -->
    <div class="col-6">
        <div class="card shadow mb-4" style="height: 100%">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center" style="height: 40px">
                    <div>
                        <h5>{{"DASHBOARD.heads_up_dash.headings.distribution" | translate }}</h5>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex flex-column">
                <div [hidden]="!isLoadingDistribution" class="col-12">
                    <mat-spinner class="m-0 m-auto"></mat-spinner>
                </div>
                <ng-container *ngIf="distributionSource">
                    <!-- Batched Cases -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.distribution.submitted_cases_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ distributionSource.submitted_cases_count }}</p>
                        </div>
                    </div>
                    <!-- Cash Hurdle -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.distribution.total_cash_hurdle" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ distributionSource.total_cash_hurdle | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- Post Hurdle Fees -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.distribution.total_fees_retained" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ distributionSource.total_fees_retained | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- Amount funded -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.distribution.total_funded_amount" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ distributionSource.total_funded_amount | currency: 'EUR' }}</p>
                        </div>
                    </div>
                    <!-- Default Rate -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.distribution.default_rate_percentage" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ distributionSource.default_rate_percentage | number:'1.2-2' }}
                                %</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- LSO Performance -->
    <div class="col-6 mb-4">
        <div class="card shadow mb-4 mt-4" style="height: 100%">
            <div class="card-header">
                <div class="d-flex justify-content-between align-items-center" style="height: 40px">
                    <div>
                        <h5>{{ 'DASHBOARD.heads_up_dash.headings.lso_performance' | translate }}</h5>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex flex-column">
                <div [hidden]="!isLoadingLsoPerformance" class="col-12">
                    <mat-spinner class="m-0 m-auto"></mat-spinner>
                </div>
                <ng-container *ngIf="lsoPerformanceSource">
                    <!-- LSO Clients Active  -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_cases_active_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_cases_active_count }}</p>
                        </div>
                    </div>
                    <!-- LSO New sales -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_cases_assigned_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_cases_assigned_count }}</p>
                        </div>
                    </div>
                    <!-- LSO Exoneration -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_te_assigned_cases_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_te_assigned_cases_count }}</p>
                        </div>
                    </div>
                    <!-- LSO Payment Plans  -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_pp_assigned_cases_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_pp_assigned_cases_count  }}</p>
                        </div>
                    </div>
                    <!-- LSO Liquidation -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_lq_assigned_cases_count" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_lq_assigned_cases_count }}</p>
                        </div>
                    </div>
                    <!-- LSO Book Value -->
                    <div class="row align-items-baseline">
                        <mat-label class="col-6">
                            {{"DASHBOARD.heads_up_dash.lso.lso_amount_to_be_collected" | translate}}
                        </mat-label>
                        <div class="col-6">
                            <p class="text-end"
                               style="padding-top: 7px;">{{ lsoPerformanceSource.lso_amount_to_be_collected | currency: 'EUR' }}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
  <!-- DGS Performance -->
  <div class="col-6 mb-4">
    <div class="card shadow mb-4 mt-4" style="height: 100%">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center" style="height: 40px">
          <div>
            <h5>{{"DASHBOARD.heads_up_dash.headings.dgs_performance" | translate }}</h5>
          </div>
        </div>
      </div>
      <div class="card-body d-flex flex-column">
        <div [hidden]="!isLoadingDgsPerformance" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <ng-container *ngIf="dgsPerformanceSource">
          <!--DGS cases active-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"DASHBOARD.heads_up_dash.dgs.dgs_cases_active_count" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end"
                 style="padding-top: 7px;">{{ dgsPerformanceSource.dgs_cases_active_count }}</p>
            </div>
          </div>
          <!--DGS Plans Active-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"DASHBOARD.heads_up_dash.dgs.dgs_cases_payment_active_count" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end"
                 style="padding-top: 7px;">{{ dgsPerformanceSource.dgs_cases_payment_active_count }}</p>
            </div>
          </div>
          <!--DGS Sales-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"DASHBOARD.heads_up_dash.dgs.dgs_cases_assigned_count" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end"
                 style="padding-top: 7px;">{{ dgsPerformanceSource.dgs_cases_assigned_count }}</p>
            </div>
          </div>
          <!--DGS Sent-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"DASHBOARD.heads_up_dash.dgs.dgs_proposals_sent_count" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end"
                 style="padding-top: 7px;">{{ dgsPerformanceSource.dgs_proposals_sent_count }}</p>
            </div>
          </div>
          <!--DGS Agreed-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              {{"DASHBOARD.heads_up_dash.dgs.dgs_proposals_accepted_count" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end"
                 style="padding-top: 7px;">{{ dgsPerformanceSource.dgs_proposals_accepted_count }}</p>
            </div>
          </div>
          <!--DGS Book Value-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              DGS {{"DASHBOARD.heads_up_dash.dgs.dgs_amount_to_be_collected" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end" style="padding-top: 7px;">
                {{ dgsPerformanceSource.dgs_amount_to_be_collected | currency: 'EUR'}}
              </p>
            </div>
          </div>
          <!--DM Book Value-->
          <div class="row align-items-baseline">
            <mat-label class="col-6">
              DM {{"DASHBOARD.heads_up_dash.dm.dm_amount_to_be_collected" | translate}}
            </mat-label>
            <div class="col-6">
              <p class="text-end" style="padding-top: 7px;">
                {{ dmPerformanceSource?.dm_amount_to_be_collected | currency: 'EUR'}}
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- BOOK PERFORMANCE -->
<div class="col-6" style="padding-left: 0px;">
  <div class="card shadow mb-4 mt-4">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center" style="height: 40px">
        <div>
          <h5>{{"DASHBOARD.heads_up_dash.headings.book_performance" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <div [hidden]="!isLoadingBookPerformance" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <ng-container *ngIf="bookPerformanceSource">
        <!-- Total Active Cases -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_active_cases' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_active_cases }}</p>
          </div>
        </div>
        <!-- Total LSO -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_lso_cases_count' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_lso_cases_count }}</p>
          </div>
        </div>
        <!-- Total DM -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_dm_cases_count' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_dm_cases_count }}</p>
          </div>
        </div>
        <!-- Total Dgs -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_dgs_cases_count' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_dgs_cases_count }}</p>
          </div>
        </div>
        <!-- New Sales -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.new_sales_count' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.new_sales_count }}</p>
          </div>
        </div>
        <!-- 14 Day Cancels -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_14_day_cancels_count' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_14_day_cancels_count }}</p>
          </div>
        </div>
        <!-- Total Cancellations -->
        <div class="row align-items-baseline">
          <mat-label class="col-6">
            {{ 'DASHBOARD.heads_up_dash.book_performance.total_cancellations' | translate }}
          </mat-label>
          <div class="col-6">
            <p class="text-end"
               style="padding-top: 7px;">{{ bookPerformanceSource.total_cancellations }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
