import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';

import { Affiliate } from '../../../../../_base-shared/models/Affiliate/Affiliate';
import { User } from '../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { AffiliateMarketingChannel } from '../../../../../_base-shared/models/Affiliate/AffiliateMarketingChannel';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends MainBaseApiService {

  public index(requestData, relations: Array<string> = [],
               relationsCount: Array<string>         = []): Observable<LaravelResourceResponse<Array<Affiliate>>> {
    const params = MainBaseApiService.convertFiltersForRequest(
      { ...requestData, with: relations, with_count: relationsCount }, 'get'
    );
    return this.http.get<LaravelResourceResponse<Array<Affiliate>>>(this.apiUrl + '/affiliates', { params })
      .pipe(catchError(response => this.handleError(response)));
  }

  get(id: number, relations: Array<string> = []): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({ with: relations }, 'get');
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/affiliates/${ id }`, { params })
      .pipe(catchError(response => this.handleError(response)));
  }

  store(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/affiliates`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(affiliateId: number, data): Observable<LaravelResourceResponse> {
    return this.http.put<LaravelResourceResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(affiliateId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getProducts(affiliateId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/affiliates/${ affiliateId }/products`)
      .pipe(catchError(response => this.handleError(response)));
  }

  getExport(filters): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/affiliate/cases/export`, { params: filters })
      .pipe(catchError(response => this.handleError(response)));
  }

  public getAffiliateUsers(data): Observable<LaravelResourceResponse<Array<User>>> {
    return this.http.get<LaravelResourceResponse<Array<User>>>(`${ this.apiUrl }/affiliate/users`, { params: data })
      .pipe(catchError(response => this.handleError(response)));
  }

  public getAffiliateAffiliates(data): Observable<LaravelResourceResponse<Array<Affiliate>>> {
    return this.http.get<LaravelResourceResponse<Array<Affiliate>>>(`${ this.apiUrl }/affiliate/affiliates`,
      { params: data })
      .pipe(catchError(response => this.handleError(response)));
  }

  public getMarketingChannels(): Observable<LaravelResourceResponse<Array<AffiliateMarketingChannel>>> {
    return this.http.get<LaravelResourceResponse<Array<AffiliateMarketingChannel>>>(`${ this.apiUrl }/affiliate-marketing-channels`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getAffiliateVariables(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/affiliate-postback-variables`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getLeadConversionTypes(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/lead-conversion-types`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getBonusTiers(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/bonus-tiers`)
      .pipe(catchError(response => this.handleError(response)));
  }
}
