import { Affiliate } from './Affiliate';
import { AffiliateMarketingChannel } from './AffiliateMarketingChannel';

export class AffiliateCampaign {
  id: number;
  affiliate_id: number;
  marketing_channel_id: number;
  tracking_id: string;
  name: string;
  default: boolean;
  created_at: string | Date;
  updated_at: string | Date;

  // Relations
  affiliate?: Affiliate;
  marketing_channel?: AffiliateMarketingChannel;
}
