<h5 class="pb-5">{{ "REPORTS.headline-reports" | translate }}</h5>
<div class="row px-1">
  <div class="col-md-4" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-body text-center">
        <h5>{{ "REPORTS.total-unpaid-fees" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{totalUnpaidFee | number: '1.2-2'}}€</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-body text-center">
<!--        <h5>{{ "REPORTS.total-fees-this-month" | translate }}</h5>-->
<!--        <div>-->
<!--          <span class="text-primary" style="font-size: 25px">{{feesThisMonth | number: '1.2-2'}}€</span>-->
<!--        </div>-->
        <h5>{{ "REPORTS.cancellation-percent" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{cancellationPercent}}%</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4" style="padding: 0">
    <div class="card mb-4 h-100">
      <div class="card-body text-center">
        <h5>{{ "REPORTS.default-percent" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{defaultPercent}}%</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row px-1 pt-5">
  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>{{ "REPORTS.clients-gastion-documental" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{clientsGestion}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>Clients in Extra Judicial</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{extraJudicial}}</span>
        </div>
      </div>
    </div>
  </div>


  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>{{ "REPORTS.clients-judiscial" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{clientsJudicial}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>{{ "REPORTS.clients-frozen" | translate }}</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{frozenClients}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row px-1">
  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>Documentacion Completa Last Month</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{documentacionCompleta}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>Notaria Citado Last Month</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{notariaCitadoLastMonth}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>Plan De Pagos Aprobado</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{planDePagos}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" style="padding: 0">
    <div class="card mb-4">
      <div class="card-body text-center">
        <h5>Clients Exonerated</h5>
        <div>
          <span class="text-primary" style="font-size: 25px">{{clientsExonerated}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
