<table class="table table-bordered">
  <tbody>
  <tr>
    <th style="width: 20%;">Order code</th>
    <td>{{ arcopayOrder.arco_uid }}</td>
  </tr>
  <tr>
    <th>Payment Processor</th>
    <td>{{ arcopayOrder.payment_processor?.slug }}</td>
  </tr>
  <tr>
    <th>Amount</th>
    <td>{{ (arcopayOrder.amount_100 / 100) | currency: "EUR" }}</td>
  </tr>
  <tr>
    <th>Currency</th>
    <td>{{ arcopayOrder.currency }}</td>
  </tr>
  <tr>
    <th>Success</th>
    <td>{{ arcopayOrder.is_success }}</td>
  </tr>
  <tr>
    <th>Error</th>
    <td>{{ arcopayOrder.error }}</td>
  </tr>
  <tr>
    <th>Completed at</th>
    <td>{{ arcopayOrder.completed_at }}</td>
  </tr>
  </tbody>
</table>
