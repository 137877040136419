import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { UserDepartmentChange } from '../../../../../../../../../_base-shared/models/Case/UserDepartmentChange';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-departments-assignment-logs',
  templateUrl: './case-departments-assignment-logs.component.html',
  styles:      []
})
export class CaseDepartmentsAssignmentLogsComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                           = 0;
  public caseDepartmentsAssignmentLogs: Array<UserDepartmentChange>;
  private subscriptions: Array<Subscription> = [];

  constructor (private caseService: CaseService) {
  }

  ngOnInit (): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy (): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs (caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.getCaseDepartmentsAssignmentsLogs(caseId, ['user_assigned', 'user_unassigned', 'user_changed_by', 'department'],
        {select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.caseDepartmentsAssignmentLogs = result.data,
      ),
    );

  }
}
