import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ScriptLoaderService } from '../../../../../_base-shared/services/script-loader.service';
import {
  BaseArcopayWidgetHandlerComponent
} from '../../../../../_base-shared/components/payment/base-arcopay-widget-handler/base-arcopay-widget-handler.component';
import { PaymentService } from '../../admin/payment/payment.service';
import { environment } from '../../../environments/environment';

@Component({
  selector:    'app-main-arcopay-widget-handler',
  templateUrl: '../../../../../_base-shared/components/payment/base-arcopay-widget-handler/base-arcopay-widget-handler.component.html',
  styleUrls:   []
})
export class MainArcopayWidgetHandlerComponent extends BaseArcopayWidgetHandlerComponent {

  constructor(protected route: ActivatedRoute,
              protected scriptLoaderService: ScriptLoaderService,
              protected paymentService: PaymentService
  ) {
    super(route, scriptLoaderService);
    this.arcopayShownBanks = environment.ARCOPAY_SHOWN_BANKS
  }

  protected fetchArcopayOrder(orderUuid: string): void {
    this.paymentService.getArcopayOrder(this.orderUuid)
      .pipe(finalize(() => this.isLoading--))
      .subscribe({
        next: result => {
          this.arcopayOrder = result.data;
          this.initArcopayOrderPayment(this.arcopayOrder);
        }
      });
  }
}
