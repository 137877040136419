<form [formGroup]="form" (ngSubmit)="onSubmit()" class="request-documents">
  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="dni_nie">DNI/NIE</mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div class="input-group flex-nowrap" [class.has-length]="isFocused.dni_nie_message" *ngIf="form.value.dni_nie">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="dni_nie_message"
          name="dni_nie_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="regulatory_agreement_of_the_judgment">
        Regulatory Agreement of the Judgment
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div
        class="input-group flex-nowrap"
        [class.has-length]="isFocused.regulatory_agreement_of_the_judgment_message"
        *ngIf="form.value.regulatory_agreement_of_the_judgment"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="regulatory_agreement_of_the_judgment_message"
          name="regulatory_agreement_of_the_judgment_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="family_book">
        Family Book
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div class="input-group flex-nowrap" [class.has-length]="isFocused.family_book_message" *ngIf="form.value.family_book">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="family_book_message"
          name="family_book_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="child_birth_certificate">
        Child birth certificate
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div
        class="input-group flex-nowrap"
        [class.has-length]="isFocused.child_birth_certificate_message"
        *ngIf="form.value.child_birth_certificate"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="child_birth_certificate_message"
          name="child_birth_certificate_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="martial_capitulations">
        Marital capitulations (divorce cert)
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div
        class="input-group flex-nowrap"
        [class.has-length]="isFocused.martial_capitulations_message"
        *ngIf="form.value.martial_capitulations"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="martial_capitulations_message"
          name="martial_capitulations_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="pensioner_certificate">
        Pensioner certificate
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div
        class="input-group flex-nowrap"
        [class.has-length]="isFocused.pensioner_certificate_message"
        *ngIf="form.value.pensioner_certificate"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="pensioner_certificate_message"
          name="pensioner_certificate_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="work_contract">
        Work contract
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div class="input-group flex-nowrap" [class.has-length]="isFocused.work_contract_message" *ngIf="form.value.work_contract">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="work_contract_message"
          name="work_contract_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="subsidy">
        Subsidy
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div class="input-group flex-nowrap" [class.has-length]="isFocused.subsidy_message" *ngIf="form.value.subsidy">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="subsidy_message"
          name="subsidy_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="deed_of_sale">
        Deed of sale
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div class="input-group flex-nowrap" [class.has-length]="isFocused.deed_of_sale_message" *ngIf="form.value.deed_of_sale">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="deed_of_sale_message"
          name="deed_of_sale_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="row pt-5">
    <div class="col-lg-6">
      <mat-checkbox color="primary" class="example-margin" formControlName="deed_of_mortgage_guarantee">
        Deed of mortgage guarantee
      </mat-checkbox>
    </div>
    <div class="col-lg-6">
      <div
        class="input-group flex-nowrap"
        [class.has-length]="isFocused.deed_of_mortgage_guarantee_message"
        *ngIf="form.value.deed_of_mortgage_guarantee"
      >
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fal fa-comment-dots"></i></span>
        </div>
        <input
          formControlName="deed_of_mortgage_guarantee_message"
          name="deed_of_mortgage_guarantee_message"
          type="text"
          class="form-control"
          placeholder="Message:"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
        >
      </div>
    </div>
  </div>

  <div class="text-end submit-btn-wrap">
    <app-spinner-btn [loading]="loading" name="Submit"></app-spinner-btn>
  </div>
</form>
