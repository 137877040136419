import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../reports/report.service';

@Component({
  selector: 'app-gclid-conversion-modal',
  templateUrl: './gclid-conversion-modal.component.html',
  styleUrls: ['./gclid-conversion-modal.component.scss']
})
export class GclidConversionModalComponent implements OnInit{
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<GclidConversionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      ...this.data,
      start_date: DateTime.fromJSDate(this.form.get('start_date').value).startOf('day').toJSDate().toISOString(),
      end_date:  DateTime.fromJSDate(this.form.get('end_date').value).endOf('day').toJSDate().toISOString(),
    };
    this.isLoading++;

    this.reportService.generateGCLIDReport(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          // const fileName = 'sales_report' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
          // saveAs(res.body, fileName);
          this.dialogRef.close(true);
          this.toast.success(res.message);

        },
        err => console.error(err),
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
