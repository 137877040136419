import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-splash-modal',
  templateUrl: './splash-modal.component.html',
  styleUrls: ['./splash-modal.component.scss']
})
export class SplashModalComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SplashModalComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
