import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseExpense } from '../../../../../_base-shared/models/Case/CaseExpense';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseExpenseService extends MainBaseApiService {
  public index(caseId: number) {
    return this.http.get<LaravelResourceResponse<CaseExpense>>(this.apiUrl + '/cases/' + caseId + '/expenses')
        .pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, caseAssets) {
    return this.http.put<LaravelResourceResponse<CaseExpense>>(
        this.apiUrl + '/cases/' + caseId + '/expenses', caseAssets,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
