import { AffiliateCampaign } from './AffiliateCampaign';
import { Lead } from '../Case/Lead';
import { Packager } from '../Packager/Packager';
import { Status } from '../Status/Status';
import { User } from '../User/User';
import { AffiliateProduct } from './AffiliateProductPivot';
import { MonthlyVolumeBonus } from './MonthlyVolumeBonus';
import { AffiliatePostback } from './AffiliatePostback';
import { BonusTier } from './BonusTier';

export class Affiliate {
  id?: number;
  packager_id?: number;
  name: string;
  overload_status_id?: number;
  track_id: string;
  daily_limit: number;
  active: boolean;
  allow_without_debt: boolean;
  allow_under_5000: boolean;
  allow_duplicates: boolean;
  postback_url: string;
  overwrite_cliente_regreso_status_id?: number;
  is_df_affiliate: boolean;
  allow_overwrite_df_affiliates: boolean;
  allow_overwrite_non_df_affiliates: boolean;
  volume_bonus_conversion_status_id?: number;

  created_at?: string | Date;
  updated_at?: string | Date;

  // Relations
  packager?: Packager;
  leads?: Array<Lead>;
  overload_status?: Status;
  user?: User;
  users?: Array<User>;
  products?: Array<AffiliateProduct>;
  postbacks?: Array<AffiliatePostback>;
  lead_creation_postbacks?: Array<AffiliatePostback>;
  primary_postbacks?: Array<AffiliatePostback>;
  secondary_postbacks?: Array<AffiliatePostback>;
  volume_bonus_postbacks?: Array<AffiliatePostback>;
  bonus_tiers?: Array<BonusTier>;
  campaigns?: Array<AffiliateCampaign>;
  default_campaign?: AffiliateCampaign;
  volume_bonuses?: Array<MonthlyVolumeBonus>;
  volume_bonus_conversion_status?: Status;
}
