import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Court } from '../../../../../../_base-shared/models/Entity/Court';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotariesService } from '../notaries/notaries.service';
import { AdministratorsService } from './administrators.service';
import Swal from 'sweetalert2';

@Component({
  selector:    'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls:   ['./administrators.component.scss']
})
export class AdministratorsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public search = new UntypedFormControl('');
  public searchFocus = false;
  displayedColumns: string[] = [
    'id',
    'name',
    'company_name',
    'approved',
    'address',
    'email_general',
    'submission_method',
    'actions',
  ];
  public paginatorConfig = {
    pageIndex: 0,
    pageSize: 10,
    length: 1
  };
  public isLoading = 0;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private administratorsService: AdministratorsService) { }

  ngOnInit(): void {
    this.fetchAdministrators();

    this.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(res => {
        this.fetchAdministrators();
      });
  }

  fetchAdministrators() {
    this.dataSource             = new MatTableDataSource<any>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page: this.paginatorConfig.pageIndex + 1,
      search: this.search.value
    };

    this.administratorsService.index(data).pipe(finalize(() => this.isLoading--)).subscribe(res => {
      this.dataSource             = new MatTableDataSource<Court>(res.data);
      this.dataSource.sort        = this.sort;
      this.paginatorConfig.length = res.data.total;
    });
  }

  toggleFocus(setFocus: boolean) {
    this.searchFocus = setFocus;
  }

  openDeleteDialog(id, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('ADDRESS-BOOK.delete-administrator-note'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteNotary(id);
      }
    });
  }

  public paginatorChange($event: any) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchAdministrators();
  }

  private deleteNotary(caseId: number) {
    this.administratorsService.delete(caseId).subscribe(
      res => {
        this.fetchAdministrators();
        this.toastr.success(this.translate.instant('ADDRESS-BOOK.delete-administrator-success'),
          this.translate.instant('SHARED.success'));
      },
      error => {
        this.toastr.error(error.error?.message, this.translate.instant('ADDRESS-BOOK.delete-administrator-error'),
          this.translate.instant('SHARED.error'));
      },
    );
  }

}
