import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseStatusLog } from '../../../../../../../../../_base-shared/models/Case/CaseStatusLog';
import { PackagerStatusLog } from '../../../../../../../../../_base-shared/models/Case/PackagerStatusLog';
import { PackagerService } from '../../../../../../packager/packager.service';

@Component({
  selector:    'app-packager-status-log',
  templateUrl: './packager-status-log.component.html',
  styles:      [],
})
export class PackagerStatusLogComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                           = 0;
  public packagerStatusLogs: Array<PackagerStatusLog>;
  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private packagerService: PackagerService) {
  }

  ngOnInit(): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.packagerService.getPackagerStatusLogs(caseId, {select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.packagerStatusLogs = result.data,
      ),
    );
  }
}
