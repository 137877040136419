import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import { DialerTriggerListWrapperComponent } from './dialer-trigger-list-wrapper/dialer-trigger-list-wrapper.component';
import { DialerListsComponent } from './dialer-lists/dialer-lists.component';
import { DialerRoutingModule } from './dialer-routing.module';
import { DialerComponent } from './dialer.component';
import { DialerStatusTriggerListComponent } from './dialer-trigger-list-wrapper/dialer-status-trigger-list/dialer-status-trigger-list.component';

@NgModule({
  declarations: [
    DialerComponent,
    DialerListsComponent,
    DialerTriggerListWrapperComponent,
    DialerStatusTriggerListComponent
  ],
  imports:      [
    SharedModule,
    DialerRoutingModule,
  ],
})
export class DialerModule {
}
