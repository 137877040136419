<div class="d-flex flex-row justify-content-between align-items-center">
  <h2>{{ 'CASES.single.general.relation_list.dialer_history_log.heading' | translate }}</h2>
</div>
<div class="pt-4 pl-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !dialerCallRequests?.length"
                   [message]="'CASES.single.general.relation_list.dialer_history_log.empty_dataset' | translate">
</app-empty-dataset>
<div class="row m-2" *ngFor="let dialerRequest of dialerCallRequests" [hidden]="isLoading">
  <div class="card col-12">
    <div class="card-body">
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.dialer_list_id' | translate }}
        : {{ dialerRequest.dialer_list_id }}</p>
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.priority' | translate }}
        : {{ dialerRequest.priority }}</p>
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.auto_queue' | translate }}
        : {{ dialerRequest.auto_queue ? 'Yes' : 'No' }}</p>
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.force_dial' | translate }}
        : {{ dialerRequest.force_dial ? 'Yes' : 'No' }}</p>
      <p
        *ngIf="!dialerRequest.completed_at">{{ 'CASES.single.general.relation_list.dialer_history_log.result.heading' | translate }}
        : {{ 'CASES.single.general.relation_list.dialer_history_log.result.options.pending' | translate }}</p>
      <p
        *ngIf="dialerRequest.completed_at">{{ 'CASES.single.general.relation_list.dialer_history_log.result.heading' | translate }}
        : {{ dialerRequest.success ? ('CASES.single.general.relation_list.dialer_history_log.result.options.success' | translate) :
          ('CASES.single.general.relation_list.dialer_history_log.result.options.failed' | translate) }}</p>
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.scheduled_for' | translate }}
        : {{ dialerRequest.scheduled_for | toDateObject | date: 'medium' }}</p>
      <p>{{ 'CASES.single.general.relation_list.dialer_history_log.completed_at' | translate }}
        : {{ dialerRequest.completed_at | toDateObject | date: 'medium' }}</p>
    </div>
  </div>
</div>
