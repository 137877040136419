<div *ngIf="isLoading" class="card mb-3">
  <div class="card-body">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
    </div>
  </div>
</div>

<div class="row outcome-wrap mb-5">
  <div class="offset-4"></div>
  <div class="col-sm-4">
    <div class="card shadow">
      <div class="card-header text-center selected">
        <h4 class="m-0 text-white">{{"CASES.loan.loan-calculator" | translate}}</h4>
      </div>
      <div class="card-body">
        <form *ngIf="form" [formGroup]="form">
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.original_debt_amount' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="original_debt_amount" [showClear]="false"
                       [label]="'CASES.loan.original_debt_amount' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.debt_settlement_amount' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="debt_settlement_agreed" [showClear]="false"
                       [label]="'CASES.loan.debt_settlement_amount' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.total_reduction' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="total_reduction" [showClear]="false"
                       readonly [label]="'CASES.loan.total_reduction' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.debt_reduction_fee' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="debt_reduction_fee" [showClear]="false"
                       readonly [label]="'CASES.loan.debt_reduction_fee' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.new_loan_amount' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="new_loan_amount" [showClear]="false"
                       readonly [label]="'CASES.loan.new_loan_amount' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.new_loan_term' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="phase_one_duration" [showClear]="false"
                       [label]="'CASES.loan.new_loan_term' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.total_repayable' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="repayable_amount" [showClear]="false"
                       readonly [label]="'CASES.loan.total_repayable' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-between">
            <p>{{'CASES.loan.monthly_repayments' | translate}}</p>
            <app-input type="number" appearance="outline" formControlName="phase_one_monthly_fee" [showClear]="false"
                       readonly [label]="'CASES.loan.monthly_repayments' | translate">
            </app-input>
          </div>
          <div class="outcome-input d-flex justify-content-start">
            <app-spinner-btn type="submit" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                             [disabled]="form?.invalid"
                             (click)="saveLoan(form)">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
