<div class="row d-flex flex-row justify-content-center">
  <div class="col-8">
    <h5 class="pb-5">{{ "REPORTS.financial-reports.title" | translate }}</h5>
    <div class="row px-1 financial-report-table">
      <table mat-table [dataSource]="dataSource">
        <!-- Report Name Column -->
        <ng-container matColumnDef="reportName">
          <th mat-header-cell *matHeaderCellDef>{{'REPORTS.financial-reports.report-name' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.reportName | translate }}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>{{'REPORTS.financial-reports.description' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.description | translate }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{'REPORTS.financial-reports.actions' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" (click)="handleElementAction($event,element.actionName)">
              {{ element.actionText | translate }}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="w-100">
        <div class="d-flex flex-row justify-content-between py-5">
          <h4>{{ "REPORTS.financial-reports.jobs-list.title" | translate }}</h4>
          <div class="text-end">
            <button mat-icon-button color="primary" (click)="fetchQueuedJobs()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="isLoadingJobs" class="text-center">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isLoadingJobs && !appJobs.length" class="pt-5 text-center">
          <h5>{{ "REPORTS.financial-reports.jobs-list.no-items" | translate }}</h5>
        </div>
        <mat-card>
          <mat-card-content>
            <mat-list *ngIf="appJobs.length" style="background-color: white">
              <mat-list-item *ngFor="let appJob of appJobs">
                <div matLine>
                  <span *ngIf="!appJob.user_id">{{ "SHARED.system" | translate }}</span>
                  <span *ngIf="appJob.user_id">{{ appJob.user.first_name + ' ' + appJob.user.last_name }}</span>
                  <span *ngIf="appJob.processing_start_at">
          {{ "REPORTS.financial-reports.jobs-list.status.progress" | translate }}
        </span>
                  <span *ngIf="!appJob.processing_start_at">
          {{ "REPORTS.financial-reports.jobs-list.status.pending" | translate }}
        </span>
                  <span>{{ appJob.label }}</span>
                </div>
                <div *ngIf="appJob.progress > 0" matLine>
                  <mat-progress-bar mode="determinate" [value]="appJob.progress"></mat-progress-bar>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

  </div>
</div>
