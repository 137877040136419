import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CreditorService } from '../../../../creditor/creditor.service';
import { CaseService } from '../../../case.service';

@Component({
  selector:    'app-additional-input',
  templateUrl: './additional-input.component.html',
  styleUrls:   ['./additional-input.component.scss'],
})
export class AdditionalInputComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;

  constructor(public dialogRef: MatDialogRef<AdditionalInputComponent>,
              private fb: UntypedFormBuilder,
              private toast: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private creditorService: CreditorService,
              public translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data.type === 'number') {
      this.form = this.fb.group({
        phone: ['', [Validators.required]],
      });

      this.formActive = true;
    } else {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      emails: this.fb.array([]),
      // email: ['', [Validators.required, Validators.email]]
    });

    const emails = this.form.get('emails') as UntypedFormArray;
    if (this.data.debtIds.selected && this.data.debtIds.selected.length > 0) {
      this.data.debtIds.selected.map(creditor => {
        emails.push(
          this.fb.group({
            creditorId: [creditor.id, Validators.required],
            name:       [creditor.public_organisation, Validators.required],
            email:      ['', [Validators.required, Validators.email]],
          }),
        );
      });
    } else {
      this.data.debtIds.map(creditor => {
        emails.push(
          this.fb.group({
            creditorId: [creditor, Validators.required],
            email:      ['', [Validators.required, Validators.email]],
          }),
        );
      });
    }

    this.formActive = true;
  }

  public getFormArray() {
    return this.form.get('emails') as UntypedFormArray;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit() {
    if (this.form.valid) {
      this.isLoading++;
      if (this.data.type === 'number') {
        const observableCreditor = this.data.creditorType === 'unsecured_creditors' ?
          this.creditorService.sendDeleteNumberUnsecured(this.data.case.id, this.data.creditorId) :
          this.creditorService.sendDeleteNumberEmail(this.data.creditorId, this.form.value);
        observableCreditor
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            next => {
              this.toast.success(this.translate.instant('CASES.details.send-delete-number-success'));
              this.closeDialog(next);
            }, error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.send-delete-number-error'));
              }
            });
      } else {
        this.caseService.verifyPublicDebt(this.data.case.id, this.form.value.emails[0].creditorId, this.form.value)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.resend-mandates-success'));
              this.closeDialog(value);
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.resend-mandates-error'));
              }
            });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

}
