<table class="table table-bordered">
  <tbody>
  <tr>
    <th style="width: 20%;">Order code</th>
    <td>{{mymoidOrder.payment_order_id}}</td>
  </tr>
  <tr>
    <th>Payment Processor</th>
    <td>{{mymoidOrder.payment_processor?.slug}}</td>
  </tr>
  <tr>
    <th>Amount</th>
    <td>{{(mymoidOrder.amount_100 / 100) | currency: "EUR"}}</td>
  </tr>
  <tr>
    <th>Currency code</th>
    <td>{{mymoidOrder.currency_code}}</td>
  </tr>
  <tr>
    <td>Expires at</td>
    <td>{{mymoidOrder.expires_at}}</td>
  </tr>
  <tr>
    <th>Status</th>
    <td>{{mymoidOrder.status}}</td>
  </tr>
  <tr>
    <th>Success</th>
    <td>{{mymoidOrder.success}}</td>
  </tr>
  <tr>
    <th>Error</th>
    <td>{{mymoidOrder.error}}</td>
  </tr>
  <tr>
    <th>Author</th>
    <td>{{mymoidOrder.author?.full_name}}</td>
  </tr>
  </tbody>
</table>
