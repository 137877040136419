import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainAuthGuard } from '../../../_shared/guards/main-auth-guard.service';
import {VerifierDashboardComponent} from './verifier-dashboard.component';


const routes: Routes = [
  { path: 'verifier-dashboard', component: VerifierDashboardComponent, canActivate: [MainAuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerifierDashboardRoutingModule { }
