<div [formGroup]="form">
  <div class="row pb-3">
    <div class="col-12">
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="from" formControlName="from" (dateChange)="onDateChange($event, 'from')">
        <button
          type="button"
          *ngIf="form.value.from"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'from')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from color="primary"></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input id="to" matInput [matDatepicker]="to" formControlName="to" (dateChange)="onDateChange($event, 'to')">
        <button
          type="button"
          *ngIf="form.value.to"
          mat-button matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clearDatePicker($event, 'to')"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
        <mat-datepicker #to color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="d-inline-block pb-2">
      <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)">
        <mat-radio-button checked value="lso" class="mx-2">Cases Assigned</mat-radio-button>
        <mat-radio-button value="dm" class="mr-2">Cocumentacion Completa</mat-radio-button>
        <mat-radio-button value="dgs" class="mr-2">Revisar Documents</mat-radio-button>
        <mat-radio-button value="cajaplus" class="mr-2">DDA PTE Revision</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<app-details-overview-table [dataSource]="dataSource"></app-details-overview-table>



