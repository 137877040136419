import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';


type inputTypes =
  'color' |
  'date' |
  'datetime' |
  'datetime-local' |
  'email' |
  'month' |
  'number' |
  'password' |
  'search' |
  'tel' |
  'text' |
  'time' |
  'url' |
  'week' |
  // not applied to <input>
  'select' |
  'textarea' |
  'checkbox' |
  'switch' |
  'mat-datepicker';

@Component({
  selector:    'app-input',
  templateUrl: './app-input.component.html',
  styleUrls:   ['./app-input.component.scss'],
  providers:   [
    // {
    //   provide:     NG_VALUE_ACCESSOR,
    //   multi:       true,
    //   useExisting: AppInputComponent,
    // },
    // {
    //   provide:     NG_VALIDATORS,
    //   multi:       true,
    //   useExisting: AppInputComponent,
    // },
  ]
})
export class AppInputComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
  @ViewChild(MatDatepicker) matDatePicker: MatDatepicker<Date>;
  @ViewChild('selectAllMatSelect') matSelectAll: MatSelect;
  @Input() label                              = '';
  @Input() requiredStar                       = false;
  @Input() type: inputTypes                   = 'text';
  @Input() inputMode: null | 'numeric'        = null;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() color: ThemePalette                = 'primary';
  @Input() submitted                          = false;
  @Input() clientTypography                   = false;
  @Input() selectAll                          = false;

  @Input() set selectOptions(value: Array<any>) {
    this.internalSelectOptions = value;
    if ( ! this.emitSearch) {
      this.searchControl.patchValue(null, {onlySelf: true, emitEvent: false});
    }
    this.filteredOptions.next(value);
  }

  @Input() selectLabel: string | Array<string>   = 'label';
  @Input() selectValue                           = 'value';
  @Input() selectDisabledProperty: string | null = null;
  @Input() optGroupLabel: string | null          = 'name';
  @Input() optGroupProperty: string | null       = null;
  @Input() labelDescriptionField: string;
  @Input() searchable                            = false;
  @Input() emitSearch                            = false;
  @Input() emitSearchMinLength                   = 3;
  @Input() multiple                              = false;
  @Input() placeholder: string;
  @Input() readonly: boolean; // TODO: implement for all input types
  @Input() hint: string;
  @Input() prefix: string;
  @Input() prefixType: 'text' | 'icon'           = 'text';
  @Input() suffix: string;
  @Input() suffixType: 'text' | 'icon'           = 'text';
  @Input() showLabel                             = true;
  @Input() extraLabel                            = false;
  @Input() flexClass: string;
  @Input() width                                 = '';
  @Input() fullWidth: boolean;
  @Input() showClear                             = true;
  @Input() indeterminate                         = false;
  @Input() labelPosition: 'before' | 'after'     = 'after';
  @Input() highlight                             = false;
  @Input() textAreaCols                          = 20;
  @Input() textAreaRows                          = 2;

  @Input() requiredError: string;
  @Input() emailError: string;
  @Input() patternError: string;
  @Input() minLengthError: string;
  @Input() maxLengthError: string;
  @Input() minError: string;
  @Input() maxError: string;

  @Output() filterSearch: EventEmitter<string> = new EventEmitter<string>();

  public nonFormField: Array<string>              = ['checkbox', 'switch', 'mat-datepicker'];
  public searchControl: UntypedFormControl        = new UntypedFormControl();
  public filteredOptions: ReplaySubject<any>      = new ReplaySubject<any>(1);
  public selectAllFormControl: UntypedFormControl = new UntypedFormControl();
  public selectAllIndeterminate                   = false;
  public selectLabels                             = [];
  public labelId                                  = 'app-inp-' + Math.ceil(Math.random() * 1000);
  public isFiltering                              = false;
  public componentReady: boolean;
  public datePickerReady: boolean;
  // Angular
  public internalFormControl: UntypedFormControl  = new UntypedFormControl();

  public disabled                           = false;
  private internalSelectOptions: Array<any> = [];
  private touched                           = false;

  private errorMessages = new Map<string, () => string>();

  constructor(@Self() @Optional() public wrapperControl: NgControl) {
    if (this.wrapperControl) {
      this.wrapperControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.datePickerReady = true;
    }, 0);
  }

  ngOnInit(): void {
    // this.wrapperControl?.control.setValidators([this.validate.bind(this)]);
    // this.wrapperControl?.control.updateValueAndValidity();
    if (typeof this.selectLabel === 'string') {
      this.selectLabels.push(this.selectLabel);
    } else {
      this.selectLabel.forEach(label => this.selectLabels.push(label));
    }

    if (this.type === 'password' && ! this.suffixType) {
      this.suffixType = 'icon';
    }

    this.filterOptions(null);
    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe(searchValue => {
        if (this.emitSearch && searchValue.length >= this.emitSearchMinLength) {
          this.filterSearch.emit(searchValue);
        } else {
          this.filterOptions(searchValue);
        }
      });
    this.filteredOptions.subscribe(result => this.isFiltering = false);
    this.componentReady = true;
  }

  onTouched = () => {
  };

  onChange          = (value) => {
  };
  onValidatorChange = () => {
  };

  public get invalid(): boolean {
    return this.wrapperControl ? this.wrapperControl.invalid : false;
  }

  public get showError(): boolean {
    if ( ! this.wrapperControl) {
      return false;
    }

    const {dirty, touched} = this.wrapperControl;

    return this.invalid && (this.submitted || (dirty || touched));
  }

  public get errors(): Array<string> {
    if ( ! this.wrapperControl) {
      return [];
    }

    const {errors} = this.wrapperControl;
    return Object.keys(errors)
      .map(key => this.errorMessages.has(key) ? this.errorMessages.get(key)() : errors[key] as string || key);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.internalFormControl.disable() : this.internalFormControl.enable();
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.internalFormControl.setValue(obj);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }

  public updateControl($event: any) {
    this.markAsTouched();
    if (this.type === 'select' && this.multiple && this.selectAll) {
      this.selectAllIndeterminate = true;
    }
    if ( ! this.disabled) {
      this.onChange(
        this.type === 'mat-datepicker' && (typeof $event.toISOString === 'function') ?
          $event.toISOString() :
          $event
      );
    }
  }

  private markAsTouched() {
    if ( ! this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public displayClearButton(): boolean {
    return this.multiple ?
      (this.showClear && this.internalFormControl.value?.length && ! this.internalFormControl.disabled) :
      (this.showClear && this.internalFormControl.value && ! this.internalFormControl.disabled);
  }

  public clearSelect($event) {
    $event.stopPropagation();
    if ( ! this.disabled) {
      this.internalFormControl.patchValue(this.multiple ? [] : null);
    }
  }

  public filterOptions(searchValue: string | null): void {
    if ( ! this.searchable || ! searchValue) {
      this.filteredOptions.next(this.internalSelectOptions);
      return;
    }

    const search = searchValue.toLowerCase();

    if (this.optGroupProperty) {
      const filteredData = this.internalSelectOptions.map(selectOptionGroup => {
        selectOptionGroup                        = {...selectOptionGroup};
        selectOptionGroup[this.optGroupProperty] = selectOptionGroup[this.optGroupProperty]
          .filter(selectOption => this.checkForKeyword(selectOption, search));
        return selectOptionGroup;
      }).filter(selectOptionGroup => selectOptionGroup[this.optGroupProperty].length);
      this.filteredOptions.next(filteredData);
    } else {
      this.filteredOptions.next(
        this.internalSelectOptions.filter(selectOption => this.checkForKeyword(selectOption, search))
      );
    }
  }

  private checkForKeyword(selectOption: any, search: string): boolean {
    let searchTarget = '';
    if (typeof this.selectLabel === 'string') {
      searchTarget = selectOption[this.selectLabel as string].toString().toLowerCase();
    } else {
      this.selectLabel.forEach(sLabel => {
        if (selectOption[sLabel]) {
          searchTarget += selectOption[sLabel].toString().toLowerCase();
        }
      });
    }
    return searchTarget.indexOf(search) > -1;
  }

  public startFilteringLoader($event: any) {
    if ($event) {
      this.isFiltering = true;
    }
  }

  public parseDescriptionLabel(option: any, labelDescriptionField: string): string {
    return labelDescriptionField.split('.').reduce((r, val) => {
      return r ? r[val] : undefined;
    }, option);
  }

  public toggleIcon() {
    if (this.type === 'text') {
      this.type = 'password';
    } else if (this.type === 'password') {
      this.type = 'text';
    } else {
      return;
    }
  }

  public selectAllOptions(toggle: boolean): void {
    if (toggle) {
      const values = [];
      if ( ! this.optGroupProperty) {
        this.filteredOptions.pipe(take(1)).subscribe(options => {
          options.forEach(option => {
            values.push(option[this.selectValue]);
          });
        });
      } else {
        this.filteredOptions.pipe(take(1)).subscribe(optionGroups => {
          optionGroups.forEach(optionGroup => {
            optionGroup[this.optGroupProperty].forEach(option => {
              values.push(option[this.selectValue]);
            });
          });
        });
      }
      this.internalFormControl.patchValue(values);
      this.updateControl(this.internalFormControl.value);
    } else {
      this.internalFormControl.patchValue([]);
      this.updateControl([]);
    }
    this.selectAllIndeterminate = false;
  }
}
