import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { ReportService } from '../report.service';

@Component({
  selector:    'app-missing-documents',
  templateUrl: './missing-documents.component.html',
  styleUrls:   ['./missing-documents.component.scss'],
})
export class MissingDocumentsComponent implements OnInit {
  public form: UntypedFormGroup;

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'ref_number',
    'first_name',
    'last_name',
    'missing_files',
    'pending_files',
    'status_name',
    'payment_status_name',
  ];
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };

  public isLoading           = 0;
  public simpleExportLoading = 0;
  public exportLoading       = 0;

  constructor(
      private toast: ToastrService,
      private reportService: ReportService,
      private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: ['', null],
    });

    this.fetchMissingDocs();
    this.form.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(next => {
      this.fetchMissingDocs();
    });
  }

  resetValue() {
    this.form.patchValue({
      value: '',
    });
  }

  private fetchMissingDocs() {
    this.isLoading++;
    const data      = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      paid:     this.form.value?.value || 0,
    };
    this.dataSource = new MatTableDataSource<any>([]); //  Empty data in table
    this.reportService.getMissingDocs(data).pipe(finalize(() => this.isLoading--)).subscribe(next => {
      this.dataSource             = new MatTableDataSource<any>(next.data);
      this.paginatorConfig.length = next.meta.total;
      this.toast.success(next.message);
    });
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchMissingDocs();
  }

  getSimpleExport() {
    this.simpleExportLoading++;
    this.reportService.getMissingDocsSimpleExport().pipe(finalize(() => this.simpleExportLoading--))
        .subscribe(result => {
              const fileName = 'missing_docs_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.csv';
              saveAs(result.body, fileName);
              // this.toast.success(result.message);
            },
            err => console.error(err),
        );
  }

  getAdvancedExport() {
    this.exportLoading++;
    this.reportService.getMissingDocsExport().pipe(finalize(() => this.exportLoading--))
        .subscribe(result => {
              const fileName = 'missing_docs_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.csv';
              saveAs(result.body, fileName);
              // this.toast.success(result.message);
            },
            err => console.error(err),
        );
  }

}
