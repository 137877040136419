<mat-dialog-content>
  <mat-card class="mat-elevation-z3">
    <mat-card-header>
      <mat-card-title class="mb-3">
        <h5>{{ 'CASES.single.payments.case_payment_plan_generator.heading' | translate }}</h5>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
      <form *ngIf="form" [formGroup]="form" class="p-2">
        <div class="row">
          <!-- Payment Plan Type -->
          <div class="col-6 col-lg">
            <app-input type="select" formControlName="payment_plan_type_id" appearance="fill" fullWidth="false"
                       [label]="'PAYMENTS.list.filters.payment_plan_type.label' | translate" [searchable]="true"
                       [selectOptions]="paymentPlanTypes" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>
          </div>
        </div>
        <div *ngFor="let formGroup of phasesArray.controls; let i = index"
             formArrayName="phases">
          <ng-container [formGroupName]="i">
            <div class="row">
              <!-- Split Amount type -->
              <div class="col-6 col-lg">
                <app-input type="select" formControlName="split_amount_type" appearance="fill" fullWidth="false"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.split_amount_type.label' | translate "
                           (ngModelChange)="splitTypeChanged($event, i)"
                           [selectOptions]="splitAmountTypes" [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
              </div>
              <!-- Amount -->
              <div class="col-6 col-lg">
                <app-input type="number" formControlName="amount" appearance="fill"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.amount' | translate"
                           (ngModelChange)="amountUpdated($event, i)">
                </app-input>
              </div>
              <!-- Start From Type -->
              <div class="col-6 col-lg">
                <app-input type="select" formControlName="start_from_type" appearance="fill" fullWidth="false"
                           [selectOptions]="startFromTypes" [selectLabel]="'label'" [selectValue]="'value'"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.start_from_type.label' | translate">
                </app-input>
              </div>
              <!-- Start From -->
              <div class="col-6 col-lg" *ngIf="phasesArray.at(i).get('start_from_type').value === 'date'">
                <app-input type="mat-datepicker" formControlName="start_from" appearance="fill" fullWidth="false"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.start_from' | translate">
                </app-input>
              </div>
              <!-- Installment Amount -->
              <div class="col-6 col-lg">
                <app-input type="number" formControlName="installment_amount" appearance="fill" fullWidth="false"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.installment_amount' | translate"
                           (ngModelChange)="installmentAmountChanged($event, i)">
                </app-input>
              </div>
              <!-- Installments Count -->
              <div class="col-6 col-lg">
                <app-input type="number" formControlName="installments_count" appearance="fill" fullWidth="false"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.installments_count' | translate"
                           (ngModelChange)="installmentCountChanged($event, i)">
                </app-input>
              </div>
            </div>
            <div class="row" *ngIf="formGroup.get('installments_count').value > 1">
              <!-- Interval -->
              <div class="col-6 col-lg">
                <app-input type="number" formControlName="interval" appearance="fill" fullWidth="false"
                           [label]="'CASES.single.payments.case_payment_plan_generator.form.interval' | translate">
                </app-input>
              </div>
              <!-- Interval Unit -->
              <div class="col-6 col-lg">
                <app-input type="select" formControlName="interval_unit" appearance="fill" fullWidth="false"
                           [label]="''"
                           [selectOptions]="intervalUnitOptions" [selectLabel]="'label'" [selectValue]="'value'">
                </app-input>
              </div>
            </div>
            <!-- Actions -->
            <div class="row">
              <div class="col-12 d-flex justify-content-end">
                <button mat-raised-button color="primary" (click)="submitForm(form)">
                  {{ 'SHARED.save' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
