import {Component, ElementRef, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {Case} from '../../../../../../_base-shared/models/Case/Case';
import {CaseService} from '../case.service';

@Component({
  selector:    'app-creditor-proposal-preview',
  templateUrl: './creditor-proposal-preview.component.html',
  styleUrls:   ['./creditor-proposal-preview.component.scss']
})
export class CreditorProposalPreviewComponent implements OnInit {
  public isLoadingDocument         = false;
  public isLoadingCreditorProposal = false;
  public isLoadingAccord           = false;
  public caseUuid: string;
  public sign: any;
  public resign: any;
  public type: string;
  public documentId: string;
  public caseCreditorId: string;
  public currentDate               = new Date();
  public signatureImg              = '';
  public prevSignatureImg          = '';
  public prevSignatureImgPartner   = '';
  public imgPath;
  public case: Case;
  public pdfFileName               = '';
  public loading                   = false;
  public creditorProposalDocumentHtml: string;
  public creditorAccordDocumentHtml: string;
  public ownership: string;

  constructor(
    private elRef: ElementRef,
    private caseService: CaseService,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.caseUuid       = this.route.snapshot.queryParamMap.get('uuid');
    this.type           = this.route.snapshot.queryParamMap.get('type');
    this.caseCreditorId = this.route.snapshot.queryParamMap.get('case_creditor_id');
    this.ownership      = this.route.snapshot.queryParamMap.get('ownership');

    this.isLoadingCreditorProposal = true;
    this.caseService.previewCreditorProposal(this.caseUuid)
      .pipe(finalize(() => this.isLoadingCreditorProposal = false))
      .subscribe(result => this.creditorProposalDocumentHtml = result.data);

    this.isLoadingAccord = true;
    this.caseService.previewCreditorProposalAccord(this.caseUuid, 'client', this.caseCreditorId, this.ownership)
      .pipe(finalize(() => this.isLoadingAccord = false))
      .subscribe(result => this.creditorAccordDocumentHtml = result.data);
  }

}
