import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseIncome, CaseIncomeType } from '../../../../../../../_base-shared/models/Case/CaseIncome';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseIncomeService } from '../../case-income.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-income-editor',
  templateUrl: './case-income-editor.component.html',
  styles:      [
    `
      .incomes-wrapper {
        color: #1D2123;
      }
    `],
})
export class CaseIncomeEditorComponent implements OnInit {
  public case: Case;
  public caseIncomes: CaseIncome;
  public isLoading = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;

  public totalSalary        = 0;
  public totalBenefits      = 0;
  public totalPension       = 0;
  public totalOther         = 0;
  public totalSpecialIncome = 0;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseIncomeService: CaseIncomeService,
    private financialOverviewService: FinancialOverviewService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case = result.data;
        this.isLoading++;
        this.caseIncomeService.index(caseId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
          this.caseIncomes = res.data ? res.data : new CaseIncome();
          this.buildForm(this.caseIncomes);
        });
      });
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseIncomeService.upsert(this.case.id, form.getRawValue()).pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.incomes.result.success'));
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.incomes.result.error'), error.error.message);
        },
      );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  public updateTotalIncomes(incomeType: CaseIncomeType, updateFinancialOverview = true) {
    let totalNet = 0;

    if (incomeType === 'salary') {
      totalNet         = (+this.form.get('salary_client').value || 0) + (+this.form.get('salary_partner').value || 0) +
        (+this.form.get('subsidy_benefit').value || 0) + (+this.form.get('unemployment_benefit').value || 0) +
        (+this.form.get('pension_state').value || 0);
      this.totalSalary = parseFloat(totalNet.toFixed(2));
    }

    if (incomeType === 'benefits') {
      totalNet           = (+this.form.get('compensatory_pension').value || 0) + (+this.form.get('alimony').value || 0);
      this.totalBenefits = parseFloat(totalNet.toFixed(2));
    }

    if (incomeType === 'pension') {
      totalNet          = (+this.form.get('pension_private').value || 0) +
        (+this.form.get('pension_credit').value || 0) +
        (+this.form.get('pension_other').value || 0);
      this.totalPension = parseFloat(totalNet.toFixed(2));
    }

    if (incomeType === 'other') {
      totalNet        = (+this.form.get('other_boarders_or_lodgers').value || 0) +
        (+this.form.get('other_non_dependent_contributions').value || 0) +
        (+this.form.get('other_student_loans_and_grants').value || 0);
      this.totalOther = parseFloat(totalNet.toFixed(2));
    }
    if (incomeType === 'special_incomes') {
      const specialIncomeArray = this.getFormArray('special_incomes');
      if (specialIncomeArray.controls.length) {
        specialIncomeArray.controls.forEach(formGroup => {
          totalNet += (+formGroup.get('value').value || 0);
        });
      }
      this.totalSpecialIncome = parseFloat(totalNet.toFixed(2));
    }
    if (updateFinancialOverview) {
      this.updateTotalNet();
    }
  }

  public addSpecialIncome() {
    const formArray = this.getFormArray('special_incomes');
    formArray.push(this.fb.group({label: null, value: 0}));
  }

  public getFormArray(arrayName: string) {
    return this.form.get(arrayName) as UntypedFormArray;
  }

  public removeSpecialExpenses(index: number) {
    this.getFormArray('special_incomes').removeAt(index);
  }

  private buildForm(caseIncomes: CaseIncome) {
    this.form = this.fb.group({
      // Salary
      salary_day:           [caseIncomes.salary_day],
      salary_client:        [caseIncomes.salary_client],
      salary_partner:       [caseIncomes.salary_partner],
      subsidy_benefit:      [caseIncomes.subsidy_benefit],
      unemployment_benefit: [caseIncomes.unemployment_benefit],
      pension_state:        [caseIncomes.pension_state],
      // Benefits
      compensatory_pension: [caseIncomes.compensatory_pension],
      alimony:              [caseIncomes.alimony],
      // Pension
      pension_private: [caseIncomes.pension_private],
      pension_credit:  [caseIncomes.pension_credit],
      pension_other:   [caseIncomes.pension_other],
      // Other
      other_boarders_or_lodgers:         [caseIncomes.other_boarders_or_lodgers],
      other_non_dependent_contributions: [caseIncomes.other_non_dependent_contributions],
      other_student_loans_and_grants:    [caseIncomes.other_student_loans_and_grants],
      // Special Income
      special_incomes: this.fb.array([]),
    });

    const specialIncomes = this.form.get('special_incomes') as UntypedFormArray;

    if (caseIncomes.special_incomes?.length) {
      caseIncomes.special_incomes.forEach(specialIncome => {
        specialIncomes.push(
          this.fb.group({label: specialIncome.label, value: specialIncome.value}),
        );
      });
    }
    this.updateTotalIncomes('salary', false);
    this.updateTotalIncomes('benefits', false);
    this.updateTotalIncomes('pension', false);
    this.updateTotalIncomes('other', false);
    this.updateTotalIncomes('special_incomes', false);
  }

  private updateTotalNet() {
    const totalNet = this.totalSalary + this.totalBenefits + this.totalPension + this.totalOther +
      this.totalSpecialIncome;
    this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
      if (overview) {
        overview.income = totalNet;
        this.financialOverviewService.updateFinancialOverviewLocally(overview);
      }
    });
  }
}
