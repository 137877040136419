import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-overview-table',
  templateUrl: './details-overview-table.component.html',
  styles: []
})
export class DetailsOverviewTableComponent implements OnInit{
  @Input() dataSource: any;

  public displayedColumns: string[]        = ['name', 'ref_number', 'date_achieved', 'date_cancelled'];

  constructor() { }

  ngOnInit(): void {
    if (this.dataSource) {
      this.getTotal();
    }
  }

  public getTotal() {
    /*    let total = 0;
        if (this.dataSource) {
          if (statusId === 'all') {
            // Used to count only data from 1st row (since we have 2 data objects for each agent)
            //  This way we ignore data from 2nd row
            this.dataSource.data.forEach(data => {
              if (data.type === 'DM') {
                total += +(data.amount_period || 0);
              }
            });
            return total.toFixed(2);
          } else if (statusId === 'cases') {
            this.dataSource.data.forEach(data => {
              if (data.type === 'DM') {
                total += +(data.case_count || 0);
              }
            });
            return total;
          } else {
            this.dataSource.data.forEach(data => {
              if (data.type === 'DM') {
                total += +(data.statuses[statusId]?.cases || 0);
              }
            });
            return total;
          }
        }*/
  }
}
