<div class="row">
  <div [hidden]="!isDialerTriggersLoading" class="col-12">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
  <div *ngIf="serverResponse" class="col-12">
    <app-server-response [response]="serverResponse"></app-server-response>
  </div>
</div>
<form *ngIf="form" [formGroup]="form" style="width: 100%">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ componentType === 'status' ? ('CONFIG.dialer.status_triggers' | translate) : (componentType === 'payment_status' ?
        ('CONFIG.dialer.payment_status_triggers' | translate) : (componentType === 'call_status' ? ('CONFIG.dialer.call_status_triggers' | translate) : '')) }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <!-- Table Headings -->
        <div class="table-row table-header">
          <div class="table-cell">Packagers</div>
          <div class="table-cell">
            {{ componentType === 'status' ? ('CONFIG.dialer.status' | translate) : (componentType === 'payment_status' ?
            ('CONFIG.dialer.payment_status' | translate) : (componentType === 'call_status' ? ('CONFIG.dialer.call_status' | translate) : '')) }}
          </div>
          <div class="table-cell">{{ 'CONFIG.dialer.product_type' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.dialer_list_id' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.priority' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.delay' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.delay' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.active' | translate }}</div>
          <div class="table-cell">{{ 'CONFIG.dialer.force_dial' | translate }}</div>
          <!-- <div class="table-cell">{{ 'CONFIG.dialer.auto_queue' | translate }}</div> -->
          <div class="table-cell">Actions</div>
        </div>

        <!-- Table Body -->
        <ng-container [formArrayName]="'dialer_triggers'"
                      *ngFor="let formGroup of getFormArray().controls; let i = index">
          <div class="table-row" [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === componentType">
            <div class="table-cell">
              <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2"
                         [fullWidth]="true"
                         [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" searchable="true"
                         [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'"
                         [searchable]="true">
              </app-input>
            </div>
            <!-- STATUS -->
            <div class="table-cell">
              <app-input type="select" *ngIf="statusCategories?.length && componentType === 'status'"
                         formControlName="statusable_id"
                         appearance="outline" [fullWidth]="true"
                         [searchable]="true" [label]="'CONFIG.dialer.status' | translate"
                         [showClear]="false" [showLabel]="true"
                         [selectOptions]="statusCategories" [optGroupProperty]="'statuses'"
                         [optGroupLabel]="'name'"
                         [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
              <app-input *ngIf="callStatuses?.length && componentType === 'call_status'" type="select"
                         formControlName="statusable_id" appearance="outline" [fullWidth]="true"
                         [searchable]="true" [label]="'CONFIG.dialer.call_status' | translate"
                         [showClear]="false" [showLabel]="true" [selectOptions]="callStatuses"
                         [selectLabel]="['name']" [selectValue]="'id'">
              </app-input>
              <app-input type="select" *ngIf="paymentStatuses?.length && componentType === 'payment_status'"
                         formControlName="statusable_id" appearance="outline" class="full-width"
                         [searchable]="true" [label]="'CONFIG.dialer.status' | translate" [fullWidth]="true"
                         [showClear]="false" [showLabel]="true" [selectOptions]="paymentStatuses"
                         [selectLabel]="['name']"
                         [selectValue]="'id'">
              </app-input>
            </div>
            <!-- Product type -->
            <div class="table-cell">
              <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.product_type' | translate"
                         formControlName="product_group_slugs" (ngModelChange)="productGroupUpdated($event, i)"
                         [showClear]="false" [showLabel]="true" [fullWidth]="true"
                         [selectOptions]="productTypeGroups" [multiple]="true"
                         [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- Dialer List Id -->
            <div class="table-cell">
              <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.dialer_list_id' | translate"
                         formControlName="dialer_list_id" class="full-width" [showClear]="false" [searchable]="true"
                         [selectOptions]="dialerLists" [selectLabel]="'name'" [selectValue]="'id'" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Priority -->
            <div class="table-cell">
              <app-input type="number" formControlName="priority" [fullWidth]="true"
                         [label]="'CONFIG.dialer.priority' | translate">
              </app-input>
            </div>
            <!-- Delay -->
            <div class="table-cell">
              <app-input type="number" formControlName="delay" appearance="outline" [fullWidth]="true"
                         [label]="'CONFIG.drip-campaign.editor.drip-notification.delay.label' | translate">
              </app-input>
            </div>
            <!-- Delay Unit -->
            <div class="table-cell">
              <app-input type="select" formControlName="delay_unit" appearance="outline"
                         [fullWidth]="true" [searchable]="true"
                         [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                         [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <!-- Active -->
            <div class="table-cell">
              <app-input type="switch" formControlName="active" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Force dial -->
            <div class="table-cell">
              <app-input type="switch" formControlName="force_dial" appearance="fill" [fullWidth]="true">
              </app-input>
            </div>
            <!-- Actions -->
            <div class="table-cell">
              <button type="button" mat-icon-button color="warn" (click)="removeStatus(i)">
                <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div class="d-flex justify-content-end">
        <button type="button" class="mr-3 mb-3" mat-raised-button color="primary"
                (click)="addNewStatusTrigger(componentType)">
          + {{ 'CONFIG.dialer.trigger.actions.add' | translate }}
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</form>
