import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector:    'app-packager-detail',
  templateUrl: './packager-detail.component.html',
  styles:      []
})
export class PackagerDetailComponent implements OnInit {
  public packagerId: number;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.packagerId = +params.get('id');
    });
  }

}
