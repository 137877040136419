<mat-card class="mat-elevation-z3">
  <mat-card-header>
    <mat-card-title class="mb-3">
      <h1>{{ 'PAYMENTS.scheduled-payments.heading' | translate }}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
    <form *ngIf="form" [formGroup]="form" class="p-2 row">
      <!-- Start From -->
      <!-- <div class="col-6 col-lg">
        <app-input type="mat-datepicker" formControlName="start_from" appearance="standard" fullWidth="false"
                   [label]="'CASES.single.payments.case_payment_plan_generator.form.start_from' | translate">
        </app-input>
      </div> -->
      <!-- Start Installment ID -->
      <div class="col-6 col-lg-6">
        <app-input type="select" appearance="standard" class="m-2" formControlName="start_installment_id"
                   [fullWidth]="true"
                   [label]="'PAYMENTS.scheduled-payments.start_installment' | translate"
                   [selectOptions]="upcomingInstallments" selectValue="id"
                   [selectDisabledProperty]="'optionDisabled'"
                   selectLabel="name">
        </app-input>
      </div>
      <div class="col-6 col-lg-6"></div>
      <!-- Installment Amount -->
      <div class="col-6 col-lg-6">
        <app-input type="number" formControlName="installment_amount" appearance="standard" fullWidth="false" readonly
                   [label]="'CASES.single.payments.case_payment_plan_generator.form.installment_amount' | translate">
        </app-input>
      </div>
      <!-- Installments Count -->
      <div class="col-6 col-lg-6">
        <app-input type="number" formControlName="installments_count" appearance="standard" fullWidth="false"
                   [readonly]="authUser?.role?.slug !== 'super-admin'"
                   [label]="'CASES.single.payments.case_payment_plan_generator.form.installments_count' | translate">
        </app-input>
      </div>
      <!-- Interval -->
      <!-- <div class="col-6 col-lg">
        <app-input type="number" formControlName="interval" appearance="standard" fullWidth="false"
                   [label]="'CASES.single.payments.case_payment_plan_generator.form.interval' | translate">
        </app-input>
      </div> -->
      <!-- Interval Unit -->
      <!-- <div class="col-6 col-lg">
        <app-input type="select" formControlName="interval_unit" appearance="standard" fullWidth="false"
                   [label]="''"
                   [selectOptions]="intervalUnitOptions" [selectLabel]="'label'" [selectValue]="'value'">
        </app-input>
      </div> -->
      <!--Send to client -->
      <div *ngIf="editorType === 'create'" class="col-6 col-lg-6">
        <app-input type="checkbox" formControlName="send_to_client" class="text-small text-muted" [extraLabel]="false"
                   [label]="'PAYMENTS.scheduled-payments.send_to_client' | translate">
        </app-input>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="d-flex justify-content-end">
      <button type="button" mat-button color="primary" (click)="dialogRef.close()">
        {{ "SHARED.close" | translate }}
      </button>
      <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.submit' | translate" class="ml-2"
                       (click)="submitForm(form)">
      </app-spinner-btn>
    </div>
  </mat-card-actions>
</mat-card>
