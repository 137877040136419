<table mat-table [dataSource]="allocationDetails" class="w-100 table-border">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
    <td mat-cell *matCellDef="let element">{{element?.name}}</td>
  </ng-container>
  <ng-container matColumnDef="payment_plan">
    <th mat-header-cell *matHeaderCellDef>{{'PAYMENTS.list.filters.payment_plan_type.label' | translate}}</th>
    <td mat-cell *matCellDef="let element">{{element?.case_payment_plan?.type.name}}</td>
  </ng-container>
  <ng-container matColumnDef="amount_applied">
    <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
    <td mat-cell *matCellDef="let element">{{element?.pivot?.amount_applied | currency:'EUR'}}</td>
  </ng-container>
  <ng-container matColumnDef="term_date">
    <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.term_date" | translate }}</th>
    <td mat-cell *matCellDef="let element">{{element?.term_date | date: 'd/M/y'}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumnsTerms"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsTerms;"></tr>
</table>
