import { Component, Input } from '@angular/core';
import { PaymentTerm } from '../../../../../../../../../_base-shared/models/Payment/PaymentTerm';

@Component({
  selector: 'app-allocation-details',
  templateUrl: './allocation-details.component.html',
  styleUrls: ['./allocation-details.component.scss']
})
export class AllocationDetailsComponent {
  @Input() allocationDetails: Array<PaymentTerm>;
  public displayedColumnsTerms: string[] = ['name', 'payment_plan', 'amount_applied', 'term_date'];
}
