import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector   : 'app-payment-error-modal',
  templateUrl: './payment-error-modal.component.html',
  styleUrls  : ['./payment-error-modal.component.scss']
})
export class PaymentErrorModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PaymentErrorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }

}
