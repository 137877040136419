<div class="card-body">
  <div class="row">
    <div *ngIf="isLoading" class="col-12 p-4">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <div *ngIf="serverResponse" class="col-12">
      <app-server-response [response]="serverResponse"></app-server-response>
    </div>
  </div>
  <div *ngIf="case" class="row">
    <div class="col-12">
      <div class="col-6">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitFiles()" class="form">
          <div>
            <div class="row pb-4">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="uploadFile1">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                  <div class="input-group">
                    <div class="custom-file">
                      <input multiple type="file" class="custom-file-input" [lang]="lang" id="uploadFile1"
                             formControlName="files" (change)="onFileChange($event)">
                      <label class="custom-file-label" for="uploadFile1">
                        {{ 'DOCUMENTS.choose-file' | translate }}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center" *ngFor="let file of files">
                    <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                    <!-- <button mat-icon-button color="warn" (click)="removeFile($event, file.index)"> -->
                    <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                    <!--     remove_circle -->
                    <!--   </mat-icon> -->
                    <!-- </button> -->
                  </div>
                </div>
              </div>
              <div *ngIf="case.joint_application"
                   class="col-md-2 col-lg-2 inline-form-button d-flex justify-content-between">
                <div class="form-group upload-for" style="margin-right: 0">
                  <label for="uploadFor">{{ "CASES.single.upload-for" | translate }}</label>
                  <select id="uploadFor" class="form-control" formControlName="uploadFor"
                          (ngModelChange)="changeUploadFor($event)">
                    <option value="client">{{ "CASES.single.client" | translate }}</option>
                    <option value="partner">{{ "CASES.single.partner" | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 d-flex">
                <div class="form-group">
                  <label for="status">{{ "DOCUMENTS.select-category" | translate }}</label>
                  <select class="form-control" id="status" formControlName="fileType">
                    <optgroup label="Legal">
                      <option value="sepa">{{ "CASES.single.sepa" | translate }}</option>
                      <option value="contract">{{ "CASES.single.document-types.contract" | translate }}</option>
                      <option value="mandate">{{ "CASES.single.document-types.mandates" | translate }}</option>
                    </optgroup>
                    <optgroup *ngFor="let documentTypeCategory of documentTypeCategories"
                              [label]="documentTypeCategory.label">
                      <option *ngFor="let type of documentTypeCategory.document_types" [value]="type.id">
                        {{ type.custom ? type.name : type.label }}
                      </option>
                    </optgroup>
                  </select>
                </div>
              </div>
              <div style="padding-top: 25px">
                <!-- <app-spinner-btn [loading]="uploadSpinner" [name]="'SHARED.submit' | translate"></app-spinner-btn> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-6">
      <mat-accordion multi class="mt-2">
        <mat-expansion-panel class="mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "CASES.single.required-documents-v2" | translate }} {{ "CASES.single.client" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-required-v2 [case]="case" (closeAccordion)="closeRequestAccordion($event)"
                           [documentRequests]="case.file_requests" [clientType]="'client'">
          </app-required-v2>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="case.joint_application" class="mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "CASES.single.required-documents-v2" | translate }} {{ "CASES.single.partner" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-required-v2 [case]="case" (closeAccordion)="closeRequestAccordion($event)"
                           [documentRequests]="case.partner_file_requests" [clientType]="'partner'">
          </app-required-v2>
        </mat-expansion-panel>
        <!--           Client Documents -->
        <mat-expansion-panel class="mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "CASES.single.documents-list-v2" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion multi>
            <!-- Basic -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Documentación Básica
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(clientBasicDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientBasicDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Circumstantial -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Según Circunstancias Del Cliente
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(clientCircumstantialDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientCircumstantialDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Case Creditor -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Justificante de deudas
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(clientCaseCreditorDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientCaseCreditorDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
              <app-all-files-v2 *ngIf="Object.keys(clientPublicDebtDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientPublicDebtDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Case Asset Bank Account -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Certificado de titularidad y saldo de las cuentas bancarias
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(clientCaseBankAssetDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientCaseBankAssetDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Custom -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Otros documentos
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(clientCustomDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="clientCustomDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'client'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
        <!--           Partner Documents -->
        <mat-expansion-panel *ngIf="case.joint_application" class="mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "CASES.single.documents-list-partner-v2" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion multi>
            <!-- Basic -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Documentación Básica
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(partnerBasicDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerBasicDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Circumstantial -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Según Circunstancias Del Cliente
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(partnerCircumstantialDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerCircumstantialDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Case Creditor -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Justificante de deudas
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(partnerCaseCreditorDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerCaseCreditorDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
              <app-all-files-v2 *ngIf="Object.keys(partnerPublicDebtDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerPublicDebtDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Case Asset Bank Account -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Certificado de titularidad y saldo de las cuentas bancarias
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(partnerCaseBankAssetDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerCaseBankAssetDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
            <!-- Custom -->
            <mat-expansion-panel class="mt-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Otros documentos
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-all-files-v2 *ngIf="Object.keys(partnerCustomDocumentRequests).length" [case]="case"
                                (documentInfoChange$)="documentInfoChange($event)"
                                (documentStatusChange$)="documentStatusChange($event)"
                                (documentResendRequest$)="resendDocumentRequest($event)"
                                (documentDeleteRequest$)="deleteFile($event)"
                                (documentsMerged$)="documentsMerged($event)"
                                [userDocuments]="partnerCustomDocumentRequests"
                                [documentTypeCategories]="documentTypeCategories"
                                [allCreditors]="allCreditors"
                                [clientRole]="'partner'"
                                (selectedFileEvent)="setFileUrl($event)">
              </app-all-files-v2>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- Legal Docs Client -->
      <mat-expansion-panel class="mt-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "CASES.single.legal-documents" | translate }} {{ "CASES.single.client" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form *ngIf="legalForm" [formGroup]="legalForm" (ngSubmit)="submitLegalFiles('client')" class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="legal-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang" id="legal-docs-client"
                           formControlName="files" (change)="legalDocsChange($event, 'client')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let file of legalDocsClient">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" (click)="removeLegalFile($event, file.index, 'client')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group">
                <label for="legal-select">{{ "DOCUMENTS.select-category" | translate }}</label>
                <select class="form-control" id="legal-select" formControlName="fileType">
                  <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>
                  <option *ngFor="let legalType of legalDocumentTypes" [value]="legalType.name">
                    {{ legalType.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <!-- <div style="padding-top: 25px"> -->
              <!--   <app-spinner-btn [loading]="uploadSpinnerClientLegal" [name]="'SHARED.submit' | translate"> -->
              <!--   </app-spinner-btn> -->
              <!-- </div> -->
            </div>
          </div>
        </form>
        <form *ngIf="legalFormCustom" [formGroup]="legalFormCustom" (ngSubmit)="submitLegalFilesCustom('client')"
              class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="legal-docs-client-custom">{{ "DOCUMENTS.upload-documents-custom" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang"
                           id="legal-docs-client-custom" formControlName="files"
                           (change)="legalDocsChangeCustom($event, 'client')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     *ngFor="let file of legalDocsClientCustom">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" -->
                  <!--         (click)="removeLegalFileCustom($event, file.index, 'client')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group" style="width: 100%;">
                <label for="legal-select-custom">{{ "DOCUMENTS.enter-custom-name" | translate }}</label>
                <input type="text" class="form-control" id="legal-select-custom" formControlName="fileType"/>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <!-- <div style="padding-top: 25px"> -->
              <!--   <app-spinner-btn [loading]="uploadSpinnerClientLegalCustom" [name]="'SHARED.submit' | translate"> -->
              <!--   </app-spinner-btn> -->
              <!-- </div> -->
            </div>
          </div>
        </form>
        <app-all-legal-files [case]="case" [filesByLegal]="files_by_legal" [updateFiles]="updateFiles"
                             [type]="'client'" (selectedFileEvent)="setFileUrl($event)">
        </app-all-legal-files>
      </mat-expansion-panel>
      <!-- Legal Docs Partner -->
      <mat-expansion-panel *ngIf="case.joint_application" class="mt-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "CASES.single.legal-documents" | translate }} {{ "CASES.single.partner" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form *ngIf="legalFormPartner" [formGroup]="legalFormPartner" (ngSubmit)="submitLegalFiles('partner')"
              class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="legal-docs-partner">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang" id="legal-docs-partner"
                           formControlName="files" (change)="legalDocsChange($event, 'partner')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     *ngFor="let file of legalDocsPartner">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" (click)="removeLegalFile($event, file.index, 'partner')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group">
                <label for="legal-select-partner">{{ "DOCUMENTS.select-category" | translate }}</label>
                <select class="form-control" id="legal-select-partner" formControlName="fileType">
                  <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>
                  <option *ngFor="let legalType of legalDocumentTypes"
                          [value]="legalType.name">
                    {{ legalType.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <!-- <div style="padding-top: 25px"> -->
              <!--   <app-spinner-btn [loading]="uploadSpinnerPartnerLegal" -->
              <!--                    [name]="'SHARED.submit' | translate"></app-spinner-btn> -->
              <!-- </div> -->
            </div>
          </div>
        </form>
        <form *ngIf="legalFormCustomPartner" [formGroup]="legalFormCustomPartner"
              (ngSubmit)="submitLegalFilesCustom('partner')" class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="legal-docs-partner-custom">{{ "DOCUMENTS.upload-documents-custom" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang"
                           id="legal-docs-partner-custom"
                           formControlName="files" (change)="legalDocsChangeCustom($event, 'partner')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     *ngFor="let file of legalDocsPartnerCustom">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" -->
                  <!--         (click)="removeLegalFileCustom($event, file.index, 'partner')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group" style="width: 100%;">
                <label for="legal-select-custom-partner">{{ "DOCUMENTS.enter-custom-name" | translate }}</label>
                <input type="text" class="form-control" id="legal-select-custom-partner"
                       formControlName="fileType"/>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <!-- <div style="padding-top: 25px"> -->
              <!--   <app-spinner-btn [loading]="uploadSpinnerPartnerLegalCustom" [name]="'SHARED.submit' | translate"> -->
              <!--   </app-spinner-btn> -->
              <!-- </div> -->
            </div>
          </div>
        </form>
        <app-all-legal-files [case]="case" [filesByLegal]="partner_files_by_legal" [updateFiles]="updateFiles"
                             [type]="'partner'" (selectedFileEvent)="setFileUrl($event)">
        </app-all-legal-files>
      </mat-expansion-panel>
      <!-- Court Docs -->
      <mat-expansion-panel class="mt-2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "CASES.single.court-documents" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form *ngIf="courtForm" [formGroup]="courtForm" (ngSubmit)="submitCourtFiles('client')" class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="court-docs-client">{{ "DOCUMENTS.upload-documents" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang" id="court-docs-client"
                           formControlName="files" (change)="courtDocsChange($event, 'client')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center" *ngFor="let file of courtDocsClient">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" (click)="removeCourtFile($event, file.index, 'client')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group" style="width: 100%;">
                <label for="legal-select">{{ "DOCUMENTS.select-category" | translate }}</label>
                <select class="form-control" id="court-select" formControlName="fileType">
                  <option value="">{{ "DOCUMENTS.select-category" | translate }}</option>
                  <option *ngFor="let courtType of courtDocumentTypes"
                          [value]="courtType.name">
                    {{ courtType.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <!-- <div style="padding-top: 25px"> -->
              <!--   <app-spinner-btn [loading]="uploadSpinnerClientCourt" -->
              <!--                    [name]="'SHARED.submit' | translate"></app-spinner-btn> -->
              <!-- </div> -->
            </div>
          </div>
        </form>
        <form *ngIf="courtFormCustom" [formGroup]="courtFormCustom"
              (ngSubmit)="submitCourtFilesCustom('client')" class="form">
          <div class="row pb-4">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="court-docs-client-custom">{{ "DOCUMENTS.upload-documents-custom" | translate }}</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input multiple type="file" class="custom-file-input" [lang]="lang"
                           id="court-docs-client-custom"
                           formControlName="files" (change)="courtDocsChangeCustom($event, 'client')">
                    <label class="custom-file-label" for="uploadFile1">
                      {{ 'DOCUMENTS.choose-file' | translate }}
                    </label>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center"
                     *ngFor="let file of courtDocsClientCustom">
                  <p class="m-0 uploaded-file-name">{{ file.name }}</p>
                  <!-- <button mat-icon-button color="warn" -->
                  <!--         (click)="removeCourtFileCustom($event, file.index, 'client')"> -->
                  <!--   <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon"> -->
                  <!--     remove_circle -->
                  <!--   </mat-icon> -->
                  <!-- </button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex">
              <div class="form-group" style="width: 100%;">
                <label for="court-select-custom-client">{{ "DOCUMENTS.enter-custom-name" | translate }}</label>
                <input type="text" class="form-control" id="court-select-custom-client"
                       formControlName="fileType"/>
              </div>
            </div>
            <div class="col-md-4 col-lg-3 inline-form-button d-flex justify-content-between">
              <div style="padding-top: 25px">
                <!-- <app-spinner-btn [loading]="uploadSpinnerClientCourtCustom" [name]="'SHARED.submit' | translate"> -->
                <!-- </app-spinner-btn> -->
              </div>
            </div>
          </div>
        </form>
        <app-all-court-files [case]="case" [filesByCourt]="files_by_court" [updateFiles]="updateFiles"
                             [type]="'client'" (selectedFileEvent)="setFileUrl($event)">
        </app-all-court-files>
      </mat-expansion-panel>
    </div>
    <div class="col-6" *ngIf="fileType === 'object'; else photo" style="height: auto">
      <div *ngIf="fileUrl" style="height: 100%">
        <div [ngClass]="{'height-50': false, 'sticky' : isSticky}" style="height: 100%">
          <object [data]="fileUrl" type="application/pdf" width="100%" height="100%">
            <iframe [src]="fileUrl" width="100%" height="100%" style="border: none;">
              <p>
                Your browser does not support PDFs.
                <a [href]="fileUrl">Download the PDF</a>
              </p>
            </iframe>
          </object>
        </div>
      </div>
    </div>
    <ng-template #photo>
      <div class="col-6">
        <div [ngClass]="{'height-50' : true, 'sticky' : isSticky}">
          <img [src]="fileUrl" alt="" class="img-fluid" style="max-height: 83vh">
        </div>
      </div>
    </ng-template>
  </div>
</div>
