<div class="d-flex flex-column flex-md-row justify-content-between pb-2">
  <div class="d-flex flex-row align-items-center">
    <h3 class="m-0">
      <span *ngIf="editorType === 'edit'">{{ 'CASES.editor.heading_edit' | translate }}</span>
      <span *ngIf="editorType === 'create'">{{ 'CASES.editor.heading_create' | translate }}</span>
      <span class="primary-color-text ms-2" style="font-size: 18px">{{ case?.ref_number | uppercase }}</span>
      <span *ngIf="case?.is_test"> - {{ 'CASES.single.case_flags.is_test' | translate }}
        <mat-icon>warning</mat-icon>
    </span>
    </h3>
  </div>
  <div *ngIf="!isLoading && case" class="d-flex">
    <a *ngIf="case.id" [routerLink]="['/cases', case.id, 'general']" mat-button mat-raised-button color="primary" type="button"
       class="ms-2">
      {{ 'SHARED.details' | translate }}
    </a>
  </div>
</div>
<div class="row case-editor">
  <div *ngIf="serverResponse" class="col-12">
    <app-server-response [localAlerts]="localAlerts" [response]="serverResponse"></app-server-response>
  </div>
  <div *ngIf="isLoading" class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
  <div *ngIf="!isLoading" class="col-12">
    <nav *ngIf="tabs?.length" mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
      <a *ngFor="let tab of tabs" mat-tab-link [routerLink]="tab.path" routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive">
        {{ tab.label }}
      </a>
    </nav>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <app-case-finance-overview *ngIf="caseId" [caseId]="caseId"></app-case-finance-overview>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
    <app-floating-note [case]="case"></app-floating-note>
  </div>
</div>
