<div class="row">
  <div class="col-12">
    <h4>Notaries</h4>
  </div>
  <div class="col-md-8">
    <button mat-raised-button color="primary" routerLink="create" type="button">
      Add Notary
    </button>
  </div>
  <div class="col-md-4 pt-md-0 pt-5">
    <app-input type="text" [formControl]="search" [label]="'SHARED.search' | translate"
               [showLabel]="true" [extraLabel]="false" appearance="outline" [fullWidth]="true"
               [prefix]="'search'" [prefixType]="'icon'"></app-input>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource" style="width: 100%">

    <!-- ID -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.name" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Company Name -->
    <ng-container matColumnDef="company_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.company-name" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
    </ng-container>

    <!-- Approved -->
    <ng-container matColumnDef="approved">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.approved" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.approved}} </td>
    </ng-container>

    <!-- Address -->
    <ng-container matColumnDef="address">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.address" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <!-- Email General -->
    <ng-container matColumnDef="email_general">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.general-email" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.email_general}} </td>
    </ng-container>

    <!-- Submission Method -->
    <ng-container matColumnDef="submission_method">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"ADDRESS-BOOK.preferred-submission" | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.submission_method}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">{{"SHARED.actions" | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex">
          <a class="pe-3" routerLink="{{element.id}}/edit">{{"SHARED.edit" | translate}}</a>
          <a href="#" (click)="openDeleteDialog(element.id, $event)">{{"SHARED.delete" | translate}}</a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1"  style="padding: 50px">
    <h5 class="text-center">{{ "ADDRESS-BOOK.no-notaries" | translate }}</h5>
  </div>

  <mat-paginator
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="paginatorChange($event)"
    showFirstLastButtons
  ></mat-paginator>
</div>
