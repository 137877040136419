<!-- AGENT NAV -->
<ul *ngIf="authUser && authUser.role.is_staff && authUser.role_id !== 6" class="navbar-nav me-auto">
  <!-- Dashboard -->
  <li class="nav-item-custom" *ngIf="authUser?.packager?.master" routerLinkActive="nav-item-custom-active"
      [routerLinkActiveOptions]="{ exact: true }">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="dashboardMenu">
      {{ "NAVBAR.dashboard" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #dashboardMenu="matMenu" class="dropdown-custom">
      <a routerLink="" title="Sales leaderboard" mat-menu-item>
        <span>{{"DASHBOARD.sales-leaderboard" | translate}}</span>
      </a>
      <a routerLink="mi-dash" title="Mi dashboard" mat-menu-item>
        <span>{{"NAVBAR.mi-dashboard" | translate}}</span>
      </a>
      <a routerLink="mi-dash-customer-care" title="Mi dashboard customer" mat-menu-item>
        <span>{{"NAVBAR.mi-dashboard-customer" | translate}}</span>
      </a>
      <a routerLink="mi-dash-packager" title="Mi dashboard customer" mat-menu-item>
        <span>{{'NAVBAR.mi-dash-packager' | translate}}</span>
      </a>
      <a routerLink="mi-dash-details" title="Mi dashboard customer" mat-menu-item>
        <span>{{"NAVBAR.mi-dash-details" | translate}}</span>
      </a>
      <a routerLink="verifier-dashboard" title="Verifier Dashboard" mat-menu-item>
        <span>{{"NAVBAR.verifier-dash" | translate}}</span>
      </a>
      <a routerLink="tasks-dashboard" title="Tasks" mat-menu-item>
        <span>{{"NAVBAR.tasks" | translate}}</span>
      </a>
      <a routerLink="heads-up-dash" title="Heads Up Dashboard" mat-menu-item>
        <span>{{"NAVBAR.heads-up-dashboard" | translate}}</span>
      </a>
      <a routerLink="case-manager-dash" title="Case Manager Dash" mat-menu-item>
        <span>{{"NAVBAR.dashboard-case-manager" | translate}}</span>
      </a>
      <a routerLink="legal-advisor-dash" title="Legal Advisor Dash" mat-menu-item>
        <span>{{"NAVBAR.dashboard-legal-advisor" | translate}}</span>
      </a>
    </mat-menu>
  </li>
  <!-- Cases -->
  <li routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['case-dashboard']" title="Cases" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.cases" | translate }}</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master && authUser.role.slug === 'super-admin'" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['users']" title="Users" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.users" | translate }}</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master && authUser.role.slug === 'super-admin'" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['packagers', authUser.packager_id, 'details', 'general']" title="Config" class="nav-link">
      <span class="nav-link-text">Config</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master && authUser.role.slug === 'super-admin'" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['drip-campaigns']" title="Drip Campaigns" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.drip-campaigns" | translate }}</span>
    </a>
  </li>

  <!-- MANAGER & ADMIN NAV -->
  <ng-container *ngIf="authUser.packager?.master && authUser.role_id !== 3">
    <!-- Affiliate Cases-->
    <li class="nav-item-custom" routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="affiliateCasesMenu">
        {{ "NAVBAR.affiliate-cases" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #affiliateCasesMenu="matMenu" class="dropdown-custom-two">
        <a [routerLink]="['affiliate-cases']" title="Affiliate Cases" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.affiliate-cases" | translate }}</span>
        </a>
        <a [routerLink]="['new-partner-cases']" title="New Partner" class="dropdown-item">
          <span class="nav-link-text">{{'NAVBAR.new-partner' | translate}}</span>
        </a>
      </mat-menu>
    </li>
    <!-- Packager Cases-->
    <li routerLinkActive="active" class="nav-item"
        *ngIf="authUser.id === 27 || authUser.id === 36 || authUser.id === 2497 || authUser.id === 34 || authUser.id === 1
               || authUser.id === 705239 || authUser.id === 755799 || authUser.id === 211750 || authUser.id === 21">
      <a class="nav-link" href="#" [routerLink]="['packager-cases']" title="Packager Cases">
        <span class="nav-link-text">{{ "NAVBAR.packager-cases" | translate }}</span>
      </a>
    </li>
    <!-- Payments -->
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['customer-contact']" title="Customer Contact" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.customer-contact" | translate }}</span>
      </a>
    </li>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['payment']" title="Payments" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.payments" | translate }}</span>
      </a>
    </li>
    <li routerLinkActive="active" class="nav-item">
      <a [routerLink]="['legal']" title="Legal" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.legal" | translate }}</span>
      </a>
    </li>

    <!-- Creditors -->
    <!--        <li routerLinkActive="active" *ngIf="authUser.role_id !==  3" class="nav-item">-->
    <!--          <a routerLink="creditors" title="Creditors" class="nav-link">-->
    <!--            <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>-->
    <!--            <i *ngIf="unapproved" class="fal fa-exclamation-circle ml-1 text-danger"></i>-->
    <!--          </a>-->
    <!--        </li>-->
  </ng-container>

  <li class="nav-item-custom" *ngIf="authUser?.packager?.master" routerLinkActive="nav-item-custom-active">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="adrdressBookMenu">
      {{ "NAVBAR.address-book" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #adrdressBookMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="creditors" title="Creditors" class="dropdown-item" *ngIf="authUser.role_id !==  3">
        <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>
        <i *ngIf="unapproved" class="fal fa-exclamation-circle ms-1 text-danger"></i>
      </a>
      <a routerLink="affiliates" title="Affiliates" class="dropdown-item"
         *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
        <span class="nav-link-text">{{ "NAVBAR.affiliates" | translate }}</span>
      </a>
      <a routerLink="packagers" title="Packagers" class="dropdown-item"
         *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
        <span class="nav-link-text">{{ "NAVBAR.packagers" | translate }}</span>
      </a>
      <a routerLink="notaries" title="Notaries" class="dropdown-item">
        <span class="nav-link-text">Notaries</span>
      </a>
      <a routerLink="administrators" title="Administrators" class="dropdown-item">
        <span class="nav-link-text">{{'NAVBAR.administrators' | translate}}</span>
      </a>
      <a routerLink="court" title="Court" class="dropdown-item">
        <span class="nav-link-text">Court</span>
      </a>
      <a routerLink="town-halls" title="Court" class="dropdown-item">
        <span class="nav-link-text">{{'NAVBAR.town_halls' | translate}}</span>
      </a>
      <a routerLink="solicitors" title="Court" class="dropdown-item">
        <span class="nav-link-text">{{'NAVBAR.solicitors' | translate}}</span>
      </a>
    </mat-menu>
  </li>
  <!-- ADMIN ONLY NAV -->
  <ng-container *ngIf="authUser?.packager?.master && (authUser.role_id === 1 || authUser.role_id === 5)">
    <!-- Users -->
    <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="usersMenu">
        {{ "NAVBAR.users" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #usersMenu="matMenu" class="dropdown-custom">
        <a [routerLink]="['users']" title="Users" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.users" | translate }}</span>
        </a>
        <a [routerLink]="['teams']" title="Users" class="dropdown-item">
          <span class="nav-link-text">{{ "TEAM.list.heading" | translate }}</span>
        </a>
      </mat-menu>
    </li>
  </ng-container>
  <!-- Distribution -->
  <li *ngIf="userIsAMami()" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['/distribution']" title="Distribution" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.distribution" | translate }}</span>
    </a>
  </li>
  <ng-container *ngIf="authUser?.packager?.master && (authUser.role_id === 1 || authUser.role_id === 5)">
    <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="reportsMenu">
        {{ "NAVBAR.reports" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #reportsMenu="matMenu" class="dropdown-custom">
        <a *ngIf="authUser.role_id === 5" routerLink="reports/headline-reports" title="Headline Reports"
           class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.headline-reports" | translate}}</span>
        </a>
        <a routerLink="reports/creditor-book-value" title="Creditor - Book Value" class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.creditor-book-value" | translate}}</span>
        </a>
        <a *ngIf="authUser.role_id === 5" routerLink="reports/financial-reports" title="Financial Reports"
           class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.financial-reports" | translate}}</span>
        </a>
        <a routerLink="reports/missing-documents" title="Missing documents"
           class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.missing-documents" | translate}}</span>
        </a>
        <a routerLink="tasks" title="Tasks" class="dropdown-item">
          <span class="nav-link-text">{{"NAVBAR.tasks" | translate}}</span>
        </a>
      </mat-menu>
    </li>
    <!-- Config -->
    <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="configurationMenu">
        {{ "NAVBAR.configuration" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #configurationMenu="matMenu" class="dropdown-custom">
        <a routerLink="notification" title="Notifications" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.notifications" | translate }}</span>
        </a>
        <a routerLink="status" title="Statuses" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.statuses" | translate }}</span>
        </a>
        <a *ngIf="authUser?.role_id === 5" routerLink="call-statuses" title="Call statuses" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.call-statuses" | translate }}</span>
        </a>
        <a *ngIf="authUser?.role_id === 5" routerLink="packager-statuses" title="Packager statuses"
           class="dropdown-item">
          <span class="nav-link-text">{{'NAVBAR.packager-statuses' | translate}}</span>
        </a>
        <a routerLink="task-templates" title="Task Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.task-templates" | translate }}</span>
        </a>
        <a routerLink="sms" title="SMS Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.sms-templates" | translate }}</span>
        </a>
        <a routerLink="email" title="Email Templates" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.email-templates" | translate }}</span>
        </a>
        <a routerLink="system-event" title="System Events" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.system-events" | translate }}</span>
        </a>
        <a routerLink="drip-campaigns" title="Drip Campaigns" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.drip-campaigns" | translate }}</span>
        </a>
        <a routerLink="config/dialer" title="Dialer Configuration" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.dialer-config" | translate }}</span>
        </a>
        <a routerLink="misc-config" title="Misc Config" class="dropdown-item">
          <span class="nav-link-text">{{ "NAVBAR.misc-config" | translate }}</span>
        </a>
      </mat-menu>
    </li>
  </ng-container>
  <!--Employees-->
  <li *ngIf="authUser?.packager?.master" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['/employee-list']" title="Employees" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.employees" | translate }}</span>
    </a>
  </li>
</ul>

<ul *ngIf="authUser && authUser.role.is_staff && authUser.packager.master && authUser.role_id === 6"
    class="navbar-nav me-auto">
  <!-- Dashboard -->
  <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active" [routerLinkActiveOptions]="{ exact: true }">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="dashboardMenu">
      {{ "NAVBAR.dashboard" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #dashboardMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="" title="Sales leaderboard" class="dropdown-item">
        <span class="nav-link-text">{{"DASHBOARD.sales-leaderboard" | translate}}</span>
      </a>
    </mat-menu>
  </li>
  <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="reportsTwoMenu">
      {{ "NAVBAR.reports" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #reportsTwoMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="reports/headline-reports" title="Headline Reports"
         class="dropdown-item">
        <span class="nav-link-text">{{"NAVBAR.headline-reports" | translate}}</span>
      </a>
    </mat-menu>
  </li>
</ul>
