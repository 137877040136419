import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';

@Component({
  selector: 'app-case-user-qualifying-details',
  templateUrl: './case-user-qualifying-details.component.html',
  styles: []
})
export class CaseUserQualifyingDetailsComponent implements OnInit {
  @Input() public case: Case;
  constructor() { }

  ngOnInit(): void {
  }

  public getQualifyingQuestionsValue(field: boolean | null){
    if (field === true){
      return 'Yes';
    }
    else if (field === false){
      return 'No';
    } else {
      return 'Not set';
    }
  }
}
