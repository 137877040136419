import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DepartmentCategory } from '../../../../../../_base-shared/models/Department/DepartmentCategory';
import { Product } from '../../../../../../_base-shared/models/Product';
import { CaseInvoiceStatus } from '../../../../../../_base-shared/models/Status/CaseInvoiceStatus';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { User } from '../../../../../../_base-shared/models/User/User';
import { DepartmentService } from '../../department/department.service';
import { PaymentStatusService } from '../../payment-status/payment-status.service';
import { PaymentService } from '../../payment/payment.service';
import { StatusPickerTrait } from '../../status/status-picker.trait';
import { StatusService } from '../../status/status.service';
import { CaseService } from '../case.service';
import { ProductService } from '../product.service';

@Component({
  selector:    'app-case-bulk-action-modal',
  templateUrl: './case-bulk-action-modal.component.html',
  styles:      [],
})
export class CaseBulkActionModalComponent extends StatusPickerTrait implements OnInit {
  public form: UntypedFormGroup;
  public isLoading                                      = 0;
  public componentType: 'case' | 'installment';
  public formType: 'status' | 'payment_status' | 'case_invoice_status' | 'department_assignments' | 'product_type';
  public statusFormControlName                          = 'bulk_status_id';
  public paymentStatuses: Array<PaymentStatus>          = [];
  public categoryDepartments: Array<DepartmentCategory> = [];
  public caseInvoiceStatuses: Array<CaseInvoiceStatus>  = [];
  public filteredAgents: Array<User>                    = [];
  public superStatus                                    = new UntypedFormControl(null, null);
  public serverResponse: LaravelResourceResponse;
  public isSubmitting: boolean;
  public products: Array<Product>                       = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<CaseBulkActionModalComponent>,
              private toastr: ToastrService,
              private translate: TranslateService,
              private statusService: StatusService,
              private paymentStatusService: PaymentStatusService,
              private departmentService: DepartmentService,
              private caseService: CaseService,
              private paymentService: PaymentService,
              private productService: ProductService
  ) {
    super('status_id', false, false);
  }

  ngOnInit(): void {
    this.formType      = this.data.formType;
    this.componentType = this.data.componentType;
    this.buildForm(this.formType);
    this.fetchProducts();
  }

  private buildForm(formType: 'status' | 'payment_status' | 'case_invoice_status' | 'department_assignments' | 'product_type') {
    if (formType === 'status') {
      this.fetchStatuses();
      this.buildStatusChangeForm();
    } else if (formType === 'payment_status') {
      this.fetchPaymentStatuses();
      this.buildPaymentStatusChangeForm();
    } else if (formType === 'case_invoice_status') {
      this.fetchCaseInvoiceStatuses();
      this.buildCaseInvoiceStatusChangeForm();
    } else if (formType === 'product_type') {
      this.buildProductTypeChangeForm();
    } else {
      this.buildDepartmentStatusChangeForm();
      this.fetchDepartments();
    }
  }

  private buildStatusChangeForm() {
    this.form = this.fb.group({
      bulk_status_id: [null, [Validators.required]],
    });
  }

  private buildPaymentStatusChangeForm() {
    this.form = this.fb.group({
      bulk_payment_status_id: [null, [Validators.required]],
    });
  }

  private buildCaseInvoiceStatusChangeForm() {
    this.form = this.fb.group({
      bulk_case_invoice_status_id: [null, [Validators.required]],
    });
  }

  private buildDepartmentStatusChangeForm() {
    this.form = this.fb.group({
      bulk_department_id: [null, [Validators.required]],
      bulk_user_id:       [null, [Validators.required]],
    });
  }

  private buildProductTypeChangeForm() {
    this.form = this.fb.group({
      new_product_id: [[null], [Validators.required]],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let observable: Observable<LaravelResourceResponse>;
    let formTypeLabel: string;
    if (this.formType === 'status') {
      formTypeLabel = 'CASES.list.bulk_actions.forms.status';
    } else if (this.formType === 'payment_status') {
      formTypeLabel = 'CASES.list.bulk_actions.forms.payment_status';
    } else if (this.formType === 'case_invoice_status') {
      formTypeLabel = 'CASES.list.bulk_actions.forms.case_invoice_status';
    } else if (this.formType === 'product_type') {
      formTypeLabel = 'CASES.list.bulk_actions.forms.product_type';
    } else {
      formTypeLabel = 'CASES.list.bulk_actions.forms.department_assignments';
    }

    if (this.componentType === 'case') {
      if (this.formType === 'status') {
        observable = this.caseService.updateCaseStatus(form.value, this.data.requestFilter);
      } else if (this.formType === 'payment_status') {
        observable = this.caseService.updatePaymentStatus(form.value, this.data.requestFilter);
      } else if (this.formType === 'case_invoice_status') {
        observable = this.caseService.updateCaseInvoiceStatus(form.value, this.data.requestFilter);
      } else if (this.formType === 'product_type') {
        observable = this.caseService.updateProductType(form.value, this.data.requestFilter);
      } else {
        observable = this.caseService.updateDepartmentAssignment(form.value, this.data.requestFilter);
      }
    } else {
      if (this.formType === 'status') {
        observable = this.paymentService.updateCaseStatus(form.value, this.data.requestFilter);
      } else if (this.formType === 'payment_status') {
        observable = this.paymentService.updatePaymentStatus(form.value, this.data.requestFilter);
      } else if (this.formType === 'product_type') {
        observable = this.paymentService.updateProductType(form.value, this.data.requestFilter);
      } else {
        observable = this.paymentService.updateDepartmentAssignment(form.value, this.data.requestFilter);
      }
    }

    this.isSubmitting = true;
    observable.pipe(finalize(() => this.isSubmitting = false)).subscribe(result => {
      this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
        {model: this.translate.instant(formTypeLabel)}));
      this.dialogRef.close();
    }, error => {
      this.serverResponse = error.error;
      this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
        {model: this.translate.instant(formTypeLabel)}));
    });
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.statusCategories         = result.data;
        this.filteredStatusCategories = result.data;
        this.statusCategories.forEach(category => {
          this.allStatuses.push(category);
          category.statuses.forEach(status => this.allStatuses.push(status));
        });
        this.setStatusControls(this.form.get(this.statusFormControlName).value || []);
      },
    );
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.paymentStatusService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchCaseInvoiceStatuses() {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexInvoiceStatuses({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.caseInvoiceStatuses = result.data));
  }

  private fetchDepartments() {
    this.isLoading++;
    this.subscriptions.push(
      this.departmentService.categoryIndex({}, ['departments', 'departments.users']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.categoryDepartments = result.data;
          console.log(result.data);
        }),
    );
  }

  public updateSelectedStatusCategories(selectedStatusCategoryIds: Array<number> | number) {
    this.filteredStatusCategories = [];
    setTimeout(() => super.updateSelectedStatusCategories(selectedStatusCategoryIds), 0);
  }

  public updateSelectedDepartmentCategories(departmentId: number) {
    this.caseService.getAgentsByCategory(departmentId).subscribe(result => {
      this.filteredAgents = result.data;
    });
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.products = result.data;
        }),
    );
  }
}
