<div class="d-flex flex-row justify-content-between align-items-center">
  <h4>{{ 'CASES.single.general.relation_list.aep_history.heading' | translate }}</h4>
</div>
<div class="pt-4 ps-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !caseAepDocuments?.length"></app-empty-dataset>
<mat-accordion multi>
  <mat-expansion-panel class="bg-light m-1" *ngFor="let aep of caseAepDocuments">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p class="m-0">{{ 'SHARED.model.created_at' | translate }}:
          <b>{{ aep.created_at | toDateObject | date: 'medium' }}</b>
        </p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="d-flex">
      <a mat-raised-button color="primary" target="_blank" href="{{ storageUrl + aep.location }}">
        {{ 'CASES.single.general.relation_list.aep_history.aep_download' | translate }}
      </a>
    </div>
  </mat-expansion-panel>
</mat-accordion>
