<h5 mat-dialog-title>
  {{ 'LEAD.dialer-list-id-selector.title' | translate }}
</h5>
<div mat-dialog-content class="pt-5 pb-5">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4">
        <!-- Dialer list names -->
        <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.dialer_list_id' | translate"
                   formControlName="list_id" class="full-width" [showClear]="false" [searchable]="true"
                   [selectOptions]="dialerLists" [selectLabel]="'name'" [selectValue]="'id'">
        </app-input>
      </div>
      <div class="col-3 d-flex flex-row justify-content-center">
        <!-- Send owner  -->
        <app-input type="checkbox" [formControl]="sendOwner" appearance="fill"
                   [label]="'LEAD.dialer-list-id-selector.form.send_owner' | translate">
        </app-input>
      </div>
      <div class="col-5">
        <!-- User Departments -->
        <app-input *ngIf="categoryDepartments?.length && sendOwner.value === true" type="select"
                   [formControl]="departmentIds"  [showClear]="false" [searchable]="true"
                   [label]="'USERS.editor.departments' | translate" (ngModelChange)="onDepartmentChange($event)"
                   [selectOptions]="categoryDepartments" [selectLabel]="'name'" [selectValue]="'id'"
                   [optGroupProperty]="'departments'" [extraLabel]="false"  appearance="outline">
        </app-input>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions slot="end">
  <button mat-button color="primary" (click)="submitForm(form)">
    {{ 'LEAD.dialer-list-id-selector.actions.submit' | translate }}
  </button>
  <button mat-button mat-dialog-close>{{ "SHARED.close" | translate }}</button>
</div>
