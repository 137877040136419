<div class="row">
  <div class="col-12" *ngIf="newNotification; then createTitle; else editTitle"></div>
  <ng-template #createTitle>
    <h4 class="ps-4">{{"CONFIG.notifications.add-notification" | translate}}</h4>
  </ng-template>
  <ng-template #editTitle>
    <h4 class="ps-4">{{"CONFIG.notifications.edit-notification" | translate}}</h4>
  </ng-template>
</div>
<div class="row" *ngIf="loading; then thenBlock else elseBlock">

</div>
<ng-template #thenBlock>
  <div class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
</ng-template>
<ng-template #elseBlock>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="notificationForm" (ngSubmit)="onSubmit()">
        <div class="card shadow mb-5">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 pt-md-0">
                <label class="ps-2">
                  {{"CONFIG.notifications.title" | translate}}
                </label>
                <input type="text"
                       formControlName="title"
                       class="form-control"
                       [placeholder]="'CONFIG.notifications.title' | translate">
              </div>
              <div class="col-md-6 pt-md-0 pt-5">
                <label class="ps-2">
                  {{"CONFIG.notifications.type" | translate}}
                </label>
                <select formControlName="type" class="custom-select form-control">
                  <option value="info">{{"CONFIG.notifications.info" | translate}}</option>
                  <option value="warning">{{"CONFIG.notifications.warning" | translate}}</option>
                  <option value="danger">{{"CONFIG.notifications.danger" | translate}}</option>
                  <option value="success">{{"SHARED.success" | translate}}</option>
                </select>
              </div>
            </div>

            <div class="row pt-5">
              <div class="col-md-6 pt-md-0">
                <label class="ps-2">
                  {{"CONFIG.notifications.content" | translate}}
                </label>
                <textarea class="form-control"
                          formControlName="body"
                          [placeholder]="'CONFIG.notifications.content' | translate">
                          rows="5"></textarea>
              </div>
              <div class="col-md-6 pt-md-0">
                <label for="sends_via" class="ps-2">
                  {{"CONFIG.notifications.sends-via" | translate}}
                </label>
                <select id="sends_via" formControlName="sends_via" class="custom-select form-control">
                  <option value="email">Email</option>
                  <option value="sms">SMS</option>
                </select>
              </div>
            </div>

            <div class="row pt-5">
              <button mat-raised-button color="primary" style="min-width: 120px" class="ms-3" type="submit">
                {{"SHARED.submit" | translate}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

