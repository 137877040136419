<div class="row">
  <div class="col col-md-10 col-lg-8 col-xl-6 m-auto">
    <mat-card>
      <mat-card-content>
        <mat-spinner diameter="50" *ngIf="isLoading" class="m-0 m-auto"></mat-spinner>
        <nav mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
          <a mat-tab-link [routerLink]="['/profile', 'details']" routerLinkActive #rla="routerLinkActive"
             [routerLinkActiveOptions]="{exact:true}" [active]="rla.isActive">
            Details
          </a>
          <a mat-tab-link [routerLink]="['/profile', 'security']" routerLinkActive #rla1="routerLinkActive"
             [routerLinkActiveOptions]="{exact:true}" [active]="rla1.isActive">
            Security
          </a>
          <a *ngIf="authUser.role.is_staff" mat-tab-link [routerLink]="['/profile', 'connections']"
             routerLinkActive #rla2="routerLinkActive" [routerLinkActiveOptions]="{exact:true}"
             [active]="rla2.isActive">
            Connections
          </a>
        </nav>
        <div class="router-container">
          <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
          </mat-tab-nav-panel>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
