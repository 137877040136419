import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { AppFile } from '../../../../../../../../_base-shared/models/File/AppFile';
import { SafePipe } from '../../../../../../../../_base-shared/pipes/safe.pipe';
import { environment } from '../../../../../../environments/environment';
import {
  CaseClientRequestedDocumentListV3Component
} from './case-client-requested-document-list-v3/case-client-requested-document-list-v3.component';
import { CaseCourtDocumentListV3Component } from './case-court-document-list-v3/case-court-document-list-v3.component';

@Component({
  selector:    'app-case-document-list-v3',
  templateUrl: './case-document-list-v3.component.html',
  styleUrls:   ['./case-document-list-v3.component.scss']
})
export class CaseDocumentListV3Component implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() documentableType!: 'case_creditor' | 'case_public_debt' | 'case_asset';
  @Input() documentableId!: number;
  @ViewChild('clientRequestedDocs') clientRequestedDocs!: CaseClientRequestedDocumentListV3Component;
  @ViewChild('partnerRequestedDocs') partnerRequestedDocs!: CaseClientRequestedDocumentListV3Component;
  @ViewChild('courtClientDocs') courtClientDocs!: CaseCourtDocumentListV3Component;
  @ViewChild('courtPartnerDocs') courtPartnerDocs!: CaseCourtDocumentListV3Component;

  public storageUrl = environment.STORAGE_URL + '/';

  public previewFileUrl: string;
  public previewFileType: string;
  public previewIsSticky = false;

  private subscriptions: Array<Subscription> = [];

  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    this.previewIsSticky = window.pageYOffset >= 250;
  }

  constructor(private safePipe: SafePipe,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public previewFile(documentFile: AppFile): void {
    let url = this.storageUrl + documentFile.path;

    this.previewFileUrl  = this.safePipe.transform(url + '?output=embed', 'resourceUrl') as string;
    this.previewFileType = documentFile.mime_type?.includes('image') ? 'image' : 'object';
  }

  public reloadRequestedDocList(clientRole: 'client' | 'partner'): void {
    if (clientRole === 'client') {
      this.clientRequestedDocs.loadDocumentTypeCategories();
    } else {
      this.partnerRequestedDocs.loadDocumentTypeCategories();
    }
  }

  public addUpdatedFileToDocList($event: AppFile, componentList: 'clientList' | 'courtList', clientRole: 'client' | 'partner'): void {
    if (componentList === 'clientList') {
      if (clientRole === 'client') {
        console.log('adding to client list client role');
        this.clientRequestedDocs.appendUpdatedFile($event);
      } else {
        console.log('adding to client list partner role');
        this.partnerRequestedDocs.appendUpdatedFile($event);
      }
    } else {
      if (clientRole === 'client') {
        console.log('adding to court list client role');
        this.courtClientDocs.appendUpdatedFile($event);
      } else {
        console.log('adding to court list partner role');
        this.courtPartnerDocs.appendUpdatedFile($event);
      }
    }
  }
}
