<mat-dialog-content>
  <div class="d-flex flex-column" style="height: 100%">
    <div mat-dialog-title class="d-flex justify-content-center">
      <h4>{{ title }}</h4>
    </div>
    <div mat-dialog-content class="flex-grow-1" style="overflow-y: scroll">
      <ng-container *ngIf="previewType === 'email'">
        <div class="email-header" *ngIf="emailHeader">
          <div>
            <small>{{ 'SHARED-COMPONENTS.email-preview.from' | translate }}:
              <span class="fw-bold">{{ emailHeader.from[1] }}<{{ emailHeader.from[0] }}></span>
            </small>
          </div>
          <div>
            <small>{{ 'SHARED-COMPONENTS.email-preview.to' | translate }}:
              <span class="fw-bold">{{ emailHeader.to }}</span>
            </small>
          </div>
          <div>
            <small>{{ 'SHARED-COMPONENTS.email-preview.cc' | translate }}:
              <span class="fw-bold">{{ emailHeader.cc.join(', ') }}</span>
            </small>
          </div>
          <div>
            <small>{{ 'SHARED-COMPONENTS.email-preview.subject' | translate }}:
              <span class="fw-bold">{{ emailHeader.subject }}</span>
            </small>
          </div>
        </div>
        <div class="email-body" *ngIf="emailBody" [innerHTML]="emailBody | safe: 'html'"></div>
        <div class="email-attachments" *ngIf="attachments && attachments.length">
          <h5>{{ 'SHARED-COMPONENTS.email-preview.attachments.title' | translate }}:</h5>
          <ul>
            <li *ngFor="let document of attachments; let i = index"
                class="d-flex justify-content-around align-items-center">
              <div>{{ document.slug }}<small> <{{ document.mime_type }}></small></div>
              <div>
                <button type="button" mat-raised-button (click)="previewAttachment(i)">
                  {{ 'SHARED-COMPONENTS.email-preview.attachments.actions.preview' | translate }}
                </button>
                <!--<button class="ml-1" type="button" mat-raised-button (click)="saveAttachment(i)">Save</button>-->
                <button type="button" mat-raised-button class="ms-1"
                        (click)="saveAndDownloadAttachment(i)">
                  {{ 'SHARED-COMPONENTS.email-preview.attachments.actions.save-and-download' | translate }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="previewType === 'document'">
        <div [innerHTML]="documentBody | safe: 'html'"></div>
      </ng-container>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <ng-container *ngIf="previewType === 'email'">
        <button type="button" mat-raised-button color="primary"
                (click)="closeAndRunAction('send-email')">
          {{ 'SHARED-COMPONENTS.email-preview.actions.send' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="previewType === 'document'">
        <button type="button" mat-raised-button color="primary"
                (click)="closeAttachmentPreview()">
          {{ 'SHARED.back' | translate }}
        </button>
        <button type="button" mat-raised-button color="primary"
                (click)="saveAndDownloadAttachment(activeAttachmentIndex)">
          {{ 'SHARED-COMPONENTS.email-preview.attachments.actions.save-and-download' | translate }}
        </button>
      </ng-container>
      <button type="button" mat-raised-button color="primary" (click)="closePreview()">
        {{ 'SHARED.close' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
