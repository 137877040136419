import { Creditor } from '../Entity/Creditor';
import { Case } from './Case';

export type CaseAssetType = 'properties' | 'vehicles' | 'bank_accounts' | 'other';
export type CaseAssetOwnership = 'applicant' | 'partner' | 'joint';

export class CaseAsset {
  id: number;
  case_id: number;
  entity_id: number;
  type: CaseAssetType;
  ownership: CaseAssetOwnership;
  registration: string;
  make: string;
  model: string;
  age: number;
  value: number;
  estimated_value: number;
  outstanding_finance: number;
  home_type: string;
  address_1: string;
  post_code: string;
  mortgage: string;
  other_assets_type: string;
  notes: string;
  additional_partner: number;
  on_finance: number;
  balance: number;
  exposed: number;
  office: string;
  account_number: string;
  securities_account: string;
  property_registration: string;
  acquisition_value: number;
  liquidable: 0 | 1 | boolean;
  client_documents_visible: boolean | 0 | 1;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  case?: Case;
  entity?: Creditor;
}
