<mat-dialog-content>
  <!--Change status form-->
  <div>
    <h5 mat-dialog-title>  {{ "CASES.list.bulk_actions.forms." + formType | translate }}</h5>
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
    <ng-container *ngIf="formType === 'status'">
      <div class="pt-3">
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <app-input *ngIf="statusCategories?.length" type="select"
                     appearance="fill" [searchable]="true" [formControl]="superStatus"
                     [label]="'CASES.single.general.status_editor.status_category' | translate"
                     [selectOptions]="statusCategories" [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                     (ngModelChange)="updateSelectedStatusCategories($event)"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false" [required]="true">
          </app-input>
          <app-input *ngIf="filteredStatusCategories?.length" type="select"
                     formControlName="bulk_status_id"
                     appearance="fill" [searchable]="true"
                     [multiple]="statusMultipleSelection"
                     [label]="'CASES.single.general.status_editor.status' | translate"
                     [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'" [optGroupLabel]="'name'"
                     [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
          </app-input>
          <div class='pt-2 text-end'>
            <button type="button" mat-button color="primary" class="ms-2" (click)="dialogRef.close()">
              {{ "SHARED.close" | translate }}
            </button>
            <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </ng-container>
    <!--Change payment status form-->
    <ng-container *ngIf="formType === 'payment_status'">
      <div class="pt-3">
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <app-input type="select" formControlName="bulk_payment_status_id"
                     appearance="fill" [searchable]="true"
                     [label]="'CASES.single.general.status_editor.payment_status' | translate"
                     [selectOptions]="paymentStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
          </app-input>
          <div class='pt-2 text-end'>
            <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
              {{ "SHARED.close" | translate }}
            </button>
            <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </ng-container>
    <!-- Case Invoice Status -->
    <ng-container *ngIf="formType === 'case_invoice_status'">
      <div class="pt-3">
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <app-input type="select" formControlName="bulk_case_invoice_status_id"
                     appearance="fill" [searchable]="true"
                     [label]="'CASES.single.general.status_editor.case_invoice_status' | translate"
                     [selectOptions]="caseInvoiceStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
          </app-input>
          <div class='pt-2 text-end'>
            <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
              {{ "SHARED.close" | translate }}
            </button>
            <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </ng-container>
    <!--Change department status form-->
    <ng-container *ngIf="formType === 'department_assignments'">
      <div class="pt-3">
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <app-input *ngIf="categoryDepartments?.length" type="select" formControlName="bulk_department_id"
                     [label]="'USERS.editor.departments' | translate" appearance="fill"  [searchable]="true"
                     [selectOptions]="categoryDepartments" [selectLabel]="'name'" [selectValue]="'id'"
                     [optGroupProperty]="'departments'" [extraLabel]="true" [fullWidth]="true"
                     (ngModelChange)="updateSelectedDepartmentCategories($event)">
          </app-input>
          <app-input *ngIf="filteredAgents?.length" type="select"
                     formControlName="bulk_user_id"
                     appearance="fill" [searchable]="true"
                     [label]="'CASES.single.general.status_editor.agent' | translate"
                     [selectOptions]="filteredAgents"
                     [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
          </app-input>
          <div class='pt-2 text-end'>
            <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
              {{ "SHARED.close" | translate }}
            </button>
            <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </ng-container>
    <!--Change product type-->
    <ng-container *ngIf="formType === 'product_type'">
      <div class="pt-3">
        <form [formGroup]="form" (ngSubmit)="submitForm(form)">
          <app-input *ngIf="products?.length" type="select"
                     formControlName="new_product_id"
                     appearance="fill" [searchable]="true"
                     [label]="'CASES.single.product-type' | translate"
                     [selectOptions]="products"
                     [selectLabel]="'name'" [selectValue]="'id'" class="full-width"
                     [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
          </app-input>
          <div class='pt-2 text-end'>
            <button type="button" mat-button color="primary" class="mt-3 ms-2" (click)="dialogRef.close()">
              {{ "SHARED.close" | translate }}
            </button>
            <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
            </app-spinner-btn>
          </div>
        </form>
      </div>
    </ng-container>
  </div>

</mat-dialog-content>
