import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { AppDocumentRequest } from '../../../../../_base-shared/models/Document/AppDocumentRequest';
import { AppDocumentTypeCategory } from '../../../../../_base-shared/models/Document/AppDocumentTypeCategory';
import { DocumentType } from '../../../../../_base-shared/models/DocumentType';
import { AppFile, UploadedAppDocument } from '../../../../../_base-shared/models/File/AppFile';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService extends MainBaseApiService {

  public legacyIndex(requestData = {}): Observable<LaravelResourceResponse<Array<DocumentType>>> {
    return this.http.get<LaravelResourceResponse<Array<DocumentType>>>(this.apiUrl + '/document-types', {params: requestData})
      .pipe(catchError(response => this.handleError(response)));
  }


  // AppDocument Start
  // tslint:disable-next-line:max-line-length
  public indexCaseDocumentTypeCategories(caseId: number, data = {}, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<AppDocumentTypeCategory>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<AppDocumentTypeCategory>>>(this.apiUrl + '/cases/' + caseId + '/app-document-type-categories', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseDocumentRequests(caseId: number, data = {}, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<AppDocumentRequest>>> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Array<AppDocumentRequest>>>(this.apiUrl + '/cases/' + caseId + '/app-document-requests', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public requestDocumentUpload(caseId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<Array<AppDocumentRequest>>>(this.apiUrl + '/cases/' + caseId + '/app-document-requests', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public deleteDocumentRequest(caseId: number, documentRequestId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/app-document-requests/' + documentRequestId)
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO:
  public resendRequest(caseId: number, fileId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<AppDocumentRequest>>(
      this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId + '/resend-request', data
    ).pipe(catchError(response => this.handleError(response)));
  }

  public storeDocument(documentTypeId: number, data): Observable<LaravelResourceResponse<AppDocument>> {
    return this.http.post<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/app-document-types/' + documentTypeId + '/app-documents', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateDocument(caseId: number, documentId: number, data): Observable<LaravelResourceResponse> {
    return this.http.patch<LaravelResourceResponse<AppDocument>>(this.apiUrl + '/cases/' + caseId + '/app-documents/' + documentId, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public mergeDocumentFiles(caseId: number, documentId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<AppFile>>(
      this.apiUrl + '/cases/' + caseId + '/app-documents/' + documentId + '/merge-files', data
    ).pipe(catchError(response => this.handleError(response)));
  }

  public adminUploadContract(caseId, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ caseId }/contract-mandates-upload`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public adminUploadAppDocument(caseId: number, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<UploadedAppDocument>>(this.apiUrl + '/cases/' + caseId + '/app-documents/upload', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateDocumentFile(caseId: number, fileId: number, data, relations: Array<string> = []): Observable<LaravelResourceResponse<AppFile>> {
    data = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'post');

    return this.http.patch<LaravelResourceResponse<AppFile>>(this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public deleteDocumentFile(caseId: number, fileId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/app-files/' + fileId)
      .pipe(catchError(response => this.handleError(response)));
  }

  // AppDocument End
}
