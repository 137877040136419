<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h5>{{'CASES.case-snapshot.expanses' | translate}}</h5>
      </div>
    </div>
  </div>
  <div class="card-body" style="padding-top: 0">
    <div class="row">
      <ng-container>
        <div class="col" style="padding: 0px;border-right: 1px solid #e9e9e9;">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.ibi" | translate }}</th>
              <td>{{expenses?.ibi  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.itv" | translate }}</th>
              <td>{{expenses?.itv  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.legal_fees" | translate }}</th>
              <td>{{expenses?.legal_fees  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.phone_home" | translate }}</th>
              <td>{{expenses?.phone_home  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.legal_fees" | translate }}</th>
              <td>{{expenses?.legal_fees  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.medications" | translate }}</th>
              <td>{{expenses?.medications  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.other_other" | translate }}</th>
              <td>{{expenses?.other_other  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.travel_fuel" | translate }}</th>
              <td>{{expenses?.travel_fuel  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.pension_plan" | translate }}</th>
              <td>{{expenses?.pension_plan  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.pet_expenses" | translate }}</th>
              <td>{{expenses?.pet_expenses  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.phone_mobile" | translate }}</th>
              <td>{{expenses?.phone_mobile  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.housekeeping_newspapers" | translate }}</th>
              <td>{{expenses?.housekeeping_newspapers  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.travel_public_transport" | translate }}</th>
              <td>{{expenses?.travel_public_transport  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_clothing_footwear" | translate }}</th>
              <td>{{expenses?.housekeeping_clothing_footwear  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_food_and_groceries" | translate }}</th>
              <td>{{expenses?.housekeeping_food_and_groceries  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_cleaning" | translate }}</th>
              <td>{{expenses?.housekeeping_cleaning  | currency: 'EUR'}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.essential_hire_purchase" | translate }}</th>
              <td>{{expenses?.essential_hire_purchase  | currency: 'EUR'}}</td>
            </tr>
            <th>{{'CLIENT-PORTAL.validator-form.special_expenses' | translate}}</th>
            <tr *ngFor="let specialExpenses of expenses?.special_expenses; let i = index">
              <th scope="row"> {{specialExpenses.label}}</th>
              <td>{{specialExpenses.value  | currency: 'EUR'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col" style="padding: 0px">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.essential_gas" | translate }}</th>
              <td>{{expenses?.essential_gas }}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.essential_rent" | translate }}</th>
              <td>{{expenses?.essential_rent}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.home_insurance" | translate }}</th>
              <td>{{expenses?.home_insurance}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.life_insurance" | translate }}</th>
              <td>{{expenses?.life_insurance}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.essential_other" | translate }}</th>
              <td>{{expenses?.essential_other}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.essential_water" | translate }}</th>
              <td>{{expenses?.essential_water }}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.school_expenses" | translate }}</th>
              <td>{{expenses?.school_expenses}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.medical_expenses" | translate }}</th>
              <td>{{expenses?.medical_expenses}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.essential_mortgage" | translate }}</th>
              <td>{{expenses?.essential_mortgage}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.travel_vehicle_tax" | translate }}</th>
              <td>{{expenses?.travel_vehicle_tax}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.essential_local_tax" | translate }}</th>
              <td>{{expenses?.essential_local_tax}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.cosmetics_and_beauty" | translate }}</th>
              <td>{{expenses?.cosmetics_and_beauty}}</td>
            </tr>
            <tr>
              <th scope="row"> {{"AEP-SIGNATURE.home_insurance" | translate }}</th>
              <td>{{expenses?.home_insurance}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_alcohol" | translate }}</th>
              <td>{{expenses?.housekeeping_alcohol}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_laundry" | translate }}</th>
              <td>{{expenses?.housekeeping_laundry}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.housekeeping_cleaning" | translate }}</th>
              <td>{{expenses?.housekeeping_cleaning}}</td>
            </tr>
            <tr>
              <th scope="row">{{"AEP-SIGNATURE.travel_car_insurance" | translate }}</th>
              <td>{{expenses?.travel_car_insurance}}</td>
            </tr>
            <tr>
              <th scope="row">  {{"AEP-SIGNATURE.essential_electricity" | translate }}</th>
              <td>{{expenses?.essential_electricity}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>
