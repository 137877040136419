import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { Status } from '../../../../../../../../_base-shared/models/Status/Status';
import { environment } from '../../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../../creditor/creditor.service';
import { StatusService } from '../../../../status/status.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { BaseCaseCreditorComponent } from '../base-case-creditor/base-case-creditor.component';

@Component({
  selector:    'app-case-creditor',
  templateUrl: './case-creditor.component.html',
  styleUrls:   ['../base-case-creditor/base-case-creditor.component.scss'],
})
export class CaseCreditorComponent extends BaseCaseCreditorComponent implements OnInit, OnDestroy {
  @Input() creditors: MatTableDataSource<any>;
  @Input() originalCreditors: Array<CaseCreditor>;
  @Input() type: string;
  @Input() case: Case;
  @Output() moveToExcluded: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveToIncluded: EventEmitter<any> = new EventEmitter<any>();
  @Output() editToggle: EventEmitter<any>     = new EventEmitter<any>();
  @Output() typeToggle: EventEmitter<any>     = new EventEmitter<any>();
  @Input() missingAepFiles: boolean;
  @Input() isSigned: boolean;

  public form: UntypedFormGroup;
  public excludedForm: UntypedFormGroup;
  public displayedColumns: string[]          = [];
  public isLoading                           = 0;
  public isFetching                          = 0;
  public isLoadingIds: Array<number>         = []; //  User to determinate in which row to show spinner
  public statuses: Array<Status>             = [];
  private subscriptions: Array<Subscription> = [];

  /*public creditorsSelection = new SelectionModel<CaseCreditor>(true, []);*/

  public creditorSendAepDisabled         = true;
  public creditorResendAepDisabled       = true;
  public creditorVerifyDisabled          = true;
  public creditorResendDisabled          = true;
  public creditorRecoveryDisabled        = true;
  public creditorProposalAccordDisabled  = true;
  public isSubmitting                    = false;
  public storageUrl                      = environment.STORAGE_URL + '/';
  public isExcluded                      = 0;
  public isEditing                       = false;
  public currentRow: number;
  public isEmailEnabled                  = false;
  public isGetRightOfAccessClientEnabled = false;

  constructor(
    protected router: Router,
    protected fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toast: ToastrService,
    protected translate: TranslateService,
    protected toastr: ToastrService,
    protected globalEventService: MainGlobalEventService,
    protected caseService: CaseService,
    protected creditorService: CreditorService,
    protected caseCreditorService: CaseCreditorService,
    protected caseDocumentService: CaseDocumentService,
    protected statusService: StatusService,
    protected el: ElementRef,
  ) {
    super(router, fb, route, dialog, toast, translate, toastr, globalEventService, caseService, creditorService,
      caseCreditorService, caseDocumentService, statusService, el);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayColumns();
    this.fetchStatuses();
    this.buildDMForm();
    this.buildForm();
    this.creditorsSelection.changed.subscribe(next => {
      this.checkIfDisabled(next);
    });
  }

  private buildDisplayColumns(): void {
    if (this.type === 'unsecured') {
      this.displayedColumns = !! (this.case.product.group_slug === 'dm' || this.case.product.group_slug ===
        'dgs' ||
        this.case.product.group_slug === 'cajaplus') ? [
        'select',
        'name',
        'debt_type',
        'initial_balance',
        'current_balance',
        'amount_repaid',
        'claimed_amount',
        'reference_number',
        'response_received',
        'additional_partner',
        'making_payments',
        'debt_agreed',
        'debt_reduction',
        'monthly_repayment',
        'term',
        'status',
        'reference',
        'actions',
        'loader',
      ] : [
        'select',
        'name',
        'ownership',
        'debt_type',
        'initial_balance',
        'current_balance',
        'amount_repaid',
        'claimed_amount',
        'reference_number',
        'response_received',
        'additional_partner',
        'making_payments',
        'actions',
        'loader',
      ];

      if ( ! this.displayedColumns.includes('ownership') && this.case.joint_application &&
        this.case.product.group_slug === 'dm') {
        this.displayedColumns.splice(2, 0, 'ownership');
      }
    } else if (this.type === 'secured' || this.type === 'claim') {
      this.displayedColumns = [
        'select',
        'name',
        'ownership',
        'debt_type',
        'initial_balance',
        'current_balance',
        'claimed_amount',
        'reference_number',
        'response_received',
        'additional_partner',
        'making_payments',
        'azcarate',
        'actions',
        'loader',
      ];
    } else if (this.type === 'excluded') {
      this.displayedColumns = [
        'select',
        'name',
        'ownership',
        'debt_type',
        'initial_balance',
        'current_balance',
        'amount_repaid',
        'claimed_amount',
        'reference_number',
        'response_received',
        'additional_partner',
        'making_payments',
        'actions',
        'loader',
      ];
    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      ownership:         new UntypedFormControl(''),
      verified:          new UntypedFormControl(''),
      response_received: new UntypedFormControl(''),
    });
  }

  buildDMForm(): void {
    this.excludedForm = this.fb.group({
      debt_agreed:       new UntypedFormControl(''),
      monthly_repayment: new UntypedFormControl(''),
      reference:         new UntypedFormControl(''),
      status:            new UntypedFormControl(''),
    });
  }

  public toggleEditMode(index: number, element): void {
    this.currentRow = index;
    this.isEditing  = ! this.isEditing;
    this.editToggle.emit({isEditing: this.isEditing, element, excludedForm: {...this.excludedForm, excluded: 0}});
  }

  public sendRightOfAccess(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.subscriptions.push(
      this.creditorService.sendRightOfAccess(this.case.id, {selected_ids: selectedIds})
        .pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = [];
          if (selectedCaseCreditors.selected) {
            selectedCaseCreditors.clear(); //  Remove selections
          }
        }))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
          },
          error => {
            if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
              this.throwMultipleErrors(error.error.errors.case.errors);
            } else {
              this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
                {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access')}));
            }
          }),
    );
  }

  public sendRightOfAccessPpi(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.subscriptions.push(this.creditorService.sendRightOfAccessPpi(this.case.id, {selected_ids: selectedIds})
      .pipe(finalize(() => {
        this.isLoading--;
        this.isLoadingIds = [];
        if (selectedCaseCreditors.selected) {
          selectedCaseCreditors.clear(); //  Remove selections
        }
      }))
      .subscribe(
        value => {
          this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
            {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
        },
        error => {
          if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
            this.throwMultipleErrors(error.error.errors.case.errors);
          } else {
            this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
              {subject: this.translate.instant('CASES.single.creditors.actions.right_of_access_ppi')}));
          }
        }));
  }

  public sendPpiClaim(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.subscriptions.push(
      this.creditorService.sendPpiClaim(this.case.id, {selected_ids: selectedIds})
        .pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = [];
          if (selectedCaseCreditors.selected) {
            selectedCaseCreditors.clear(); //  Remove selections
          }
        }))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
              {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
          },
          error => {
            if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
              this.throwMultipleErrors(error.error.errors.case.errors);
            } else {
              this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
                {subject: this.translate.instant('CASES.single.creditors.actions.ppi_claim')}));
            }
          }),
    );
  }

  public sendAzcarateLoanLetter(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.subscriptions.push(
      this.creditorService.sendAzcarateLoanLetter(this.case.id, {selected_ids: selectedIds})
        .pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = [];
          if (selectedCaseCreditors.selected) {
            selectedCaseCreditors.clear(); //  Remove selections
          }
        }))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
          },
          error => {
            if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
              this.throwMultipleErrors(error.error.errors.case.errors);
            } else {
              this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
                {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_loan_letter')}));
            }
          }),
    );
  }

  public sendAzcarateRevolvingLetter(selectedCaseCreditors): void {
    const selectedIds = selectedCaseCreditors.selected ?
      selectedCaseCreditors.selected.map(cred => cred.id) :
      selectedCaseCreditors;

    this.isLoading++;
    this.isLoadingIds = selectedIds;
    this.subscriptions.push(
      this.creditorService.sendAzcarateRevolvingLetter(this.case.id, {selected_ids: selectedIds})
        .pipe(finalize(() => {
          this.isLoading--;
          this.isLoadingIds = [];
          if (selectedCaseCreditors.selected) {
            selectedCaseCreditors.clear(); //  Remove selections
          }
        }))
        .subscribe(
          value => {
            this.toast.success(this.translate.instant('SHARED.submit_result.sent_email.success',
              {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
          },
          error => {
            if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
              this.throwMultipleErrors(error.error.errors.case.errors);
            } else {
              this.toast.error(this.translate.instant('SHARED.submit_result.sent_email.error',
                {subject: this.translate.instant('CASES.single.creditors.actions.azcarate_revolving_letter')}));
            }
          }),
    );
  }

  public checkIfDisabled(selection): void {
    this.creditorVerifyDisabled = !! (selection.source.selected.length === 0 ||
      selection.source.selected.filter(cred => cred.verified === 1).length);

    this.creditorResendDisabled = !! (selection.source.selected.length === 0 ||
      selection.source.selected.filter(cred => cred.verified !== 1).length);

    this.creditorRecoveryDisabled = !! (selection.source.selected.length === 0 ||
      selection.source.selected.filter(cred => cred.recovery_creditor_id).length);

    this.creditorSendAepDisabled = !! (selection.source.selected.length === 0 ||
      (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 &&
        (this.case.legal_documents.filter(
            file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
          this.case.legal_documents.filter(
            file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
      selection.source.selected.filter(cred => cred.aep_sent === 1).length);

    this.creditorResendAepDisabled = !! (selection.source.selected.length === 0 ||
      (this.case.legal_documents.filter(file => file.document_type === 'aep').length === 0 &&
        (this.case.legal_documents.filter(
            file => file.document_type === 'aep-reg' && file.uploaded_by === 'client').length === 0 ||
          this.case.legal_documents.filter(
            file => file.document_type === 'aep-reg' && file.uploaded_by === 'partner').length === 0)) ||
      selection.source.selected.filter(cred => cred.aep_sent !== 1).length);

    this.creditorProposalAccordDisabled = !! (selection.source.selected.length === 0 || ! this.isSigned ||
      selection.source.selected.filter(cred => ! cred.proposal_location).length);
  }

  public returnLenderType(lenderType: 'standard' | 'high_interest' | 'collections'): string {
    const types = {
      collections:   '',
      standard:      '',
      high_interest: '#FFFF63',
    };
    return types[lenderType];
  }

  public onExclude(element, formValue?): void {
    this.isExcluded = 1;
    const data      = {
      excluded:          this.isExcluded,
      debt_agreed:       formValue?.debt_agreed,
      monthly_repayment: formValue?.monthly_repayment,
      reference:         formValue?.reference,
      status:            formValue?.status,
    };
    this.moveToExcluded.emit({element, form: data});
    this.excludedForm.reset();
  }

  public onInclude(element): void {
    this.isExcluded = 0;
    this.moveToIncluded.emit({element, isExcluded: this.isExcluded});
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.index().pipe(finalize(() => this.isLoading--)).subscribe(result => {
          this.statuses = result.data;
        },
      ),
    );
  }

  public previewCreditorProposal(): void {
    const element          = this.creditorsSelection.selected[0];
    const navigationExtras = {
      queryParams: {uuid: this.case.uuid, case_creditor_id: element.id, ownership: element.ownership},
    };
    this.router.navigate(['/creditor-proposal-preview'], navigationExtras);
  }

  public downloadFile(documentType: string): void {
    const document = this.creditorsSelection.selected[0];
    if (documentType === 'proposal') {
      saveAs(this.storageUrl + document.proposal_location);
    } else if (documentType === 'proposal_accord') {
      saveAs(this.storageUrl + document.proposal_location_accord);
    }
  }

  public sendProposalAndAccordSelection(selection: SelectionModel<CaseCreditor>): void {
    return this.sendProposalAndAccord(selection.selected.map(caseCreditor => caseCreditor.id), selection);
  }

  public toggleType(value: CaseCreditor, creditorType: 'unsecured' | 'claim' | 'secured' | string,
                    prevCreditorType = null): void {
    this.typeToggle.emit({value, creditorType, prevCreditorType});
  }

  public returnHeadingByType(type: string): string {
    if (type === 'unsecured') {
      return 'CASE_CREDITOR.model.type.options.unsecured';
    } else if (type === 'secured') {
      return 'CASE_CREDITOR.model.type.options.secured';
    } else if (type === 'claim') {
      return 'CASE_CREDITOR.model.type.options.claim';
    } else if (type === 'excluded') {
      return 'CASE_CREDITOR.model.type.options.excluded';
    }
  }
}
