import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { CaseListFilter } from '../../../../../_base-shared/models/Case/CaseListFilter';
import { CaseDistributionExportType } from '../../../../../_base-shared/models/Distribution/CaseDistribution';
import { Payment } from '../../../../../_base-shared/models/Payment/Payment';
import { PaymentRequest } from '../../../../../_base-shared/models/Payment/PaymentRequest';
import { PaymentTerm } from '../../../../../_base-shared/models/Payment/PaymentTerm';
import { OppwaOrder } from '../../../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import { RedsysOrder } from '../../../../../_base-shared/models/Payment/Transaction/RedsysOrder';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DistributionService extends MainBaseApiService {
  public showCase(caseUuid: string, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
      {with: relations}, 'get',
    );
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public assignToDistribution(filters) {
    return this.http.post<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/case-distributions/assign', filters)
      .pipe(catchError(response => this.handleError(response)));
  }

  public unassignFromDistribution(filters) {
    return this.http.post<LaravelResourceResponse<Array<Case>>>(this.apiUrl + '/case-distributions/unassign', filters)
      .pipe(catchError(response => this.handleError(response)));
  }

  public assignToBatch(batchId: number, filters) {
    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/case-distributions/assign-batch/' + batchId, filters,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public unassignFromBatch(filters) {
    return this.http.post<LaravelResourceResponse<Array<Case>>>(
      this.apiUrl + '/case-distributions/unassign-batch', filters,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public export(type: CaseDistributionExportType, requestData: CaseListFilter) {
    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData, export_type: type}, 'get',
    );

    return this.http.get<LaravelResourceResponse>(this.apiUrl + '/case-distributions/export', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexInstallments(caseUuid: string, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<PaymentTerm>>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid + '/installments', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPayments(caseUuid: string, params) {
    return this.http.get<LaravelResourceResponse<Array<Payment>>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid + '/payments', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexTransactions(caseUuid: string, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<OppwaOrder>>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid + '/transactions', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentOrders(caseUuid: string, data, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<RedsysOrder>>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid + '/payment-orders', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPaymentRequests(caseUuid: string, params) {
    return this.http.get<LaravelResourceResponse<Array<PaymentRequest>>>(
      this.apiUrl + '/distribution-provider/cases/' + caseUuid + '/payment-requests', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }
}
