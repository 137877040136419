import { Injectable } from '@angular/core';
import { da } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  CaseManagerDashboardPerformanceInterface,
  HeadsUpDashboardBookPerformanceInterface,
  HeadsUpDashboardCollectionsInterface,
  HeadsUpDashboardDgsPerformanceInterface,
  HeadsUpDashboardDistributionInterface,
  HeadsUpDashboardDmPerformanceInterface,
  HeadsUpDashboardLsoPerformanceInterface,
  HeadsUpDashboardRequestFilters,
} from '../../../../../_base-shared/contracts/dashboard.interface';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends MainBaseApiService {

  public index(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/dashboard`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexV2(data?): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/dashboard-v1`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexV3(data?): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/dashboard/mi-dash-packager`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public indexCustomerCare(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/dashboard-customer-care`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getSalesData(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/leaderboard`)
      .pipe(catchError(response => this.handleError(response)));
  }

  public getVerifierData(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/verifierboard`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  public getCollectionsData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardCollectionsInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardCollectionsInterface>>(
      this.apiUrl + '/dashboard/heads-up/collections', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDistributionData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardDistributionInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDistributionInterface>>(
      this.apiUrl + '/dashboard/heads-up/distribution', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getLsoSummaryData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardLsoPerformanceInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardLsoPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/lso-summary', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDmSummaryData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardDmPerformanceInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDmPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/dm-summary', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getBookPerformanceData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardBookPerformanceInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardBookPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/book-performance', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDgsSummaryData(data?: HeadsUpDashboardRequestFilters)
    : Observable<LaravelResourceResponse<HeadsUpDashboardDgsPerformanceInterface>> {
    return this.http.get<LaravelResourceResponse<HeadsUpDashboardDgsPerformanceInterface>>(
      this.apiUrl + '/dashboard/heads-up/dgs-summary', {params: data as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public caseManagerIndex(selectedProduct: string): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + `/dashboard/case-manager-dashboard`, {params: {selectedProduct}}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public legalAdvisorsIndex(selectedProduct: string, dateFrom: string, dateTo: string): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + `/dashboard/legal-advisor-dashboard`, {params: {selectedProduct, dateFrom, dateTo}}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public caseManagerDetail(data, agentId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + `/dashboard/case-manager-dashboard/${agentId}/detail`, {params: data}
    ).pipe(catchError(response => this.handleError(response)));
  }
}
