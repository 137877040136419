<div class="d-flex flex-column flex-md-row justify-content-between mt-5 pb-2">
  <div class="d-flex flex-row align-items-center">
    <h5 class="m-0">{{ 'CASES.single.heading' | translate }}
      <span class="primary-color-text" style="font-size: 18px">{{ case?.ref_number | uppercase }}</span>
    </h5>
  </div>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<nav *ngIf="case" mat-tab-nav-bar dynamicHeight mat-stretch-tabs="false" [tabPanel]="tabPanel">
  <a mat-tab-link [routerLink]="['payments']" routerLinkActive
     #rla4="routerLinkActive" [active]="rla4.isActive">
    {{ 'CASES.single.payments.heading'  | translate }}
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
