import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {DashboardService} from '../dashboard.service';
import {BaseChartDirective } from 'ng2-charts';
import {ChartOptions, ChartType} from 'chart.js';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-case-manager-dashboard',
  templateUrl: './case-manager-dashboard.component.html',
  styleUrls: ['./case-manager-dashboard.component.scss']
})
export class CaseManagerDashboardComponent implements OnInit{
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  public isLoading                     = 0;
  public agentData: any[]                       = [];
  public totalTarget: number;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[]    = [
    'agent_name', 'cases_assigned', 'documentacion_completa', 'revisar_documents', 'dda_pte_revision', 'target_demandas'
  ];
  public selectedProduct: 'dm' | 'lso' | 'dgs' | 'cajaplus' | 'unified'            = 'lso';
  public chartsReady: boolean;
  public doughnutCharts: {
    casoCongelado?: {
      labels: any[],
      datasets: any,
      options: ChartOptions,
      type: ChartType,
      plugins: any[]
    },
    documentacionCompleta?: {
      labels: any[],
      datasets: any,
      options: ChartOptions,
      type: ChartType,
      plugins: any[]
    }
  }                        = {};

  public doughnutChartLabelsDefaultCasoCongleado: any[]  = ['Target', 'Caso Congelado Conversions', 'Sales goal'];
  public doughnutChartLabelsExtendedCasoCongleado: any[] = [
    'Target',
    'Over Target',
    'Caso Congelado Conversions',
    'Sales goal'];

  public doughnutChartLabelsDefaultDocumentacionCompleta: any[]  = [
    'Target',
    'No of Documentacion Completa',
    'Sales goal'];
  public doughnutChartLabelsExtendedDocumentacionCompleta: any[] = [
    'Target',
    'Over Target',
    'No of Documentacion Completa',
    'Sales goal'];

  public doughnutChartOptionsDefault: any  = {
    // responsive: true,
    // maintainAspectRatio: false,
    aspectRatio: 1.9,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartOptionsExtended: any = {
    tooltips: {
      custom: (label) => {
        if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
          return label.opacity = 0;
        } else {
          return label;
        }
      },
    },
    plugins:  {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
            return null;
          } else {
            return value === 0 ? null : value;
          }
        },
      },
    },
  };

  public doughnutChartColorsDefault  = ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'];
  public doughnutChartColorsExtended = ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'];

  totalNumberOfDocumentacionCompleta = 0;
  public monthlyQuotaDocCompleta;

  constructor(private route: ActivatedRoute,
              private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
   this.getDashboardData();
   this.doughnutCharts.documentacionCompleta = {
     options:  this.doughnutChartOptionsDefault,
     labels:   this.doughnutChartLabelsDefaultDocumentacionCompleta,
     type:     'doughnut',
     plugins:  [ChartDataLabels],
     datasets: [
       {
         data:            [0, 0, 0],
         backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)'],
       }
     ],
   };
  }

  public onRadioChange($event): void {
    this.selectedProduct       = $event.value;
    this.getDashboardData();

  }

  public getTotal(colum: string): number {
    let total = 0;

    this.agentData.forEach(agent => {
      total += agent?.grouped_accomplishments[colum]?.length || 0;
    });

    return total;
  }

  public getTotalTarget(): void {
    let total = 0;

    this.agentData.forEach(agent => {
      total += agent?.sale_target?.monthly_completed_documentations;
    });

    this.totalTarget = total;
  }

  public updateChartData(): void {
    const currentDayInMonth = DateTime.local().day;
    const chartDaysInMonth  = DateTime.local().daysInMonth;
    const percentOfTotal    = Math.round(currentDayInMonth / chartDaysInMonth * 100);
    this.agentData.forEach(agent => {
      this.totalNumberOfDocumentacionCompleta += agent?.grouped_accomplishments['full-documentation']?.length || 0;
    });

    this.monthlyQuotaDocCompleta   = this.totalTarget;
    const dayTargetDocumentcion    = (this.monthlyQuotaDocCompleta / 100) * percentOfTotal;
    const actualSalesDocumentcion  = this.totalNumberOfDocumentacionCompleta;

    // Documemtacion Completa
    this.doughnutCharts.documentacionCompleta.datasets[0].data[0] = +dayTargetDocumentcion.toFixed(); //  update day target
    this.doughnutCharts.documentacionCompleta.datasets[0].data[2] = +(this.monthlyQuotaDocCompleta -
      dayTargetDocumentcion).toFixed(); //  update sales goal
    this.doughnutCharts.documentacionCompleta.datasets[0].data[1] = +actualSalesDocumentcion.toFixed(); //  update actual sales

    if (this.monthlyQuotaDocCompleta - actualSalesDocumentcion < 0) {
      this.doughnutCharts.documentacionCompleta.labels     = this.doughnutChartLabelsExtendedDocumentacionCompleta;
      this.doughnutCharts.documentacionCompleta.options    = this.doughnutChartOptionsExtended;
    }

    this.charts.forEach(chart => chart.update());
    this.chartsReady = true;
  }

  protected getDashboardData(): void {
    this.dashboardService.caseManagerIndex(this.selectedProduct)
      .subscribe(res => {
        this.agentData = res.data;
        this.dataSource = new MatTableDataSource(this.agentData);
        this.getTotalTarget();
        this.updateChartData();
      });
  }
}
