import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckIfComponentIsDirty } from '../../../../../_base-shared/guards/check-if-comopnent-dirty.guard';
import { MainAuthGuard } from '../../_shared/guards/main-auth-guard.service';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { PackagerCasesPermissionGuard } from '../../_shared/guards/packager-cases-permission.guard';
import { DocumentComponent } from '../document/document.component';
import { NotificationListComponent } from '../notification/notification-list/notification-list.component';
import { TaskListComponent } from '../task/task-list/task-list.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { CaseCreditorDetailComponent } from './case-detail/case-creditor-detail/case-creditor-detail.component';
import {
  CaseCreditorDocumentListComponent
} from './case-detail/case-creditor-detail/case-creditor-document-list/case-creditor-document-list.component';
import {
  CaseCreditorGeneralDetailComponent
} from './case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-general-detail.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { CaseDocumentListV1Component } from './case-detail/case-documents/case-document-list-v1/case-document-list-v1.component';
import { CaseDocumentListV2Component } from './case-detail/case-documents/case-document-list-v2/case-document-list-v2.component';
import {
  CaseDocumentListV3WrapperComponent
} from './case-detail/case-documents/case-document-list-v3-wrapper/case-document-list-v3-wrapper.component';
import { CaseDocumentsComponent } from './case-detail/case-documents/case-documents.component';
import { CaseAepCreatorComponent } from './case-detail/case-draft/case-aep-creator/case-aep-creator.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { DemandGeneratorComponent } from './case-detail/case-draft/demand-generator/demand-generator.component';
import { EpiRequestGeneratorComponent } from './case-detail/case-draft/epi-request/epi-request-generator.component';
import { LsoGeneratorComponent } from './case-detail/case-draft/lso-generator/lso-generator.component';
import { CaseLoanCalculatorComponent } from './case-detail/case-loan-calculator/case-loan-calculator.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import {
  ClientInstallmentListPreviewComponent
} from './case-detail/case-payment/client-installment-list-preview/client-installment-list-preview.component';
import { CaseAssetEditorComponent } from './case-editor/case-asset-editor/case-asset-editor.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseExpenseEditorComponent } from './case-editor/case-expense-editor/case-expense-editor.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import { CaseIncomeEditorComponent } from './case-editor/case-income-editor/case-income-editor.component';
import { CaseOutcomeComponent } from './case-editor/case-outcome/case-outcome.component';
import { CasePaymentEditorComponent } from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseSnapshotComponent } from './case-snapshot/case-snapshot.component';
import { CreditorProposalPreviewComponent } from './creditor-proposal-preview/creditor-proposal-preview.component';

const caseListChildren: Routes = [
  {
    path:        'create',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'create', allowAgent: false, allowManager: true},
    children:    [
      {
        path:      'general',
        component: CaseGeneralEditorComponent,
        data:      {editorType: 'create'},
      },
    ],
  },
  {
    path:        ':id',
    component:   CaseDetailComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    children:    [
      {path: '', redirectTo: 'general', pathMatch: 'full'},
      {path: 'general', component: CaseGeneralDetailComponent},
      {
        path: 'documents', component: CaseDocumentsComponent, children: [
          {path: 'v1', component: CaseDocumentListV1Component},
          {path: 'v2', component: CaseDocumentListV2Component},
          {path: 'v3', component: CaseDocumentListV3WrapperComponent},
        ]
      },
      {
        path: 'creditors', children: [
          {path: '', component: CaseCreditorListComponent},
          {
            path: ':id', component: CaseCreditorDetailComponent, children: [
              {path: '', redirectTo: 'general', pathMatch: 'full'},
              {path: 'general', component: CaseCreditorGeneralDetailComponent},
              {path: 'documents', component: CaseCreditorDocumentListComponent},
            ]
          }
        ]
      },
      {path: 'legal', component: CaseDraftComponent},
      {path: 'legal-router/document-generator/demand', component: DemandGeneratorComponent},
      {path: 'legal-router/document-generator/epi', component: EpiRequestGeneratorComponent},
      {path: 'legal-router/document-generator/lso', component: LsoGeneratorComponent},
      {path: 'loan-calculator', component: CaseLoanCalculatorComponent},
      {path: 'payments', component: CasePaymentComponent},
      {path: 'aep/create', component: CaseAepCreatorComponent},
      {path: 'snapshot', component: CaseSnapshotComponent},
      {path: 'payments/:caseUuid/preview-client-installment-list', component: ClientInstallmentListPreviewComponent},
    ],
    data:        {allowAgent: false, allowManager: true},
  },
  {
    path:        ':id/edit',
    component:   CaseEditorComponent,
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {editorType: 'edit', allowAgent: true, allowManager: true},
    children:    [
      {
        path:          'general',
        component:     CaseGeneralEditorComponent,
        canDeactivate: [CheckIfComponentIsDirty],
        data:          {editorType: 'edit'},
      },
      {path: 'creditors', component: CaseCreditorEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'assets', component: CaseAssetEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'incomes', component: CaseIncomeEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'expenses', component: CaseExpenseEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
      {path: 'outcome', component: CaseOutcomeComponent},
      {path: 'payment', component: CasePaymentEditorComponent, canDeactivate: [CheckIfComponentIsDirty]},
    ],
  },
];

const routes: Routes = [
  {
    path:        'cases',
    children:    [
      {path: '', component: CaseListComponent, data: {onlyRelated: false}},
      ...caseListChildren,
    ],
    canActivate: [MainAuthGuard, MainPermissionGuard],
    data:        {filterType: 'case', allowAgent: true, allowManager: true},
  },
  {
    path:     'legal',
    children: [
      {path: '', component: CaseListComponent, data: {filterType: 'legal'}},
    ],
  },
  {
    path:     'affiliate-cases',
    children: [
      {path: '', component: CaseListComponent, data: {filterType: 'affiliate_case'}},
    ],
  },
  {
    path:     'packager-cases',
    children: [
      {
        path:        '',
        component:   CaseListComponent,
        data:        {filterType: 'packager_non_transferred'},
        canActivate: [PackagerCasesPermissionGuard],
      },
    ],
  },
  {
    path:      'case-dashboard',
    component: CaseDashboardComponent,
    children:  [
      {path: '', redirectTo: 'cases', pathMatch: 'full'},
      {
        path:        'cases',
        canActivate: [MainAuthGuard, MainPermissionGuard],
        data:        {filterType: 'case', allowAgent: true, allowManager: false},
        children:    [
          {path: '', component: CaseListComponent},
          ...caseListChildren,
        ],
      },
      {
        path:     'my-cases',
        children: [
          {path: '', component: CaseListComponent, data: {filterType: 'case', onlyRelated: true}},
          ...caseListChildren,
        ],
      },
      {
        path:     'tasks',
        children: [
          {path: '', component: TaskListComponent},
        ],
      },
      {
        path:     'my-tasks',
        children: [
          {path: '', component: TaskListComponent, data: {onlyRelated: true}},
        ],
      },
      {
        path:     'notifications',
        children: [
          {path: '', component: NotificationListComponent},
        ],
      },
    ],
  },
  {
    path:     'new-partner-cases',
    children: [
      {path: '', component: CaseListComponent, data: {newPartner: 1}},
    ],
  },
  {path: 'app-documents/download', component: DocumentComponent},
  {
    path:     'new-partner-cases',
    children: [
      {path: '', component: CaseListComponent, data: {newPartner: 1}},
    ],
  },
  {path: 'creditor-proposal-preview', component: CreditorProposalPreviewComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class CaseRoutingModule {
}
