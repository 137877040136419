import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { debounce, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { CasePaymentPlan } from '../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { PaymentMethod, PaymentMethodType } from '../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentProcessorType } from '../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { User } from '../../../../../../../_base-shared/models/User/User';
import {
  RequestPaymentModalComponent
} from '../../../../_shared/components/request-payment-modal/request-payment-modal.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { EmailPreviewComponent } from '../../../app-document/email-preview/email-preview.component';
import { AdminPaymentHandlerComponent } from '../../../payment/admin-payment-handler/admin-payment-handler.component';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { PaymentMethodService } from '../../../payment/payment-method.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseDocumentService } from '../../case-document.service';
import { CasePaymentPlanService } from '../../case-payment-plan.service';
import { CaseService } from '../../case.service';
import { ProductService } from '../../product.service';

@Component({
  selector:    'app-case-payment-editor',
  templateUrl: './case-payment-editor.component.html',
  styleUrls:   ['./case-payment-editor.component.scss']
})
export class CasePaymentEditorComponent implements OnInit {
  public authUser: User;
  public case: Case;
  public isLoading                            = 0;
  public submittingPaymentPlan: boolean;
  public paymentPlanForm: UntypedFormGroup;
  public paymentPlanResponse: LaravelResourceResponse;
  public submittingPaymentConfiguration: boolean;
  public paymentConfigurationForm: UntypedFormGroup;
  public paymentConfigResponse: LaravelResourceResponse;
  public financialOverview: FinancialOverview;
  public phaseOnePaid: boolean; // TODO:
  public phaseTwoPaid: boolean; // TODO:
  public taxRate                              = 1.21;
  public contractPdfLocation: string;
  public paymentMethods: Array<PaymentMethod> = [];
  public daysInMonth: Array<AppSelectOption>  = [];
  public summaryResponse: LaravelResourceResponse;

  public sendLinksError             = '';
  public creditors: Array<Creditor> = [];
  public products: Array<Product>   = [];

  // Payment Plan
  public selectedProduct: Product;
  public casePaymentPlanOutdated: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private changeRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseCreditorService: CaseCreditorService,
    private globalEventsService: MainGlobalEventService,
    private paymentMethodService: PaymentMethodService,
    private productService: ProductService,
    private casePaymentPlanService: CasePaymentPlanService,
    private financialOverviewService: FinancialOverviewService,
    private caseDocumentService: CaseDocumentService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId,
        ['product.payment_plans.payment_plan_phases', 'debt_payment_plan', 'creditors', 'distribution', 'latest_contract_request', 'contracts']
      )
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.fetchProducts();
          this.fetchPaymentMethods();
          this.displayDistributionWarningModal();
          this.contractPdfLocation = this.case.contracts[0]?.pdf_location;
        });
    });

    this.financialOverviewService.financialOverview$.subscribe(overview => this.financialOverview = overview);
    this.daysInMonth = [];
    for (let i = 1; i <= 28; i++) {
      this.daysInMonth.push({label: i.toString(10), value: i});
    }
  }

  public isComponentDirty(): boolean {
    return this.paymentPlanForm.dirty || this.paymentConfigurationForm.dirty;
  }

  public isComponentValid(): boolean {
    return this.paymentPlanForm.valid || this.paymentConfigurationForm.valid;
  }

  public submitComponent() {
    if (this.paymentPlanForm.dirty) {
      this.submitPaymentPlanForm(this.paymentPlanForm);
    }
    if (this.paymentConfigurationForm.dirty) {
      this.submitPaymentConfigurationForm(this.paymentConfigurationForm);
    }

    return true;
  }

  private subscribeToPaymentPlanChanges() {
    this.calculatePhaseDurations();

    this.paymentPlanForm.get('phase_one_value').valueChanges
      .pipe(debounce(() => interval(250)))
      .subscribe(value => this.calculatePhaseDurations());

    this.paymentPlanForm.get('phase_one_monthly_fee').valueChanges
      .pipe(debounce(() => interval(250)))
      .subscribe(value => this.calculatePhaseDurations());

    this.paymentPlanForm.get('phase_two_value').valueChanges
      .pipe(debounce(() => interval(250)))
      .subscribe(value => this.calculatePhaseDurations());

    this.paymentPlanForm.get('phase_two_monthly_fee').valueChanges
      .pipe(debounce(() => interval(250)))
      .subscribe(value => this.calculatePhaseDurations());
  }

  public calculatePhaseDurations() {
    const phaseOneValue      = this.paymentPlanForm.get('phase_one_value').value;
    const phaseOneMonthlyFee = this.paymentPlanForm.get('phase_one_monthly_fee').value;
    const phaseTwoValue      = this.paymentPlanForm.get('phase_two_value').value;
    const phaseTwoMonthlyFee = this.paymentPlanForm.get('phase_two_monthly_fee').value;

    let phaseOneDuration = 0;
    let phaseTwoDuration = 0;

    if ( !! phaseOneValue && !! phaseOneMonthlyFee && phaseOneMonthlyFee > 0) {
      const errors = this.paymentPlanForm.get('phase_one_monthly_fee').errors;

      if (phaseOneValue % phaseOneMonthlyFee !== 0) {
        this.paymentPlanForm.get('phase_one_monthly_fee').setErrors({...errors, ...{uneven_modulo: true}});
      } else {
        if ( ! errors || ! Object.keys(errors).length) {
          this.paymentPlanForm.get('phase_one_monthly_fee').setErrors(null);
        } else {
          let newErrors = {};
          const keys    = Object.keys(errors);
          keys.forEach((key, index) => {
            if (key !== 'uneven_modulo') {
              newErrors[key] = errors[key];
            }
          });
          if ( ! Object.keys(newErrors).length) {
            newErrors = null;
          }

          this.paymentPlanForm.get('phase_one_monthly_fee').setErrors(newErrors);
        }
      }
      phaseOneDuration = Math.ceil(phaseOneValue / phaseOneMonthlyFee);
    }
    if (phaseTwoValue !== '' && !! phaseTwoMonthlyFee && phaseTwoMonthlyFee > 0) {
      phaseTwoDuration = Math.ceil(phaseTwoValue / phaseTwoMonthlyFee);
    }

    this.paymentPlanForm.get('phase_one_duration').patchValue(phaseOneDuration);
    this.paymentPlanForm.get('phase_two_duration').patchValue(phaseTwoDuration);
  }

  public hasError(form: UntypedFormGroup, formControlName: string) {
    return form.get(formControlName)?.touched && this.paymentPlanForm.get(formControlName)?.invalid;
  }

  public sendSepaLink(send_to) {
    this.sendLinksError = '';
    const amount        = this.case.debt_payment_plan.initial_fee;

    if ( ! this.case.id || ! amount) {
      this.sendLinksError = this.translate.instant('CASES.single.save-case-error');

      return this.toastr.error(this.translate.instant('CASES.single.save-case-error'));
    }

    const data = {
      caseId: this.case.id,
      send_to
    };
    this.caseService.sendSepaLink(data).subscribe(
      () => {
        this.toastr.success(this.translate.instant('CASES.single.send-link-success'));
      },
      err => {
        console.log(err);
        this.toastr.error(this.translate.instant('CASES.single.send-link-error'));
      }
    );
  }

  public requestPaymentDialog(paymentMethodType: PaymentMethodType, paymentProcessorType: PaymentProcessorType) {
    this.sendLinksError = '';
    const amount        = this.case.debt_payment_plan.initial_fee;

    if ( ! this.case.id || ! amount) {
      this.sendLinksError = this.translate.instant('CASES.single.save-case-error');

      return this.toastr.error(this.translate.instant('CASES.single.save-case-error'));
    }

    const dialogRef = this.dialog.open(RequestPaymentModalComponent, {
      width:        '50%',
      minHeight:    200,
      disableClose: true,
      autoFocus:    false,
      data:         {
        caseId:         this.case.id,
        paymentMethodType,
        paymentProcessorType,
        amount,
        disabledAmount: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.success) {
          this.toastr.success(this.translate.instant('CASES.single.request-payment-success'),
            this.translate.instant('SHARED.success'));
        } else {
          this.toastr.error(this.translate.instant('CASES.single.request-payment-error'),
            this.translate.instant('SHARED.error'));
        }
      }
    });
  }

  public sendContract($event, sendTo) {
    $event.preventDefault();
    const data = {
      caseId:  this.case.id,
      send_to: sendTo
    };
    this.caseService.sendContract(data).subscribe(next => {
        this.toastr.success(this.translate.instant('CASES.single.send-contract-success'));
      }, error => {
        this.toastr.error(this.translate.instant('CASES.single.send-contract-error'));
        this.summaryResponse = error.error;
      }
    );
  }

  public previewEmail($event, emailType: 'SendInvoiceToCreditorNotification' | 'NewContract', caseId: number,
                      creditorId: number) {
    $event.preventDefault();

    this.caseCreditorService.previewEmail(caseId, creditorId, emailType).subscribe(result => {
        const emailHeader = {
          from:    result.data.from,
          to:      result.data.to,
          cc:      result.data.cc,
          subject: result.data.subject
        };
        const dialogRef   = this.dialog.open(EmailPreviewComponent, {
          width:  '310mm',
          height: '100vh',
          data:   {
            title:       'Creditor Invoice Preview',
            previewType: 'email',
            emailHeader,
            emailBody:   result.data.body,
            attachments: result.data.attachments
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          if (res?.action === 'send-email') {
            this.caseCreditorService.sendEmail(caseId, creditorId, emailType).subscribe(
              r => this.toastr.success(
                this.translate.instant('CASES.single.reclama.toast-messages.invoice-sent-success')
              ),
              errorResponse => {
                this.parseValidationErrors(errorResponse);
                this.translate.instant('CASES.single.reclama.toast-messages.invoice-sent-error');
              }
            );
          }
          if (res?.action === 'save-and-download-document' && res?.params?.type) {
            this.caseCreditorService.saveAndDownloadDocument(caseId, creditorId, res.params.type).subscribe(
              r => {
                if (r.type === 4) {
                  const fileName = res.params?.type + '_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.pdf';
                  saveAs(r.body, fileName);
                  this.toastr.success('Document downloaded');
                }
              },
              errorResponse => this.parseValidationErrors(errorResponse)
            );
          }
        });
      }, errorResponse => this.parseValidationErrors(errorResponse)
    );
  }

  public openCardForm(type: 'charge' | 'verify'): void {
    const amount = type === 'charge' && this.case.debt_payment_plan.initial_fee > 0 ? this.case.debt_payment_plan.initial_fee : 0.01;
    if (this.isComponentDirty()) {
      this.submitComponent();
    }
    const dialogRef = this.dialog.open(AdminPaymentHandlerComponent, {
      width:        '60%',
      minHeight:    200,
      disableClose: false,
      panelClass:   ['custom-mat-dialog', 'scrollable-mat-dialog'],
      autoFocus:    false,
      data:         {
        caseId:          this.case.id,
        clientRole:      'client',
        type:            'moto',
        amount,
        editableAmount:  true,
        paymentPlanType: 'debt_plan',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private fetchPaymentMethods(): void {
    this.isLoading++;
    this.paymentMethodService.index().pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.paymentMethods = result.data,
      error => console.log(error)
    );
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.products        = result.data;
        this.selectedProduct = this.products.find(product => product.id === this.case.product_id);
        if ( ! this.case.debt_payment_plan) {
          this.case.debt_payment_plan = this.getDefaultPaymentPlan(this.case);
        }
        this.buildPaymentPlanForm(this.case.debt_payment_plan);
        this.buildPaymentConfigurationForm(this.case.debt_payment_plan);
      }
    );
  }

  private getDefaultPaymentPlan(clientCase: Case): CasePaymentPlan {
    const selectedPaymentPlan = clientCase.product.payment_plans.find(
      plan => plan.is_joint === clientCase.joint_application
    );
    const phaseOne            = selectedPaymentPlan.payment_plan_phases.find(phase => phase.phase_order === 1);
    const phaseTwo            = selectedPaymentPlan.payment_plan_phases.find(phase => phase.phase_order === 2);

    const paymentPlan = new CasePaymentPlan();

    paymentPlan.phase_one_value       = phaseOne.amount;
    paymentPlan.phase_one_monthly_fee = phaseOne.installment_amount;
    paymentPlan.phase_one_duration    = Math.ceil(phaseOne.amount / phaseOne.installment_amount);

    paymentPlan.phase_two_value       = phaseTwo.amount;
    paymentPlan.phase_two_monthly_fee = phaseTwo.installment_amount;
    paymentPlan.phase_two_duration    = Math.ceil(phaseTwo.amount / phaseTwo.installment_amount);

    return paymentPlan;
  }

  public getPaymentMethodSlug(paymentMethodId: number): string {
    const paymentMethod = this.paymentMethods?.find(pMethod => {
      return Number(pMethod.id) === Number(paymentMethodId);
    });

    return paymentMethod?.slug;
  }

  public contractButtonDisabled(): boolean {
    return ! this.paymentPlanForm.get('phase_one_monthly_fee').value ||
      ! this.paymentPlanForm.get('phase_two_monthly_fee').value ||
      ! this.paymentConfigurationForm.get('payment_day').value || ! this.validIdCards(this.case) ||
      ! this.case.product_assigned_at;
  }

  private parseValidationErrors(errorResponse: any) {
    const error = errorResponse.error;
    if (+error.code === 422 && error.errors) {
      for (const [key, value] of Object.entries(error.errors)) {
        const valueArray = value as any;
        valueArray.forEach(e => this.toastr.error(e));
      }
    }
  }

  public validIdCards(clientCase: Case): boolean {
    return clientCase.joint_application ?
      !! (clientCase.client?.id_card && clientCase.partner?.id_card) :
      !! (clientCase.client?.id_card);
  }

  private buildPaymentPlanForm(casePaymentPlan: CasePaymentPlan): void {
    const oldPhaseOneValue = casePaymentPlan.phase_one_value;
    const oldPhaseTwoValue = casePaymentPlan.phase_two_value;
    if (this.case.product.group_slug === 'cajaplus') {
      const disposableIncome = +((this.financialOverview?.income -
        this.financialOverview?.expenses).toFixed(2));
      const legalFees        = Math.max(disposableIncome * 0.2 * 1.21, 50);

      if (casePaymentPlan.phase_one_value === 0) {
        casePaymentPlan.phase_one_monthly_fee = disposableIncome;
        casePaymentPlan.phase_one_duration    = 2;
        casePaymentPlan.phase_one_value       = disposableIncome * casePaymentPlan.phase_one_duration;
      }

      casePaymentPlan.phase_two_monthly_fee = legalFees;
      casePaymentPlan.phase_two_duration    = Math.ceil(this.financialOverview.unsecured_debt / disposableIncome);
      casePaymentPlan.phase_two_value       = casePaymentPlan.phase_two_monthly_fee * casePaymentPlan.phase_two_duration;

      this.casePaymentPlanOutdated = casePaymentPlan.phase_one_value !== oldPhaseOneValue ||
        casePaymentPlan.phase_two_value !== oldPhaseTwoValue;
    }

    this.paymentPlanForm = this.fb.group({
      phase_one_value:          [casePaymentPlan.phase_one_value, [Validators.min(50)]],
      phase_one_monthly_fee:    [casePaymentPlan.phase_one_monthly_fee],
      phase_one_duration:       [{value: casePaymentPlan.phase_one_duration, disabled: false}, [Validators.min(1)]],
      phase_two_value:          [
        {
          value:    casePaymentPlan.phase_two_value,
          disabled: this.case.product.group_slug === 'cajaplus' || this.case.product.slug === 'unified'
        },
        this.case.product.slug === 'cajaplus' || this.case.product.slug === 'unified' ? [] : [Validators.min(95)]
      ],
      phase_two_monthly_fee:    [
        {
          value:    casePaymentPlan.phase_two_monthly_fee,
          disabled: this.case.product.group_slug === 'cajaplus' || this.case.product.slug === 'unified'
        }
      ],
      phase_two_duration:       [
        {
          value:    casePaymentPlan.phase_two_duration,
          disabled: this.case.product.group_slug === 'cajaplus' || this.case.product.slug === 'unified'
        },
        this.case.product.slug === 'cajaplus' || this.case.product.slug === 'unified' ? [] : [Validators.min(1)]
      ],
      phase_two_defer:          [casePaymentPlan.phase_two_defer],
      generate_scheduled_plans: [!!casePaymentPlan.generated_scheduled_plans_at],
    });
    this.subscribeToPaymentPlanChanges();
  }

  private buildPaymentConfigurationForm(paymentConfig: CasePaymentPlan) {
    this.paymentConfigurationForm = this.fb.group({
      initial_fee:         [
        paymentConfig.initial_fee === 0 ? null : paymentConfig.initial_fee,
        [Validators.min(1), Validators.max(5000)]],
      payment_method_id:   [paymentConfig.payment_method_id],
      iban:                [paymentConfig.iban],
      payment_day:         [paymentConfig.payment_day, [Validators.min(1), Validators.max(28)]],
      contract_date:       [paymentConfig.contract_date],
      initial_payment_day: [paymentConfig.initial_payment_day],
      charge_amount:       [paymentConfig.charge_amount, [Validators.min(1), Validators.max(5000)]]
    });
  }

  public submitPaymentPlanForm(paymentPlanForm: UntypedFormGroup) {
    if (paymentPlanForm.invalid) {
      paymentPlanForm.markAllAsTouched();
      return;
    }
    this.submittingPaymentPlan = true;
    this.casePaymentPlanService.upsertPaymentPlan(this.case.id, paymentPlanForm.getRawValue())
      .pipe(finalize(() => this.submittingPaymentPlan = false))
      .subscribe(
        () => {
          this.paymentPlanForm.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.payment.payment_plan.result.success'));
        },
        error => {
          this.paymentPlanResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.payment.payment_plan.result.error'),
            error.error.message);
        }
      );
  }

  public submitPaymentConfigurationForm(paymentConfigurationForm: UntypedFormGroup) {
    if (paymentConfigurationForm.invalid) {
      paymentConfigurationForm.markAllAsTouched();
      return;
    }
    this.submittingPaymentConfiguration = true;
    this.casePaymentPlanService.upsertPaymentConfig(this.case.id, paymentConfigurationForm.getRawValue())
      .pipe(finalize(() => this.submittingPaymentConfiguration = false))
      .subscribe(
        () => {
          this.paymentConfigurationForm.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.payment.payment_config.result.success'));
        },
        error => {
          this.paymentConfigResponse = error.error;
          this.toastr.error(
            this.translate.instant('CASES.editor.payment.payment_config.result.error'), error.error.message
          );
        }
      );
  }

  public resignContract($event: MouseEvent, type: string, client_type: string): void {
    $event.preventDefault();
    const data = {
      type,
      client_type,
    };
    this.caseDocumentService.resignContract(this.case.id, data)
      .subscribe(
        next => {
          this.toastr.success(
            this.translate.instant('CASES.single.resend-contract-response'),
            this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.resend-contract-error'),
            this.translate.instant('SHARED.error'),
          );
        },
      );
  }

  public updateUserIdCard(idCard: string, relation: 'client' | 'partner') {
    this.case[relation].id_card = idCard;
  }

  public updatedClient(newUser: User, relation: 'client' | 'partner') {
    this.case[relation] = newUser;
  }

  public displayDistributionWarningModal(): void {
    if (this.case.distribution && this.case.distribution.distribution_batch_id) {
      Swal.fire({
        text:               this.translate.instant('DISTRIBUTION.modals.prior-authorization'),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2'
      });
    } else if (this.case.distribution) {
      Swal.fire({
        text:               this.translate.instant('DISTRIBUTION.modals.notify-of-changes'),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2'
      });
    }
  }

}
