import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../_base-shared/models/Status/Status';
import { User } from '../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../environments/environment';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { PackagerService } from '../../../packager/packager.service';
import { UploadService } from '../../app-file/upload.service';
import { NotificationMessageComponent } from '../../notification/notification-message/notification-message.component';
import { StatusService } from '../../status/status.service';
import { TaskEditorComponent } from '../../task/task-editor/task-editor.component';
import { CaseService } from '../case.service';
import { QuickNoteComponent } from './quick-note/quick-note.component';

@Component({
  selector:      'app-case-detail',
  templateUrl:   './case-detail.component.html',
  styleUrls:     ['./case-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaseDetailComponent implements OnInit, OnDestroy {
  public appEnv                 = environment.APP_ENV;
  public case: Case;
  public authUser: User;
  public isLoading              = 0;
  public quickNotesDialogOpened = false;
  public unapprovedCreditors    = false;
  public showNewDocumentsTab: boolean;
  public showSnapshot: boolean;
  public docListVersion: number;
  public showLegalTab: boolean;
  public packagerTransferStatus: Status;
  public quillModules           = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };

  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private packagerService: PackagerService,
              private statusService: StatusService,
              private uploadService: UploadService,
              private globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.route.paramMap.subscribe(params => this.fetchCase(+params.get('id')));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'q') { //  Open dialog on 'ctrl' + 'q'
      if ( ! this.quickNotesDialogOpened) { //  Check if dialog is not already opened
        const dialog = this.dialog.open(QuickNoteComponent, {
          width: '50%',
          data:  {case: this.case},
        });
        dialog.afterOpened().subscribe(result => {
          this.quickNotesDialogOpened = true;
        });
        dialog.afterClosed().subscribe(result => {
          this.quickNotesDialogOpened = false;
        });
      }
    }
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['packager', 'client', 'payment_status', 'secured_creditors', 'unsecured_creditors', 'transfer_request', 'product', 'locked_by'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case                = result.data;
          this.showLegalTab        = this.case.product.group_slug !== 'dm' && this.case.product.group_slug !== 'unified';
          this.docListVersion      = this.case.doc_version;
          this.showNewDocumentsTab = this.appEnv === 'local' || this.appEnv === 'staging' ||
            (this.appEnv === 'production' && !! this.case.is_test);

          if (this.case.locked_by_id) {
            let lockedMessage = this.translate.instant('CASES.single.case_flags.locked_by_a_case');
            if (this.authUser.packager.master) {
              lockedMessage = lockedMessage + ' ' +
                this.translate.instant('CASES.single.case_flags.locked_by_a_case_ref', {activeCaseRef: this.case.locked_by?.ref_number});
            }
            Swal.fire({
              title:              this.translate.instant('SHARED.warning'),
              text:               lockedMessage,
              icon:               'warning',
              showCancelButton:   false,
              allowOutsideClick:  false,
              confirmButtonText:  'OK',
              confirmButtonColor: '#886AB5',
              cancelButtonText:   this.translate.instant('SHARED.cancel'),
            }).then(res => {
              if (res.isConfirmed) {
                this.router.navigate(['case-dashboard', 'cases']);
              }
            });
          }
          if (this.authUser.packager.master) {
            this.showSnapshot = false;
          } else {
            this.showSnapshot = !! (this.case.transfer_request && this.case.transfer_request?.accepted !== false);
          }
          this.checkForCaseAlerts(this.case);
          // this.checkForDNI();
          // this.checkSignatures();
          this.checkCreditors();
        }),
    );
    this.subscriptions.push(
      this.statusService.getStatusByName('P. Transfer a Unifye')
        .subscribe(result => {
          this.packagerTransferStatus = result.data;

        })
    );
  }

  private checkForCaseAlerts(clientCase: Case) {
    if (clientCase.client.vulnerability) {
      const vulnerabilityReason = this.translate.instant(
        'USERS.model.vulnerability.options.' + clientCase.client.vulnerability,
      );

      Swal.fire({
        text:               this.translate.instant('CASES.single.alerts.vulnerability',
          {reason: vulnerabilityReason}),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2',
      }).then(res => {
        if (res.isConfirmed) {
          this.checkForPaymentStatusAlerts(clientCase.payment_status);
        }
      });
    } else {
      this.checkForPaymentStatusAlerts(clientCase.payment_status);
    }
  }

  private checkForPaymentStatusAlerts(paymentStatus: PaymentStatus) {
    if (['Impago 1', 'Impago 2', 'Reclamar Pago'].includes(paymentStatus?.name)) {
      Swal.fire({
        text:               this.translate.instant('CASES.single.alerts.impago',
          {status: paymentStatus.name}),
        icon:               'warning',
        showCancelButton:   false,
        confirmButtonText:  'OK',
        confirmButtonColor: '#4267b2',
      }).then(res => {
        // if (res.isConfirmed) {
        // }
      });
    }
  }

  private checkCreditors() {
    this.case.unsecured_creditors.forEach(creditor => {
      if ( ! creditor.approved) {
        this.unapprovedCreditors = true;
      }

    });
    this.case.secured_creditors.forEach(creditor => {
      if ( ! creditor.approved) {
        this.unapprovedCreditors = true;
      }
    });
  }

  public openNotificationModal($event, caseId: number, channel: string) {
    $event.preventDefault();
    const channels = [];
    if (channel === 'all' || channel === 'email') {
      channels.push('email');
    }
    if (channel === 'all' || channel === 'sms') {
      channels.push('sms');
    }

    const data      = {
      templatableType: 'case',
      channels,
      filters:         {cases: [caseId]},
    };
    const dialogRef = this.dialog.open(NotificationMessageComponent, {
      width:       '50%',
      minHeight:   '230px',
      hasBackdrop: false,
      data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public addNewTask(taskCase: Case) {
    const dialogRef = this.dialog.open(TaskEditorComponent, {
      width:     '40%',
      autoFocus: false,
      data:      {
        case:       taskCase,
        editorType: 'create',
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      // if needed
    });
  }

  public onDuplicateCase(): void {
    Swal.fire({
      text:               this.translate.instant('CASES.single.alerts.duplicate_warining'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.duplicateCase(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(
          result => this.toastr.success(this.translate.instant('SHARED.success')),
          err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  public requestTransfer(): void {
    Swal.fire({
      text:               this.translate.instant('CASES.single.actions.transfer.request.confirm_warning'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  'Yes',
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.packagerService.requestTransfer(this.case.uuid).pipe(finalize(() => this.isLoading--)).subscribe(
          result => {
            this.showSnapshot          = true;
            this.case.transfer_request = result.data;
            this.toastr.success(this.translate.instant('CASES.single.actions.transfer.request.result.success'));
            this.router.navigate(['cases', this.case.id, 'snapshot'])
          },
          err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  public acceptTransfer(): void {
    Swal.fire({
      text:               this.translate.instant('CASES.single.actions.transfer.accept.confirm_warning'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  'Yes',
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.acceptTransfer(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(
          result => this.toastr.success(this.translate.instant('CASES.single.actions.transfer.accept.result.success')),
          err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  public rejectTransfer(): void {
    Swal.fire({
      text:               this.translate.instant('CASES.single.actions.transfer.reject.confirm_warning'),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  'Yes',
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.rejectTransfer(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(
          result => this.toastr.success(this.translate.instant('CASES.single.actions.transfer.reject.result.success')),
          err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  public sendCase(clientCase: Case, projectId: string): void {
    Swal.fire({
      title:             this.translate.instant('CASE_CREDITOR.refer_case.heading'),
      text:              this.translate.instant('CASE_CREDITOR.refer_case.warning-text') + ' ' +
                           projectId.toUpperCase(),
      icon:              'warning',
      showCancelButton:  true,
      confirmButtonText: 'OK',
      cancelButtonText:  this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.sendCase(clientCase.id, {destination_project_id: projectId})
          .pipe(finalize(() => this.isLoading--))
          .subscribe(result => {
              this.toastr.success('Sent case successfully');
            },
            error => {
              let errorMessage = 'Failed to send case';
              if (error.error.status === 422) {
                const parsedError = JSON.parse(error.error.message);
                errorMessage      = parsedError[Object.keys(parsedError)[0]][0];
              }
              errorMessage = errorMessage.replace('.', ' ');
              this.toastr.error(errorMessage);
            },
          );
      }
    });
  }

  public setUpdatableByPackager(state: boolean) {
    this.isLoading++;
    this.caseService.togglePackagerUpdatable(this.case.id, {updatable_by_packager: state ? 1 : 0})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.toastr.success(this.translate.instant('CASES.editor.general.result.update.success'));
          this.case.updatable_by_packager = result.data.updatable_by_packager
        },
        error => this.toastr.error(this.translate.instant('CASES.editor.general.result.update.error')),
      );
  }
}
