import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { CaseDocumentService } from '../../../admin/case/case-document.service';

@Component({
  selector:    'app-img-edit-modal',
  templateUrl: './img-edit-modal.component.html',
  styleUrls:   ['./img-edit-modal.component.scss'],
})
export class ImgEditModalComponent implements OnInit {
  storageUrl                = environment.STORAGE_URL + '/';
  imageChangedEvent: any    = '';
  croppedImage: any         = '';
  canvasRotation            = 0;
  rotation                  = 0;
  scale                     = 1;
  showCropper               = false;
  containWithinAspectRatio  = false;
  transform: ImageTransform = {};

  constructor(private documentService: CaseDocumentService,
              public translate: TranslateService,
              public toastr: ToastrService,
              public dialogRef: MatDialogRef<ImgEditModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
    // console.log('loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // cropper ready
    // console.log('rdy', sourceImageDimensions);
  }

  loadImageFailed($event) {
    // show message
    // console.log('failed', $event);
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  saveImg() {
    if (this.data.type === 'legal') {
      const data = {
        img: this.croppedImage,
      };
      this.documentService.changeStatusLegal(this.data.case.id, this.data.document.id, data)
        .subscribe(
          res => {
            this.dialogRef.close(res.data);
          },
          err => {
            this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
          },
        );
    } else if (this.data.type === 'court') {
      const data = {
        img: this.croppedImage,
      };
      this.documentService.changeStatusCourt(this.data.case.id, this.data.document.id, data)
        .subscribe(
          res => {
            this.dialogRef.close(res.data);
          },
          err => {
            this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
          },
        );
    } else {
      const data = {
        caseId: this.data.case.id,
        fileId: this.data.document.id,
        img:    this.croppedImage,
      };
      this.documentService.changeStatus(data)
        .subscribe(
          res => {
            this.dialogRef.close(res.data);
          },
          err => {
            this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
          },
        );
    }
  }
}
