import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Team } from '../../../../../../_base-shared/models/User/Team';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { TeamService } from '../team.service';

@Component({
  selector:    'app-team-list',
  templateUrl: './team-list.component.html',
  styles:      [
    `
        mat-table {
            display: table;
            width: 100%;
        }

        mat-table > tr > td {
            padding-top: 10px;
        }
    `,
  ],
})
export class TeamListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoading                       = 0;
  public teams: MatTableDataSource<Team> = new MatTableDataSource<Team>([]);
  public displayedColumns: Array<string>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;

  private requestFilters: any;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private teamService: TeamService) {
  }

  ngOnInit(): void {
    this.displayedColumns       = ['id', 'name', 'leaders', 'members_count', 'actions'];
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.requestFilters         = {select_all: 0};
    this.fetchTeams();
  }

  ngAfterViewInit(): void {
    this.teams.sort = this.sort;
  }

  public paginatorChange($event: PageEvent) {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.requestFilters.page     = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchTeams();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchTeams();
  }

  private fetchTeams() {
    const requestData = MainBaseApiService.convertFiltersForRequest(this.requestFilters, 'get');

    this.isLoading++;
    this.teamService.index(requestData, ['leaders'], ['members']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.teams                  = new MatTableDataSource<Team>(result.data);
          this.teams.sort             = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        error => console.error(error),
    );
  }

  public deleteTeam(teamId: number) {
    Swal.fire({
      text:               this.translate.instant('SHARED.action.delete_confirmation',
          {model: this.translate.instant('TEAM.model_name.singular')}),
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.teamService.delete(teamId).pipe(finalize(() => this.isLoading--)).subscribe(
            () => {
              const localTeams     = this.teams.data;
              const localTeamIndex = localTeams.findIndex(sTeam => sTeam.id === teamId);
              if (localTeamIndex >= 0) {
                localTeams.splice(localTeamIndex, 1);
              } else {
                console.error('Deleted team not in list');
              }

              this.teams = new MatTableDataSource<Team>(localTeams);
              this.toastr.success(
                  this.translate.instant('SHARED.submit_result.delete.success',
                      {model: this.translate.instant('TEAM.model_name.singular')},
                  ),
              );
            },
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
      }
    });
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order   = this.defaultSort.direction;
  }
}
