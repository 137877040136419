import { DripCampaign } from '../Notification/DripCampaign';
import { StatusCategory } from './StatusCategory';

export class Status {
  id?: number;
  status_id?: number;

  name: string;
  flag_case_active?: boolean | 0 | 1 | null;
  flag_case_complete?: boolean | 0 | 1;
  allow_affiliate_overwrite_anytime?: boolean | 0 | 1;
  gclid_conversion_name?: string;

  created_at?: string | Date;
  updated_at?: string | Date;

  parent?: Status;
  category?: StatusCategory;
  statuses?: Array<Status>;
  drip_campaigns?: Array<DripCampaign>;
}
