<div class="d-flex justify-content-between align-items-center">
  <div>
    <button mat-raised-button color="primary" class="mb-1 me-md-4" *ngIf="!cajaplusProduct"
            [routerLink]="['/cases', case?.id, 'legal-router', 'document-generator','demand']">
      {{ "CASES.single.draft.actions.generate_demand" | translate }}
    </button>
    <button mat-raised-button color="primary" class="mb-1 me-md-4"
            *ngIf="case?.product?.group_slug === 'lso' || !cajaplusProduct"
            [routerLink]="['/cases', case?.id, 'legal-router', 'document-generator','lso']">
      {{ "CASES.single.draft.actions.generate_lso" | translate }}
    </button>
    <button mat-raised-button color="primary" class="mb-1 me-md-4" [routerLink]="['/cases', case?.id, 'aep','create']"
            *ngIf="!cajaplusProduct">
      {{ "CASES.details.generate-aep" | translate }}
    </button>

    <!-- Appointed Entities -->
    <button mat-raised-button color="primary" class="ms-2" [matMenuTriggerFor]="entitiesList" *ngIf="!cajaplusProduct">
      {{ 'CASES.single.draft.actions.entity_appointer_button' | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #entitiesList="matMenu">
      <button mat-menu-item class="mb-1 me-md-4" (click)="selectAdministrator($event)">
        {{ 'CASE_ENTITY.administrator.model_name.singular' | translate}}
      </button>
      <button mat-menu-item class="mb-1 me-md-4" (click)="selectNotary($event)">
        {{ 'CASE_ENTITY.notary.model_name.singular' | translate}}
      </button>
      <button mat-menu-item class="mb-1 me-md-4" (click)="selectSolicitor()">
        {{ 'CASE_ENTITY.solicitor.model_name.singular' | translate}}
      </button>
    </mat-menu>

    <button [disabled]="!case?.aep" mat-raised-button color="primary" class="mb-1 me-md-4 ms-2"
            (click)="downloadAep()" *ngIf="!cajaplusProduct">
      {{"CASES.details.download-aep" | translate}}
    </button>
    <!-- Asset List -->
    <ng-container *ngIf="case && !cajaplusProduct">
      <button mat-raised-button color="primary" class="ms-2" [matMenuTriggerFor]="assetList"
              aria-label="Send notification menu" [disabled]="case?.product?.group_slug === 'dgs'">
        {{ "CASES.single.draft.asset-list" | translate }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #assetList="matMenu">
        <a mat-menu-item [routerLink]="['/client/sign-creditor-asset-list']" target="_blank"
                [queryParams]="{uuid: case.uuid, type: 'asset_list_documents'}">
          <span>{{ "CASES.single.draft.actions.preview_document" | translate }}</span>
        </a>
        <a href="{{creditorListDocumentUrl}}" target="_blank" mat-menu-item [disabled]="!creditorListSigned"
           style="font-weight: normal">
          <span>{{ "CASES.single.draft.actions.download_signed_document" | translate }}</span>
        </a>
      </mat-menu>
    </ng-container>
    <!-- Creditor List -->
    <ng-container *ngIf="case && !cajaplusProduct">
      <button mat-raised-button color="primary" class="ms-2 me-2" [matMenuTriggerFor]="creditorList"
              aria-label="Send notification menu" [disabled]="case?.product?.group_slug === 'dgs'">
        {{ "CASES.single.draft.creditor-list" | translate }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #creditorList="matMenu">
        <button mat-menu-item
                [routerLink]="['/client/sign-creditor-asset-list']" target="_blank"
                [queryParams]="{uuid: case.uuid, type: 'creditor_list_documents'}">
          <span>{{ "CASES.single.draft.actions.preview_document" | translate }}</span>
        </button>
        <a href="{{assetListDocumentUrl}}" target="_blank" mat-menu-item [disabled]="!assetListSigned"
           style="font-weight: normal">
          <span>{{ "CASES.single.draft.actions.download_signed_document" | translate }}</span>
        </a>
      </mat-menu>
    </ng-container>

    <button mat-raised-button color="primary" class="me-1" [disabled]="case?.product?.group_slug === 'dgs'"
            *ngIf="!cajaplusProduct">
      <span
        (click)="requestCreditorAssetListSignature()">{{ "CASES.single.draft.actions.request_signature" | translate }}</span>
    </button>

    <button mat-raised-button color="primary" class="mb-1 me-md-4" (click)="openCourtModal($event)"
            *ngIf="!cajaplusProduct">
      {{ 'CASES.single.draft.appoint_court.heading' | translate }}
    </button>

    <button [matMenuTriggerFor]="proposalMenu" mat-raised-button color="primary" class="ms-2 mt-2">
      {{ 'CASES.single.draft.proposal.label' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #proposalMenu="matMenu">
      <button mat-menu-item color="primary" class="mb-1 me-md-4" (click)="generateProposal()"
              [disabled]="case?.product?.group_slug === 'lso' && !case.proposal && !selectedAdministrator">
        {{ "CASES.details.generate-proposal" | translate }}
      </button>
      <button mat-menu-item (click)="previewProposal('client')">
        {{ 'CASES.single.draft.proposal.preview_client' | translate }}
      </button>
      <button mat-menu-item (click)="previewProposal('partner')" *ngIf="case?.joint_application">
        {{ 'CASES.single.draft.proposal.preview_partner' | translate }}
      </button>
      <button mat-menu-item (click)="requestProposalSignature()">
        {{ 'CASES.single.draft.proposal.request_signature.label' | translate }}
      </button>
      <button mat-menu-item (click)="sendProposalToCreditors(case.id)">
        {{ 'CASES.single.draft.proposal.send_to_creditors' | translate }}
      </button>
    </mat-menu>
    <button mat-raised-button color="primary" class="mb-1 me-md-4 ms-1" [disabled]="!lsoProduct"
            [routerLink]="['/cases', case?.id, 'legal-router', 'document-generator','epi']">
      {{'CASES.single.draft.epi-request.label-btn' | translate}}
    </button>
    <button [matMenuTriggerFor]="answersForProcurador" mat-raised-button color="primary" class="ms-2 mt-2" *ngIf="this.case.id === 713839">
      {{'CASES.single.draft.answers-for-attorney.label' | translate}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #answersForProcurador="matMenu">
      <a mat-menu-item href="{{storageUrl + answersForAttorneyDocument?.files[0]?.path}}" [disabled]="!answersForAttorneyDocument">
        {{'CASES.single.draft.answers-for-attorney.download-pdf' | translate}}
      </a>
    </mat-menu>
  </div>

  <div class="dropleft" *ngIf="!cajaplusProduct">
    <app-button [stylesObj]="{minWidth: '160.8px'}" (buttonClickedEvent)="requestAepSign($event)"
                [buttonClasses]="'mb-1 me-md-4'" [isLoading]="isSendingNotaryEmail"
                [buttonText]="'SHARED.request-aep-sign' | translate"
    ></app-button>
    <app-button [stylesObj]="{minWidth: '160.8px'}" (buttonClickedEvent)="sendNotaryEmail($event)"
                [buttonClasses]="'mb-1 me-md-4'" [isLoading]="isSendingNotaryEmail"
                [buttonText]="'CLIENT.notary-download.send-email' | translate"
    ></app-button>
    <button [disabled]="!canRequestProposalSign" style="min-width: 120px" mat-raised-button color="primary" class="btn" type="button"
            [matMenuTriggerFor]="eSignMenu">
      <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_left</mat-icon>
      {{"SHARED.request-e-sign" | translate}}
    </button>
    <mat-menu #eSignMenu="matMenu" xPosition="before" class="eSignMenu">
      <a mat-menu-item (click)="requestProposalSign('email')">Email</a>
      <!--    <a class="dropdown-item" (click)="requestESign('sms')" href="#">SMS</a>-->
      <!--    <a class="dropdown-item" (click)="requestESign('all')" href="#">Email & SMS</a>-->
    </mat-menu>
  </div>
</div>

<div class="pt-3 mb-5" *ngIf="appointedCourt">
  <app-appointed-court-editor [appointedCourt]="appointedCourt" [caseEntities]="caseEntities"
                              [caseStatusId]="caseStatusId"
                              (removedAppointedCourt)="removeAppointedCourt($event)">
  </app-appointed-court-editor>
</div>
<div class="card mb-3 mt-3" *ngIf="selectedAdministrator?.name || selectedSolicitor?.name">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <div class="card-title">
        <h4>{{'CASE_CREDITOR.model.assigned_solicitor_administrator' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row d-flex flex-column ms-1">
      <div>
        <p class="appointed-link"><b>{{'CASE_CREDITOR.model.administrator' | translate}}:</b>
          <a [routerLink]="['/administrators', selectedAdministrator?.id, 'edit']"> {{selectedAdministrator?.name}}</a>
        </p>
      </div>
      <div>
        <p class="appointed-link"><b>{{'CASE_CREDITOR.model.solicitor' | translate}}:</b>
          <a [routerLink]="['/solicitors', selectedSolicitor?.id, 'edit']"> {{selectedSolicitor?.name}}</a></p>
      </div>
    </div>
  </div>
</div>
<div class="pt-3 mb-5">
  <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px" *ngIf="uniqueUnsecuredCreditors">
    <table style="width: 100%" mat-table matSort [dataSource]="uniqueUnsecuredCreditors">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <form *ngIf="clientCaseCreditor" [formGroup]="clientCaseCreditor" class="p-2">
        <div formGroupName="client_data">
          <ng-container matColumnDef="date_of_the_origin">
            <th mat-header-cell *matHeaderCellDef>{{'CASES.single.origin_date' | translate}}</th>
            <td mat-cell *matCellDef="let element; let i =  index">
              <mat-form-field color="primary" appearance="fill" *ngIf="isEditing && currentRow === i">
                <mat-label>{{'CASES.single.origin_date' | translate}}</mat-label>
                <input matInput [matDatepicker]="date_of_the_origin"
                       formControlName="date_of_the_origin">
                <button *ngIf="clientCaseCreditor?.get('client_data')?.get('date_of_the_origin') && clientCaseCreditor?.get('client_data')?.get('date_of_the_origin').value"
                        matSuffix mat-icon-button [disabled]="clientCaseCreditor?.get('client_data')?.get('date_of_the_origin').disabled"
                        (click)="clearFormControl($event, 'date_of_the_origin')" aria-label="Clear">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="date_of_the_origin"></mat-datepicker-toggle>
                <mat-datepicker #date_of_the_origin color="primary"></mat-datepicker>
              </mat-form-field>
              <!-- <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="last_paid"
                          [showLabel]="false" [extraLabel]="false" appearance="fill" [fullWidth]="false"
                          flexClass="align-items-center">
               </app-input>-->
              <p *ngIf="!isEditing || currentRow != i">{{clientCaseCreditor?.get('client_data')?.get('date_of_the_origin').value
                | date: 'shortDate'}}</p>
            </td>
          </ng-container>


          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef>{{'CASES.single.expiration_date' | translate}}</th>
            <td mat-cell *matCellDef="let element; let i =  index">
              <mat-form-field color="primary" appearance="fill"  *ngIf="isEditing && currentRow === i">
                <mat-label>{{'CASES.single.expiration_date' | translate}}</mat-label>
                <input matInput [matDatepicker]="expiration_date"
                       formControlName="expiration_date">
                <button
                  *ngIf="clientCaseCreditor?.get('client_data')?.get('expiration_date') && clientCaseCreditor?.get('client_data')?.get('expiration_date').value"
                  matSuffix mat-icon-button [disabled]="clientCaseCreditor?.get('client_data')?.get('expiration_date').disabled"
                  (click)="clearFormControl($event, 'expiration_date')" aria-label="Clear">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="expiration_date"></mat-datepicker-toggle>
                <mat-datepicker #expiration_date color="primary"></mat-datepicker>
              </mat-form-field>
              <p *ngIf="!isEditing || currentRow != i">{{clientCaseCreditor?.get('client_data')?.get('expiration_date').value
                | date: 'shortDate'}}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>{{'CASES.single.reason' | translate}}</th>
            <td mat-cell *matCellDef="let element; let i = index">
              <app-input *ngIf="isEditing && currentRow === i"  type="select" appearance="fill" formControlName="reason"
                         [selectOptions]="reasonOptions" [selectValue]="'value'" [label]="'CASES.single.reason' | translate"
                         [selectLabel]="'label_es'" [searchable]="true">
              </app-input>
              <p *ngIf="!isEditing || currentRow != i">{{clientCaseCreditor?.get('client_data')?.get('reason').label_es}}</p>
            </td>
          </ng-container>
        </div>
        <ng-container matColumnDef="current_balance">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i= index">
            <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="current_balance"
                       [showLabel]="false" [extraLabel]="false" appearance="fill" [fullWidth]="false"
                       flexClass="align-items-center">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{clientCaseCreditor?.get('current_balance').value | number: '1.2-2'}}€</p>
          </td>
        </ng-container>

      </form>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.drafting_status) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-end">{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"  class="text-end">
          <button mat-stroked-button (click)="toggleEditMode(i, element)">
            {{ !isEditing || currentRow != i ? ("SHARED.edit" | translate) : ("SHARED.save" | translate) }}</button>
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item class="dropdown-item" target="_blank"
               href="{{case?.product?.group_slug === 'dgs' || case?.product?.group_slug === 'dm' ? storageUrl + element.pivot.proposal_location :
               storageUrl + case.propuesta_modelo?.location}}"
            >
              {{ "CASES.details.get-proposal" | translate }}
            </a>
            <a *ngIf="element.pivot.proposal_location_accord" mat-menu-item class="dropdown-item"
               target="_blank"
               href="{{storageUrl + element.pivot.proposal_location_accord}}"
            >
              {{ "CASES.details.get-accord" | translate }}
            </a>
            <a *ngIf="element.pivot.proposal_location_accord_partner" mat-menu-item class="dropdown-item"
               target="_blank"
               href="{{storageUrl + element.pivot.proposal_location_accord_partner}}"
            >
              {{ "CASES.details.get-accord-partner" | translate }}
            </a>
            <a *ngIf="element.pivot.proposal_location_accord_both" mat-menu-item class="dropdown-item"
               target="_blank"
               href="{{storageUrl + element.pivot.proposal_location_accord_both}}"
            >
              {{ "CASES.details.get-accord-both" | translate }}
            </a>
            <a mat-menu-item class="dropdown-item"
               (click)="changeCreditorStatus($event, [element.pivot.id], 'secured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </a>
            <a *ngIf="case?.product?.group_slug === 'lso'" mat-menu-item class="dropdown-item"
               [disabled]="!case.propuesta_modelo"
               (click)="sendProposalToAdministrator($event)">
              {{ "CASES.details.send-proposal-administrator" | translate }}
            </a>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="uniqueUnsecuredCreditors && !uniqueUnsecuredCreditors.data.length" style="padding: 50px">
      <h5 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h5>
    </div>
  </div>
</div>


