import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';
import { BaseSharedModule } from '../../../../_base-shared/base-shared.module';
import { VerifySignatureComponent } from '../admin/document/verify-signature/verify-signature.component';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { ChangeAmountModalComponent } from './components/change-amount-modal/change-amount-modal.component';
import { ChangeCreditorStatusComponent } from './components/change-creditor-status/change-creditor-status.component';
import { ChangeDateModalComponent } from './components/change-date-modal/change-date-modal.component';
import { ImageEditorModalV2Component } from './components/image-editor-modal-v2/image-editor-modal-v2.component';
import { ImgEditModalComponent } from './components/img-edit-modal/img-edit-modal.component';
import { PaymentErrorModalComponent } from './components/payment-error-modal/payment-error-modal.component';
import { RequestPaymentModalComponent } from './components/request-payment-modal/request-payment-modal.component';
import { ServerErrorsComponent } from './components/server-errors/server-errors.component';
import { SplashModalComponent } from './components/splash-modal/splash-modal.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { DateDifferencePipe } from './pipes/date-difference.pipe';
import { GetLabelFromNamePipe } from './pipes/getLabelFromName.pipe';
import { JsonPrettyPrintPipe } from './pipes/json-pretty-print.pipe';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports:      [
    BaseSharedModule,
    QuillModule,
    ImageCropperModule,
    // TODO: remove unneeded imports after components moved
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    NgxMatSelectSearchModule,
    MatTabsModule,
    DragDropModule,
    MatDatepickerModule,
    MatDialogModule
  ],
  declarations: [
    ServerErrorsComponent,
    GetLabelFromNamePipe,
    StatusFilterComponent,
    ChangeDateModalComponent,
    ChangeAmountModalComponent,
    RequestPaymentModalComponent,
    VerifySignatureComponent,
    ChangeCreditorStatusComponent,
    PaymentErrorModalComponent,
    ImgEditModalComponent,
    SplashModalComponent,
    AppButtonComponent,
    DateDifferencePipe,
    ImageEditorModalV2Component,
    JsonPrettyPrintPipe
  ],
  exports:      [
    BaseSharedModule,
    QuillModule,
    JsonPrettyPrintPipe,
    // Material Imports
    MatNativeDateModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatProgressBarModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    // End material import
    ServerErrorsComponent,
    StatusFilterComponent, // @deprecate
    MatDialogModule,
    // TODO: move
    ChangeDateModalComponent,
    ChangeAmountModalComponent,
    GetLabelFromNamePipe,
    AppButtonComponent,
    DateDifferencePipe
    // End move
  ],
})

export class SharedModule {
}
