<div class="d-flex justify-content-between align-items-center">
  <h4>{{ "NOTIFICATION.list.title" | translate }}</h4>
</div>

<div *ngIf="formReady" class="pb-5">
  <div class="row" [formGroup]="form">
    <div *ngIf="authUser.role_id === 5" class="col-4 col-lg-3">
      <app-input type="select" [showLabel]="true" formControlName="notifiables" appearance="standard"
                 [searchable]="true" [label]="'Users'" [multiple]="true" [showClear]="true" [selectAll]="true"
                 [selectOptions]="users" [selectLabel]="'label'" [selectValue]="'value'" [fullWidth]="true">
      </app-input>
    </div>
    <div class="col-8 col-lg-9 d-flex align-items-center">
      <!--<mat-radio-group aria-label="Select an option" formControlName="time">-->
      <!--  <mat-radio-button value="current">-->
      <!--    {{ "NOTIFICATION.list.filters.time.options.current" | translate }}-->
      <!--  </mat-radio-button>-->
      <!--  <mat-radio-button class="ml-2" value="upcoming">-->
      <!--    {{ "NOTIFICATION.list.filters.time.options.upcoming" | translate }}-->
      <!--  </mat-radio-button>-->
      <!--</mat-radio-group>-->
      <mat-slide-toggle formControlName="unread" color="primary" class="ms-5">
        {{ "NOTIFICATION.list.filters.unread" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle *ngIf="authUser.role_id === 5" formControlName="only_related" color="primary" class="ms-5"
                        (ngModelChange)="onlyRelatedChange($event)">
        {{ "NOTIFICATION.list.filters.only_related" | translate }}
      </mat-slide-toggle>
    </div>
  </div>
</div>

<div class="mat-elevation-z8">
  <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" style="width: 100%">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openNotification(row)"></tr>
    <!-- UUID -->
    <!--<ng-container matColumnDef="id">-->
    <!--  <th mat-header-cell mat-sort-header *matHeaderCellDef>UUID</th>-->
    <!--  <td mat-cell *matCellDef="let element"> {{ element.id }} </td>-->
    <!--</ng-container>-->

    <!-- Authorable -->
    <ng-container matColumnDef="authorable">
      <th mat-header-cell *matHeaderCellDef>{{ "NOTIFICATION.list.table.authorable" | translate }}</th>
      <td mat-cell *matCellDef="let element"><span>{{ element.authorable.name }}</span></td>
    </ng-container>

    <!-- Notifiable -->
    <ng-container matColumnDef="notifiable">
      <th mat-header-cell *matHeaderCellDef>{{ "NOTIFICATION.list.table.notifiable" | translate }}</th>
      <td mat-cell *matCellDef="let element"><span>{{ element.notifiable.name }}</span></td>
    </ng-container>

    <!-- Subject -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef>{{ "NOTIFICATION.list.table.subject" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.data.subject }} </td>
    </ng-container>

    <!-- Created At -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "NOTIFICATION.list.table.created_at" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.created_at | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.created_at | date:'HH:mm' }}</p>
      </td>
    </ng-container>

    <!-- Read At -->
    <ng-container matColumnDef="read_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "NOTIFICATION.list.table.read_at" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.read_at">
          <p class="m-0">{{ element.read_at | date:'dd/MM/yy' }}</p>
          <p class="m-0">{{ element.read_at | date:'HH:mm' }}</p>
        </div>
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%">{{ "SHARED.actions" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex">
          <a *ngIf="!element.read_at" class="pe-3" (click)="markAsRead($event, [element.id])">
            {{ "NOTIFICATION.list.table.actions.mark-as-read" | translate }}
          </a>
        </div>
      </td>
    </ng-container>
  </table>

  <div *ngIf="isLoading > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
    <h5 class="text-center">{{ "NOTIFICATION.list.table.no-data" | translate }}</h5>
  </div>

  <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                 [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
  </mat-paginator>
</div>
