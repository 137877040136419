<div class="container" style="padding-bottom: 100px">
  <div class="row justify-content-center">
    <div class="col-8">
      <form *ngIf="formActive" [formGroup]="form" (ngSubmit)="submitForm()">
        <mat-card class="example-card">
          <mat-card-header class="justify-content-center">
            <mat-card-title>{{"CASES.aep.generate-aep-form" | translate}}</mat-card-title>
          </mat-card-header>
          <mat-card-content style="padding-top: 50px">

            <div><label>{{"CASES.aep.change-address" | translate}}:</label></div>
            <mat-radio-group aria-label="Select an option" formControlName="change_of_address">
              <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
              <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
            </mat-radio-group>

            <mat-form-field color="primary" appearance="fill" style="display: block">
              <mat-label>{{"CASES.aep.place-of-birth" | translate}}</mat-label>
              <input matInput formControlName="place_of_birth">
              <mat-error *ngIf="form.get('place_of_birth').touched && form.get('place_of_birth').errors?.required">
                {{"SHARED.field-required" | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field color="primary" appearance="fill" style="display: block">
              <mat-label>{{"CASES.aep.nationality" | translate}}</mat-label>
              <input matInput formControlName="nationality">
            </mat-form-field>

            <ng-container *ngIf="form.get('civil_status')">
              <div style="margin-bottom: 20px">
                <div><label>{{"CASES.aep.civil-status" | translate}}:</label></div>
                <mat-radio-group aria-label="Select an option" formControlName="civil_status" style="margin-bottom: 20px">
                  <mat-radio-button value="divorced_with_agreement">{{"CASES.aep.divorced-with-agreement" | translate}}</mat-radio-button>
                  <mat-radio-button value="divorced_without_agreement">{{"CASES.aep.divorced-without-agreement" | translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.spouses-are-owners" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="spouses_are_owners">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div [@inOutAnimation] *ngIf="form.get('spouses_are_owners').value === 'yes'">
              <div style="margin-bottom: 20px">
                <div><label>{{"CASES.aep.if-so" | translate}}:</label></div>
                <mat-radio-group aria-label="Select an option" formControlName="spouses_are_owners_if_so" style="margin-bottom: 20px">
                  <mat-radio-button value="consent-accompanied">{{"CASES.aep.consent-is-accompanied" | translate}}</mat-radio-button>
                  <mat-radio-button value="signature-request">{{"CASES.aep.signature-request" | translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.people-in-charge" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="people_in_charge">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="form.get('people_in_charge').value === 'yes'" style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.people-in-charge-text" | translate}}:</label></div>
              <quill-editor [modules]="modules" formControlName="people_in_charge_names" [styles]="{height: '150px'}"></quill-editor>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.not-married-partner" | translate}}:</label></div>
              <quill-editor [modules]="modules" formControlName="not_married_partner" [styles]="{height: '150px'}"></quill-editor>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.insolvency-type" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="insolvency_type">
                <mat-radio-button class="break-normal" value="current">{{"CASES.aep.insolvency-current" | translate}}</mat-radio-button>
                <mat-radio-button class="break-normal" value="imminent">{{"CASES.aep.insolvency-imminent" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.insolvency-derives" | translate}}:</label></div>
              <section class="example-section" formGroupName="insolvency_derives">
                <mat-checkbox style="display: block" formControlName="unemployment">
                  {{"CASES.aep.insolvency-unemployment" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="overIndebtedness">
                  {{"CASES.aep.insolvency-over-indebtedness" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="businessLosses">
                  {{"CASES.aep.insolvency-business-losses" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="decreaseInSales">
                  {{"CASES.aep.insolvency-decrease-in-sales" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="increaseInExpenses">
                  {{"CASES.aep.insolvency-increase-expenses" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="increaseInFinancialCost">
                  {{"CASES.aep.insolvency-increase-financial-cost" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="increaseInCustomerDelinquency">
                  {{"CASES.aep.insolvency-increase-customer-delinquency" | translate}}
                </mat-checkbox>
                <mat-checkbox style="display: block" formControlName="increaseOther">
                  {{"CASES.aep.insolvency-other" | translate}}
                </mat-checkbox>
              </section>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.convicted" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="convicted">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.out-of-cort-payment" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="out_of_court_payment">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.negotiating-refinancing-agreement" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="negotiating_refinancing_agreement">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.application-for-bankruptcy" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="application_for_bankruptcy">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.seizures" | translate}}:</label></div>
              <quill-editor [modules]="modules" formControlName="seizures" [styles]="{height: '150px'}"></quill-editor>
            </div>

              <div style="margin-bottom: 20px" *ngIf="form.get('car_assets')">
                <div><label>{{"CASES.aep.car-assets" | translate}}:</label></div>
                <section class="example-section" formGroupName="car_assets">
                  <mat-checkbox style="display: block" *ngFor="let car of formData.vehicles" [formControlName]="car.id">
                    {{car.make}} {{car.model}} {{car.age}} {{car.registration}}
                  </mat-checkbox>
                </section>
              </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.bank-account-holder" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="bank_account_holder">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.owner-of-movable-capital" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="owner_of_movable_capital">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <ng-container *ngIf="form.get('owner_of_movable_capital').value === 'yes'">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th scope="col">{{"CASES.aep.entity" | translate}}</th>
                  <th scope="col">{{"CASES.aep.office" | translate}}</th>
                  <th scope="col">{{"CASES.aep.securities-account" | translate}}</th>
                  <th scope="col">
                    {{"CASES.aep.value-date" | translate}}
                    <mat-form-field color="primary" appearance="fill">
<!--                      <mat-label>{{"CASES.aep.value-date" | translate}}</mat-label>-->
                      <input matInput [matDatepicker]="value_date" formControlName="value_date">
                      <mat-datepicker-toggle matSuffix [for]="value_date"></mat-datepicker-toggle>
                      <mat-datepicker #value_date color="primary"></mat-datepicker>
                    </mat-form-field>
                  </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let prop of getFormArray('other_assets').controls let i = index" formArrayName="other_assets">
                  <tr [formGroupName]="i">
                    <th scope="row" style="width: 25%">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <input matInput formControlName="company_name">
                        <mat-error *ngIf="prop.controls.company_name.touched &&
                        prop.controls.company_name.errors?.required">
                          {{"SHARED.field-required" | translate}}
                        </mat-error>
                      </mat-form-field>
                    </th>
                    <td style="width: 25%">{{prop.controls.office.value}}</td>
                    <td style="width: 25%">{{prop.controls.securities_account.value}}</td>
                    <td style="width: 25%">{{prop.controls.estimated_value.value | number: '1.2-2' }}€</td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </ng-container>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.owner-of-real-estate" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="owner_of_real_estate">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <ng-container *ngIf="form.get('owner_of_real_estate').value === 'yes'">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{{"CASES.aep.prop-type" | translate}}</th>
                    <th scope="col">{{"CASES.aep.situation" | translate}}</th>
                    <th scope="col">{{"CASES.aep.property-registry" | translate}}</th>
                    <th scope="col">{{"CASES.aep.cadastral-value" | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let prop of getFormArray('properties_table').controls let i = index" formArrayName="properties_table">
                  <tr [formGroupName]="i">
                    <th scope="row" style="width: 25%">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <input matInput formControlName="type">
                        <mat-error *ngIf="prop.controls.type.touched &&
                        prop.controls.type.errors?.required">
                          {{"SHARED.field-required" | translate}}
                        </mat-error>
                      </mat-form-field>
                    </th>
                    <td style="width: 25%">{{prop.controls.situation.value}}</td>
                    <td style="width: 25%">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <input matInput formControlName="registration">
                        <mat-error *ngIf="prop.controls.registration.touched &&
                        prop.controls.registration.errors?.required">
                          {{"SHARED.field-required" | translate}}
                        </mat-error>
                      </mat-form-field>
                    </td>
                    <td style="width: 25%">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <input matInput formControlName="value">
                        <mat-error *ngIf="prop.controls.value.touched &&
                        prop.controls.value.errors?.required">
                          {{"SHARED.field-required" | translate}}
                        </mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </ng-container>

            <ng-container *ngIf="form.get('debts')">
              <div><label>{{"CASES.aep.unsecured-title" | translate}}:</label></div>
              <div class="table-responsive pb-4">
                <table class="table table-bordered" style="border: 1px solid #e9e9e9; margin-bottom: 0px">
                  <thead>
                  <tr>
                    <th scope="col">{{"CASES.aep.creditor-identity" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-address" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-email" | translate}}</th>
                    <th scope="col">{{"CASES.aep.unsecured-creditor-amount" | translate}}</th>
                    <th scope="col">{{"CASES.aep.unsecured-expiration" | translate}}</th>
                    <th scope="col">{{"CASES.aep.unsecured-repayments" | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let debt of getFormArray('debts').controls let i = index"
                                formArrayName="debts">
                    <tr [formGroupName]="i">
                      <th scope="row" style="width: 20%">
                        <ng-container *ngIf="debt.controls.type.value === 'unsecured'">
                          {{debt.controls.company_name.value}}
                        </ng-container>
                        <ng-container *ngIf="debt.controls.type.value !== 'unsecured' && debt.controls.type.value !== 'town-hall'">
                          {{debt.controls.company_name.value}}
                        </ng-container>
                        <ng-container *ngIf="debt.controls.type.value === 'town-hall'">
                          <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                            <mat-label> Ayuntamiento de</mat-label>
                            <input matInput formControlName="company_name">
                          </mat-form-field>
                        </ng-container>
                      </th>
                      <td style="width: 20%; word-break: break-all">
                        <ng-container *ngIf="debt.controls.type.value === 'unsecured'">
                          {{debt.controls.address.value}}
                        </ng-container>
                        <ng-container *ngIf="debt.controls.type.value !== 'unsecured'">
                          <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                            <input matInput formControlName="address">
                          </mat-form-field>
                        </ng-container>
                      </td>
                      <td style="width: 20%; word-break: break-all">
                        <ng-container *ngIf="debt.controls.type.value === 'unsecured'">
                          {{debt.controls.email.value}}
                        </ng-container>
                        <ng-container *ngIf="debt.controls.type.value !== 'unsecured'">
                          <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                            <input matInput formControlName="email">
                          </mat-form-field>
                        </ng-container>
                      </td>
                      <td style="width: 15%; word-break: break-all">{{debt.controls.current_balance.value | number: '1.2-2'}}€</td>
                      <td style="width: 15%; word-break: break-all">{{debt.controls.expiration_date.value}}</td>
                      <td style="width: 10%; word-break: break-all">{{debt.controls.repayments.value}}</td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </ng-container>

            <ng-container *ngIf="form.get('identification_of_credits')">
              <div><label>{{"CASES.aep.identification-of-credits" | translate}}:</label></div>
              <div class="table-responsive">
                <table class="table table-bordered" style="border: 1px solid #e9e9e9; margin-bottom: 0px">
                  <thead>
                  <tr>
                    <th scope="col">{{"CASES.aep.creditor-identity" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-address" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-email" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-amount" | translate}}</th>
                    <th scope="col">{{"CASES.aep.creditor-type" | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let credit of getFormArray('identification_of_credits').controls let i = index"
                                formArrayName="identification_of_credits">
                    <tr [formGroupName]="i">
                      <th scope="row" style="width: 20%">{{credit.controls.company_name.value}}</th>
                      <td style="width: 20%; word-break: break-all">{{credit.controls.address.value}}</td>
                      <td style="width: 20%; word-break: break-all">{{credit.controls.email.value}}</td>
                      <td style="width: 15%; word-break: break-all">{{credit.controls.current_balance.value | number: '1.2-2'}}€</td>
                      <td style="width: 25%">
                        <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
<!--                          <mat-label>Favorite Food</mat-label>-->
                          <mat-select formControlName="type">
                            <mat-option value="mortgage">{{"CASES.aep.creditor-mortgage" | translate}}</mat-option>
                            <mat-option value="reservation_of_ownership">{{"CASES.aep.creditor-reservation" | translate}}</mat-option>
                            <mat-option value="guarantee">{{"CASES.aep.creditor-guarantee" | translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field color="primary" appearance="fill">
                          <mat-label>{{"CASES.aep.creditor-select-date" | translate}}</mat-label>
                          <input matInput [matDatepicker]="type_date" formControlName="type_date">
                          <mat-datepicker-toggle matSuffix [for]="type_date"></mat-datepicker-toggle>
                          <mat-datepicker #type_date color="primary"></mat-datepicker>
                        </mat-form-field>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </ng-container>
            <p><b>{{"CASES.aep.creditor-amount-note" | translate}}</b></p>

            <ng-container *ngIf="form.get('list_of_contracts')">
              <div><label>{{"CASES.aep.list-of-contracts" | translate}}:</label></div>
                <table class="table table-bordered" style="border: 1px solid #e9e9e9;">
                  <thead>
                  <tr>
                    <th scope="col">{{"CASES.aep.contract-date" | translate}}</th>
                    <th scope="col">{{"CASES.aep.contract-counterpart" | translate}}</th>
                    <th scope="col">{{"CASES.aep.contract-type" | translate}}</th>
                    <th scope="col">{{"CASES.aep.contract-debtor-obligations" | translate}}</th>
                    <th scope="col">{{"CASES.aep.contract-counterparty-obligations" | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let credit of getFormArray('list_of_contracts').controls let i = index"
                                formArrayName="list_of_contracts">
                    <tr [formGroupName]="i">
                      <td style="width: 20%">
                        <mat-form-field color="primary" appearance="fill">
                          <mat-label>{{"CASES.aep.creditor-select-date" | translate}}</mat-label>
                          <input matInput [matDatepicker]="date_of_contract" formControlName="date_of_contract">
                          <mat-datepicker-toggle matSuffix [for]="date_of_contract"></mat-datepicker-toggle>
                          <mat-datepicker #date_of_contract color="primary"></mat-datepicker>
                        </mat-form-field>
                      </td>
                      <td style="width: 20%; word-break: break-all">
                        <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                          <input matInput formControlName="landlord_name">
                          <mat-error *ngIf="credit.controls.landlord_name.touched &&
                        credit.controls.landlord_name.errors?.required">
                            {{"SHARED.field-required" | translate}}
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td style="width: 20%; word-break: break-all">{{credit.controls.contract_type.value}}</td>
                      <td style="width: 20%; word-break: break-all">{{credit.controls.debtor_obligations.value}}</td>
                      <td style="width: 20%; word-break: break-all">{{credit.controls.counterparty_obligations.value}}</td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
            </ng-container>

            <div style="margin-bottom: 20px">
              <div><label>{{"CASES.aep.own-personal-properties" | translate}}:</label></div>
              <mat-radio-group aria-label="Select an option" formControlName="owner_of_personal_properties">
                <mat-radio-button value="yes">{{"SHARED.yes" | translate}}</mat-radio-button>
                <mat-radio-button value="no">{{"SHARED.no" | translate}}</mat-radio-button>
              </mat-radio-group>
            </div>

            <div style="margin-bottom: 20px">
              <p>{{"CASES.aep.workers" | translate}}</p>
              <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                <mat-label>{{"CASES.aep.workers-number" | translate}}</mat-label>
                <input matInput formControlName="number_of_workers">
              </mat-form-field>
            </div>

            <ng-container *ngIf="form.get('number_of_workers').value > 0">
              <div><label>{{"CASES.aep.workers-details" | translate}}:</label></div>
              <ng-container *ngFor="let credit of getFormArray('worker_names').controls let i = index"
                            formArrayName="worker_names">
                <div style="margin-bottom: 20px" [formGroupName]="i">
                  <div class="row" style="border-bottom: 1px solid #d1c9c9;">
                    <div class="col-4">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <mat-label>{{"CASES.aep.workers-name" | translate}}</mat-label>
                        <input matInput formControlName="name">
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <mat-label>{{"CASES.aep.workers-address" | translate}}</mat-label>
                        <input matInput formControlName="address">
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="aep-table-input" color="primary" appearance="fill" style="display: block">
                        <mat-label>{{"CASES.aep.workers-email" | translate}}</mat-label>
                        <input matInput type="email" formControlName="email">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div style="margin-bottom: 20px">
              <p style="display: inline-block; margin-right: 10px">{{"CASES.aep.presenting-in" | translate}}</p>
              <mat-form-field class="aep-table-input" color="primary" appearance="fill">
                <mat-label>{{"CASES.aep.presenting-in-city" | translate}}</mat-label>
                <input matInput formControlName="presented_in">
              </mat-form-field>
              <p style="display: inline-block; margin-left: 10px; margin-right: 10px">{{"CASES.aep.presenting-a" | translate}}</p>
              <mat-form-field color="primary" appearance="fill">
                <mat-label>{{"CASES.aep.presenting-in-date" | translate}}</mat-label>
                <input matInput [matDatepicker]="presented_date" formControlName="presented_date">
                <mat-datepicker-toggle matSuffix [for]="presented_date"></mat-datepicker-toggle>
                <mat-datepicker #presented_date color="primary"></mat-datepicker>
              </mat-form-field>
            </div>

          </mat-card-content>
          <mat-card-actions>
            <div class="d-flex">
              <app-spinner-btn [loading]="isSubmitting" [name]="'SHARED.submit' | translate">
              </app-spinner-btn>
            </div>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>
</div>
