import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Product } from '../../../../../../../../_base-shared/models/Product';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { ProductService } from '../../../product.service';

@Component({
  selector:    'app-lso-generator',
  templateUrl: './lso-generator.component.html',
  styles:   [],
})
export class LsoGeneratorComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public isLoading                                    = 0;
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public serverResponse: LaravelResourceResponse;
  public products: Array<Product>;
  public productOptionOptions: Array<AppSelectOption>;
  private subscriptions: Array<Subscription>          = [];
  public maritialList: Array<AppSelectOption>;
  public procedureList: Array<AppSelectOption>        = [];
  public procedureRecordsList: Array<AppSelectOption> = [];
  public courtList: Array<AppSelectOption>            = [];
  public is_maritial_status: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { case: Case },
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private productService: ProductService,
  ) {
  }

  ngOnInit(): void {
    this.getProcedures();
    this.getRecordsProcedures();
    this.getMaritialStatus();
    this.fetchProducts();
    this.getCourtList();
    if (this.data && this.data.case) {
      this.case = this.data.case;
      if (this.case.product) {
        this.buildForm();
      } else {
        this.fetchCaseProduct(this.case);
      }
    } else {
      this.route.parent.paramMap.subscribe(params => {
        this.fetchCase(+params.get('id'));
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseDocumentService.generateDocument(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'lso_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') +
            '.' + (form.get('type').value === 'pdf' ? 'pdf' : 'doc');
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded lso document');
        }
      }, err => {
        this.toastr.error('Failed to generate lso document');
      });
  }

  private buildForm() {
    const formGroupData: { [key: string]: any } = {
      document_type_slug:        ['voluntary-competition-application', [Validators.required]],
      civil_registry:            [null, [Validators.required]],
      employment_status:         [null, [Validators.required]],
      address_change:            ['no', [Validators.required]],
      marital_status:            this.fb.group({
        marital_status_value:          [null, []],
        marital_status_married:        [null, []],
        marital_status_married_value:  [null, []],
        marital_status_divorced:       [null, []],
        marital_status_divorced_value: ['no', []],
      }),
      partner:                   this.fb.group({
        partner_applied: ['no', []],
        procedure:       [null, []],
        number:          [null, []],
        year:            [null, []],
        court:           [null, []],
        number_two:      [null, []],
        location:        [null, []],
        coowners:        ['no', []],
      }),
      debtor:                    this.fb.group({
        empowerment: [null, [Validators.required]],
      }),
      state_of_insolvency:       this.fb.group({
        insolvency_class:  ['current', [Validators.required]],
        situation_facts:   [null, [Validators.required, Validators.maxLength(500)]],
        ceased_activity:   ['no', [Validators.required]],
        number_of_workers: [null, [Validators.required]],
        fees_received:     this.fb.group({
          lawyer_amount:   [null, [Validators.required]],
          attorney_amount: [null, [Validators.required]],
        }),
      }),
      competition_solution:      this.fb.group({
        solution: [null, [Validators.required]],
      }),
      application_documentation: this.fb.group({
        special_power:                  [null, []],
        economic_and_legal_memory:      [null, []],
        inventory_of_assets_and_rights: [null, []],
        list_of_creditors:              [null, []],
        staff_of_workers:               [null, []],
        early_agreement_proposal:       [null, []],
        accessions:                     [null, []],
        settlement_plan:                [null, []],
        other_documents:                [null, []],
      }),
      observations:              [null, [Validators.required, Validators.maxLength(500)]],
      records:                   this.fb.group({
        record_one:   this.fb.group({
          procedure:  [null],
          number_one: [null],
          year:       [null],
          court:      [null],
          number_two: [null],
          location:   [null],
        }),
        record_two:   this.fb.group({
          procedure:  [null],
          number_one: [null],
          year:       [null],
          court:      [null],
          number_two: [null],
          location:   [null],
        }),
        record_three: this.fb.group({
          procedure:  [null],
          number_one: [null],
          year:       [null],
          court:      [null],
          number_two: [null],
          location:   [null],
        }),
        record_four:  this.fb.group({
          procedure:  [null],
          number_one: [null],
          year:       [null],
          court:      [null],
          number_two: [null],
          location:   [null],
        }),
        record_five:  this.fb.group({
          procedure:  [null],
          number_one: [null],
          year:       [null],
          court:      [null],
          number_two: [null],
          location:   [null],
        }),
      }),
      signature_date:            [null, [Validators.required]],
      signature_location:        [null, [Validators.required]],
      type:                      [null],
      lawyer:                    [null],
    };

    this.form = this.fb.group(formGroupData);
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.products = result.data,
    );
  }

  private fetchCaseProduct(clientCase: Case) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.buildForm();
        }),
    );
  }

  private fetchCase(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case = result.data;
          this.buildForm();
        }),
    );
  }

  public chooseDocsType(type: 'doc' | 'pdf', submitedForm: UntypedFormGroup) {
    this.form.get('type').patchValue(type);
    this.form.get('type').updateValueAndValidity();
    this.submitForm(submitedForm);
  }

  public changeSelection($event) {
    if ($event === 'married') {
      this.form.get('marital_status').get('marital_status_married').setValue(true);
      this.form.get('marital_status').get('marital_status_divorced').setValue(false);
      this.form.get('marital_status').get('marital_status_divorced_value').setValue(null);
    } else if ($event === 'divorced') {
      this.form.get('marital_status').get('marital_status_married').setValue(null);
      this.form.get('marital_status').get('marital_status_married_value').setValue(null);
    }
    this.is_maritial_status = $event;
  }

  private getProcedures() {
    this.procedureList = [
      {label: 'CNA - Concurso Abreviado', value: 'cna'},
      {label: 'CNO - concurso Prdinario', value: 'cno'},
    ];
  }

  private getRecordsProcedures() {
    this.procedureRecordsList = [
      {label: 'CNA - Concurso Abreviado', value: 'cna'},
      {label: 'CNO - concurso Prdinario', value: 'cno'},
      {label: 'CLC - Comunicaciones 5 bis', value: 'clc'},
    ];
  }

  private getCourtList() {
    this.courtList = [
      {label: 'MERCANTIL', value: 'trade'},
      {label: '1ªINSTANCIA', value: 'first_instance'},
    ];
  }

  private getMaritialStatus() {
    this.maritialList = [
      {label: 'Capitulaciones', value: 'capitulaciones'},
      {label: 'Gananciales', value: 'gananciales'},
      {label: 'Separación de bienes', value: 'separación de bienes'},
      {label: 'Participación', value: 'participación'},
      {label: 'Consorcio conyugal (Aragón)', value: 'consorcio conyugal (Aragón)'},
      {label: 'Participación en las ganancias (Cataluña)', value: 'participación en las ganancias (Cataluña)'},
      {label: 'Asociación a compras y mejoras (Cataluña)', value: 'asociación a compras y mejoras (Cataluña)'},
      {
        label: '"Aagermanament" o pacto de mitad por mitad (Cataluña)',
        value: '"agermanament" o pacto de mitad por mitad (Cataluña)',
      },
      {
        label: 'Pacto de conveniencia o "mitja guadanyeria" (Cataluña)',
        value: 'pacto de conveniencia o "mitja guadanyeria" (Cataluña)',
      },
      {label: 'Comunidad de bienes (Cataluña)', value: 'comunidad de bienes (Cataluña)'},
      {label: 'Conquistas (Navarra)', value: 'conquistas (Navarra)'},
      {label: 'Comunicación foral (Vizcaya)', value: 'comunicación foral (Vizcaya)'},
      {label: 'Otros', value: 'otros'},
    ];
  }

  public setDivorcedStatus($event) {
    this.is_maritial_status = 'divorced';
  }
}
