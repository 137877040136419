<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h3>Packager Profile</h3>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <nav *ngIf="packagerId" mat-tab-nav-bar dynamicHeight mat-stretch-tabs="false" [tabPanel]="tabPanel">
        <a mat-tab-link [routerLink]="['/packagers', packagerId, 'details', 'general']" routerLinkActive
           #rla="routerLinkActive" [active]="rla.isActive">
          General
        </a>
        <a mat-tab-link [routerLink]="['/packagers', packagerId, 'details', 'configuration']" routerLinkActive
           #rla1="routerLinkActive" [active]="rla1.isActive">
          Configuration
        </a>
      </nav>
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </mat-card-content>
  </mat-card>
</div>
