import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Creditor } from '../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../creditor/creditor.service';

@Component({
  selector:    'app-creditor-book-value',
  templateUrl: './creditor-book-value.component.html',
  styleUrls:   ['./creditor-book-value.component.scss'],
})
export class CreditorBookValueComponent implements OnInit {
  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public creditors: Creditor[]          = [];
  public selectedCreditor;
  public optionSelected;
  public isLoading                      = 0;
  public filterForm: UntypedFormGroup;
  public creditorCtrl: UntypedFormControl      = new UntypedFormControl();
  public dataSource: MatTableDataSource<any>;
  public dataSourceList: MatTableDataSource<any>;
  public displayedColumns: string[]     = ['bookValue', 'noOfClients'];
  public displayedColumnsList: string[] = ['Ref', 'Ownership', 'CaseEmail', 'ApplicationType', 'CurrentBalance'];
  public totalSum                       = 0;
  public book_value_active_all          = 0;
  public book_value_all                 = 0;
  public totalSumAll                    = 0;
  public reportAll;

  constructor(private creditorService: CreditorService,
              private toastr: ToastrService,
              private fb: UntypedFormBuilder,
              public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.getCreditors();
    this.buildForm();

    //  Get all value
    this.creditorService.showReportAll().subscribe(value => {
      this.reportAll             = value.data;
      this.book_value_active_all = value.data.total_book_value_active;
      this.book_value_all        = value.data.total_book_value;
      this.totalSumAll           = this.book_value_active_all + this.book_value_all;
    });
  }

  buildForm() {
    this.filterForm = this.fb.group({
      'status[]': [{value: [], disabled: !this.selectedCreditor}, [Validators.required]],
    });

    this.filterForm.valueChanges.subscribe(next => {
      this.getReport(this.selectedCreditor.id, next);
      console.log(this.selectedCreditor);
    });
  }

  onClearSelect($event) {
    this.creditorCtrl.patchValue('');
    this.dataSource     = new MatTableDataSource([]);
    this.dataSourceList = new MatTableDataSource([]);
  }

  onSelectCreditor($event) {
    this.selectedCreditor = this.creditors.find(cred => cred.id === $event.value);
    this.filterForm.enable();
    this.getReport(this.selectedCreditor.id, this.filterForm.value);
  }

  onRadioChange($event) {
    this.optionSelected = $event.value;
    if ($event.value === 'secured') {
      this.book_value_active_all = this.reportAll.total_book_value_active_secured;
      this.book_value_all        = this.reportAll.total_book_value_inactive_secured;
      this.totalSumAll           = this.book_value_active_all + this.book_value_all;
    }
    if ($event.value === 'unsecured') {
      this.book_value_active_all = this.reportAll.total_book_value_active_unsecured;
      this.book_value_all        = this.reportAll.public_debt_value;
      this.totalSumAll           = this.reportAll.public_debt_value;
    }
    if ($event.value === 'public_debts') {
      this.book_value_active_all = 0;
      this.book_value_all        = this.reportAll.total_book_value_inactive_unsecured;
      this.totalSumAll           = this.book_value_active_all + this.book_value_all;
    }
    if ($event.value === 'combined') {
      this.book_value_active_all = this.reportAll.total_book_value_active;
      this.book_value_all        = this.reportAll.total_book_value;
      this.totalSumAll           = this.book_value_active_all + this.book_value_all;
    }
  }

  private getCreditors() {
    this.creditorService.index({approved: 1, active: 1, all: 1})
        .subscribe(next => this.creditors = next.data);
  }

  private getReport(creditorId: number, filters = null) {
    this.dataSource     = new MatTableDataSource([]); //  Empty data source
    this.dataSourceList = new MatTableDataSource([]); //  Empty data source
    this.isLoading++;
    this.creditorService.showReport(creditorId, filters)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(next => {
          this.totalSum            = next.data.book_value + next.data.book_value_active;
          this.dataSource          = new MatTableDataSource([next.data]);
          this.dataSourceList      = new MatTableDataSource(next.data.list);
          this.dataSourceList.sort = this.sort;
        });
  }

  exportRecords() {
    const fileNameAppend = '_' + this.selectedCreditor.name + '.csv'; //  Append creditor name to document name

    this.creditorService.downloadReport(this.selectedCreditor.id)
        .subscribe(
            result => {
              this.toastr.success(result.message);
              // saveAs(value.body, this.translateService.instant('REPORTS.export-file-name') + fileNameAppend);
            },
            error => {
              console.log(error);
            },
        );
  }
}
