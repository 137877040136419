import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { PaymentStatus } from '../../../../../../_base-shared/models/Status/PaymentStatus';
import { Task } from '../../../../../../_base-shared/models/Task/Task';
import { TaskListFilter } from '../../../../../../_base-shared/models/Task/TaskListFilter';
import { Team } from '../../../../../../_base-shared/models/User/Team';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { PaymentStatusService } from '../../payment-status/payment-status.service';
import { StatusPickerTrait } from '../../status/status-picker.trait';
import { StatusService } from '../../status/status.service';
import { TeamService } from '../../team/team.service';
import { UserService } from '../../user/user.service';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { TaskService } from '../task.service';
import { DateTime } from 'luxon';

@Component({
  selector:    'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls:   ['./task-list.component.scss'],
})
export class TaskListComponent extends StatusPickerTrait implements OnInit, AfterViewInit, OnDestroy {
  public authUser: User;
  public onlyRelated: boolean = null;

  public form: UntypedFormGroup;
  public formReady                                = false;
  public filtersReady: boolean;
  public users: Array<User>                       = [];
  public statusFormControlName                    = 'status_ids';
  public paymentStatuses: Array<PaymentStatus>    = [];
  public teams: Array<Team>                       = [];
  public dueDateOptions: Array<AppSelectOption>   = [];
  public completedOptions: Array<AppSelectOption> = [];
  // Datatable
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoading                                = 0;
  public isSubmittingExport: boolean;
  public displayedColumns: string[]               = [
    'select',
    'id',
    'ref_number',
    'name',
    'case_status',
    'payment_status',
    'assigned_users',
    'assigned_departments',
    'teams',
    'due_date',
    'created_at',
    'completed_at',
  ];
  public totalResults: number;
  public totalPages: number;
  public dataSource: MatTableDataSource<Task>;
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  // End Datatable
  public subscriptions: Array<Subscription>       = [];
  private formChangeSubscriber: Subscription;
  public taskListFilter: TaskListFilter;
  //  Used for fetching new tasks
  public pageSelected                             = false;
  public initialSelection                         = [];
  public allowMultiSelect                         = true;
  public selection                                = new SelectionModel(this.allowMultiSelect, this.initialSelection);
  public isEndDateEnabled                         = true;

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              public translateService: TranslateService,
              private globalEventsService: MainGlobalEventService,
              private taskService: TaskService,
              private userService: UserService,
              private statusService: StatusService,
              private paymentStatusService: PaymentStatusService,
              private teamService: TeamService,
              private toastr: ToastrService) {
    super('status_ids', true, true);
  }

  ngOnInit(): void {
    this.onlyRelated = this.route.snapshot.data.hasOwnProperty('onlyRelated') ?
      this.route.snapshot.data.onlyRelated :
      true;

    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'created_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (!this.onlyRelated && this.authUser.role_id !== 5) {
        this.onlyRelated = true;
      }
      this.taskListFilter = {
        assigned_users: this.onlyRelated ? [this.authUser.id] : [],
        only_related:   this.onlyRelated,
      };
      this.buildForm(this.taskListFilter);
    }));

    this.fetchUsers();
    this.fetchStatuses();
    this.fetchPaymentStatuses();
    this.fetchTeams();
    this.buildDueDateOptions();
    this.buildIsCompleteOptions();
    this.translateService.onLangChange.subscribe(lang => {
      this.buildIsCompleteOptions();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }
  }

  public clearFilters($event): void {
    // this.taskListFilter.clearFilters();
    this.form.get('start_due_date').enable();
    this.form.get('end_due_date').enable();
    this.form.get('start_due_date').patchValue(null);
    this.form.get('end_due_date').patchValue(null);
    this.form.get('assigned_users').patchValue(null);
    this.form.get('status_ids').patchValue(null);
    this.form.get('payment_status_ids').patchValue(null);
    this.form.get('team_ids').patchValue(null);
    this.form.get('due_date_statuses').patchValue(null);
    this.form.get('is_completed').patchValue(null);
    this.clearStatusControls($event);
  }

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
  }

  public handleFilters($event: TaskListFilter): void {
    this.taskListFilter = $event;
    this.resetPagination();
    this.resetSort();
    this.fetchTasks();
  }

  private resetPagination(): void {
    this.paginatorConfig         = this.defaultPaginatorConfig;
    this.taskListFilter.per_page = this.paginatorConfig.pageSize;
    this.taskListFilter.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.taskListFilter.sort_by = this.defaultSort.active;
    this.taskListFilter.order   = this.defaultSort.direction;
  }

  private buildForm(taskListFilter: TaskListFilter) {
    if (this.formChangeSubscriber) {
      this.formChangeSubscriber.unsubscribe();
    }
    this.form = this.fb.group({
      assigned_users:     [{value: taskListFilter.assigned_users, disabled: taskListFilter.only_related}],
      only_related:       [taskListFilter.only_related],
      is_completed:       [taskListFilter.is_completed],
      start_due_date:     [taskListFilter.start_due_date],
      end_due_date:       [taskListFilter.end_due_date],
      status_ids:         [taskListFilter.status_ids || []],
      payment_status_ids: [taskListFilter.payment_status_ids],
      team_ids:           [taskListFilter.team_ids],
      due_date_statuses:  [taskListFilter.due_date_statuses],
      select_all:         [taskListFilter.select_all || false],
    });

    this.formReady = true;
    this.handleFiltersState(true);
    this.handleFilters(taskListFilter);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges() {
    if (this.form.invalid) {
      return;
    }
    this.taskListFilter = this.form.value;
    this.handleFilters(this.taskListFilter);
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.taskListFilter.page     = this.paginatorConfig.pageIndex + 1;
    this.taskListFilter.per_page = this.paginatorConfig.pageSize;
    this.fetchTasks();
  }

  public sortData(sort) {
    this.taskListFilter.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.taskListFilter.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchTasks();
    this.fetchStatuses();
    this.fetchPaymentStatuses();
  }

  private fetchTasks(): void {
    this.clearSelection();
    const requestData = {
      ...this.taskListFilter,
      with: ['case.status', 'assigned_users.teams', 'assigned_departments', 'case.payment_status'],
    };

    if (requestData.start_due_date) {
      requestData.start_due_date = DateTime.fromISO(requestData.start_due_date.toString()).toFormat('yyyy-LL-dd HH:mm:ss');
    }

    if (requestData.end_due_date) {
      requestData.end_due_date = DateTime.fromISO(requestData.end_due_date.toString()).toFormat('yyyy-LL-dd HH:mm:ss');
    }

    this.dataSource = new MatTableDataSource<Task>([]);
    this.isLoading++;
    this.subscriptions.push(
      this.taskService.indexUserTasks(this.authUser.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          const tasksWithTeams = this.mapTasksTeams(result.data);
          this.dataSource      = new MatTableDataSource<Task>(tasksWithTeams);
          this.dataSource.sort        = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        err => console.error(err),
      ),
    );
  }

  private fetchUsers(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.users = result.data),
    );
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
            this.statusCategories         = result.data;
            this.filteredStatusCategories = result.data;
            this.statusCategories.forEach(category => {
              this.allStatuses.push(category);
              category.statuses.forEach(status => this.allStatuses.push(status));
            });
            this.setStatusControls(this.form.get(this.statusFormControlName).value);
          },
        ),
    );
  }

  private fetchPaymentStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.paymentStatuses = result.data),
    );
  }

  private fetchTeams(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.teamService.index({all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.teams = result.data),
    );
  }

  public onlyRelatedChange($event: any) {
    this.form.get('assigned_users').patchValue($event ? [this.authUser.id] : []);
    $event ?
      this.form.get('assigned_users').disable({onlySelf: true, emitEvent: false}) :
      this.form.get('assigned_users').enable({onlySelf: true, emitEvent: false});
  }

  public openTask(task: Task) {
    const dialogRef = this.dialog.open(TaskDetailComponent, {
      width:      '40%',
      autoFocus:  false,
      panelClass: 'custom-task-dialog',
      // minHeight: '500px',
      data: {
        task,
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'reFetch') {
        this.fetchTasks();
      }
    });
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(clientCase => this.selection.deselect(clientCase));
    } else {
      this.dataSource.data.forEach(clientCase => this.selection.select(clientCase));
    }
  }

  public patchFilter(name: string, value, options = {}): void {
    this.form.get(name).patchValue(value, options);
  }

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    this.patchFilter('select_all', state, {emitEvent: false, onlySelf: true});
    this.taskListFilter.select_all = state;
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public toggleRow(event: MatCheckboxChange, row) {
    this.selection.toggle(row);
    if (!event.checked) {
      this.pageSelected = false;  //  Hide global select
      this.patchSelectAllFilter(0);
    }
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    this.patchSelectAllFilter(0);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public exportRecords(taskListFilter: TaskListFilter): void {
    taskListFilter.tasks = [];
    this.selection.selected.forEach(selectedTask => taskListFilter.tasks.push(selectedTask.id));
    taskListFilter.expected_count = taskListFilter.select_all ? this.totalResults : taskListFilter.tasks.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(taskListFilter, 'get');

    this.isSubmittingExport = true;
    this.subscriptions.push(
      this.taskService.exportTasks(requestFilter, this.authUser.id)
        .pipe(finalize(() => this.isSubmittingExport = false))
        .subscribe(
          result => {
            this.toastr.success(result.message);
            this.clearSelection();
          },
          err => console.error(err),
        ),
    );
    this.taskListFilter.tasks = null;
  }

  private mapTasksTeams(tasks?: Array<Task>): Array<Task> {
    return tasks.map(task => {
      task.teams = [];
      task.assigned_users.forEach(user => {
        user.teams.forEach(team => {
          if (!task.teams.includes(team.name)) {
            task.teams.push(team.name);
          }
        });
      });
      return task;
    });
  }

  public applyFilters() {
    this.resetPagination();
    this.resetSort();
    this.subscribeToFormChanges();
  }

  private buildDueDateOptions() {
    this.dueDateOptions = [
      {label: 'Due today', value: 'due_today'},
      {label: 'Overdue', value: 'overdue'},
      {label: 'Upcoming', value: 'upcoming'},
    ];
  }

  private buildIsCompleteOptions() {
    this.completedOptions = [
      {label: this.translateService.instant('TASK.list.filters.all'), value: null},
      {label: this.translateService.instant('TASK.list.filters.complete'), value: 1},
      {label: this.translateService.instant('TASK.list.filters.open'), value: 0},
    ];
  }

  public onDueDateSelection(dueDateValue: string) {
    if (dueDateValue != null) {
      this.form.get('start_due_date').patchValue(null);
      this.form.get('end_due_date').patchValue(null);
      if (dueDateValue === 'due_today') {
        this.form.get('start_due_date').disable();
        this.form.get('end_due_date').disable();
      }
    } else {
      this.form.get('start_due_date').enable();
      this.form.get('end_due_date').enable();
    }
  }
}
