import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { AppointedCourt } from '../../../../../../_base-shared/models/Entity/AppointedCourt';
import { Court } from '../../../../../../_base-shared/models/Entity/Court';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CourtService extends MainBaseApiService {

  index(requestData = {}) {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData}, 'get');
    return this.http.get<LaravelResourceResponse<Array<Court>>>(this.apiUrl + '/courts', {params})
        .pipe(catchError(response => this.handleError(response)));
  }

  show(id: number) {
    return this.http.get<LaravelResourceResponse<Court>>(this.apiUrl + '/courts/' + id)
        .pipe(catchError(response => this.handleError(response)));
  }

  store(data) {
    return this.http.post<LaravelResourceResponse<Court>>(this.apiUrl + '/courts', data)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(id: number, data) {
    return this.http.put<LaravelResourceResponse<Court>>(this.apiUrl + '/courts/' + id, data)
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(id: number) {
    return this.http.delete<LaravelResourceResponse<Court>>(this.apiUrl + '/courts/' + id)
        .pipe(catchError(response => this.handleError(response)));
  }

  getCaseAppointedCourt(caseId: number) {
    return this.http.get<LaravelResourceResponse<AppointedCourt>>(this.apiUrl + '/cases/' + caseId + '/appointed-court')
        .pipe(catchError(response => this.handleError(response)));
  }

  saveAppointedCourt(caseId, data) {
    return this.http.put<LaravelResourceResponse<AppointedCourt>>(
        this.apiUrl + '/cases/' + caseId + '/appointed-court', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  deleteAppointedCourt(caseId) {
    return this.http.delete<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/appointed-court');
  }
}
