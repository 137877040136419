<mat-dialog-content>
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!isLoading">
    <h4>{{ "CASES.editor-prefill.title" | translate }}</h4>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" (submit)="submitForm(form)">
          <div class="card shadow mb-4">
            <div class="card-body">
              <div class="col-12">
                <!-- Affiliate -->
                <app-input type="select" formControlName="affiliate_id" appearance="fill"
                           [label]="'CASES.editor-prefill.form.affiliate_id' | translate" [searchable]="true"
                           [selectOptions]="affiliates" [selectLabel]="'name'" [selectValue]="'id'">
                </app-input>
              </div>
              <!-- Legal Advisor -->
              <div class="col-12 mt-2">
                <app-input type="select" formControlName="legal_advisor_id" appearance="fill"
                           [label]="'CASES.editor-prefill.form.legal_advisor_id' | translate" [searchable]="true"
                           [selectOptions]="allLegalAdvisors" [selectLabel]="['first_name', 'last_name']"
                           [selectValue]="'id'">
                </app-input>
              </div>
              <ng-container *ngIf="authUser?.packager?.master">
                <!-- Verifier -->
                <div class="col-12 mt-2">
                  <app-input type="select" formControlName="verifier_id" appearance="fill"
                             [label]="'CASES.editor-prefill.form.verifier_id' | translate" [searchable]="true"
                             [selectOptions]="allVerifiers" [selectLabel]="['first_name', 'last_name']"
                             [selectValue]="'id'">
                  </app-input>
                </div>
              </ng-container>
              <!-- Submit -->
              <div class="row pt-5">
                <div class="col-12">
                  <button mat-raised-button color="primary" type="submit">
                    {{ "CASES.editor-prefill.form.submit" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-dialog-content>
