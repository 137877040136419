import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../../../dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Component({
  selector:    'app-advisor-leaderboard',
  templateUrl: './advisor-leaderboard.component.html',
  styleUrls:   ['./advisor-leaderboard.component.scss']
})
export class AdvisorLeaderboardComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;

  public isLoading                  = 0;
  public typeForm                   = new UntypedFormControl('lso');
  public form: UntypedFormGroup;
  public dataSource: MatTableDataSource<any>;
  public agentData: any[]           = [];
  public displayedColumns: string[] = [
    'name',
    'type',
    'cases_assigned',
    'contract_sent',
    'enviar_contrato_percent_lso',
    'contrato_firmado_lso',
    'contrato_firmado_percent_lso',
    'pago_lso',
    'pago_percent_lso',
    'caso_cancelado_lso',
    'caso_cancelado_percent_lso',
    'amount_period_lso',
  ];

  public tableData = {};
  public sortBy    = 'id';
  public sortOrder = 'desc';

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.buildForm();
      this.getData();

      this.form.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged(),
        )
        .subscribe(res => {
          this.getData();
        });
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      from:      [new Date()],
      to:        [new Date()],
      dateRadio: ['today'],
    });
  }

  clearDatePicker($event, name): void {
    this.form.patchValue({[name]: ''});
  }

  onDateChange($event, type): void {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  onRadioChange($event): void {
    if ($event.value === 'today') {
      this.form.patchValue({
        from: new Date(),
        to:   new Date(),
      });
    }
    if ($event.value === 'this-week') {
      const startOfWeek = DateTime.local().startOf('week').toISO();
      const endOfWeek   = DateTime.local().endOf('week').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
    if ($event.value === 'this-month') {
      const startOfWeek = DateTime.local().startOf('week').toISO();
      const endOfWeek   = DateTime.local().endOf('week').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
  }

  onRadioChangeType($event): void {
    this.typeForm.setValue($event.value);
    this.getData();
  }

  getData(): void {
    this.isLoading++;
    const startDate   = this.form.value.from ? this.form.value.from.toDateString() : '';
    const endDate     = this.form.value.to ? this.form.value.to.toDateString() : '';
    const productType = this.typeForm.getRawValue();

    this.dashboardService.legalAdvisorsIndex(productType, startDate, endDate)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        const originalAgentData = res.data;
        const mappedData        = originalAgentData.map((item) => {
          let cases_assigned_legal_advisor, contract_sent, contrato_firmado_lso, pago_lso, caso_cancelado_lso,
              amount_period_lso;
          const agentGroupedAccomplishments = [item.grouped_accomplishments];
          agentGroupedAccomplishments.filter(accomplishment => {
            cases_assigned_legal_advisor = accomplishment['cases-assigned-legal-advisor']?.length;
            contract_sent                = accomplishment['contract-sent']?.length;
            contrato_firmado_lso         = accomplishment['contract-signed']?.length;
            pago_lso                     = accomplishment['installment-payment']?.length;
            caso_cancelado_lso           = accomplishment['canceled-case']?.length;
            amount_period_lso            = this.sumAgentAmountPaid(item.id).toFixed(2);
          });

          return {
            ...item,
            cases_assigned: cases_assigned_legal_advisor,
            contract_sent,
            contrato_firmado_lso,
            pago_lso,
            caso_cancelado_lso,
            amount_period_lso
          };
        });

        this.dataSource      = new MatTableDataSource(mappedData);
        this.dataSource.sort = this.sort;
      });

  }

  public getTotal(colum: string): number {
    let total = 0;

    this.agentData.forEach(agent => {
      total += agent?.grouped_accomplishments[colum]?.length || 0;
    });

    return total;
  }

  public getPercent(agentId: number, column: string): number | string {
    let columnTotal = 0;

    const agent = this.agentData.find(dashAgent => dashAgent.id === agentId);

    const agentTotalCases = agent?.grouped_accomplishments['cases-assigned-legal-advisor']?.length || 0;
    columnTotal           = agent?.grouped_accomplishments[column]?.length || 0;
    if (agentTotalCases === 0 || columnTotal === 0) {
      return 0;
    }

    return ((columnTotal / agentTotalCases) * 100).toFixed(2);
  }

  public sumAgentAmountPaid(agentId: number): number {
    const agent   = this.agentData.find(dashAgent => dashAgent.id === agentId);
    let totalPaid = 0;
    agent?.grouped_accomplishments['cases-assigned-legal-advisor']?.forEach(agentAccomplishment => {
      totalPaid += agentAccomplishment.case.amount_paid;
    });

    return totalPaid;
  }

  public sumTotalAmountPaid(): number {
    let totalPaid = 0;
    this.agentData.forEach(agent => {
      agent?.grouped_accomplishments['cases-assigned-legal-advisor']?.forEach(agentAccomplishment => {
        totalPaid += agentAccomplishment.case.amount_paid;
      });
    });

    return totalPaid;
  }
}
