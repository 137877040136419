<h4>{{ "TASK.dashboard.title" | translate }}</h4>

<div class="row calendar-view-wrap">
  <div class="col-lg-4">
    <div class="row text-center">
      <div class="col-xl-4">
        <div class="btn-group">
          <div
                  class="btn btn-primary"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
          >
            {{ "TASK.dashboard.actions.previous" | translate }}
          </div>
          <div
                  class="btn btn-outline-secondary"
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
          >
            {{ "TASK.dashboard.actions.today" | translate }}
          </div>
          <div
                  class="btn btn-primary"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
          >
            {{ "TASK.dashboard.actions.next" | translate }}
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <h5>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h5>
      </div>
      <div class="col-xl-4">
        <div class="btn-group">
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month"
          >
            {{ "TASK.dashboard.actions.month" | translate }}
          </div>
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Week)"
                  [class.active]="view === CalendarView.Week"
          >
            {{ "TASK.dashboard.actions.week" | translate }}
          </div>
          <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day"
          >
            {{ "TASK.dashboard.actions.day" | translate }}
          </div>
        </div>
      </div>
    </div>

    <ng-template
        #defaultTemplate
        let-day="day"
        let-locale="locale">
      <div class="cal-cell-top custom-template">
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        <div class="d-flex justify-content-center align-items-center h-100">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        </div>
      </div>
    </ng-template>
    <div [ngSwitch]="view">
      <!--        [activeDayIsOpen]="activeDayIsOpen" This is used for showing events list directly in calendar-->
      <mwl-calendar-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              [cellTemplate]="defaultTemplate"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>
  </div>
  <div class="col-lg-8">
    <div *ngIf="selectedDayEvents && !isLoadingTasks">
      <h4 class="text-center">{{ viewDate | calendarDate:('day' + 'ViewTitle'):'en' }}</h4>
      <div class="row">
        <div class="col-md-6" style="margin-bottom: 20px" *ngFor="let task of selectedDayEvents">
          <div (click)="openTask(task)" class="task-card-background">
            <app-task-preview [task]="task"></app-task-preview>
          </div>
        </div>
      </div>
<!--      <div class="task-info d-flex justify-content-between align-items-center" (click)="openTask(task)"-->
<!--            style="border-bottom: 1px solid #f5f5f5; padding: 10px">-->
<!--        <div>-->
<!--          -->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>
