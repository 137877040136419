import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SystemEventConfiguration } from '../../../../../../../_base-shared/models/SystemEventConfiguration';
import { StatusService } from '../../../status/status.service';
import { SystemEventService } from '../system-event.service';

@Component({
  selector:    'app-system-event-editor',
  templateUrl: './system-event-editor.component.html',
  styleUrls:   ['./system-event-editor.component.scss'],
})
export class SystemEventEditorComponent implements OnInit {
  public statuses     = [];
  public isLoading    = 0;
  public isSubmitting = false;
  public configuration: SystemEventConfiguration;
  public form: UntypedFormGroup;

  constructor(
    private statusService: StatusService,
    private systemEventService: SystemEventService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.getStatuses();
    this.getConfiguration();
  }

  getStatuses(): void {
    this.isLoading++;
    this.statusService.index({all: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.statuses = res.data.filter(status => !!status.status_id);
      });
  }

  getConfiguration(): void {
    this.isLoading++;
    this.systemEventService.index()
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.configuration = res.data;
          this.buildForm();
        }
      );
  }

  buildForm(): void {
    this.isLoading++;
    this.form = this.fb.group({
      default_status: [this.configuration.default_status?.status_id],
      new_creditor: [this.configuration.new_creditor?.status_id],
      sign_contract: [this.configuration.sign_contract?.status_id],
      send_contract: [this.configuration.send_contract?.status_id],
    });
    this.isLoading--;
  }

  submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.systemEventService.store(this.form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        res => {
          this.toastr.success(this.translateService.instant('CONFIG.system-event.config-saved'));
          // this.router.navigateByUrl('/cases');
        },
        err => {
          this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
        }
      );
  }
}
