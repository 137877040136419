import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { DialerList } from '../../../../../../../_base-shared/models/Dialer/DialerList';
import { DialerTrigger } from '../../../../../../../_base-shared/models/Dialer/DialerTrigger';
import { Packager } from '../../../../../../../_base-shared/models/Packager/Packager';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { CallStatus } from '../../../../../../../_base-shared/models/Status/CallStatus';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { Status } from '../../../../../../../_base-shared/models/Status/Status';
import { AdminPackagerService } from '../../../admin-packager/admin-packager.service';
import { CallStatusService } from '../../../call-status/call-status.service';
import { ProductService } from '../../../case/product.service';
import { PaymentStatusService } from '../../../payment-status/payment-status.service';
import { StatusService } from '../../../status/status.service';
import { DialerService } from '../../dialer.service';

@Component({
  selector:    'app-dialer-status-trigger-list',
  templateUrl: './dialer-status-trigger-list.component.html',
  styleUrls:   ['./dialer-status-trigger-list.component.scss']
})
export class DialerStatusTriggerListComponent implements OnInit {
  @Input() statusCategories: Array<Status>;
  @Input() componentType: 'status' | 'payment_status' | 'call_status';
  @Output() removeStatusClicked: EventEmitter<number> = new EventEmitter<number>();

  public delayOptions: Array<AppSelectOption>;
  public productTypeGroups: Array<AppSelectOption>;
  public dialerLists: Array<DialerList>;
  public products: Array<Product>              = [];
  public packagers: Array<Packager>            = [];
  public isLoading                             = 0;
  public isDialerTriggersLoading               = 0;
  public callStatuses: Array<CallStatus>       = [];
  public paymentStatuses: Array<PaymentStatus> = [];
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public dialerTriggers: Array<DialerTrigger>;

  constructor(private translate: TranslateService,
              private dialerService: DialerService,
              private statusService: StatusService,
              private productService: ProductService,
              private callStatusService: CallStatusService,
              private packagerService: AdminPackagerService,
              private toastr: ToastrService,
              private paymentStatusService: PaymentStatusService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.isDialerTriggersLoading++;
    this.dialerService.indexTriggers(['products']).pipe(finalize(() => this.isDialerTriggersLoading--)).subscribe(result => {
      this.dialerTriggers = result.data;
      this.buildForm(this.dialerTriggers);
    });
    this.buildDelayOptions();
    this.fetchDialerLists();
    this.fetchProducts();
    this.fetchStatuses();
    this.productTypeGroups = this.getProductTypeGroups();
    this.fetchPackagers();
    this.fetchCallStatuses();
    this.fetchPaymentStatuses();
  }

  public productGroupUpdated(productGroupSlugs: Array<string>, index: number): void {
    const productIds = [];
    productGroupSlugs.forEach(groupSlug => {
      this.products.forEach(product => {
        if (product.group_slug === groupSlug) {
          productIds.push(product.id);
        }
      });
    });
    this.getFormArray().at(index).get('product_ids').patchValue(productIds);
  }

  private buildForm(dialerTriggers: Array<DialerTrigger>): void {
    this.form       = this.fb.group({
      dialer_triggers: this.fb.array([]),
    });
    const formArray = this.getFormArray();

    dialerTriggers.forEach(dialerTrigger => {
      formArray.push(this.dialerTriggerFormGroup(dialerTrigger));
    });
  }

  public dialerTriggerFormGroup(dialerTrigger: DialerTrigger): UntypedFormGroup {
    const productGroups = [];
    const productIds    = [];
    dialerTrigger.products?.forEach(product => {
      productIds.push(product.id);
      if (productGroups.findIndex(pG => pG === product.group_slug) < 0) {
        productGroups.push(product.group_slug);
      }
    });

    return this.fb.group({
      id:                  [dialerTrigger.id],
      statusable_type:     [dialerTrigger.statusable_type, [Validators.required]],
      statusable_id:       [dialerTrigger.statusable_id, [Validators.required]],
      product_group_slugs: [productGroups, [Validators.required]],
      product_ids:         [productIds, [Validators.required]],
      dialer_list_id:      [dialerTrigger.dialer_list_id, [Validators.required]],
      priority:            [dialerTrigger.priority, [Validators.required, Validators.min(1), Validators.max(99)]],
      force_dial:          [dialerTrigger.force_dial],
      delay:               [dialerTrigger.delay, [Validators.required, Validators.min(0)]],
      delay_unit:          [dialerTrigger.delay_unit, [Validators.required]],
      auto_queue:          [dialerTrigger.auto_queue, [Validators.required]],
      active:              [dialerTrigger.active, [Validators.required]],
      packager_id:         [dialerTrigger.packager_id],
    });
  }

  public getFormArray(): FormArray<any> {
    return this.form.get('dialer_triggers') as UntypedFormArray;
  }

  public removeStatus($event: number): void {
    this.getFormArray().removeAt($event);
  }

  public addNewStatusTrigger(statusableType: 'status' | 'payment_status' | 'call_status'): void {
    const dialerTrigger           = new DialerTrigger();
    dialerTrigger.statusable_type = statusableType;
    dialerTrigger.product_ids     = null;
    dialerTrigger.delay           = 0;
    dialerTrigger.delay_unit      = 'minute';
    dialerTrigger.auto_queue      = true;
    dialerTrigger.active          = true;
    dialerTrigger.force_dial      = true;

    const formArray = this.getFormArray();
    formArray.push(this.dialerTriggerFormGroup(dialerTrigger));
  }

  private buildDelayOptions(): void {
    this.delayOptions = [
      {
        value: 'minute',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.minute'),
      },
      {
        value: 'hour',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.hour'),
      },
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ]
    ;
  }

  private getProductTypeGroups(): ({ label: 'LSO'; value: 'lso' } | { label: 'DM'; value: 'dm' } | {
    label: 'DGS';
    value: 'dgs'
  } | { label: 'Cajaplus'; value: 'cajaplus' } | { label: 'Unifye'; value: 'unified' })[] {
    return [
      {label: 'LSO', value: 'lso'},
      {label: 'DM', value: 'dm'},
      {label: 'DGS', value: 'dgs'},
      {label: 'Cajaplus', value: 'cajaplus'},
      {label: 'Unifye', value: 'unified'},
    ];
  }

  private fetchProducts(): void {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.products = result.data;
      });
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.statusCategories = result.data);
  }

  private fetchDialerLists(): void {
    this.isLoading++;
    this.dialerService.indexLists({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.dialerLists = result.data);
  }

  private fetchPackagers(): void {
    this.isLoading++;
    this.packagerService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => this.packagers = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }

  private fetchCallStatuses(): void {
    this.isLoading++;
    this.callStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.callStatuses = result.data);
  }

  private fetchPaymentStatuses(): void {
    this.isLoading++;
    this.paymentStatusService.index({all: 1}).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.paymentStatuses = result.data);
  }

  private getFormValidationErrors(): void {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  public gatherFormData(): UntypedFormGroup {
    this.getFormValidationErrors();
    if (this.form.invalid) {
      this.getFormValidationErrors();
      this.form.markAllAsTouched();
      return;
    } else {
      return this.form;
    }
  }
}
