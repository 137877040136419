import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseEntity } from '../../../../../../../../_base-shared/models/Case/CaseEntity';
import { AppointedCourt } from '../../../../../../../../_base-shared/models/Entity/AppointedCourt';
import { Court } from '../../../../../../../../_base-shared/models/Entity/Court';
import { CourtService } from '../../../../address-book/court/court.service';
import { StatusService } from '../../../../status/status.service';

@Component({
  selector:    'app-appointed-court-editor',
  templateUrl: './appointed-court-editor.component.html',
  styles:      [],
})
export class AppointedCourtEditorComponent implements OnInit {
  @Input() appointedCourt: AppointedCourt;
  @Input() caseEntities: CaseEntity;
  @Input() caseStatusId: number;
  @Output() removedAppointedCourt: EventEmitter<number> = new EventEmitter<number>();

  public isLoading = 0;
  public isStatusDemandaTerminada = false;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public courts: Array<Court>;
  public statusOptions: Array<AppSelectOption>;
  public outcomeOptions: Array<AppSelectOption>;

  constructor(
      private fb: UntypedFormBuilder,
      private toastr: ToastrService,
      private translate: TranslateService,
      private courtService: CourtService,
      private statusService: StatusService
  ) {
  }

  ngOnInit(): void {
    this.fetchCourts();
    this.buildSelectOptions();
    this.buildForm(this.appointedCourt);
    this.translate.onLangChange.subscribe(() => this.buildSelectOptions());
    this.getCurrentStatus();
  }

  private buildForm(appointedCourt: AppointedCourt) {
    this.form = this.fb.group({
      court_id:           [{value: appointedCourt.court_id, disabled: true}],
      status:             [{value: appointedCourt.status, disabled: true}],
      outcome:            [appointedCourt.outcome],
      exoneration_amount: [appointedCourt.exoneration_amount],
      presented_date:     [appointedCourt.presented_date ? new Date(appointedCourt.presented_date) : null],
      accepted_date:      [appointedCourt.accepted_date ? new Date(appointedCourt.accepted_date) : null],
      application_date:   [appointedCourt.application_date ? new Date(appointedCourt.application_date) : null],
      concession_date:    [appointedCourt.concession_date ? new Date(appointedCourt.concession_date) : null],
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.courtService.saveAppointedCourt(this.appointedCourt.case_id, form.getRawValue()).subscribe(
        res => this.toastr.success(this.translate.instant('LEGAL_ENTITY.appointed_court.editor.result.success')),
        err => this.toastr.error(this.translate.instant('LEGAL_ENTITY.appointed_court.editor.result.error')));
  }

  public openDeleteDialog($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
          {model: this.translate.instant('LEGAL_ENTITY.appointed_court.model_name.singular')}),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.courtDelete();
      }
    });
  }

  public courtDelete() {
    this.courtService.deleteAppointedCourt(this.appointedCourt.court_id).subscribe(res => {
      this.toastr.success(this.translate.instant('SHARED.submit_result.delete.success',
          {model: this.translate.instant('LEGAL_ENTITY.appointed_court.model_name.singular')},
      ));
      this.removedAppointedCourt.emit(this.appointedCourt.id);
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.submit_result.delete.error',
          {model: this.translate.instant('LEGAL_ENTITY.appointed_court.model_name.singular')},
      ));
    });
  }

  private fetchCourts() {
    this.isLoading++;
    this.courtService.index({select_all: 1}).pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.courts = result.data);
  }

  private buildSelectOptions() {
    this.statusOptions = [
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.status.options.presented'),
        value: 'presented',
      },
      {label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.status.options.accepted'), value: 'accepted'},
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.status.options.application'),
        value: 'application',
      },
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.status.options.concession'),
        value: 'concession',
      },
    ];
    this.outcomeOptions = [
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.outcome.options.exoneration'),
        value: 'exoneration',
      },
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.outcome.options.payment_plan'),
        value: 'payment_plan',
      },
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.outcome.options.liquidation'),
        value: 'liquidation',
      },
      {
        label: this.translate.instant('LEGAL_ENTITY.appointed_court.model.outcome.options.rejection'),
        value: 'rejection',
      },
    ];
  }

  private getCurrentStatus(){
    this.statusService.get(this.caseStatusId).subscribe(res => {
      if (res.data.name === 'Demanda terminada'){
        this.isStatusDemandaTerminada = true;
      }
    });
  }
}
