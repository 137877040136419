<div class="container">
  <mat-card>
    <mat-card-header>
      <!--<mat-card-title>-->
      <!--  <h1>{{ 'PACKAGER.list.heading' | translate }}</h1>-->
      <!--</mat-card-title>-->
      <div class="d-flex justify-content-end w-100">
        <a mat-raised-button color="primary" [routerLink]="['/packagers', 'create']">
          + {{ "SHARED.create" | translate }}
        </a>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-table [dataSource]="packagers" matSort (matSortChange)="sortData($event)">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.id' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'PACKAGER.model.name' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element['name_' + currentLanguage] }}</td>
        </ng-container>
        <!-- Users -->
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef>{{ 'PACKAGER.relations.users' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <span *ngFor="let user of element.users" class="badge badge-success ms-1">
              {{ user.first_name + ' ' + user.last_name }}
            </span>
          </td>
        </ng-container>
        <!-- Created at -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date }} </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'SHARED.actions' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex">
              <a *ngIf="!element.master" class="pe-3" [routerLink]="[element.id, 'details']">
                {{ 'SHARED.details' | translate }}
              </a>
              <a class="pe-3" [routerLink]="[element.id, 'edit']">{{ 'SHARED.edit' | translate }}</a>
              <a class="pe-3" (click)="deletePackager(element.id)">{{ 'SHARED.delete' | translate }}</a>
            </div>
          </td>
        </ng-container>
      </mat-table>
      <!-- No Data -->
      <div *ngIf="isLoading < 1 && packagers && packagers.data.length < 1" style="padding: 50px">
        <h5
          class="text-center">{{ 'SHARED.no_data' | translate: {models: 'PACKAGER.model_name.plural' | translate} }}</h5>
      </div>
      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                     [pageSizeOptions]="[5, 10, 20]" [pageIndex]="paginatorConfig.pageIndex"
                     (page)="paginatorChange($event)">
      </mat-paginator>
    </mat-card-footer>
  </mat-card>
</div>

