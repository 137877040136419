import { Component, Inject, OnInit } from '@angular/core';
import {
  CreditorService
} from '../../creditor.service';
import {
  MAT_DIALOG_DATA, MatDialogRef
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { User } from '../../../../../../../_base-shared/models/User/User';

@Component({
  selector:    'app-creditor-reassign',
  templateUrl: './creditor-reassign.component.html',
  styleUrls:   ['./creditor-reassign.component.scss']
})
export class CreditorReassignComponent implements OnInit {
  public isLoading                  = 0;
  public authUser: User;
  public creditors: Array<Creditor> = [];
  public deleteCreditor: Creditor;
  public reassignmentCreditor: Creditor;
  public form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CreditorReassignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalEventsService: MainGlobalEventService,
    private creditorService: CreditorService,
  ) {
  }

  ngOnInit(): void {
    this.deleteCreditor = this.data.deleteCreditor;
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.fetchCreditors();
    this.buildForm(this.deleteCreditor);
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe({
        next:  res => this.creditors = res.data,
        error: err => console.error(err)
      });
  }

  private buildForm(deleteCreditor: Creditor) {
    this.form = this.fb.group({
      delete_creditor_id:   [deleteCreditor.id, [Validators.required]],
      reassign_creditor_id: [null, [Validators.required]],
      case_creditor_notes:  [null]
    });

    this.form.get('reassign_creditor_id').valueChanges.subscribe(reassignCreditorId => {
      this.reassignmentCreditor = this.creditors.find(searchCreditor => searchCreditor.id === reassignCreditorId);
    })
  }

  public submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading++;
    this.creditorService.deleteAndReassign(this.form.value).pipe(finalize(() => this.isLoading--))
      .subscribe({
        next:  result => {
          this.toastr.success(result.message);
          this.dialogRef.close(true);
        },
        error: err => console.error(err)
      });
  }
}
