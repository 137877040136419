import { NgModule } from '@angular/core';
import { SharedModule } from '../../_shared/shared.module';
import {CustomerContactRoutingModule} from './customer-contact-routing.module';

@NgModule({
    declarations: [],
    imports:      [
        SharedModule,
        CustomerContactRoutingModule,
    ],
})
export class CustomerContactModule {

}
