import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseContract } from '../../../../../../../../../_base-shared/models/CaseDocument/CaseContract';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-contract-list',
  templateUrl: './case-contract-list.component.html',
  styles:      [],
})
export class CaseContractListComponent implements OnInit {
  @Input() case: Case;
  public contracts: Array<CaseContract>;
  public isLoading  = 0;
  public storageUrl = environment.STORAGE_URL + '/';

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.loadCaseContracts(this.case);
  }

  private loadCaseContracts(clientCase: Case): void {
    this.isLoading++;
    this.caseService.get(clientCase.id, ['contracts']).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.contracts = result.data.contracts,
    );
  }
}
