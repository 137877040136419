<table class="table table-bordered" *ngIf="transaction">
  <tbody>
  <tr>
    <th>ID</th>
    <td>{{transaction.id}}</td>
  </tr>
 <tr>
    <th>Payment Processor</th>
    <td>{{transaction.payment_processor?.slug}}</td>
  </tr>
  <tr>
    <th>Payment Type</th>
    <td>{{transaction?.payment_type}}</td>
  </tr>
  <tr>
    <th>Payment Brand</th>
    <td>{{transaction.payment_card?.card_brand ? transaction.payment_card?.card_brand : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Amount</th>
    <td>{{transaction.amount ? (transaction.amount | currency: "EUR") : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Currency</th>
    <td>{{transaction.currency_code ? transaction.currency_code : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Card BIN</th>
    <td>{{transaction.card_bin ? transaction.card_bin : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Last 4 Digits</th>
    <td>{{transaction.card_last_four ? transaction.card_last_four : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Card Holder</th>
    <td>{{transaction.card_holder ? transaction.card_holder : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Expiry</th>
    <td>{{transaction.card_expiry ? transaction.card_expiry : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Customer Name</th>
    <td>{{transaction.customer?.givenName ? transaction.customer?.givenName : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Customer Surname</th>
    <td>{{transaction.customer?.surname ? transaction.customer?.surname : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Mobile</th>
    <td>{{transaction.customer?.mobile ? transaction.customer?.mobile : 'N/A'}}</td>
  </tr>
  <tr>
    <th>IP</th>
    <td>{{transaction.customer?.ip ? transaction.customer?.ip : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Build Number</th>
    <td>{{transaction.oppwa_build_number ? transaction.oppwa_build_number : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Timestamp</th>
    <td>{{transaction.oppwa_timestamp ? transaction.oppwa_timestamp : 'N/A'}}</td>
  </tr>
  <tr>
    <th>NDC</th>
    <td>{{transaction.oppwa_ndc ? transaction.oppwa_ndc : 'N/A'}}</td>
  </tr>
    <tr>
    <th>Last Transaction Descriptor</th>
    <td>{{transaction.latest_transaction?.descriptor ? transaction.latest_transaction?.descriptor : 'N/A'}}</td>
  </tr>
    <tr>
      <th>Last Transaction Merchant Transaction Id</th>
      <td>{{transaction.latest_transaction?.merchant_transaction_id ? transaction.latest_transaction?.merchant_transaction_id : 'N/A'}}</td>
    </tr>
    <tr>
      <th>Last Transaction AVS Response</th>
      <td>{{transaction.latest_transaction?.result_data?.avsResponse ? transaction.latest_transaction?.result_data?.avsResponse : 'N/A'}}</td>
    </tr>
    <tr>
      <th>Last Transaction CVV Response</th>
      <td>{{transaction.latest_transaction?.result_data?.cvvResponse ? transaction.latest_transaction?.result_data?.cvvResponse : 'N/A'}}</td>
    </tr>
  <tr>
    <th>Last Transaction Result Code</th>
    <td>{{transaction.latest_transaction?.result_code?.transaction_result ? transaction.latest_transaction?.result_code?.transaction_result : 'N/A'}}</td>
  </tr>
  <tr>
    <th>Last Transaction Result Description</th>
    <td>{{transaction.latest_transaction?.result_code?.description ? transaction.latest_transaction?.result_code?.description : 'N/A'}}</td>
  </tr>
  </tbody>
</table>
