import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CasePublicDebt } from '../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../../creditor/creditor.service';
import { StatusService } from '../../../../status/status.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CaseDocumentService } from '../../../case-document.service';
import { CaseService } from '../../../case.service';
import { BaseCaseCreditorComponent } from '../base-case-creditor/base-case-creditor.component';

@Component({
  selector: 'app-public-debt',
  templateUrl: './public-debt.component.html',
  styles: []
})
export class PublicDebtComponent extends BaseCaseCreditorComponent implements OnInit{
  @Input() publicDebts: MatTableDataSource<any>;
  @Input() originalPublicDebts: Array<any>;
  @Input() isFetchingPublicDebt                    = 0;
  @Input() isPublicLoading                         = 0;
  @Input() isPublicLoadingId: number[]             = [];

  public selectionPublicDebts = new SelectionModel<CasePublicDebt>(true, []);

  public publicDebtsVerifyDisabled     = true;
  public publicDebtsResendDisabled     = true;
  public displayedColumns: string[] = [];

  constructor(
    protected router: Router,
    protected fb: UntypedFormBuilder,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected toast: ToastrService,
    protected translate: TranslateService,
    protected toastr: ToastrService,
    protected globalEventService: MainGlobalEventService,
    protected caseService: CaseService,
    protected creditorService: CreditorService,
    protected caseCreditorService: CaseCreditorService,
    protected caseDocumentService: CaseDocumentService,
    protected statusService: StatusService,
    protected el: ElementRef,
  ) {
    super(router, fb, route, dialog, toast, translate, toastr, globalEventService, caseService, creditorService,
      caseCreditorService
      , caseDocumentService, statusService, el);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayColumns();
    this.selectionPublicDebts.changed.subscribe(next => {
      this.checkIfDisabled(next, 'public_debts');
    });
  }


  private buildDisplayColumns(): void {
    this.displayedColumns = [
      'select', 'name', 'ownership', 'debt_type', 'initial_balance', 'current_balance', 'monthly_payments',
      'reference_number', 'response_received', 'additional_partner', 'making_payments', 'actions', 'loader',
    ];
  }

  private checkIfDisabled(selection, type: string): void {
      this.publicDebtsVerifyDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified === 1).length);

      this.publicDebtsResendDisabled = !!(selection.source.selected.length === 0 ||
        selection.source.selected.filter(cred => cred.verified !== 1).length);
    }
}
