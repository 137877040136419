import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

@Component({
  selector:    'app-packager-dashboard',
  templateUrl: './packager-dashboard.component.html',
  styles:      [],
})
export class PackagerDashboardComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public isLoading                  = 0;
  public form: UntypedFormGroup;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'packager_name',
    'total_payments_amount',
    'transfered_cases_count',
    'created_cases_count',
    'cases_entered_enviar_contrato_count',
    'cases_entered_contrato_firmado_count',
    'cases_entered_pago_fraccionado_count'];
  public packagerData: any;
  public totalPaymentsAmount: number;
  public totalTransferredCasesCount: number;
  public totalCreatedCasesCount: number;
  public totalCasesEnteredEnviarContratoCount: number;
  public totalCasesEnteredContratoFirmadoCount: number;
  public totalCasesEnteredPagoFraccionadoCount: number;

  constructor(private fb: UntypedFormBuilder,
              private dashboardService: DashboardService,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.buildForm();
      this.getData();
      this.form.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged(),
        )
        .subscribe(res => {
          this.getData();
        });
    });
  }

  buildForm(): void {
    this.form = this.fb.group({
      from:      [new Date()],
      to:        [new Date()],
      dateRadio: ['today'],
    });
  }

  clearDatePicker($event, name): void {
    this.form.patchValue({[name]: ''});
  }

  onDateChange($event, type): void {
    if (type === 'from') {
      this.form.patchValue({
        from: new Date($event.value),
      });
    }
    if (type === 'to') {
      this.form.patchValue({
        to: new Date($event.value),
      });
    }
  }

  onRadioChange($event): void {
    if ($event.value === 'today') {
      this.form.patchValue({
        from: new Date(),
        to:   new Date(),
      });
    }
    if ($event.value === 'this-week') {
      const startOfWeek = DateTime.local().startOf('week').toISO();
      const endOfWeek   = DateTime.local().endOf('week').toISO();
      this.form.patchValue({
        from: new Date(startOfWeek),
        to:   new Date(endOfWeek),
      });
    }
    if ($event.value === 'this-month') {
      const startOfMonth = DateTime.local().startOf('month').toISO();
      const endOfMonth   = DateTime.local().endOf('month').toISO();
      this.form.patchValue({
        from: new Date(startOfMonth),
        to:   new Date(endOfMonth),
      });
    }
  }

  getData(): void {
    this.isLoading++;
    const dateRange = {
      start_date: this.form.value.from ? this.form.value.from.toDateString() : '',
      end_date:   this.form.value.to ? this.form.value.to.toDateString() : '',
    };

    this.dashboardService.indexV3(dateRange)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.packagerData    = res.data;
          this.dataSource      = new MatTableDataSource(res.data);
          this.dataSource.sort = this.sort;
          this.getTotals();
        }, err => console.log(err),
      );
  }

  public getTotals(): void {
    this.totalPaymentsAmount                   = 0;
    this.totalTransferredCasesCount            = 0;
    this.totalCreatedCasesCount                = 0;
    this.totalCasesEnteredEnviarContratoCount  = 0;
    this.totalCasesEnteredContratoFirmadoCount = 0;
    this.totalCasesEnteredPagoFraccionadoCount = 0;
    for (const row of this.packagerData) {
      this.totalPaymentsAmount += row.total_payments_amount;
      this.totalTransferredCasesCount += row.transfered_cases_count;
      this.totalCreatedCasesCount += row.created_cases_count;
      this.totalCasesEnteredEnviarContratoCount += row.cases_entered_enviar_contrato_count;
      this.totalCasesEnteredContratoFirmadoCount += row.cases_entered_contrato_firmado_count;
      this.totalCasesEnteredPagoFraccionadoCount += row.cases_entered_pago_fraccionado_count;
    }
  }
}
