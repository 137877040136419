<div class="loan-creditors-section" >
  <h5>{{ "CASES.editor.creditors.heading" | translate }}</h5>
  <div *ngIf="case && loanCreditors" class="mat-elevation-z8 case-creditor-table-wrapper">
    <table  mat-table matSort [dataSource]="loanCreditors" class="case-creditor-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">
          <mat-checkbox (change)="$event ? masterToggle(selectionLoanCreditors, loanCreditors) : null" color="primary"
                        [checked]="selectionLoanCreditors.hasValue() && isAllSelected(selectionLoanCreditors, loanCreditors)"
                        [indeterminate]="selectionLoanCreditors.hasValue() && !isAllSelected(selectionLoanCreditors, loanCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionLoanCreditors.toggle(row) : null"
                        [checked]="selectionLoanCreditors.isSelected(row)">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef
            class="p-r-10">{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container
            *ngIf="element.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>
          <ng-container
            *ngIf="element.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>
          <ng-container
            *ngIf="element.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <form *ngIf="loanCreditorsForm" [formGroup]="loanCreditorsForm">
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="debt_amount"
                       appearance="fill" fullWidth="false"
                       [label]="'Debt Amount'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{element.debt_amount | number: '1.2-2'}}€</p>
          </td>
          <td mat-footer-cell *matFooterCellDef class="ms-1"> {{getTotal('debt_amount')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="negotiated_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.negotiated_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="negotiated_amount"
                       appearance="fill" fullWidth="false"
                       [label]="'Negotiated Amount'">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{element.negotiated_amount | number: '1.2-2'}} <span
              *ngIf="element.negotiated_amount">€</span></p>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{getTotal('negotiated_amount')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="total_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.total_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <p *ngIf="element.debt_amount && element.negotiated_amount">{{(element.debt_amount -
              element.negotiated_amount) | number: '1.2-2'}}€</p>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{getTotal('total_reduction')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="percent_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <p *ngIf="element.debt_amount && element.negotiated_amount">{{+(+(+(element.debt_amount -
                element.negotiated_amount) / element.debt_amount) *
              100) | number: '1.2-2'}} <span
              *ngIf="element.debt_amount && element.negotiated_amount">%</span></p>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{getTotal('percent_reduction')  | number: '1.2-2'}} %</td>
        </ng-container>
      </form>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <p>{{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}</p>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <button *ngIf="!element.verified" mat-menu-item type="button"
                    (click)="verifyCreditorIds([element.id], 'unsecured_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.verified" mat-menu-item type="button"
                    (click)="verifyCreditorIds([element.id], 'unsecured_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>

            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="!element.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.id], 'unsecured_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </button>
            </div>
            <div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="element.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.id], 'unsecured_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </button>
            </div>

            <button [disabled]="!isSigned || !element.proposal_location"
                    mat-menu-item
                    (click)="sendProposalAndAccord([element.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <!-- <button mat-menu-item
                     [disabled]="!(element.total_repaid > element.initial_balance)"
                     (click)="sendEmail($event, element.id)">
               {{ "CASES.details.send-azcarate" | translate }}
             </button>-->
            <button mat-menu-item
                    (click)="changeStatus($event, [element.id], 'unsecured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendLastChance($event, [element.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </button>
            <!--  <button mat-menu-item
                      (click)="sendDeleteNumber($event, [element.id])">
                {{ "CASES.details.send-delete-number" | translate }}
              </button>-->
            <button mat-menu-item
                    (click)="sendAntiHarassment($event, [element.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendLoanCancellationEmail($event, [element.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </button>
            <button mat-menu-item
                    *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button>
            <button mat-menu-item
                    *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button>
            <button mat-menu-item
                    [routerLink]="['/creditor-proposal-preview']"
                    [queryParams]="{uuid: case.uuid, case_creditor_id:element.id, ownership: element.ownership}">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </button>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a>
          </mat-menu>
          <button mat-stroked-button class="ms-2" (click)="toggleLoanEditMode(i, element)">
            {{ !isEditing || currentRow != i ? 'Edit' : 'Save' }}
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsLoanCreditors"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsLoanCreditors;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumnsLoanCreditors"></tr>
    </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && loanCreditors && !loanCreditors.data.length" style="padding: 50px">
      <h5 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h5>
    </div>
  </div>
</div>
