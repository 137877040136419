<div class="d-flex flex-row justify-content-between align-items-center">
  <h4>{{ 'CASES.single.general.relation_list.invoice_status.heading' | translate }}</h4>
</div>
<div class="pt-4 ps-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !caseInvoiceStatusLogs?.length"
                   [message]="'CASES.single.general.relation_list.invoice_status.empty_dataset' | translate">
</app-empty-dataset>
<div class="row m-2" *ngFor="let statusLog of caseInvoiceStatusLogs" [hidden]="isLoading">
  <div class="card col-12">
    <div class="card-body">
      <span *ngIf="statusLog.from_case_invoice_status_id">
        {{ 'CASES.single.general.relation_list.invoice_status.status_changed_from' | translate }} {{ statusLog.from_case_invoice_status.name }}
      </span>
      <span
        *ngIf="!statusLog.from_case_invoice_status_id">{{ 'CASES.single.general.relation_list.invoice_status.no_old_status' | translate }}</span>
      <span *ngIf="statusLog.to_case_invoice_status_id">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.invoice_status.status_changed_to' | translate }} {{ statusLog.to_case_invoice_status.name }}
      </span>
      <span *ngIf="statusLog.author_id">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.invoice_status.status_changed_by' | translate }} {{ statusLog.author.first_name }} {{ statusLog.author.last_name }}
      </span>
      <p>{{ statusLog.created_at| toDateObject | date: 'medium' }}</p>
    </div>
  </div>
</div>
