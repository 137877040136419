import { Component, OnDestroy, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseContract } from '../../../../../../../_base-shared/models/CaseDocument/CaseContract';
import { AppDocument } from '../../../../../../../_base-shared/models/Document/AppDocument';
import { DocumentsReadyModel } from '../../../../../../../_base-shared/models/Document/DocumentsReadyModel';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { SafePipe } from '../../../../../../../_base-shared/pipes/safe.pipe';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { VerifySignatureComponent } from '../../../document/verify-signature/verify-signature.component';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-documents',
  templateUrl: './case-documents.component.html',
  styles:      [
    'a[target]:not(.btn){' +
    'font-weight: 400 !Important}']
})
export class CaseDocumentsComponent implements OnInit, OnDestroy {
  public authUser: User;
  public case: Case;

  public storageUrl = environment.STORAGE_URL + '/';
  public latestContract: CaseContract;

  // Loaders
  public isLoading            = 0;
  public regeneratingContract = false;
  public requestingResign     = false;
  public sendingContract      = false;

  // Document Flags
  public advicePackVisible           = false;
  public autoDeConcursoUploaded: boolean | 0 | 1;
  public autoDeExoneracionUploadedClient: boolean | 0 | 1;
  public autoDeExoneracionUploadedPartner: boolean | 0 | 1;
  public areExperianAttachmentsReady = false;
  public documentsArray: DocumentsReadyModel;
  public debtExonerationDocument: AppDocument;

  private clientAppUrl                       = environment.CLIENT_APP_URL;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private safePipe: SafePipe,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');

      this.isLoading++;
      this.caseService.get(caseId, ['product', 'contracts'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.latestContract              = result.data.contracts[0];
          this.advicePackVisible           = ['lso-pp', 'lso-te', 'lso-lq'].includes(result.data.product.slug);
          this.isLoading++;
          this.caseDocumentService.indexCaseDocuments(caseId, ['legal_documents'])
            .pipe(finalize(() => this.isLoading--))
            .subscribe(res => {
              this.case = res.data;
              this.getDebtExonerationDocument(this.case);
              if (this.case.joint_application) {
                this.checkIfDocsAreDisabled('client');
                this.checkIfDocsAreDisabled('partner');
              } else {
                this.checkIfDocsAreDisabled('client');
              }
            });
        });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private checkIfDocsAreDisabled(clientRole: 'client' | 'partner'): void {
    const data = {
      document_slugs: ['auto-exoneracion', 'debt-cancellation-request', 'dni', 'mandate', 'auto-de-concurso'],
      client_role:    clientRole
    };
    this.caseDocumentService.getAreDocumentsReady(this.case.id, data).subscribe(response => {
      this.documentsArray              = response.data;
      this.areExperianAttachmentsReady = !! (this.documentsArray['auto-exoneracion'] &&
        this.documentsArray['debt-cancellation-request'] &&
        this.documentsArray.dni && this.documentsArray.mandate);
      this.autoDeConcursoUploaded      = this.documentsArray['auto-de-concurso'];
      if (clientRole === 'client') {
        this.autoDeExoneracionUploadedClient = this.documentsArray['auto-exoneracion'];
      } else if (clientRole === 'partner') {
        this.autoDeExoneracionUploadedPartner = this.documentsArray['auto-exoneracion'];
      }
    });
  }

  public verifySignature(client: User): void {
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
        user: client
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data?.result) {
        if (this.case.user_id === client.id) {
          this.case.client.signature_verified_at = result?.data.created_at;
        } else {
          this.case.partner.signature_verified_at = result?.data.created_at;
        }
      }
    });
  }

  public sendSignedContract(): void {
    this.sendingContract = true;
    this.caseDocumentService.sendSignedContract(this.case.id).pipe(finalize(() => this.sendingContract = false))
      .subscribe(
        res => this.toastr.success(this.translate.instant('CASES.single.successfully_sent')),
        err => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  regenerateContract() {
    let contractToRegen: CaseContract;
    this.case.contracts.every(contract => {
      if (contract.signature) {
        contractToRegen = contract;
        return false;
      }
      return true;
    });

    if ( ! contractToRegen) {
      contractToRegen = this.case.contracts[0];
    }

    this.regeneratingContract = true;
    this.caseDocumentService.regenerateContract(this.case.id, contractToRegen.id)
      .pipe(finalize(() => this.regeneratingContract = false))
      .subscribe(
        next => {
          this.latestContract = next.data;
          this.toastr.success(
            this.translate.instant('CASES.single.regenerate-contract-response'),
            this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.regenerate-contract-error'),
            this.translate.instant('SHARED.error')
          );
        }
      );
  }

  public resignContract($event: MouseEvent, type: string, clientType: string): void {
    $event.preventDefault();
    const data            = {
      type,
      client_type: clientType
    };
    this.requestingResign = true;
    this.caseDocumentService.resignContract(this.case.id, data)
      .pipe(finalize(() => this.requestingResign = false))
      .subscribe(
        next => {
          this.toastr.success(
            this.translate.instant('CASES.single.resign-contract-response'),
            this.translate.instant('SHARED.success')
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.resign-contract-error'),
            this.translate.instant('SHARED.error')
          );
        }
      );
  }

  public sendAutoDeConscursoDoc(clientRole: 'client' | 'partner'): void {
    this.caseDocumentService.sendAutoDeConcurso(this.case.uuid, clientRole).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public sendAutoDeExoneracionDoc(clientRole: 'client' | 'partner') {
    this.caseDocumentService.sendAutoDeExoneracion(this.case.uuid, clientRole).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public resendAdvicePackEmail(regenerate: boolean): void {
    this.caseDocumentService.resendAdvicePack(this.case.id, regenerate).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public requestDebtCancellationRequestSignature(clientRole: 'client' | 'partner'): void {
    this.caseDocumentService.requestDebtCancellationRequestSignature(this.case.id, clientRole).subscribe(res => {
      this.toastr.success(
        this.translate.instant(
          'CASES.single.documents.debt_cancellation_request.request_signature.response.success')
      );
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }

  public sendEmailToExperian() {
    this.caseDocumentService.sendExperianEmail(this.case.id).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public previewDebtExoneration(clientCase: Case): void {
    window.top.location.href = this.clientAppUrl + '/public/sign-debt-exoneration?uuid=' + clientCase.uuid + '&sign=';
  }

  public requestDebtExonerationSignature(channel: 'sms' | 'email'): void {
    this.caseDocumentService.requestDebtExonerationSignature(this.case.id, { channel }).subscribe(res => {
      this.toastr.success(
        this.translateService.instant(
          'CASES.single.documents.send_debt_exoneration.request_signature.response.success')
      );
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public getDebtExonerationDocument(clientCase: Case): void {
    this.caseDocumentService.getCaseDocument(clientCase.id, 'debt-exoneration', clientCase.user_id, ['files'])
      .subscribe(response => this.debtExonerationDocument = response.data);
  }
}
