<mat-dialog-content>
  <h5 mat-dialog-title>Error</h5>
  <div mat-dialog-content class="pt-5 pb-5">
    {{"CARD-INFO.error-100-100-700" | translate}}
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close style="background-color: #222d65; color: white">
      {{"SHARED.close" | translate}}
    </button>
  </div>
</mat-dialog-content>
