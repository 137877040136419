<div class="d-flex flex-row justify-content-between align-items-center">
  <h2>{{ 'CASES.single.general.relation_list.case-departments-assignment-logs.heading' | translate }}</h2>
</div>
<div class="pt-4 pl-4" *ngIf="isLoading">
  <mat-spinner class="m-0 m-auto"></mat-spinner>
</div>
<app-empty-dataset [show]="!isLoading && !caseDepartmentsAssignmentLogs?.length"
                   [message]="'CASES.single.general.relation_list.case-departments-assignment-logs.empty_dataset' | translate">
</app-empty-dataset>
<div class="row m-2" *ngFor="let statusLog of caseDepartmentsAssignmentLogs" [hidden]="isLoading">
  <div class="card col-12">
    <div class="card-body">
      <span *ngIf="statusLog.user_unassigned && !statusLog.user_assigned">
        {{ statusLog.user_unassigned.name }} {{'CASES.single.general.relation_list.case-departments-assignment-logs.user_unassigned' | translate}}: {{ statusLog.department.name }}.
      </span>
      <span *ngIf="statusLog.user_assigned && !statusLog.user_unassigned">
        {{ statusLog.user_assigned.name }} {{'CASES.single.general.relation_list.case-departments-assignment-logs.user_assigned' | translate}}: {{ statusLog.department.name }}.
      </span>
      <span *ngIf="statusLog.user_assigned && statusLog.user_unassigned">
        {{ statusLog.user_assigned.name }} {{'CASES.single.general.relation_list.case-departments-assignment-logs.user_assigned' | translate}}: {{ statusLog.department.name }}
        , {{ statusLog.user_unassigned.name }} {{'CASES.single.general.relation_list.case-departments-assignment-logs.user_unassigned' | translate}}: {{ statusLog.department.name }}.
      </span>
      <br>
      <span *ngIf="statusLog.user_changed_by">
        {{ ' ' }} {{ 'CASES.single.general.relation_list.case-departments-assignment-logs.user_changed_by' | translate }} {{ statusLog.user_changed_by.first_name }} {{ statusLog.user_changed_by.last_name }}
      </span>
      <p>{{ statusLog.created_at| toDateObject | date: 'medium' }}</p>
    </div>
  </div>
</div>

